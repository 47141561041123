import { Address, PublicClient } from 'viem'
import BN from 'bignumber.js'
import { BIG_TWO, BIG_ZERO } from '@pulsex/utils/bigNumber'
import { ChainId } from '@pulsex/chains'
import { getFarmsPrices } from './farmPrices'
import { fetchPublicFarmsData } from './fetchPublicFarmData'
import { SerializedFarmConfig } from './types'
import { getFullDecimalMultiplier } from './getFullDecimalMultiplier'

const evmNativeStableLpMap = {
  [ChainId.PULSECHAIN]: {
    address: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    wNative: 'WPLS',
    stable: 'DAI',
  },
  [ChainId.PULSECHAIN_TESTNET]: {
    address: '0xA2D510bf42D2B9766DB186F44a902228E76ef262',
    wNative: 'WPLS',
    stable: 'tDAI',
  },
}

export const getTokenAmount = (balance: BN, decimals: number) => {
  return balance.div(getFullDecimalMultiplier(decimals))
}

export type FetchFarmsParams = {
  farms: SerializedFarmConfig[]
  provider: ({ chainId }: { chainId: ChainId }) => PublicClient
  isTestnet: boolean
  masterChefAddress: string
  chainId: ChainId
  totalAllocPoint: bigint
}

export async function farmV2FetchFarms({
  farms,
  provider,
  isTestnet,
  masterChefAddress,
  chainId,
  totalAllocPoint,
}: FetchFarmsParams) {

  const [poolInfos, lpDataResults] = await Promise.all([
    fetchMasterChefData(farms, isTestnet, provider, masterChefAddress),
    fetchPublicFarmsData(farms, chainId, provider, masterChefAddress),
  ])

  const lpData = lpDataResults.map(formatClassicFarmResponse)

  const farmsData = farms.map((farm, index) => {
    try {
      return {
        ...farm,
        ...(getClassicFarmsDynamicData({
              ...lpData[index],
              token0Decimals: farm.token.decimals,
              token1Decimals: farm.quoteToken.decimals,
            })),
        ...getFarmAllocation({
          allocPoint: poolInfos[index]?.allocPoint,
          totalAllocPoint,
        }),
      }
    } catch (error) {
      console.error(error, farm, index, {
        allocPoint: poolInfos[index]?.allocPoint,
        token0Decimals: farm.token.decimals,
        token1Decimals: farm.quoteToken.decimals,
        totalAllocPoint,
      })
      throw error
    }
  })

  const farmsDataWithPrices = getFarmsPrices(farmsData, evmNativeStableLpMap[chainId], 18)

  return farmsDataWithPrices
}

const masterChefV2Abi = [
  {
		inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		name: "poolInfo",
		outputs: [
      { internalType: "contract IERC20", name: "lpToken", type: "address" },
			{ internalType: "uint256", name: "allocPoint", type: "uint256" },
			{ internalType: "uint256", name: "lastRewardTime", type: "uint256" },
			{ internalType: "uint256", name: "accIncPerShare", type: "uint256" },
		],
		stateMutability: "view",
		type: "function"
	},
  {
		inputs: [],
		name: "poolLength",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function"
	},
  {
		inputs: [],
		name: "totalAllocPoint",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function"
	},
  {
		inputs: [],
		name: "incPerSecond",
		outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
		stateMutability: "view",
		type: "function"
	},
] as const

const masterChefFarmCalls = (farm: SerializedFarmConfig, masterChefAddress: string) => {
  const { pid } = farm

  return pid || pid === 0
    ? ({
        abi: masterChefV2Abi,
        address: masterChefAddress as Address,
        functionName: 'poolInfo',
        args: [BigInt(pid)],
      }) as const
    : null
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export const fetchMasterChefData = async (
  farms: SerializedFarmConfig[],
  isTestnet: boolean,
  provider: ({ chainId }: { chainId: ChainId }) => PublicClient,
  masterChefAddress: string,
) => {
  try {
    const masterChefCalls = farms.map((farm) => masterChefFarmCalls(farm, masterChefAddress))
    const masterChefAggregatedCalls = masterChefCalls.filter(notEmpty)

    const chainId = isTestnet ? ChainId.PULSECHAIN_TESTNET : ChainId.PULSECHAIN
    const masterChefMultiCallResult = await provider({ chainId }).multicall({
      contracts: masterChefAggregatedCalls,
      allowFailure: false,
    })

    let masterChefChunkedResultCounter = 0
    return masterChefCalls.map((masterChefCall) => {
      if (masterChefCall === null) {
        return null
      }
      const data = masterChefMultiCallResult[masterChefChunkedResultCounter]
      masterChefChunkedResultCounter++
      return {
        lpToken: data[0],
        allocPoint: data[1],
        lastRewardTime: data[2],
        accIncPerShare: data[3],
      }
    })
  } catch (error) {
    console.error('MasterChef Pool info data error', error)
    throw error
  }
}

export const fetchMasterChefV2Data = async ({
  provider,
  isTestnet,
  masterChefAddress,
}: {
  provider: ({ chainId }: { chainId: ChainId }) => PublicClient
  isTestnet: boolean
  masterChefAddress: Address
}) => {
  try {
    const chainId = isTestnet ? ChainId.PULSECHAIN_TESTNET : ChainId.PULSECHAIN
    const [poolLength, totalAllocPoint, incPerSecond] = await provider({
      chainId,
    }).multicall({
      contracts: [
        {
          abi: masterChefV2Abi,
          address: masterChefAddress,
          functionName: 'poolLength',
        },
        {
          abi: masterChefV2Abi,
          address: masterChefAddress,
          functionName: 'totalAllocPoint',
        },
        {
          abi: masterChefV2Abi,
          address: masterChefAddress,
          functionName: 'incPerSecond',
        },
      ],
      allowFailure: false,
    })

    return {
      poolLength,
      totalAllocPoint,
      incPerSecond,
    }
  } catch (error) {
    console.error('Get MasterChef data error', error)
    throw error
  }
}

export const fetchMasterChefFarmPoolLength = async (
  chainId: ChainId, 
  provider: ({ chainId }: { chainId: ChainId }) => PublicClient,
  masterchefAddress: Address
) => {
  try {
    const [poolLength] = await provider({
      chainId,
    }).multicall({
      contracts: [
        {
          abi: masterChefV2Abi,
          functionName: 'poolLength',
          address: masterchefAddress,
        },
      ],
      allowFailure: false,
    })

    return poolLength
  } catch (error) {
    console.error('Fetch MasterChef Farm Pool Length Error: ', error)
    return 0
  }
}

type balanceResponse = bigint

export type ClassicLPData = [balanceResponse, balanceResponse, balanceResponse, balanceResponse]

type FormatClassicFarmResponse = {
  tokenBalanceLP: BN
  quoteTokenBalanceLP: BN
  lpTokenBalanceMC: BN
  lpTotalSupply: BN
}

const formatClassicFarmResponse = (farmData: ClassicLPData): FormatClassicFarmResponse => {
  const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceMC, lpTotalSupply] = farmData
  return {
    tokenBalanceLP: new BN(tokenBalanceLP.toString()),
    quoteTokenBalanceLP: new BN(quoteTokenBalanceLP.toString()),
    lpTokenBalanceMC: new BN(lpTokenBalanceMC.toString()),
    lpTotalSupply: new BN(lpTotalSupply.toString()),
  }
}

interface FarmAllocationParams {
  allocPoint?: bigint
  isRegular?: boolean
  totalAllocPoint: bigint
}

const getFarmAllocation = ({
  allocPoint,
  totalAllocPoint,
}: FarmAllocationParams) => {
  const _allocPoint = allocPoint ? new BN(allocPoint.toString()) : BIG_ZERO
  const totalAlloc = totalAllocPoint
  const poolWeight = !!totalAlloc && !!_allocPoint ? _allocPoint.div(totalAlloc.toString()) : BIG_ZERO

  return {
    poolWeight: poolWeight.toString(),
    multiplier: !_allocPoint.isZero() ? `${+_allocPoint.div(10).toString()}X` : `0X`,
  }
}

const getClassicFarmsDynamicData = ({
  lpTokenBalanceMC,
  lpTotalSupply,
  quoteTokenBalanceLP,
  tokenBalanceLP,
  token0Decimals,
  token1Decimals,
}: FormatClassicFarmResponse & {
  token0Decimals: number
  token1Decimals: number
  lpTokenStakedAmount?: string
}) => {
  // Raw amount of token in the LP, including those not staked
  const tokenAmountTotal = getTokenAmount(tokenBalanceLP, token0Decimals)
  const quoteTokenAmountTotal = getTokenAmount(quoteTokenBalanceLP, token1Decimals)

  // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
  const lpTokenRatio =
    !lpTotalSupply.isZero() && !lpTokenBalanceMC.isZero() ? lpTokenBalanceMC.div(lpTotalSupply) : BIG_ZERO

  // // Amount of quoteToken in the LP that are staked in the MC
  const quoteTokenAmountMcFixed = quoteTokenAmountTotal.times(lpTokenRatio)

  // // Total staked in LP, in quote token value
  const lpTotalInQuoteToken = quoteTokenAmountMcFixed.times(BIG_TWO)

  return {
    tokenAmountTotal: tokenAmountTotal.toString(),
    quoteTokenAmountTotal: quoteTokenAmountTotal.toString(),
    lpTotalSupply: lpTotalSupply.toString(),
    lpTotalInQuoteToken: lpTotalInQuoteToken.toString(),
    tokenPriceVsQuote:
      !quoteTokenAmountTotal.isZero() && !tokenAmountTotal.isZero()
        ? quoteTokenAmountTotal.div(tokenAmountTotal).toString()
        : BIG_ZERO.toString(),
    lpTokenStakedAmount: lpTokenBalanceMC.toString(),
  }
}
