import { ChainId } from '@pulsex/chains'
import SwapWarningTokensConfig from 'config/constants/swapWarningTokens'
import SafemoonWarning from './SafemoonWarning'

const { safemoon } =
  SwapWarningTokensConfig[ChainId.PULSECHAIN_TESTNET]

const BSC_WARNING_LIST = {
  [safemoon.address]: {
    symbol: safemoon.symbol,
    component: <SafemoonWarning />,
  },
}

export default BSC_WARNING_LIST
