/* eslint-disable no-await-in-loop */
import { PCS_V2_START } from 'config/constants/info'
import { gql } from 'graphql-request'
import { useEffect, useState } from 'react'
import { ChartEntry } from 'state/info/types'
import { infoClientWithChain } from 'utils/graphql'
import { fetchChartData, mapDayData } from '../helpers'
import { PulsexDayDatasResponse } from '../types'

/**
 * Data for displaying Liquidity and Volume charts on Overview page
 */
const PULSEX_DAY_DATAS = gql`
  query overviewCharts($startTime: Int!, $skip: Int!) {
    pulsexDayDatas(first: 1000, skip: $skip, where: { date_gt: $startTime }, orderBy: date, orderDirection: asc) {
      date
      dailyVolumeUSD
      totalLiquidityUSD
    }
  }
`

const getOverviewChartData = async (
  chainId: number,
  protocol: string,
  skip: number
): Promise<{ data?: ChartEntry[]; error: boolean }> => {
  try {
    const { pulsexDayDatas } = await infoClientWithChain(chainId, protocol).request<PulsexDayDatasResponse>(PULSEX_DAY_DATAS, {
      startTime: PCS_V2_START,
      skip,
    })
    const data = pulsexDayDatas.map(mapDayData)
    return { data, error: false }
  } catch (error) {
    console.error('Failed to fetch overview chart data', error)
    return { error: true }
  }
}

/**
 * Fetch historic chart data
 */
const useFetchGlobalChartData = (chainId: number, protocol: string): {
  error: boolean
  data: ChartEntry[] | undefined
} => {
  const [overviewChartData, setOverviewChartData] = useState<ChartEntry[] | undefined>()
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      const { data } = await fetchChartData(chainId, protocol, getOverviewChartData)
      if (data) {
        setOverviewChartData(data)
      } else {
        setError(true)
      }
    }
    if (!overviewChartData && !error) {
      fetch()
    }
  }, [chainId, overviewChartData, protocol, error])

  return {
    error,
    data: overviewChartData,
  }
}

export const fetchGlobalChartData = async (chainId: number, protocol: string) => {
  const { data } = await fetchChartData(chainId, protocol, getOverviewChartData)
  return data
}

export default useFetchGlobalChartData
