import { useTranslation } from '@pulsex/localization'
import { Text, Heading, Card, SubMenuItems } from '@pulsex/uikit'
import Page from 'components/Layout/Page'
import PoolTable from 'pages/Info/components/InfoTables/PoolsTable'
import useInfoUserSavedTokensAndPools from 'hooks/useInfoUserSavedTokensAndPoolsList'
import { usePoolDatasQuery } from 'state/info/hooks'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useNonSpamPoolsData } from '../hooks/usePoolsData'
import InfoNav from '../components/InfoNav'

const PoolsOverview: React.FC<{ protocol: string }> = ({ protocol }) => {
  const { t } = useTranslation()
  const { chainId } = useActiveChainId()
  const { poolsData } = useNonSpamPoolsData(protocol)

  const { v1, v2, stable } = useInfoUserSavedTokensAndPools(chainId)
  const { savedPools } = protocol === 'stable' ? stable : protocol === 'v2' ? v2 : v1
  const watchlistPools = usePoolDatasQuery(protocol, savedPools)

  return (
    <Page>
      <SubMenuItems
        items={[
          {
            label: t('V1'),
            href: `#/info`,
          },
          {
            label: t('V2'),
            href: `#/info/v2`,
          },
          {
            label: t('Stable'),
            href: `#/info/stable`,
          },
        ]}
        activeItem={protocol === 'v2' ? `#/info/v2` : protocol === 'stable' ? `#/info/stable` : `#/info`}
      />
      <InfoNav protocol={protocol}/>
      <Heading scale="lg" mb="16px">
        {t('Your Watchlist')}
      </Heading>
      <Card>
        {watchlistPools.length > 0 ? (
          <PoolTable protocol={protocol} poolDatas={watchlistPools} />
        ) : (
          <Text px="24px" py="16px">
            {t('Saved pools will appear here')}
          </Text>
        )}
      </Card>
      <Heading scale="lg" mt="40px" mb="16px" id="info-pools-title">
        {t('All Pools')}
      </Heading>
      <PoolTable protocol={protocol} poolDatas={poolsData} />
    </Page>
  )
}

export default PoolsOverview
