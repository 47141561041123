import { ERC20Token } from '@pulsex/sdk'
import { ChainId } from '@pulsex/chains'

export const plsWarningTokens = {
  safemoon: new ERC20Token(
    ChainId.PULSECHAIN,
    '0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3',
    9,
    'SAFEMOON',
    'Safemoon Token',
    '',
  )
}

export const plsTestnetWarningTokens = {}
