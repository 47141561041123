import { ChainId, INFO_BY_CHAIN, V1_SUBGRAPHS, V2_SUBGRAPHS, STABLESWAP_SUBGRAPHS } from '@pulsex/chains'
import { GraphQLClient } from 'graphql-request'

export const checkIsStableSwap = () => window.location.href.includes('stable')

export const infoClientWithChain = (chainId: number, protocol: string): GraphQLClient => {
  const isStableSwap = checkIsStableSwap()
  if (isStableSwap) return multiChainQueryStableClient[chainId]

  return new GraphQLClient(INFO_BY_CHAIN[chainId][protocol].url)
}

export const v1Clients = {
  [ChainId.PULSECHAIN]: new GraphQLClient(V1_SUBGRAPHS[ChainId.PULSECHAIN].url),
  [ChainId.PULSECHAIN_TESTNET]: new GraphQLClient(V1_SUBGRAPHS[ChainId.PULSECHAIN_TESTNET].url)
}

export const v2Clients = {
  [ChainId.PULSECHAIN]: new GraphQLClient(V2_SUBGRAPHS[ChainId.PULSECHAIN].url),
  [ChainId.PULSECHAIN_TESTNET]: new GraphQLClient(V2_SUBGRAPHS[ChainId.PULSECHAIN_TESTNET].url)
}

export const infoStableSwapClients = {
  [ChainId.PULSECHAIN]: new GraphQLClient(STABLESWAP_SUBGRAPHS[ChainId.PULSECHAIN]),
  [ChainId.PULSECHAIN_TESTNET]: new GraphQLClient(STABLESWAP_SUBGRAPHS[ChainId.PULSECHAIN_TESTNET]),
}

export const multiChainQueryStableClient = {
  [ChainId.PULSECHAIN]: infoStableSwapClients[ChainId.PULSECHAIN],
  [ChainId.PULSECHAIN_TESTNET]: infoStableSwapClients[ChainId.PULSECHAIN_TESTNET],
}
