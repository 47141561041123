import { ChainId } from '@pulsex/chains'
import { Currency, CurrencyAmount, Price, TradeType } from '@pulsex/sdk'
import { SmartRouterTrade } from '@pulsex/smart-router'
import { DAI } from '@pulsex/tokens'
import tryParseCurrencyAmount from '@pulsex/utils/tryParseAmount'
import { useMemo } from 'react'
import { warningSeverity } from 'utils/exchange'
import { multiplyPriceByAmount } from 'utils/prices'
import { computeTradePriceBreakdown } from 'pages/Swap/utils/exchange'
import { useActiveChainId } from './useActiveChainId'
import { useBestAMMTrade } from './useBestAMMTrade'

type UseStablecoinPriceConfig = {
  enabled?: boolean
  hideIfPriceImpactTooHigh?: boolean
}
const DEFAULT_CONFIG: UseStablecoinPriceConfig = {
  enabled: true,
  hideIfPriceImpactTooHigh: false,
}

export function useStablecoinPrice(
  currency?: Currency | null,
  config: UseStablecoinPriceConfig = DEFAULT_CONFIG,
): Price<Currency, Currency> | undefined {
  const { chainId: currentChainId } = useActiveChainId()
  const chainId = currency?.chainId
  const { enabled, hideIfPriceImpactTooHigh } = { ...DEFAULT_CONFIG, ...config }

  const stableCoin = chainId && chainId in ChainId ? DAI[chainId as ChainId] : undefined

  const isStableCoin = currency && stableCoin && currency.wrapped.equals(stableCoin)

  const shouldEnabled = Boolean(
    currency && stableCoin && enabled && currentChainId === chainId && !isStableCoin,
  )

  const amountOut = useMemo(
    () => (stableCoin ? CurrencyAmount.fromRawAmount(stableCoin, 5 * 10 ** stableCoin.decimals) : undefined),
    [stableCoin],
  )

  const { trade } = useBestAMMTrade({
    amount: amountOut,
    currency: currency ?? undefined,
    baseCurrency: stableCoin,
    tradeType: TradeType.EXACT_OUTPUT,
    maxSplits: 0,
    enabled: Boolean(shouldEnabled),
    autoRevalidate: false,
    type: 'offchain',
  })

  const price = useMemo(() => {
    if (!currency || !stableCoin || !enabled) {
      return undefined
    }

    // handle stable coin
    if (isStableCoin) {
      return new Price(stableCoin, stableCoin, '1', '1')
    }

    if (trade) {
      const { inputAmount, outputAmount } = trade as unknown as SmartRouterTrade<TradeType>

      // if price impact is too high, don't show price
      if (hideIfPriceImpactTooHigh) {
        const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade as unknown as SmartRouterTrade<TradeType>)

        if (!priceImpactWithoutFee || warningSeverity(priceImpactWithoutFee) > 2) {
          return undefined
        }
      }

      return new Price(currency, stableCoin, inputAmount.quotient, outputAmount.quotient)
    }

    return undefined
  }, [currency, stableCoin, enabled, isStableCoin, trade, hideIfPriceImpactTooHigh])

  return price
}

export const useStablecoinPriceAmount = (
  currency?: Currency,
  amount?: number,
  config?: UseStablecoinPriceConfig,
): number | undefined => {
  const stablePrice = useStablecoinPrice(currency, { enabled: !!currency, ...config })

  if (amount) {
    if (stablePrice) {
      return multiplyPriceByAmount(stablePrice, amount)
    }
  }
  return undefined
}

/**
 *
 * @param fiatValue string representation of a USD amount
 * @returns CurrencyAmount where currency is stablecoin on active chain
 */
export function useStablecoinAmountFromFiatValue(fiatValue: string | null | undefined) {
  const { chainId } = useActiveChainId()
  const stable = DAI[chainId]

  return useMemo(() => {
    if (fiatValue === null || fiatValue === undefined || !chainId || !stable) {
      return undefined
    }

    // trim for decimal precision when parsing
    const parsedForDecimals = parseFloat(fiatValue).toFixed(stable.decimals).toString()
    try {
      // parse USD string into CurrencyAmount based on stablecoin decimals
      return tryParseCurrencyAmount(parsedForDecimals, stable)
    } catch (error) {
      return undefined
    }
  }, [chainId, fiatValue, stable])
}
