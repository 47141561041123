import { useTranslation } from '@pulsex/localization'
import { TradeType } from '@pulsex/sdk'
import { Flex, GasIcon, MouseoverTooltip, useMatchBreakpoints } from '@pulsex/uikit'
import { SmartRouterTrade } from '@pulsex/smart-router'
import { styled } from 'styled-components'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { LoadingOpacityContainer } from 'components/Loader/LoadingRows'

const GasWrapper = styled(Flex)`
  font-size: 12px;
  align-items: center;
  color: ${({ theme }) => theme.colors.textSubtleDark};
  user-select: none;
`
const StyledGasIcon = styled(GasIcon)`
  color: ${({ theme }) => theme.colors.textSubtleDark};
  margin-right: 4px;
  height: 18px;
`
export const TooltipContainer = styled.div`
  max-width: 256px;
  cursor: default;
  padding: 0.6rem 1rem;

  color: ${({ theme }) => theme.colors.tertiary};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  word-break: break-word;

  background: ${({ theme }) => theme.colors.backgroundAlt2};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
`
export const ResponsiveTooltipContainer = styled(TooltipContainer)<{ origin?: string; width?: string }>`
  background-color: ${({ theme }) => theme.colors.backgroundAlt2};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  padding: 1rem;
  width: ${({ width }) => width ?? 'auto'};

  ${({ theme }) => theme.mediaQueries.xs} {
    transform: scale(0.8);
    transform-origin: top right;
  }
`

export default function GasEstimateBadge({
  trade,
  loading,
}: {
  trade: SmartRouterTrade<TradeType> | undefined | null // dollar amount in active chain's stablecoin
  loading: boolean
  showRoute?: boolean // show route instead of gas estimation summary
  disableHover?: boolean
}) {
  const { t } = useTranslation()
  const nativeCurrency = useNativeCurrency()
  const { isMobile } = useMatchBreakpoints()
  const formattedGasPriceString = trade?.gasEstimateInUSD
    ? trade.gasEstimateInUSD.toFixed(2) === '0.00'
      ? '<$0.01'
      : `$${  trade.gasEstimateInUSD.toFixed(2)}`
    : undefined

  return (
    <MouseoverTooltip 
      isMobile={isMobile}
      text={
        t(`The fee paid to the Network to process your transaction. This must be paid in %asset%.`, { asset: nativeCurrency.symbol })
      }
    >
      <LoadingOpacityContainer $loading={loading}>
        <GasWrapper>
          <StyledGasIcon />
          {formattedGasPriceString ?? null}
        </GasWrapper>
      </LoadingOpacityContainer>
    </MouseoverTooltip>
  )
}
