import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m565.6 372.95c0 2.0514-0.47487 4.0015-1.3754 5.6938l-92.665 160.5c-3.9299 6.8068-11.193 11-19.053 11h-205.91c-7.8598 0-15.123-4.1932-19.053-11l-92.674-160.52c-0.89795-1.6895-1.3668-3.6302-1.3668-5.6785 0-6.6431 5.3853-12.028 12.028-12.028h101.67l25.766 43.765 0.15838 0.2615c3.4384 5.5207 10.673 7.32 16.31 4.0016l0.29171-0.17734c2.5989-1.631 4.4804-4.1969 5.251-7.1758l32.831-126.92 28.191 204.33 0.0427 0.28487c1.0413 6.4281 7.0314 10.882 13.517 9.9868l0.29461-0.0444c4.6956-0.76606 8.5104-4.2347 9.7066-8.8588l39.574-152.98 16.249 27.6 0.16134 0.26612c2.1929 3.5143 6.0472 5.6599 10.204 5.6599h107.82c6.6431 0 12.028 5.3853 12.028 12.028zm-113.09-223.46c7.8598 0 15.123 4.1932 19.053 11l92.671 160.51c0.89617 1.6906 1.3696 3.6396 1.3696 5.6865 0 6.6431-5.3853 12.028-12.028 12.028h-102l-25.766-43.765-0.17735-0.29171c-1.631-2.5989-4.1969-4.4804-7.1758-5.251l-0.2797-0.0689c-6.3409-1.4823-12.738 2.3634-14.378 8.7016l-32.832 126.92-28.19-204.33-0.0443-0.29461c-0.76606-4.6956-4.2347-8.5104-8.8589-9.7066-6.4314-1.6637-12.994 2.2013-14.658 8.6327l-39.575 152.98-16.248-27.599-0.16133-0.26613c-2.1929-3.5143-6.0472-5.6599-10.204-5.6599h-107.49c-6.6431 0-12.028-5.3853-12.028-12.028 0-2.0312 0.45626-3.9524 1.3377-5.6316l92.703-160.56c3.9299-6.8068 11.193-11 19.053-11z"
      />
    </Svg>
  );
};

export default Icon;
