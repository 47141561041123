import { Currency } from "@pulsex/sdk"
import { useMemo } from "react"
import { getStableSwapPools } from "./constants"
import { Address } from "./types"

export function useStableSwapPools(chainId: number) {
  return useMemo(() => (chainId && getStableSwapPools(chainId)) || [], [chainId])
}

export function useFindStablePool({
  chainId,
  tokenA,
  tokenB,
}: {
  chainId: number
  tokenA: Currency | undefined | null
  tokenB: Currency | undefined | null
}) {
  const stablePools = useStableSwapPools(chainId)

  return useMemo(
    () => {
      const foundPair = stablePools.find((stablePool) => {
        return (
          tokenA &&
          tokenB &&
          ((stablePool?.token0?.equals(tokenA.wrapped) && stablePool?.token1?.equals(tokenB.wrapped)) ||
            (stablePool?.token1?.equals(tokenA.wrapped) && stablePool?.token0?.equals(tokenB.wrapped)))
        )
      })
      return foundPair
    },
    [tokenA, tokenB, stablePools],
  )
}

export function useFindStablePoolByPoolAddress({
  chainId,
  poolAddress
}: {
  chainId: number | undefined | null
  poolAddress: Address | undefined | null
}) {
  if (!chainId || !poolAddress) return
  const stablePools = useStableSwapPools(chainId)
  return useMemo(
    () => {
      const foundPair = stablePools.find((stablePool) => {
        return stablePool?.stableSwapAddress === poolAddress
      })
      return foundPair
    },
    [chainId, poolAddress, stablePools],
  )
}
