import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps & { color?: string }> = ({ color = "currentColor", ...props }) => {
  return (
    <Svg viewBox="0 0 32 32" {...props}>
      <path d="m10 8.0335c-0.36819 0-0.66667 0.29848-0.66667 0.66666v6h5.3334v-6c0-0.36818-0.2985-0.66666-0.6667-0.66666h-4z" fill={color} />
      <path d="m8 2.7002c-2.2091 0-4 1.7909-4 4v21.333c0 0.7364 0.59695 1.3334 1.3333 1.3334h13.333c0.7363 0 1.3333-0.597 1.3333-1.3334v-10.667h0.9253c0.8114 0 1.4347 0.7186 1.3199 1.5219l-0.4945 3.462c-0.328 2.2956 1.4533 4.3494 3.7721 4.3494 2.1045 0 3.8105-1.706 3.8105-3.8105v-12.304c0-0.70723-0.2809-1.3855-0.781-1.8856l-4.2762-4.2761-1.8856 1.8856 1.6095 1.6095v3.9344c0 0.3957 0.1758 0.7709 0.4798 1.0243l2.1869 1.8224v8.1895c0 0.6317-0.5121 1.1438-1.1439 1.1438-0.696 0-1.2307-0.6165-1.1323-1.3055l0.4946-3.4621c0.3443-2.4098-1.5256-4.5657-3.9598-4.5657h-0.9253v-8c0-2.2091-1.7909-4-4-4h-8zm9.3333 4c0-0.73638-0.5969-1.3333-1.3333-1.3333h-8c-0.73638 0-1.3333 0.59696-1.3333 1.3333v20h10.667v-20z" clipRule="evenodd" fillRule="evenodd" fill={color} />
    </Svg>
  );
};

export default Icon;
