import { gql } from 'graphql-request'
import { mapBurns, mapMints, mapSwaps } from 'state/info/queries/helpers'
import { BurnResponse, MintResponse, SwapResponse } from 'state/info/queries/types'
import { Transaction } from 'state/info/types'
import { infoClientWithChain } from 'utils/graphql'
/**
 * Transactions of the given pool, used on Pool page
 */
interface TransactionResults {
  mints: MintResponse[]
  swaps: SwapResponse[]
  burns: BurnResponse[]
}

const fetchPoolTransactions = async (chainId: number, protocol: string, address: string): Promise<{ data?: Transaction[]; error: boolean }> => {
  try {
    const query = gql`
      query poolTransactions($address: Bytes!) {
        mints(first: 35, orderBy: timestamp, orderDirection: desc, where: { pair: $address }) {
          id
          timestamp
          pair {
            token0 {
              id
              symbol
            }
            token1 {
              id
              symbol
            }
            ${protocol === 'stable' ? `
            token2 {
              id
              symbol
            }
            ` : ''}
          }
          to
          sender
          amount0
          amount1
          ${protocol === 'stable' ? `amount2` : ''}
          amountUSD
        }
        swaps(first: 35, orderBy: timestamp, orderDirection: desc, where: { pair: $address }) {
          id
          timestamp
          pair {
            token0 {
              id
              symbol
            }
            token1 {
              id
              symbol
            }
            ${protocol === 'stable' ? `
            token2 {
              id
              symbol
            }
            ` : ''}
          }
          from
          amount0In
          amount1In
          amount0Out
          amount1Out
          ${protocol === 'stable' ? `
          amount2In
          amount2Out
          ` : ''}
          amountUSD
        }
        burns(first: 35, orderBy: timestamp, orderDirection: desc, where: { pair: $address }) {
          id
          timestamp
          pair {
            token0 {
              id
              symbol
            }
            token1 {
              id
              symbol
            }
            ${protocol === 'stable' ? `
            token2 {
              id
              symbol
            }
            ` : ''}
          }
          sender
          amount0
          amount1
          ${protocol === 'stable' ? `amount2` : ''}
          amountUSD
        }
      }
    `
    const data = await infoClientWithChain(chainId, protocol).request<TransactionResults>(query, {
      address,
    })
    const mints = data.mints.map(mapMints)
    const burns = data.burns.map(mapBurns)
    const swaps = data.swaps.map(mapSwaps)
    return { data: [...mints, ...burns, ...swaps], error: false }
  } catch (error) {
    console.error(`Failed to fetch transactions for pool ${address}`, error)
    return {
      error: true,
    }
  }
}

export default fetchPoolTransactions
