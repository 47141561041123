import React, { useMemo } from "react";
import { styled } from "styled-components";
import { Box } from "../../components/Box";
import Flex from "../../components/Box/Flex";
import { AtomBox } from "../../components/AtomBox";
import MenuItems from "../../components/MenuItems/MenuItems";
import { Text } from "../../components";
import { useMatchBreakpoints } from "../../contexts";
import PlsxPrice from "../../components/PlsxPrice/PlsxPrice";
import { MENU_HEIGHT } from "./config";
import { NavProps } from "./types";
import LangSelector from "../../components/LangSelector/LangSelector";
import { MenuContext } from "./context";
import DesktopLogo from "./components/DesktopLogo";
import MobileLogo from "./components/MobileLogo";
import packageJson from '../../../../../package.json'

const VersionTag = styled(Text)`
  width: max-content;
  
  text-align: left;
  font-size: 12px;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  };
`;
const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${MENU_HEIGHT}px;
  background-color: transparent;
  transform: translate3d(0, 0, 0);

  padding-left: 16px;
  padding-right: 16px;
`;
const FixedContainer = styled("div").withConfig({
  shouldForwardProp: (props) => !["showMenu"].includes(props),
}) <{ height: number }>`
  position: absolute;
  height: ${({ height }) => `${height}px`};
  width: 100%;
  z-index: 20;
`;
const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  max-width: 100vw;
`;
const Inner = styled.div`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`;

const Menu: React.FC<React.PropsWithChildren<NavProps>> = ({
  linkComponent = "a",
  userMenu,
  globalMenu,
  isDark,
  currentLang,
  setLang,
  plsxPriceUsd,
  links,
  hamburgerLinks,
  activeItem,
  activeSubItem,
  activeHamburgerItem,
  langs,
  children,
}) => {
  const { isMobile } = useMatchBreakpoints();
  const version = packageJson?.version
  const providerValue = useMemo(() => ({ linkComponent }), [linkComponent]);

  return (
    <MenuContext.Provider value={providerValue}>
      <FixedContainer height={MENU_HEIGHT}>
        <StyledNav>
          <Flex alignItems="center">
            {isMobile ? (
              <Flex width="32px" flexDirection="column">
                <MobileLogo isDark={isDark} href={"/"} />
                <VersionTag
                  as="a"
                  target="_blank"
                  href={"https://app.pulsex.com"}
                >v{version}
                </VersionTag>
              </Flex>
            ) : (
              <Flex width="130px" flexDirection="column">
                <DesktopLogo isDark={isDark} href={"/"} />
                <VersionTag
                  marginTop="-10px"
                  as="a"
                  target="_blank"
                  href={"https://app.pulsex.com"}
                >v{version}
                </VersionTag>
              </Flex>
            )}
            <AtomBox display={{ xs: "none", lg: "block" }}>
              <MenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} ml="24px" />
            </AtomBox>
          </Flex>
          <Flex alignItems="center" height="100%">
            <AtomBox mr="12px" display={{ xs: "none", lg: "block" }}>
              <PlsxPrice showSkeleton={false} plsxPriceUsd={plsxPriceUsd} />
            </AtomBox>
            <Box>
              <LangSelector
                currentLang={currentLang}
                langs={langs}
                setLang={setLang}
                buttonScale="xs"
                color="textSubtle"
                hideLanguage
              />
            </Box>
            {globalMenu} {userMenu}
            <AtomBox display={{ xs: "block", lg: "none" }}>
              <MenuItems items={hamburgerLinks} activeItem={activeHamburgerItem} activeSubItem={activeSubItem} ml="12px" />
            </AtomBox>
          </Flex>
        </StyledNav>
      </FixedContainer>
      <BodyWrapper >
        <Inner>{children}</Inner>
      </BodyWrapper>
    </MenuContext.Provider>
  );
};

export default Menu;
