import { ChainId } from '@pulsex/chains';
import { ERC20Token, WPLS } from '@pulsex/sdk';

// src/common.ts
var PLSX_PULSECHAIN = new ERC20Token(
  ChainId.PULSECHAIN,
  "0x95B303987A60C71504D99Aa1b13B4DA07b0790ab",
  18,
  "PLSX",
  "PulseX",
  "https://pulsex.com/"
);
var PLSX_PULSECHAIN_TESTNET = new ERC20Token(
  ChainId.PULSECHAIN_TESTNET,
  "0x8a810ea8B121d08342E9e7696f4a9915cBE494B7",
  18,
  "PLSX",
  "PulseX",
  "https://pulsex.com/"
);
var INC_PULSECHAIN = new ERC20Token(
  ChainId.PULSECHAIN,
  "0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d",
  18,
  "INC",
  "Incentive"
);
var INC_PULSECHAIN_TESTNET = new ERC20Token(
  ChainId.PULSECHAIN_TESTNET,
  "0x6eFAfcb715F385c71d8AF763E8478FeEA6faDF63",
  18,
  "INC",
  "Incentive"
);
var eDAI_PULSECHAIN = new ERC20Token(
  ChainId.PULSECHAIN,
  "0xefD766cCb38EaF1dfd701853BFCe31359239F305",
  18,
  "DAI",
  "DAI from Ethereum"
);
var tDAI_PULSECHAIN_TESTNET = new ERC20Token(
  ChainId.PULSECHAIN_TESTNET,
  "0x826e4e896CC2f5B371Cd7Bb0bd929DB3e3DB67c0",
  18,
  "tDAI",
  "DAI Mock"
);
var eUSDC_PULSECHAIN = new ERC20Token(
  ChainId.PULSECHAIN,
  "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07",
  6,
  "USDC",
  "USDC from Ethereum",
  ""
);
var eUSDC_PULSECHAIN_TESTNET = new ERC20Token(
  ChainId.PULSECHAIN_TESTNET,
  "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  6,
  "USDC",
  "USDC",
  ""
);
var NATIVE_USDT_PULSECHAIN = new ERC20Token(
  ChainId.PULSECHAIN,
  "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  6,
  "USDT",
  "USDT",
  ""
);
var NATIVE_USDT_PULSECHAIN_TESTNET = new ERC20Token(
  ChainId.PULSECHAIN_TESTNET,
  "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  6,
  "USDT",
  "USDT",
  ""
);
var PLSX = {
  [ChainId.PULSECHAIN]: PLSX_PULSECHAIN,
  [ChainId.PULSECHAIN_TESTNET]: PLSX_PULSECHAIN_TESTNET
};
var DAI = {
  [ChainId.PULSECHAIN]: eDAI_PULSECHAIN,
  [ChainId.PULSECHAIN_TESTNET]: tDAI_PULSECHAIN_TESTNET
};
var USDC = {
  [ChainId.PULSECHAIN]: eUSDC_PULSECHAIN,
  [ChainId.PULSECHAIN_TESTNET]: eUSDC_PULSECHAIN_TESTNET
};
var NATIVE_USDT = {
  [ChainId.PULSECHAIN]: NATIVE_USDT_PULSECHAIN,
  [ChainId.PULSECHAIN_TESTNET]: NATIVE_USDT_PULSECHAIN_TESTNET
};
var INC = {
  [ChainId.PULSECHAIN]: INC_PULSECHAIN,
  [ChainId.PULSECHAIN_TESTNET]: INC_PULSECHAIN_TESTNET
};
var plsTokens = {
  wpls: WPLS[ChainId.PULSECHAIN],
  plsx: PLSX_PULSECHAIN,
  // pls here points to the wpls contract. Wherever the currency PLS is required, conditional checks for the symbol 'PLS' can be used
  pls: new ERC20Token(
    ChainId.PULSECHAIN,
    "0xA1077a294dDE1B09bB078844df40758a5D0f9a27",
    18,
    "PLS",
    "PLS",
    "https://www.pulsechain.com/"
  ),
  inc: new ERC20Token(
    ChainId.PULSECHAIN,
    "0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d",
    18,
    "INC",
    "Incentive",
    ""
  ),
  hex: new ERC20Token(
    ChainId.PULSECHAIN,
    "0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39",
    8,
    "HEX",
    "HEX",
    "https://hex.com"
  ),
  dai: new ERC20Token(
    ChainId.PULSECHAIN,
    "0xefD766cCb38EaF1dfd701853BFCe31359239F305",
    18,
    "DAI",
    "DAI from Ethereum",
    ""
  ),
  usdc: new ERC20Token(
    ChainId.PULSECHAIN,
    "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07",
    6,
    "USDC",
    "USDC from Ethereum",
    ""
  ),
  usdt: new ERC20Token(
    ChainId.PULSECHAIN,
    "0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f",
    6,
    "USDT",
    "USDT from Ethereum",
    ""
  ),
  ehex: new ERC20Token(
    ChainId.PULSECHAIN,
    "0x57fde0a71132198BBeC939B98976993d8D89D225",
    8,
    "HEX",
    "HEX from Ethereum",
    ""
  ),
  weth: new ERC20Token(
    ChainId.PULSECHAIN,
    "0x02DcdD04e3F455D838cd1249292C58f3B79e3C3C",
    18,
    "WETH",
    "WETH from Ethereum",
    ""
  ),
  wbtc: new ERC20Token(
    ChainId.PULSECHAIN,
    "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
    8,
    "WBTC",
    "Wrapped BTC",
    ""
  ),
  ewbtc: new ERC20Token(
    ChainId.PULSECHAIN,
    "0xb17D901469B9208B17d916112988A3FeD19b5cA1",
    8,
    "WBTC",
    "Wrapped BTC from Ethereum",
    ""
  ),
  epepe: new ERC20Token(
    ChainId.PULSECHAIN,
    "0x4d3AeA379b7689E0Cb722826C909Fab39E54123d",
    18,
    "PEPE",
    "Pepe from Ethereum",
    ""
  ),
  pepe: new ERC20Token(
    ChainId.PULSECHAIN,
    "0x6982508145454Ce325dDbE47a25d4ec3d2311933",
    18,
    "PEPE",
    "Pepe",
    ""
  ),
  link: new ERC20Token(
    ChainId.PULSECHAIN,
    "0x514910771AF9Ca656af840dff83E8264EcF986CA",
    18,
    "LINK",
    "ChainLink Token",
    ""
  ),
  elink: new ERC20Token(
    ChainId.PULSECHAIN,
    "0xEe2D275Dbb79c7871F8C6eB2A4D0687dD85409D1",
    18,
    "LINK",
    "ChainLink Token from Ethereum",
    ""
  ),
  uni: new ERC20Token(
    ChainId.PULSECHAIN,
    "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
    18,
    "UNI",
    "Uniswap",
    ""
  ),
  euni: new ERC20Token(
    ChainId.PULSECHAIN,
    "0x3f105121A10247DE9a92e818554DD5Fcd2063AE7",
    18,
    "UNI",
    "Uniswap from Ethereum",
    ""
  )
};
var plsTestnetTokens = {
  wpls: WPLS[ChainId.PULSECHAIN_TESTNET],
  plsx: PLSX_PULSECHAIN_TESTNET,
  // pls here points to the wpls contract. Wherever the currency tPLS is required, conditional checks for the symbol 'tPLS' can be used
  pls: new ERC20Token(
    ChainId.PULSECHAIN_TESTNET,
    "0x70499adEBB11Efd915E3b69E700c331778628707",
    18,
    "tPLS",
    "tPLS",
    "https://www.pulsechain.com/"
  ),
  inc: new ERC20Token(
    ChainId.PULSECHAIN_TESTNET,
    "0x6eFAfcb715F385c71d8AF763E8478FeEA6faDF63",
    18,
    "INC",
    "Incentive",
    ""
  ),
  hex: new ERC20Token(
    ChainId.PULSECHAIN_TESTNET,
    "0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39",
    18,
    "HEX",
    "HEX",
    "https://hex.com"
  ),
  dai: new ERC20Token(
    ChainId.PULSECHAIN_TESTNET,
    "0x826e4e896CC2f5B371Cd7Bb0bd929DB3e3DB67c0",
    18,
    "tDAI",
    "DAI Mock",
    ""
  ),
  usdc: new ERC20Token(
    ChainId.PULSECHAIN_TESTNET,
    "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    6,
    "USDC",
    "USDC",
    ""
  ),
  usdt: new ERC20Token(
    ChainId.PULSECHAIN_TESTNET,
    "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    6,
    "USDT",
    "USDT",
    ""
  )
};

// src/allTokens.ts
var allTokens = {
  [ChainId.PULSECHAIN]: plsTokens,
  [ChainId.PULSECHAIN_TESTNET]: plsTestnetTokens
};

// src/helpers.ts
var EMPTY_LIST = {
  [ChainId.PULSECHAIN]: {},
  [ChainId.PULSECHAIN_TESTNET]: {}
};
function serializeTokens(unserializedTokens) {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: unserializedTokens[key].serialize };
  }, {});
  return serializedTokens;
}
function getTokensByChain(chainId) {
  if (!chainId) {
    return [];
  }
  const tokenMap = allTokens[chainId];
  return Object.values(tokenMap);
}

export { DAI, EMPTY_LIST, INC, INC_PULSECHAIN, INC_PULSECHAIN_TESTNET, NATIVE_USDT, NATIVE_USDT_PULSECHAIN, NATIVE_USDT_PULSECHAIN_TESTNET, PLSX, PLSX_PULSECHAIN, PLSX_PULSECHAIN_TESTNET, USDC, eDAI_PULSECHAIN, eUSDC_PULSECHAIN, eUSDC_PULSECHAIN_TESTNET, getTokensByChain, plsTestnetTokens, plsTokens, serializeTokens, tDAI_PULSECHAIN_TESTNET };
