import { BIG_ZERO } from '@pulsex/utils/bigNumber'
import BigNumber from 'bignumber.js'
import { useCallback, useMemo } from 'react'
import { useFarms } from 'state/farms/hooks'

export function useFarmV2Multiplier() {
  const { regularIncPerSecond, totalAllocPoint } = useFarms()

  const totalMultipliers = useMemo(
    () => (Number.isFinite(+totalAllocPoint) ? (+totalAllocPoint / 10).toString() : '-'),
    [totalAllocPoint],
  )

  return {
    totalMultipliers,
    getFarmIncPerSecond: useCallback(
      (poolWeight?: BigNumber) => {
        const farmIncPerSecondNum =
          poolWeight && regularIncPerSecond ? poolWeight.times(regularIncPerSecond) : BIG_ZERO

        const farmIncPerSecond = farmIncPerSecondNum.isZero()
          ? '0'
          : farmIncPerSecondNum.lt(0.000001)
          ? '<0.000001'
          : `~${farmIncPerSecondNum.toFixed(6)}`
        return farmIncPerSecond
      },
      [regularIncPerSecond],
    ),
    getNumberFarmIncPerSecond: useCallback(
      (poolWeight?: BigNumber) => {
        const farmIncPerSecondNum =
          poolWeight && regularIncPerSecond ? poolWeight.times(regularIncPerSecond) : BIG_ZERO
        return farmIncPerSecondNum.toNumber()
      },
      [regularIncPerSecond],
    ),
  }
}
