import { ChainId } from '@pulsex/chains'
import { BIG_ZERO } from '@pulsex/utils/bigNumber'
import BigNumber from 'bignumber.js'
import chunk from 'lodash/chunk.js'
import { erc20Abi } from 'viem'
import { plsxLabAbi } from '../abis/plsxLab'
import { getPoolsConfig } from '../constants'
import { OnChainProvider } from '../types'

export const fetchPoolsBlockLimits = async (
  chainId: ChainId,
  provider: OnChainProvider,
) => {
  const poolsConfig = getPoolsConfig(chainId)
  if (!poolsConfig) {
    return null
  }

  const poolInfo = poolsConfig.flatMap((poolConfig) => {
    return [
      {
        abi: plsxLabAbi,
        address: poolConfig.contractAddress,
        functionName: 'poolInfo',
        args: [poolConfig.poolId],
      },
    ] as const
  })

  const client = provider({ chainId })

  const poolInfoResult = await client.multicall({
    contracts: poolInfo,
    allowFailure: false,
  }) as any

  return poolsConfig.map((poolConfig, index) => {
    const { startTime, endTime } = poolInfoResult[index]

    return {
      poolId: poolConfig.poolId,
      startTime: Number(startTime),
      endTime: Number(endTime),
    }
  })
}

export const fetchPoolsTotalStaking = async (chainId: ChainId, provider: OnChainProvider) => {
  const poolsConfig = getPoolsConfig(chainId)
  if (!poolsConfig) {
    return null
  }
  const poolsBalanceOf = poolsConfig.map(({ contractAddress, stakingToken }) => {
    return {
      abi: erc20Abi,
      address: stakingToken.address,
      functionName: 'balanceOf',
      args: [contractAddress],
    } as const
  })

  const client = provider({ chainId })
  const poolsTotalStaked = await client.multicall({
    contracts: poolsBalanceOf,
    allowFailure: false,
  })

  return poolsConfig.map((p, index) => ({
    poolId: p.poolId,
    totalStaked: new BigNumber(poolsTotalStaked[index].toString()).toJSON(),
  }))
}

interface FetchingPoolsStakingLimitsParams {
  poolsWithStakingLimit: number[]
  chainId: ChainId
  provider: OnChainProvider
}

export const fetchPoolsStakingLimits = async ({
  poolsWithStakingLimit,
  chainId,
  provider,
}: FetchingPoolsStakingLimitsParams): Promise<{
  [key: string]: { stakingLimit: BigNumber; userLimitEnd: number }
}> => {
  const poolsConfig = getPoolsConfig(chainId)
  if (!poolsConfig) {
    throw new Error(`No pools found on chain ${chainId}`)
  }

  const validPools = poolsConfig
    .filter((p) => p.stakingToken.symbol !== 'PLS' && !p.isFinished)
    .filter((p) => !poolsWithStakingLimit.includes(p.poolId))


  const poolInfo = poolsConfig.flatMap((poolConfig) => {
    return [
      {
        abi: plsxLabAbi,
        address: poolConfig.contractAddress,
        functionName: 'poolInfo',
        args: [poolConfig.poolId],
      },
    ] as const
  })

  // Get the staking limit for each valid pool
  const poolStakingCalls = validPools
    .map((validPool) => {
      const contractAddress = validPool.contractAddress
      return (['baseUserLimit'] as const).map((method) => ({
        abi: plsxLabAbi,
        address: contractAddress,
        functionName: method,
      } as const))
    })
    .flat()

  const client = provider({ chainId })

  const [poolInfoResult, poolStakingResultRaw] = await Promise.all([
    client.multicall({
      contracts: poolInfo,
      allowFailure: false,
    }) as any,
    client.multicall({
      contracts: poolStakingCalls,
      allowFailure: false,
    }),
  ])

  if (!poolInfoResult[0]) {
    return {}
  }
  const { userLimitEndTime } = poolInfoResult[0]
  const chunkSize = poolStakingCalls.length / validPools.length
  const poolStakingChunkedResultRaw = chunk(poolStakingResultRaw.flat(), chunkSize)
  return poolStakingChunkedResultRaw.reduce((accum, stakingLimitRaw, index) => {
    // eslint-disable-next-line no-param-reassign
    stakingLimitRaw[1] = userLimitEndTime
    const hasUserLimit = true
    const stakingLimit = hasUserLimit && stakingLimitRaw[0] ? new BigNumber(stakingLimitRaw[0].toString()) : BIG_ZERO
    const userLimitEnd = stakingLimitRaw[1] ? Number(stakingLimitRaw[1]) : 0

    return {
      ...accum,
      [validPools[index].poolId]: stakingLimit,
      userLimitEnd,
    }
  }, {})
}
