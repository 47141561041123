import { useTranslation } from '@pulsex/localization'
import { CurrencyAmount, Percent, Token } from '@pulsex/sdk'
import { StableSwapPool } from '@pulsex/stable-swap-sdk'
import {
  AutoColumn,
  Card,
  CardBody,
  CardProps,
  Text,
} from '@pulsex/uikit'
import { MultiCurrencyLogo } from '@pulsex/widgets-internal'
import { useState } from 'react'
import { styled } from 'styled-components'
import { BIG_INT_ZERO } from 'config/constants/exchange'
import { RowBetween, RowFixed } from 'components/Layout/Row'

const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

interface PositionCardProps extends CardProps {
  pool: StableSwapPool
  isThreePool: boolean
  totalSupply?: CurrencyAmount<Token>
  userLpBalance?: CurrencyAmount<Token>
  userTokenAmounts?: CurrencyAmount<Token>[]
}

export function StableSwapMinimalPositionCard({
  pool,
  isThreePool,
  totalSupply,
  userLpBalance,
  userTokenAmounts,
}: PositionCardProps) {
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)

  const poolTokenPercentage =
    userLpBalance && totalSupply && totalSupply.quotient >= userLpBalance?.quotient
      ? new Percent(userLpBalance?.quotient, totalSupply.quotient)
      : undefined

  return (
    <>
      {userLpBalance && userLpBalance.quotient > BIG_INT_ZERO && userTokenAmounts && (
        <Card>
          <CardBody>
            <AutoColumn gap="16px">
              <FixedHeightRow>
                <RowFixed>
                  <Text color="secondary" bold>
                    {t('LP tokens in your wallet')}
                  </Text>
                </RowFixed>
              </FixedHeightRow>
              <FixedHeightRow onClick={() => setShowMore(!showMore)}>
                <RowFixed>
                  <MultiCurrencyLogo
                    currency0={pool.token0}
                    currency1={pool.token1}
                    currency2={pool?.token2}
                    margin size={20}
                  />
                  <Text small color="textSubtle">
                    {pool.token0.symbol}:{pool.token1.symbol}{pool?.token2 && `:${pool?.token2.symbol}`}
                  </Text>
                </RowFixed>
                <RowFixed>
                  <Text>{userLpBalance ? userLpBalance.toSignificant(8) : '-'}</Text>
                </RowFixed>
              </FixedHeightRow>
              <AutoColumn gap="4px">
                <FixedHeightRow>
                  <Text color="textSubtle" small>
                    {t('Share of Pool')}:
                  </Text>
                  <Text>{poolTokenPercentage ? `${poolTokenPercentage.toFixed(6)}%` : '-'}</Text>
                </FixedHeightRow>
                <FixedHeightRow>
                  <Text color="textSubtle" small>
                    {t('Pooled %asset%', { asset: pool.token0.symbol })}:
                  </Text>
                  {userTokenAmounts[0] ? (
                    <RowFixed>
                      <Text ml="6px">{userTokenAmounts[0].toSignificant(8)}</Text>
                    </RowFixed>
                  ) : (
                    '-'
                  )}
                </FixedHeightRow>
                <FixedHeightRow>
                  <Text color="textSubtle" small>
                    {t('Pooled %asset%', { asset: pool.token1.symbol })}:
                  </Text>
                  {userTokenAmounts[1] ? (
                    <RowFixed>
                      <Text ml="6px">{userTokenAmounts[1].toSignificant(8)}</Text>
                    </RowFixed>
                  ) : (
                    '-'
                  )}
                </FixedHeightRow>
                {isThreePool && pool?.token2 && (
                  <FixedHeightRow>
                    <Text color="textSubtle" small>
                      {t('Pooled %asset%', { asset: pool.token2.symbol })}:
                    </Text>
                    {userTokenAmounts[2] ? (
                      <RowFixed>
                        <Text ml="6px">{userTokenAmounts[2].toSignificant(8)}</Text>
                      </RowFixed>
                    ) : (
                      '-'
                    )}
                  </FixedHeightRow>
                )}
              </AutoColumn>
            </AutoColumn>
          </CardBody>
        </Card>
      )}
    </>
  )
}
