import { Currency } from '@pulsex/sdk'
import { PLSX, DAI } from '@pulsex/tokens'
import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useProtocol } from 'state/user/smartRouter'
import { currencyId } from 'utils/currencyId'

export const useCurrencySelectRoute = () => {
  const native = useNativeCurrency()
  const navigate = useNavigate()
  const { chainId } = useActiveChainId()

  const {
    currencyIdA,
    currencyIdB,
  } = useParams<{ currencyIdA?: string; currencyIdB?: string }>() || {
    currencyIdA: native.symbol,
    currencyIdB: PLSX[chainId]?.address ?? DAI[chainId]?.address
  }

  const [protocol] = useProtocol()

  const handleCurrencyASelect = useCallback(
    (currencyA_: Currency) => {
      const newCurrencyIdA = currencyId(currencyA_)
      if (newCurrencyIdA === currencyIdB) {
        navigate(`/add/${protocol}/${currencyIdB}/${currencyIdA}`)
      } else if (currencyIdB) {
        navigate(`/add/${protocol}/${newCurrencyIdA}/${currencyIdB}`)
      } else {
        navigate(`/add/${protocol}/${newCurrencyIdA}`)
      }
    },
    [currencyIdB, currencyIdA, protocol, navigate],
  )
  const handleCurrencyBSelect = useCallback(
    (currencyB_: Currency) => {
      const newCurrencyIdB = currencyId(currencyB_)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          navigate(`/add/${protocol}/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          navigate(`/add/${protocol}/${newCurrencyIdB}`)
        }
      } else {
        navigate(`/add/${protocol}/${currencyIdA || native.symbol}/${newCurrencyIdB}`)
      }
    },
    [currencyIdA, currencyIdB, native, protocol, navigate],
  )

  return {
    handleCurrencyASelect,
    handleCurrencyBSelect,
  }
}
