import { createAction } from '@reduxjs/toolkit'

export enum Field {
  LIQUIDITY_PERCENT = 'LIQUIDITY_PERCENT',
  LIQUIDITY = 'LIQUIDITY',
  CURRENCY_A = 'CURRENCY_A',
  CURRENCY_B = 'CURRENCY_B',
  CURRENCY_C = 'CURRENCY_C',
}

export const token0Input = createAction<{ token0Value: string }>('stableswap/token0Input')
export const token1Input = createAction<{ token1Value: string }>('stableswap/token1Input')
export const token2Input = createAction<{ token2Value: string }>('stableswap/token2Input')

export const typeInput = createAction<{ field: Field; typedValue: string }>('stableswap/typeInputBurn')
export const resetMintState = createAction<void>('stableswap/resetMintState')
