import { useTranslation } from '@pulsex/localization'
import { Percent } from '@pulsex/sdk'
import {
  ArrowDownIcon,
  AutoColumn,
  Box,
  Button,
  CardBody,
  ColumnCenter,
  Flex,
  IconButton,
  PencilIcon,
  MouseoverTooltip,
  Text,
  useModal,
  useMatchBreakpoints,
  useToast,
} from '@pulsex/uikit'
import { useUserSlippage } from '@pulsex/utils/user'
import { CurrencyLogo } from '@pulsex/widgets-internal'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Address, Hash } from 'viem'
import { AppHeader, AppBody } from 'components/App'
import { LightGreyCard } from 'components/Card'
import { CommitButton } from 'components/CommitButton'
import ConnectWalletButton from 'components/ConnectWalletButton'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { RowBetween } from 'components/Layout/Row'
import Dots from 'components/Loader/Dots'
import { SettingsMode } from 'components/Menu/GlobalSettings/types'
import SettingsModal from 'components/Menu/GlobalSettings/SettingsModal'
import { StableSwapMinimalPositionCard } from 'components/PositionCard/StableSwapMinimalPositionCard'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useStableSwapTwoNativeHelperContract, useStableSwapThreeNativeHelperContract } from 'hooks/useContract'
import { useCurrency } from 'hooks/Tokens'
import { Field } from 'state/stableswap/actions'
import { useBurnActionHandlers, useStableConfig, useStableDerivedBurnInfo } from 'state/stableswap/hooks'
import { useAddLiquidityFormState } from 'state/stableswap/reducer'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useFeeDataWithGasPrice } from 'state/user/hooks'
import { calculateGasMargin } from 'utils'
import { calculateSlippageAmount } from 'utils/exchange'
import { isUserRejected } from 'utils/sentry'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { transactionErrorToUserReadableMessage } from 'utils/transactionErrorToUserReadableMessage'
import ConfirmLiquidityModal from './ConfirmRemoveLiquidityModal'
import Page from '../../Page'

export default function RemoveStableLiquidity() {
  const { account, chainId, isWrongNetwork } = useActiveWeb3React()
  const { isMobile } = useMatchBreakpoints()
  const { poolAddress } = useParams<{ poolAddress?: Address }>()

  const {
    pool,
    isThreePool,
    stableSwapContract,
    totalSupply,
    userLpBalance,
    userTokenAmounts,
    poolPrices
  } = useStableConfig({
    chainId,
    poolAddress,
    account
  })

  const maxAmountInput = useMemo(() => maxAmountSpend(userLpBalance), [userLpBalance])

  const [currencyA, currencyB, currencyC] = [
    useCurrency(pool?.token0?.address) ?? undefined,
    useCurrency(pool?.token1?.address) ?? undefined,
    useCurrency(pool?.token2?.address) ?? undefined
  ]

  const { toastError } = useToast()
  const [tokenA, tokenB, tokenC] = useMemo(() => [currencyA?.wrapped, currencyB?.wrapped, currencyC?.wrapped], [currencyA, currencyB, currencyC])

  const { t } = useTranslation()
  const { maxFeePerGas, maxPriorityFeePerGas } = useFeeDataWithGasPrice()

  // burn state
  const { independentField, typedValue } = useAddLiquidityFormState()

  const twoNativeHelperContract = useStableSwapTwoNativeHelperContract()
  const threeNativeHelperContract = useStableSwapThreeNativeHelperContract()

  const needUnwrapped = currencyA?.isNative || currencyB?.isNative || currencyC?.isNative

  const { parsedAmounts, error } = useStableDerivedBurnInfo(chainId, poolAddress || undefined)

  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  const isValid = !error

  // modal and loading
  const [{ attemptingTxn, liquidityErrorMessage, txHash }, setLiquidityState] = useState<{
    attemptingTxn: boolean
    liquidityErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    attemptingTxn: false,
    liquidityErrorMessage: undefined,
    txHash: undefined,
  })

  // txn values
  const [allowedSlippage] = useUserSlippage()

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
        ? '<1'
        : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
    [Field.CURRENCY_C]:
      independentField === Field.CURRENCY_C ? typedValue : parsedAmounts[Field.CURRENCY_C]?.toSignificant(6) ?? '',
  }

  const { approvalState, approveCallback } = useApproveCallback(
    parsedAmounts[Field.LIQUIDITY],
    needUnwrapped && isThreePool
      ? threeNativeHelperContract?.address : needUnwrapped && !isThreePool
        ? twoNativeHelperContract?.address : pool?.stableSwapAddress,
  )

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, value: string) => {
      return _onUserInput(field, value)
    },
    [_onUserInput],
  )

  // remove commas and spaces from input
  const onLiquidityInput = useCallback((value: string): void => onUserInput(Field.LIQUIDITY, value.replace(/[,\s]/g, '')), [onUserInput])

  const handlePercentInput = useCallback(
    (percent: number) => {
      if (maxAmountInput) {
        onUserInput(Field.LIQUIDITY, maxAmountInput.multiply(new Percent(percent, 100)).toExact())
      }
    },
    [maxAmountInput, onUserInput],
  )

  // tx sending
  const addTransaction = useTransactionAdder()

  async function onRemove() {
    const contract = needUnwrapped && isThreePool
      ? threeNativeHelperContract : needUnwrapped && !isThreePool
        ? twoNativeHelperContract : stableSwapContract

    if (!chainId || !account || !contract) throw new Error('missing dependencies')
    const {
      [Field.CURRENCY_A]: currencyAmountA,
      [Field.CURRENCY_B]: currencyAmountB,
      [Field.CURRENCY_C]: currencyAmountC
    } = parsedAmounts

    if (!currencyAmountA || !currencyAmountB || isThreePool && !currencyAmountC) {
      toastError(t('Error'), t('Missing currency amounts'))
      throw new Error('missing currency amounts')
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0],
      [Field.CURRENCY_C]: isThreePool && currencyAmountC && calculateSlippageAmount(currencyAmountC, allowedSlippage)[0],
    }

    if (!currencyA || !currencyB || isThreePool && !currencyC) {
      toastError(t('Error'), t('Missing tokens'))
      throw new Error('missing tokens')
    }
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) {
      toastError(t('Error'), t('Missing liquidity amount'))
      throw new Error('missing liquidity amount')
    }

    if (!tokenA || !tokenB || isThreePool && !tokenC) {
      toastError(t('Error'), t('Could not wrap'))
      throw new Error('could not wrap')
    }

    let methodNames: string[]
    let args: Array<string | string[] | number | boolean>
    // we have approval, use normal remove liquidity
    if (approvalState === ApprovalState.APPROVED) {
      methodNames = ['remove_liquidity']
      if (needUnwrapped && stableSwapContract) {
        args = [
          stableSwapContract.address,
          liquidityAmount.quotient.toString(),
          isThreePool && amountsMin[Field.CURRENCY_C]
            ? [amountsMin[Field.CURRENCY_A].toString(), amountsMin[Field.CURRENCY_B].toString(), amountsMin[Field.CURRENCY_C].toString()]
            : [amountsMin[Field.CURRENCY_A].toString(), amountsMin[Field.CURRENCY_B].toString()],
        ]
      } else {
        args = [
          liquidityAmount.quotient.toString(),
          isThreePool && amountsMin[Field.CURRENCY_C]
            ? [amountsMin[Field.CURRENCY_A].toString(), amountsMin[Field.CURRENCY_B].toString(), amountsMin[Field.CURRENCY_C].toString()]
            : [amountsMin[Field.CURRENCY_A].toString(), amountsMin[Field.CURRENCY_B].toString()],
        ]
      }
    }
    // we have a signature, use permit versions of remove liquidity
    else {
      toastError(t('Error'), t('Attempting to confirm without approval or a signature'))
      throw new Error('Attempting to confirm without approval or a signature')
    }

    let methodSafeGasEstimate: { methodName: string; safeGasEstimate: bigint } | undefined
    for (let i = 0; i < methodNames.length; i++) {
      let safeGasEstimate
      try {
        // eslint-disable-next-line no-await-in-loop
        safeGasEstimate = calculateGasMargin(await contract.estimateGas[methodNames[i]](args, { account }))
      } catch (e) {
        console.error(`estimateGas failed`, methodNames[i], args, e)
      }

      if (typeof safeGasEstimate === 'bigint') {
        methodSafeGasEstimate = { methodName: methodNames[i], safeGasEstimate }
        break
      }
    }

    // all estimations failed...
    if (!methodSafeGasEstimate) {
      toastError(t('Error'), t('This transaction would fail'))
    } else {
      const { methodName, safeGasEstimate } = methodSafeGasEstimate

      setLiquidityState({ attemptingTxn: true, liquidityErrorMessage: undefined, txHash: undefined })
      await contract.write[methodName](args, {
        gas: safeGasEstimate,
        maxFeePerGas,
        maxPriorityFeePerGas,
      })
        .then((response: Hash) => {
          setLiquidityState({ attemptingTxn: false, liquidityErrorMessage: undefined, txHash: response })
          const amountA = parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)
          const amountB = parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)
          addTransaction(
            { hash: response },
            {
              summary: `Remove ${amountA} ${currencyA?.symbol} and ${amountB} ${currencyB?.symbol}`,
              translatableSummary: {
                text: 'Remove %amountA% %symbolA% and %amountB% %symbolB%',
                data: { amountA, symbolA: currencyA?.symbol, amountB, symbolB: currencyB?.symbol },
              },
              type: 'remove-liquidity',
            },
          )
        })
        .catch((err) => {
          if (err && !isUserRejected(err)) {
            console.error(`Remove Liquidity failed`, err, args)
          }
          setLiquidityState({
            attemptingTxn: false,
            liquidityErrorMessage:
              err && !isUserRejected(err)
                ? t('Remove liquidity failed: %message%', { message: transactionErrorToUserReadableMessage(err, t) })
                : undefined,
            txHash: undefined,
          })
        })
    }
  }

  let pendingText = t(
    'Removing %amountA% %symbolA% and %amountB% %symbolB%',
    {
      amountA: parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
      symbolA: currencyA?.symbol ?? '',
      amountB: parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
      symbolB: currencyB?.symbol ?? '',
    }
  )

  if (parsedAmounts[Field.CURRENCY_C]) {
    pendingText += t(' and %amountC% %symbolC%', {
      amountC: parsedAmounts[Field.CURRENCY_C]?.toSignificant(6) ?? '',
      symbolC: currencyC?.symbol ?? '',
    })
  }

  const handleDismissConfirmation = useCallback(() => {
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, '0')
    }
  }, [onUserInput, txHash])

  const [onPresentRemoveLiquidity] = useModal(
    <ConfirmLiquidityModal
      title={t('Removing liquidity')}
      customOnDismiss={handleDismissConfirmation}
      attemptingTxn={attemptingTxn}
      hash={txHash || ''}
      allowedSlippage={allowedSlippage}
      onRemove={onRemove}
      pendingText={pendingText}
      approval={approvalState}
      tokenA={tokenA}
      tokenB={tokenB}
      tokenC={tokenC}
      liquidityErrorMessage={liquidityErrorMessage}
      parsedAmounts={parsedAmounts}
      currencyA={currencyA}
      currencyB={currencyB}
      currencyC={currencyC}
    />,
    true,
    true,
    'removeLiquidityModal',
  )

  const [onPresentSettingsModal] = useModal(<SettingsModal mode={SettingsMode.SWAP_LIQUIDITY} />)

  return (
    <Page>
      <AppBody>
        <AppHeader
          backTo="/liquidity"
          title={t('Remove %assetA%-%assetB%%assetC%', {
            assetA: currencyA?.symbol ?? '',
            assetB: currencyB?.symbol ?? '',
            assetC: currencyC ? `-${currencyC.symbol}` : '',
          })}
          subtitle={t('To receive %assetA% and %assetB% and %assetC%', {
            assetA: currencyA?.symbol ?? '',
            assetB: currencyB?.symbol ?? '',
            assetC: currencyC?.symbol ?? '',
          })}
        />
        <CardBody>
          <AutoColumn gap="8px">
            <CurrencyInputPanel
              value={formattedAmounts[Field.LIQUIDITY]}
              onUserInput={onLiquidityInput}
              onMax={() => {
                onUserInput(Field.LIQUIDITY_PERCENT, '100')
              }}
              onPercentInput={handlePercentInput}
              maxAmount={maxAmountInput}
              showMaxButton
              showQuickInputButton
              disableCurrencySelect
              currency={pool?.liquidityToken}
              pair={pool}
              id="liquidity-amount"
              onCurrencySelect={() => null}
              label={t('You remove')}
            />

            <ColumnCenter>
              <ArrowDownIcon color="textSubtle" width="20px" />
            </ColumnCenter>

            <AutoColumn>
              <LightGreyCard>
                <Text color="textSubtleDark" fontSize="14px" mb="10px">
                  {t('You receive')}
                </Text>
                <Flex justifyContent="space-between" mb="8px" as="label" alignItems="center">
                  <Flex alignItems="center">
                    <CurrencyLogo currency={currencyA} />
                    <Text small color="textSubtle" id="remove-liquidity-tokena-symbol" ml="4px">
                      {currencyA?.symbol}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text small bold>
                      {formattedAmounts[Field.CURRENCY_A] || '0'}
                    </Text>
                  </Flex>
                </Flex>
                <Flex justifyContent="space-between" as="label" alignItems="center" mb={currencyC ? "8px" : "0"}>
                  <Flex alignItems="center">
                    <CurrencyLogo currency={currencyB} />
                    <Text small color="textSubtle" id="remove-liquidity-tokenb-symbol" ml="4px">
                      {currencyB?.symbol}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text bold small>
                      {formattedAmounts[Field.CURRENCY_B] || '0'}
                    </Text>
                  </Flex>
                </Flex>
                {currencyC && <Flex justifyContent="space-between" as="label" alignItems="center">
                  <Flex alignItems="center">
                    <CurrencyLogo currency={currencyC} />
                    <Text small color="textSubtle" id="remove-liquidity-tokenc-symbol" ml="4px">
                      {currencyC?.symbol}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text bold small>
                      {formattedAmounts[Field.CURRENCY_C] || '0'}
                    </Text>
                  </Flex>
                </Flex>}
              </LightGreyCard>
            </AutoColumn>

            {pool && poolPrices && (
              <AutoColumn gap="12px" style={{ marginTop: '16px' }}>
                <LightGreyCard>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text>
                      Prices
                    </Text>
                    <MouseoverTooltip
                      isMobile={isMobile}
                      text={
                        <>
                          <Text fontSize="12px" color="textSubtleDark">
                            {t(`1 ${currencyA?.symbol} = ${poolPrices.token0[0].toSignificant(4)} ${currencyB?.symbol}`)}
                            {poolPrices?.token2 && t(` = ${poolPrices.token0[1].toSignificant(4)} ${currencyC?.symbol}`)}
                          </Text>
                          <Text fontSize="12px" color="textSubtleDark">
                            {t(`1 ${currencyB?.symbol} = ${poolPrices.token1[0].toSignificant(4)} ${currencyA?.symbol}`)}
                            {poolPrices?.token2 && t(` = ${poolPrices.token1[1].toSignificant(4)} ${currencyC?.symbol}`)}
                          </Text>
                          {poolPrices?.token2 && (
                            <Text fontSize="12px" color="textSubtleDark">
                              {t(`1 ${currencyC?.symbol} = ${poolPrices.token2[0].toSignificant(4)} ${currencyA?.symbol} = ${poolPrices.token2[1].toSignificant(4)} ${currencyB?.symbol}`)}
                            </Text>
                          )}
                        </>
                      }
                      placement='top'
                    >
                      <Text fontSize="12px" color="textSubtleDark">
                        {`1 ${currencyA?.symbol} = `} {poolPrices.token0[0].toSignificant(4)} {currencyB?.symbol} {poolPrices?.token2 && `= ${poolPrices.token2[1].toSignificant(4)} ${currencyC?.symbol}`}
                      </Text>
                    </MouseoverTooltip>
                  </Flex>
                </LightGreyCard>
              </AutoColumn>
            )}

            <RowBetween>
              <Text bold color="secondary" fontSize="12px">
                {t('Slippage Tolerance')}
                <IconButton scale="sm" variant="text" onClick={onPresentSettingsModal}>
                  <PencilIcon color="primary" width="10px" />
                </IconButton>
              </Text>
              <Text bold color="primary">
                {allowedSlippage / 100}%
              </Text>
            </RowBetween>

            <Box position="relative">
              {!account ? (
                <ConnectWalletButton width="100%" />
              ) : isWrongNetwork ? (
                <CommitButton width="100%" />
              ) : (
                <RowBetween>
                  <Button
                    variant={approvalState === ApprovalState.APPROVED ? 'success' : 'primary'}
                    onClick={() => approveCallback()}
                    disabled={approvalState !== ApprovalState.NOT_APPROVED}
                    width="100%"
                    mr="0.5rem"
                  >
                    {approvalState === ApprovalState.PENDING ? (
                      <Dots>{t('Enabling')}</Dots>
                    ) : approvalState === ApprovalState.APPROVED ? (
                      t('Enabled')
                    ) : (
                      t('Enable')
                    )}
                  </Button>
                  <Button
                    variant={
                      !isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]
                        ? 'danger'
                        : 'primary'
                    }
                    onClick={() => {
                      setLiquidityState({
                        attemptingTxn: false,
                        liquidityErrorMessage: undefined,
                        txHash: undefined,
                      })
                      onPresentRemoveLiquidity()
                    }}
                    width="100%"
                    disabled={!isValid || approvalState !== ApprovalState.APPROVED}
                  >
                    {error || t('Remove')}
                  </Button>
                </RowBetween>
              )}
            </Box>
          </AutoColumn>
        </CardBody>
      </AppBody>

      {pool ? (
        <AutoColumn style={{ minWidth: '20rem', width: '100%', maxWidth: '450px', marginTop: '1rem' }}>
          <StableSwapMinimalPositionCard
            pool={pool}
            isThreePool={isThreePool}
            totalSupply={totalSupply}
            userLpBalance={userLpBalance}
            userTokenAmounts={userTokenAmounts}
          />
        </AutoColumn>
      ) : null}
    </Page>
  )
}
