import { ChainId, pulsechain, pulsechainTestnet } from '@pulsex/chains'
import memoize from 'lodash/memoize'
import { Chain } from 'wagmi/chains'

export const CHAIN_QUERY_NAME = {
  [ChainId.PULSECHAIN]: 'pulsechain',
  [ChainId.PULSECHAIN_TESTNET]: 'pulsechain-testnet',
} as const;

const CHAIN_QUERY_NAME_TO_ID = Object.entries(CHAIN_QUERY_NAME).reduce((acc, [chainId, chainName]) => {
  return {
    [chainName.toLowerCase()]: chainId as unknown as ChainId,
    ...acc,
  }
}, {} as Record<string, ChainId>)

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined
  return CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()] ? +CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()] : undefined
})

export const CHAINS: [Chain, ...Chain[]] = [
  pulsechain,
  pulsechainTestnet,
]
