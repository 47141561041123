// Constructing the two forward-slash-separated parts of the 'Add Liquidity' URL
// Each part of the url represents a different side of the LP pair.
import { plsTokens } from '@pulsex/tokens'

const getLiquidityUrlPathParts = ({
  protocol,
  quoteTokenAddress,
  tokenAddress,
}: {
  protocol: string
  quoteTokenAddress?: string
  tokenAddress?: string
}): string => {
  const wplsAddress = plsTokens.wpls.address
  const firstPart = !quoteTokenAddress || quoteTokenAddress === wplsAddress ? 'PLS' : quoteTokenAddress
  const secondPart = !tokenAddress || tokenAddress === wplsAddress ? 'PLS' : tokenAddress
  return `${protocol}/${firstPart}/${secondPart}`
}

export default getLiquidityUrlPathParts
