import { BIG_ONE, BIG_TWO, BIG_ZERO } from '@pulsex/utils/bigNumber'
import BN from 'bignumber.js'
import { equalsIgnoreCase } from '@pulsex/utils/equalsIgnoreCase'
import toNumber from 'lodash/toNumber'
import { SerializedFarmPublicData, FarmData } from './types'
import { getFullDecimalMultiplier } from './getFullDecimalMultiplier'

// Find USD price for token
// either via direct calculation if farm is X-PLS or X-USD
// or via quoteTokenFarm which is quoteToken-PLS or quoteToken-USD farm
export const getFarmBaseTokenPrice = (
  farm: SerializedFarmPublicData,
  quoteTokenFarm: SerializedFarmPublicData,
  nativePriceUSD: BN,
  wNative: string,
  stable: string,
  quoteTokenInUsd,
): BN => {
  const hasTokenPriceVsQuote = Boolean(farm.tokenPriceVsQuote)

  if (farm.quoteToken.symbol === stable) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return hasTokenPriceVsQuote ? new BN(farm.tokenPriceVsQuote!) : BIG_ONE
  }

  if (farm.quoteToken.symbol === wNative) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return hasTokenPriceVsQuote ? nativePriceUSD.times(new BN(farm.tokenPriceVsQuote!)) : BIG_ONE
  }

  // We can only calculate profits without a quoteTokenFarm for USD/PLS farms
  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  // Possible alternative farm quoteTokens:
  // If the farm's quote token isn't USD or WPLS, we then use the quote token, of the original farm's quote token
  if (quoteTokenFarm.quoteToken.symbol === wNative || quoteTokenFarm.quoteToken.symbol === stable) {
    return hasTokenPriceVsQuote && quoteTokenInUsd
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ? new BN(farm.tokenPriceVsQuote!).times(quoteTokenInUsd)
      : BIG_ONE
  }

  // Catch in case token does not have immediate or once-removed USD/WPLS quoteToken
  return BIG_ZERO
}

export const getFarmQuoteTokenPrice = (
  farm: SerializedFarmPublicData,
  quoteTokenFarm: SerializedFarmPublicData,
  nativePriceUSD: BN,
  wNative: string,
  stable: string,
): BN => {
  if (farm.quoteToken.symbol === stable) {
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === wNative) {
    return nativePriceUSD
  }

  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === wNative) {
    return quoteTokenFarm.tokenPriceVsQuote
      ? nativePriceUSD.times(new BN(quoteTokenFarm.tokenPriceVsQuote))
      : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === stable) {
    return quoteTokenFarm.tokenPriceVsQuote ? new BN(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  return BIG_ZERO
}

const getFarmFromTokenAddress = (
  farms: SerializedFarmPublicData[],
  tokenAddress: string,
  preferredQuoteTokens?: string[],
): SerializedFarmPublicData => {
  const farmsWithTokenSymbol = farms.filter((farm) => equalsIgnoreCase(farm.token.address, tokenAddress))
  const filteredFarm = filterFarmsByQuoteToken(farmsWithTokenSymbol, preferredQuoteTokens)
  return filteredFarm
}

const filterFarmsByQuoteToken = (
  farms: SerializedFarmPublicData[],
  preferredQuoteTokens: string[] = ['DAI', 'WPLS'],
): SerializedFarmPublicData => {
  const preferredFarm = farms.find((farm) => {
    return preferredQuoteTokens.some((quoteToken) => {
      return farm.quoteToken.symbol === quoteToken
    })
  })
  return preferredFarm || farms[0]
}

export const getStableLpTokenPrice = (
  lpTotalSupply: BN,
  tokenAmountTotal: BN,
  tokenPriceUsd: BN,
  quoteTokenAmountTotal: BN,
  quoteTokenInUsd: BN,
  decimals: number,
) => {
  if (lpTotalSupply.isZero()) {
    return BIG_ZERO
  }
  const valueOfBaseTokenInFarm = tokenPriceUsd.times(tokenAmountTotal)
  const valueOfQuoteTokenInFarm = quoteTokenInUsd.times(quoteTokenAmountTotal)

  const liquidity = valueOfBaseTokenInFarm.plus(valueOfQuoteTokenInFarm)

  const totalLpTokens = lpTotalSupply.div(getFullDecimalMultiplier(decimals))

  return liquidity.div(totalLpTokens)
}

export const getLpTokenPrice = (
  lpTotalSupply: BN,
  lpTotalInQuoteToken: BN,
  tokenAmountTotal: BN,
  tokenPriceBusd: BN,
  decimals: number,
) => {
  // LP token price
  let lpTokenPrice = BIG_ZERO
  if (lpTotalSupply.gt(0) && lpTotalInQuoteToken.gt(0)) {
    // Total value of base token in LP
    const valueOfBaseTokenInFarm = tokenPriceBusd.times(tokenAmountTotal)
    // Double it to get overall value in LP
    const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(BIG_TWO)
    // Divide total value of all tokens, by the number of LP tokens
    const totalLpTokens = lpTotalSupply.div(getFullDecimalMultiplier(decimals))
    lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
  }

  return lpTokenPrice
}

export type FarmWithPrices = FarmData & {
  tokenPriceUsd: string
  quoteTokenPriceUsd: string
  lpTokenPrice: string
}

const isNativeFarm = (
  farm: FarmData,
  nativeStableLp: {
    address: string
    wNative: string
    stable: string
  },
) => {
  const isLpFound = equalsIgnoreCase(farm.lpAddress, nativeStableLp.address)
  if (!isLpFound) {
    return (
      equalsIgnoreCase(farm.token.symbol, nativeStableLp.stable) &&
      equalsIgnoreCase(farm.quoteToken.symbol, nativeStableLp.wNative)
    )
  }
  return true
}

export const getFarmsPrices = (
  farms: FarmData[],
  nativeStableLp: {
    address: string
    wNative: string
    stable: string
  },
  decimals: number,
): FarmWithPrices[] => {
  if (!farms || !nativeStableLp || farms.length === 0) return []

  const nativeStableFarm = farms.find((farm) => isNativeFarm(farm, nativeStableLp))

  const isNativeFirst = nativeStableFarm?.token?.symbol === nativeStableLp.wNative

  const nativePriceUSD =
    nativeStableFarm && toNumber(nativeStableFarm?.tokenPriceVsQuote) !== 0
      ? isNativeFirst
        ? new BN(nativeStableFarm.tokenPriceVsQuote)
        : BIG_ONE.div(new BN(nativeStableFarm.tokenPriceVsQuote))
      : BIG_ZERO

  const farmsWithPrices = farms.map((farm) => {
    const quoteTokenFarm = getFarmFromTokenAddress(farms, farm.quoteToken.address, [
      nativeStableLp.wNative,
      nativeStableLp.stable,
    ])

    const quoteTokenPriceUsd = getFarmQuoteTokenPrice(
      farm,
      quoteTokenFarm,
      nativePriceUSD,
      nativeStableLp.wNative,
      nativeStableLp.stable,
    )

    const tokenPriceUsd = getFarmBaseTokenPrice(
      farm,
      quoteTokenFarm,
      nativePriceUSD,
      nativeStableLp.wNative,
      nativeStableLp.stable,
      quoteTokenPriceUsd,
    )

    const lpTokenPrice = getLpTokenPrice(
      new BN(farm.lpTotalSupply),
      new BN(farm.lpTotalInQuoteToken),
      new BN(farm.tokenAmountTotal),
      tokenPriceUsd,
      decimals,
    )

    return {
      ...farm,
      tokenPriceUsd: tokenPriceUsd.toString(),
      quoteTokenPriceUsd: quoteTokenPriceUsd.toString(),
      lpTokenPrice: lpTokenPrice.toString(),
    }
  })

  return farmsWithPrices
}
