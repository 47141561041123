import { Swap as SwapUI } from '@pulsex/widgets-internal'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import { AutoRow } from 'components/Layout/Row'

export default function FlipButton() {
  const { onSwitchTokens } = useSwapActionHandlers()

  return (
    <AutoRow onClick={onSwitchTokens} justify="center" marginTop="-16px" marginBottom="-16px" zIndex="2">
      <SwapUI.SwitchButton />
    </AutoRow>
  )
}
