import { SmartRouterTrade } from '@pulsex/smart-router'
import { TradeType } from '@pulsex/swap-sdk-core'
import { useThrottleFn } from 'hooks/useThrottleFn'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useSwapBestTrade } from './useSwapBestTrade'

type Trade = SmartRouterTrade<TradeType>

export const useAllTypeBestTrade = () => {
  const [isQuotingPaused, setIsQuotingPaused] = useState(false)
  const { isLoading, trade, refresh, error, refreshing, key } = useSwapBestTrade()
  const lockedAMMTrade = useRef<Trade | undefined>()

  const ammCurrentTrade = useMemo(() => {
    if (!lockedAMMTrade.current) {
      lockedAMMTrade.current = trade
    }
    lockedAMMTrade.current = isQuotingPaused ? lockedAMMTrade.current : trade
    return lockedAMMTrade.current
  }, [isQuotingPaused, trade])

  const pauseQuoting = useCallback(() => {
    setIsQuotingPaused(true)
  }, [])

  const resumeQuoting = useCallback(() => {
    setIsQuotingPaused(false)
  }, [])

  const refreshTrade = useThrottleFn(refresh, 3000)

  return {
    key,
    bestTrade: ammCurrentTrade,
    tradeLoaded: !isLoading && !refreshing,
    tradeError: error,
    refreshDisabled: !trade,
    refreshTrade,
    pauseQuoting,
    resumeQuoting,
  }
}
