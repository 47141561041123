import {
  MenuItemsType,
  DropdownMenuItemType,
  DropdownMenuItems,
} from '@pulsex/uikit'
import { ContextApi } from '@pulsex/localization'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Trade'),
    href: '/',
    items: [
      {
        label: t('Exchange'),
        href: '/',
      },
      {
        label: t('Liquidity'),
        href: '/liquidity',
      },
    ],
  },
  {
    label: t('Farm'),
    href: '/farms',
    items: [],
  },
  {
    label: t('Burn'),
    href: '/burn',
    items: [],
  },
  {
    label: t('Info'),
    href: '/info',
    items: [],
  },
  {
    label: t('Bridge'),
    href: 'https://bridge.pulsechain.com/',
    type: DropdownMenuItemType.EXTERNAL_LINK,
  },
]

export default config
