import { PairV1, PairV2, Currency, CurrencyAmount, IPulseXPair } from '@pulsex/sdk'
import { useMemo } from 'react'

import { toV1LiquidityToken, toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import { useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { useActiveChainId } from './useActiveChainId'

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

export function useV1Pairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, PairV1 | null][] {
  const { chainId } = useActiveChainId()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies],
  )

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        try {
          return tokenA && tokenB && !tokenA.equals(tokenB) ? PairV1.getAddress(tokenA, tokenB) : undefined
        } catch (error: any) {
          // Debug Invariant failed related to this line
          console.error(
            error.msg,
            `- pairAddresses: ${tokenA?.address}-${tokenB?.address}`,
            `chainId: ${tokenA?.chainId}`,
          )

          return undefined
        }
      }),
    [tokens],
  )

  const results = useMultipleContractSingleData({
    addresses: pairAddresses,
    abi: IPulseXPair,
    functionName: 'getReserves',
  })

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const [reserve0, reserve1] = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new PairV1(
          CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
        ),
      ]
    })
  }, [results, tokens])
}

export function useV1Pair(tokenA?: Currency, tokenB?: Currency): [PairState, PairV1 | null] {
  const pairCurrencies = useMemo<[Currency | undefined, Currency | undefined][]>(
    () => [[tokenA, tokenB]],
    [tokenA, tokenB])
  return useV1Pairs(pairCurrencies)[0]
}

export function useV1PairsByAccount(account: string | undefined) {
  // fetch the user's balances of all tracked V1 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()

  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV1LiquidityToken(tokens), tokens })),
    [trackedTokenPairs],
  )
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens],
  )
  const [v1PairsBalances, fetchingV1PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens,
  )

  // fetch the reserves for all V1 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v1PairsBalances[liquidityToken.address]?.greaterThan('0'),
      ),
    [tokenPairsWithLiquidityTokens, v1PairsBalances],
  )

  const v1Pairs = useV1Pairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))

  return useMemo(() => {
    const v1IsLoading =
      fetchingV1PairBalances ||
      v1Pairs?.length < liquidityTokensWithBalances.length ||
      (v1Pairs?.length && v1Pairs.every(([pairState]) => pairState === PairState.LOADING))
    const allV1PairsWithLiquidity: (PairV1 | null)[] = v1Pairs
      ?.filter(([pairState, pair]) => pairState === PairState.EXISTS && Boolean(pair))
      .map(([, pair]) => pair)

    return {
      data: allV1PairsWithLiquidity,
      loading: v1IsLoading,
    }
  }, [fetchingV1PairBalances, liquidityTokensWithBalances.length, v1Pairs])
}


export function useV2Pairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, PairV2 | null][] {
  const { chainId } = useActiveChainId()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies],
  )

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        try {
          return tokenA && tokenB && !tokenA.equals(tokenB) ? PairV2.getAddress(tokenA, tokenB) : undefined
        } catch (error: any) {
          // Debug Invariant failed related to this line
          console.error(
            error.msg,
            `- pairAddresses: ${tokenA?.address}-${tokenB?.address}`,
            `chainId: ${tokenA?.chainId}`,
          )

          return undefined
        }
      }),
    [tokens],
  )

  const results = useMultipleContractSingleData({
    addresses: pairAddresses,
    abi: IPulseXPair,
    functionName: 'getReserves',
  })

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const [reserve0, reserve1] = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new PairV2(
          CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
        ),
      ]
    })
  }, [results, tokens])
}

export function useV2Pair(tokenA?: Currency, tokenB?: Currency): [PairState, PairV2 | null] {
  const pairCurrencies = useMemo<[Currency | undefined, Currency | undefined][]>(
    () => [[tokenA, tokenB]],
    [tokenA, tokenB])
  return useV2Pairs(pairCurrencies)[0]
}

export function useV2PairsByAccount(account: string | undefined) {
  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()

  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs],
  )
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens],
  )
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens,
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0'),
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances],
  )

  const v2Pairs = useV2Pairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))

  return useMemo(() => {
    const v2IsLoading =
      fetchingV2PairBalances ||
      v2Pairs?.length < liquidityTokensWithBalances.length ||
      (v2Pairs?.length && v2Pairs.every(([pairState]) => pairState === PairState.LOADING))
    const allV2PairsWithLiquidity: (PairV2 | null)[] = v2Pairs
      ?.filter(([pairState, pair]) => pairState === PairState.EXISTS && Boolean(pair))
      .map(([, pair]) => pair)

    return {
      data: allV2PairsWithLiquidity,
      loading: v2IsLoading,
    }
  }, [fetchingV2PairBalances, liquidityTokensWithBalances.length, v2Pairs])
}

export function usePairsBothProtocols(
  currencies: [Currency | undefined, Currency | undefined][],
  protocol: string | undefined):
  [PairState, PairV1 | PairV2 | null][] {
  const { chainId } = useActiveChainId()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies],
  )

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        try {
          if (protocol === 'V1') {
            return tokenA && tokenB && !tokenA.equals(tokenB) ? PairV1.getAddress(tokenA, tokenB) : undefined
          }
          if (protocol === 'V2') {
            return tokenA && tokenB && !tokenA.equals(tokenB) ? PairV2.getAddress(tokenA, tokenB) : undefined
          }
          return undefined
        } catch (error: any) {
          // Debug Invariant failed related to this line
          console.error(
            error.msg,
            `- pairAddresses: ${tokenA?.address}-${tokenB?.address}`,
            `chainId: ${tokenA?.chainId}`,
          )

          return undefined
        }
      }),
    [tokens, protocol],
  )

  const results = useMultipleContractSingleData({
    addresses: pairAddresses,
    abi: IPulseXPair,
    functionName: 'getReserves',
  })

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const [reserve0, reserve1] = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        protocol === 'V1' ? new PairV1(
          CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
        ) : new PairV2(
          CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
        ),
      ]
    })
  }, [results, tokens, protocol])
}

export function usePairBothProtocols(
  tokenA?: Currency,
  tokenB?: Currency,
  protocol?: string):
  [PairState, PairV1 | PairV2 | null] {
  const pairCurrencies = useMemo<[Currency | undefined, Currency | undefined][]>(() => [[tokenA, tokenB]], [tokenA, tokenB])
  return usePairsBothProtocols(pairCurrencies, protocol)[0]
}
