import { useTranslation } from '@pulsex/localization'
import { CurrencyAmount, Percent, Token, WPLS } from '@pulsex/sdk'
import { PLSX, plsTokens } from '@pulsex/tokens'
import { ButtonMenu, ButtonMenuItem, Flex, Text, AddIcon, CardBody, Message, useModal } from '@pulsex/uikit'
import { useUserSlippage } from '@pulsex/utils/user'
import { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Hash } from 'viem'
import { ONE_BIPS, ROUTER_ADDRESS_V1, ROUTER_ADDRESS_V2 } from 'config/constants/exchange'
import { AppHeader, AppBody } from 'components/App'
import { LightGreyCard } from 'components/Card'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { AutoColumn, ColumnCenter } from 'components/Layout/Column'
import { MinimalPositionCard } from 'components/PositionCard'
import UnsupportedCurrencyFooter from 'components/UnsupportedCurrencyFooter'
import { useIsTransactionUnsupported, useIsTransactionWarning } from 'hooks/Trades'
import { useCurrency } from 'hooks/Tokens'
import useAccountActiveChain from 'hooks/useAccountActiveChain'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { PairState } from 'hooks/usePairs'
import { useTransactionDeadline } from 'hooks/useTransactionDeadline'
import { calculateGasMargin } from 'utils'
import { calculateSlippageAmount, useRouterContractBothProtocols } from 'utils/exchange'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { isUserRejected } from 'utils/sentry'
import { transactionErrorToUserReadableMessage } from 'utils/transactionErrorToUserReadableMessage'
import { Field } from 'state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers } from 'state/mint/hooks'
import { useAddLiquidityFormState } from 'state/mint/reducer'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useFeeDataWithGasPrice, usePairAdder } from 'state/user/hooks'
import { useProtocol } from 'state/user/smartRouter'
import { useCurrencySelectRoute } from './useCurrencySelectRoute'
import ConfirmAddLiquidityModal from './ConfirmAddLiquidityModal'
import { ConfirmAddLiquidityButton } from './ConfirmAddLiquidityButton'
import Page from '../../Page'

export default function AddV2Liquidity() {
  const { account, chainId } = useAccountActiveChain()
  const native = useNativeCurrency()
  const {
    initialProtocol = '',
    currencyIdA = native.symbol,
    currencyIdB = PLSX[chainId]?.address ?? plsTokens.dai.address,
  } = useParams<{ initialProtocol?: string; currencyIdA?: string; currencyIdB?: string }>()

  const [protocol, setProtocol] = useProtocol()

  const addPair = usePairAdder()
  const { t } = useTranslation()
  const { maxFeePerGas, maxPriorityFeePerGas } = useFeeDataWithGasPrice()

  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  const oneCurrencyIsWPLS = Boolean(
    chainId &&
    ((currencyA && currencyA.equals(WPLS[chainId])) ||
      (currencyB && currencyB.equals(WPLS[chainId]))),
  )

  // mint state
  const { independentField, typedValue, otherTypedValue } = useAddLiquidityFormState()
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
    addError,
    isOneWeiAttack,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  // Allow to set initial protocol version from path
  const prevInitialProtocol = useRef('')
  useEffect(() => {
    if (initialProtocol && prevInitialProtocol.current !== initialProtocol) {
      setProtocol(initialProtocol)
      prevInitialProtocol.current = initialProtocol
    }
  }, [initialProtocol, setProtocol])

  // modal and loading
  const [{ attemptingTxn, liquidityErrorMessage, txHash }, setLiquidityState] = useState<{
    attemptingTxn: boolean
    liquidityErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    attemptingTxn: false,
    liquidityErrorMessage: undefined,
    txHash: undefined,
  })

  // txn values
  const [deadline] = useTransactionDeadline() // custom from users settings
  const [allowedSlippage] = useUserSlippage() // custom from users

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: CurrencyAmount<Token> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {},
  )

  const handlePercentInputA = useCallback(
    (percent: number) => {
      if (maxAmounts[Field.CURRENCY_A]) {
        onFieldAInput(maxAmounts[Field.CURRENCY_A].multiply(new Percent(percent, 100)).toExact())
      }
    },
    // eslint-disable-next-line
    [maxAmounts[Field.CURRENCY_A], onFieldAInput, maxAmounts],
  )

  const handlePercentInputB = useCallback(
    (percent: number) => {
      if (maxAmounts[Field.CURRENCY_B]) {
        onFieldBInput(maxAmounts[Field.CURRENCY_B].multiply(new Percent(percent, 100)).toExact())
      }
    },
    // eslint-disable-next-line
    [maxAmounts[Field.CURRENCY_B], onFieldBInput, maxAmounts],
  )

  const { handleCurrencyASelect, handleCurrencyBSelect } = useCurrencySelectRoute()

  // get formatted amounts
  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
    }),
    [
      dependentField,
      independentField,
      noLiquidity,
      otherTypedValue,
      parsedAmounts,
      typedValue
    ],
  )

  const atMaxAmounts: { [field in Field]?: CurrencyAmount<Token> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {},
  )

  const routerAddress: string = useMemo(() => {
    return protocol === 'V1' ? ROUTER_ADDRESS_V1[chainId] : ROUTER_ADDRESS_V2[chainId]
  }, [protocol, chainId])

  // check whether the user has approved the router on the tokens
  const {
    approvalState: approvalA,
    approveCallback: approveACallback,
    revokeCallback: revokeACallback,
    currentAllowance: currentAllowanceA,
  } = useApproveCallback(parsedAmounts[Field.CURRENCY_A], routerAddress)
  const {
    approvalState: approvalB,
    approveCallback: approveBCallback,
    revokeCallback: revokeBCallback,
    currentAllowance: currentAllowanceB,
  } = useApproveCallback(parsedAmounts[Field.CURRENCY_B], routerAddress)

  const addTransaction = useTransactionAdder()

  const routerContract = useRouterContractBothProtocols(protocol)

  async function onAdd() {
    if (!chainId || !account || !routerContract) return

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
      return
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0],
    }

    // eslint-disable-next-line
    let estimate: any
    // eslint-disable-next-line
    let method: any
    // eslint-disable-next-line
    let args: Array<string | string[] | number | bigint>
    let value: bigint | null
    if (currencyA?.isNative || currencyB?.isNative) {
      const tokenBIsNative = currencyB?.isNative
      estimate = routerContract.estimateGas.addLiquidityETH
      method = routerContract.write.addLiquidityETH
      args = [
        (tokenBIsNative ? currencyA : currencyB)?.wrapped?.address ?? '', // token
        (tokenBIsNative ? parsedAmountA : parsedAmountB).quotient.toString(), // token desired
        amountsMin[tokenBIsNative ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
        amountsMin[tokenBIsNative ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
        account,
        deadline,
      ]
      value = (tokenBIsNative ? parsedAmountB : parsedAmountA).quotient
    } else {
      estimate = routerContract.estimateGas.addLiquidity
      method = routerContract.write.addLiquidity
      args = [
        currencyA?.wrapped?.address ?? '',
        currencyB?.wrapped?.address ?? '',
        parsedAmountA.quotient.toString(),
        parsedAmountB.quotient.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadline,
      ]
      value = null
    }

    setLiquidityState({ attemptingTxn: true, liquidityErrorMessage: undefined, txHash: undefined })
    await estimate(
      args,
      value
        ? { value, account: routerContract.account, chain: routerContract.chain }
        : { account: routerContract.account, chain: routerContract.chain },
    )
      .then((estimatedGasLimit: any) =>
        method(args, {
          ...(value ? { value } : {}),
          gas: calculateGasMargin(estimatedGasLimit),
          maxFeePerGas,
          maxPriorityFeePerGas,
        }).then((response: Hash) => {
          setLiquidityState({ attemptingTxn: false, liquidityErrorMessage: undefined, txHash: response })

          const symbolA = currencies[Field.CURRENCY_A]?.symbol
          const amountA = parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)
          const symbolB = currencies[Field.CURRENCY_B]?.symbol
          const amountB = parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)
          addTransaction(
            { hash: response },
            {
              summary: `Add ${amountA} ${symbolA} and ${amountB} ${symbolB}`,
              translatableSummary: {
                text: 'Add %amountA% %symbolA% and %amountB% %symbolB%',
                data: { amountA, symbolA, amountB, symbolB },
              },
              type: 'add-liquidity',
            },
          )

          if (pair) {
            addPair(pair)
          }
        }),
      )
      ?.catch((err: any) => {
        if (err && !isUserRejected(err)) {
          console.error(`Add Liquidity failed`, err, args, value)
        }
        setLiquidityState({
          attemptingTxn: false,
          liquidityErrorMessage:
            err && !isUserRejected(err)
              ? t('Add liquidity failed: %message%', { message: transactionErrorToUserReadableMessage(err, t) })
              : undefined,
          txHash: undefined,
        })
      })
  }

  const pendingText = t('Supplying %amountA% %symbolA% and %amountB% %symbolB%', {
    amountA: parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    symbolA: currencies[Field.CURRENCY_A]?.symbol ?? '',
    amountB: parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
    symbolB: currencies[Field.CURRENCY_B]?.symbol ?? '',
  })

  const handleDismissConfirmation = useCallback(() => {
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
    }
  }, [onFieldAInput, txHash])

  const addIsUnsupported = useIsTransactionUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)
  const addIsWarning = useIsTransactionWarning(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  const [onPresentAddLiquidityModal_] = useModal(
    <ConfirmAddLiquidityModal
      title={noLiquidity ? t(`You are creating a ${protocol} pool`) : t(`Adding ${protocol} liquidity`)}
      customOnDismiss={handleDismissConfirmation}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      pendingText={pendingText}
      currencyToAdd={pair?.liquidityToken}
      allowedSlippage={allowedSlippage}
      onAdd={onAdd}
      parsedAmounts={parsedAmounts}
      currencies={currencies}
      liquidityErrorMessage={liquidityErrorMessage}
      price={price}
      noLiquidity={noLiquidity}
      poolTokenPercentage={poolTokenPercentage}
      liquidityMinted={liquidityMinted}
    />,
    true,
    true,
    'addLiquidityModal',
  )

  const onPresentAddLiquidityModal = useCallback(() => {
    setLiquidityState({
      attemptingTxn: false,
      liquidityErrorMessage: undefined,
      txHash: undefined,
    })
    onPresentAddLiquidityModal_()
  }, [onPresentAddLiquidityModal_])

  const isValid = !error && !addError
  const errorText = error ?? addError

  const buttonDisabled = !isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED

  const showFieldAApproval = approvalA === ApprovalState.NOT_APPROVED || approvalA === ApprovalState.PENDING
  const showFieldBApproval = approvalB === ApprovalState.NOT_APPROVED || approvalB === ApprovalState.PENDING

  const shouldShowApprovalGroup = (showFieldAApproval || showFieldBApproval) && isValid

  const protocolSelector =
    <ButtonMenu
      scale="sm"
      activeIndex={protocol === 'V1' ? 0 : 1}
      onItemClick={(index) => setProtocol(index === 0 ? 'V1' : 'V2')}
      variant="subtle"
    >
      <ButtonMenuItem>V1</ButtonMenuItem>
      <ButtonMenuItem>V2</ButtonMenuItem>
    </ButtonMenu>

  return (
    <Page>
      <AppBody>
        <AppHeader
          title={t('Add Liquidity')}
          subtitle={t('Add liquidity to receive LP tokens')}
          helper={t(
            protocol === 'V1' ? "Adding V1 liquidity helps burn PLSX. 100% of the swap fees go the buy and burn contract which pays a 0.1% bounty for those that run it."
              : "By adding V2 liquidity you'll earn 0.22% of all trades on this pair proportional to your share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.",
          )}
          backTo="/liquidity"
          protocolSelector={protocolSelector}
        />
        <CardBody>
          <AutoColumn gap="10px">
            {noLiquidity && (
              <ColumnCenter>
                <Message variant="warning">
                  <div>
                    <Text bold mb="8px">
                      {t('You are the first liquidity provider.')}
                    </Text>
                    <Text mb="8px">{t('The ratio of tokens you add will set the price of this pool.')}</Text>
                    <Text>{t('Once you are happy with the rate click supply to review.')}</Text>
                  </div>
                </Message>
              </ColumnCenter>
            )}
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onFieldAInput}
              onPercentInput={handlePercentInputA}
              maxAmount={maxAmounts[Field.CURRENCY_A]}
              onMax={() => {
                onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
              }}
              onCurrencySelect={handleCurrencyASelect}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
              showQuickInputButton
              currency={currencies[Field.CURRENCY_A]}
              id="add-liquidity-input-tokena"
              label={t("You add")}
              showCommonBases
              showUSDPrice
            />
            <ColumnCenter>
              <AddIcon width="16px" />
            </ColumnCenter>
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onFieldBInput}
              onCurrencySelect={handleCurrencyBSelect}
              onPercentInput={handlePercentInputB}
              maxAmount={maxAmounts[Field.CURRENCY_B]}
              onMax={() => {
                onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
              }}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
              showQuickInputButton
              currency={currencies[Field.CURRENCY_B]}
              id="add-liquidity-input-tokenb"
              label={t("You add")}
              showCommonBases
              showUSDPrice
            />

            {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
              <AutoColumn gap="12px" style={{ marginTop: '16px' }}>
                <LightGreyCard>
                  <Flex justifyContent="space-between" mb={1}>
                    <Text>
                      {t('Share of Pool')}
                    </Text>
                    <Text>
                      {noLiquidity && price
                        ? '100'
                        : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
                      %
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Text small color="textSubtleDark">
                      1 {currencyA?.symbol} =
                    </Text>
                    <Text small color="textSubtleDark">{price?.toSignificant(6) ?? '-'} {currencyB?.symbol}</Text>

                  </Flex>
                  <Flex justifyContent="space-between">
                    <Text small color="textSubtleDark">
                      1 {currencyB?.symbol} =
                    </Text>
                    <Text small color="textSubtleDark">{price?.invert()?.toSignificant(6) ?? '-'} {currencyA?.symbol}</Text>

                  </Flex>
                </LightGreyCard>
              </AutoColumn>
            )}

            <ConfirmAddLiquidityButton
              approvalA={approvalA}
              approvalB={approvalB}
              showFieldAApproval={showFieldAApproval}
              showFieldBApproval={showFieldBApproval}
              addIsUnsupported={addIsUnsupported}
              addIsWarning={addIsWarning}
              currencies={currencies}
              currentAllowanceA={currentAllowanceA}
              currentAllowanceB={currentAllowanceB}
              shouldShowApprovalGroup={shouldShowApprovalGroup}
              buttonDisabled={buttonDisabled}
              approveACallback={approveACallback}
              approveBCallback={approveBCallback}
              revokeACallback={revokeACallback}
              revokeBCallback={revokeBCallback}
              onAdd={onAdd}
              onPresentAddLiquidityModal={onPresentAddLiquidityModal}
              isOneWeiAttack={isOneWeiAttack}
              errorText={errorText}
            />
          </AutoColumn>
        </CardBody>
      </AppBody>
      {!addIsUnsupported ? (
        pair && !noLiquidity && pairState !== PairState.INVALID ? (
          <AutoColumn style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', marginTop: '1rem' }}>
            <MinimalPositionCard showUnwrapped={oneCurrencyIsWPLS} pair={pair} />
          </AutoColumn>
        ) : null
      ) : (
        <UnsupportedCurrencyFooter currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]} />
      )}
    </Page>
  )
}
