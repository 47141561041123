import { ChainId } from "@pulsex/chains";
import { Currency, Token } from "@pulsex/sdk";
import memoize from "lodash/memoize";
import { getAddress } from "viem";

const mapping: { [key: number]: string } = {
  [ChainId.PULSECHAIN]: "pulsechain",
  [ChainId.PULSECHAIN_TESTNET]: "pulsechainTestnet",
};

export const getTokenLogoURL = memoize(
  (token?: Token) => {
    if (token && mapping[token.chainId]) {
      return token.chainId === ChainId.PULSECHAIN ? 
        `https://tokens.app.pulsex.com/images/tokens/${getAddress(token.address)}.png` :
        `https://tokens.app.v4.testnet.pulsex.com/images/tokens/${getAddress(token.address)}.png`
    }
    return null;
  },
  (t) => `${t?.chainId}#${t?.address}`
);

export const getCommonCurrencyUrl = memoize(
  (currency?: Currency) => `logoUrls#${currency?.chainId}#${currency?.symbol}`
);

export const getCurrencyLogoUrls = memoize(
  (currency: Currency | undefined): string[] => {
    const logoUri = getTokenLogoURL(currency?.wrapped);
    return [logoUri].filter(
      (url): url is string => !!url
    );
  },
  (currency: Currency | undefined) =>
    `logoUrls#${currency?.chainId}#${currency?.wrapped?.address} : ""}`
);
