import { styled } from "styled-components";
import { m as Motion } from "framer-motion";

export const Arrow = styled.div`
  &,
  &::before {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    z-index: -1;
  }

  &::before {
    content: "";
    transform: rotate(45deg);
    background: ${({ theme }) => theme.tooltip.background};
    border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  }
`;

export const StyledTooltip = styled(Motion.div)`
  padding: 16px;
  font-size: 16px;
  line-height: 130%;
  border-radius: 10px;
  max-width: 256px;
  z-index: 101;
  background: ${({ theme }) => theme.tooltip.background};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  color: ${({ theme }) => theme.colors.text};

  &[data-popper-placement^="top"] > ${Arrow} {
    bottom: -6px;
    &::before {
      border-top: none;
      border-left: none;
    }
  }

  &[data-popper-placement^="bottom"] > ${Arrow} {
    top: -6px;
    &::before {
      border-bottom: none;
      border-right: none;
    }
  }

  &[data-popper-placement^="left"] > ${Arrow} {
    right: -6px;
    &::before {
      border-bottom: none;
      border-left: none;
    }
  }

  &[data-popper-placement^="right"] > ${Arrow} {
    left: -6px;
    &::before {
      border-right: none;
      border-top: none;
    }
  }
` as typeof Motion.div;
