import { useWeb3React } from '@pulsex/wagmi'
import { useLocation } from 'react-router'
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom"
import { useEffect, useRef } from 'react'
import { ChainId } from '@pulsex/chains'
import { isChainSupported } from 'utils/wagmi'
import { CHAIN_QUERY_NAME, getChainId } from 'config/chains'
import { useActiveChainId } from './useActiveChainId'
import { useSwitchNetworkLoading } from './useSwitchNetworkLoading'

// TODO: workaround React Hash router does not work well with query params
// This hook is only used by NetworkSwitcher component that we dont use currently

export function useNetworkConnectorUpdater() {
  const { chainId } = useActiveChainId()
  const previousChainIdRef = useRef(chainId)
  const [loading] = useSwitchNetworkLoading()
  const { hash: uriHash } = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const chainQuery = searchParams.get("chain")

  useEffect(() => {
    const setPrevChainId = () => {
      previousChainIdRef.current = chainId
    }
    if (loading) return setPrevChainId()
    const parsedQueryChainId = getChainId(chainQuery as string)

    if (!parsedQueryChainId && chainId === ChainId.PULSECHAIN) return setPrevChainId()
    if (parsedQueryChainId !== chainId && isChainSupported(chainId)) {
      navigate({
        search: createSearchParams({ chain: CHAIN_QUERY_NAME[chainId] }).toString(),
        hash: uriHash
      },
        { replace: true }
      )
    }
    return setPrevChainId()
  }, [chainId, chainQuery, loading, navigate, uriHash])
}

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = () => {
  const web3React = useWeb3React()
  const { chainId, isWrongNetwork } = useActiveChainId()

  return {
    ...web3React,
    chainId,
    isWrongNetwork,
  }
}

export default useActiveWeb3React
