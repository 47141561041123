import orderBy from 'lodash/orderBy'
import { ChainId, V1_SUBGRAPHS, V2_SUBGRAPHS } from '@pulsex/chains'
import { BLOCKS_CLIENT, BLOCKS_CLIENT_TESTNET } from 'config/constants/endpoints'
import { ONE_DAY_UNIX, ONE_HOUR_SECONDS } from 'config/constants/info'
import dayjs from 'dayjs'
import request from 'graphql-request'
import mapValues from 'lodash/mapValues'
import { Block } from 'state/info/types'
import { getBlocksFromTimestamps } from 'utils/getBlocksFromTimestamps'
import { multiQuery } from 'pages/Info/utils/infoQueryHelpers'
import { getTVL, getDerivedPrices, getDerivedPricesQueryConstructor } from '../queries/getDerivedPrices'
import { PairDataTimeWindowEnum } from '../types'

const PROTOCOL = ['v1', 'v2'] as const
type Protocol = (typeof PROTOCOL)[number]

const SWAP_INFO_BY_CHAIN = {
  [ChainId.PULSECHAIN]: {
    v1: V1_SUBGRAPHS[ChainId.PULSECHAIN],
    v2: V2_SUBGRAPHS[ChainId.PULSECHAIN],
  },
  [ChainId.PULSECHAIN_TESTNET]: {
    v1: V1_SUBGRAPHS[ChainId.PULSECHAIN_TESTNET],
    v2: V2_SUBGRAPHS[ChainId.PULSECHAIN_TESTNET],
  },
}

export const BLOCKS_CLIENT_BY_CHAIN: Record<ChainId, string> = {
  [ChainId.PULSECHAIN]: BLOCKS_CLIENT,
  [ChainId.PULSECHAIN_TESTNET]: BLOCKS_CLIENT_TESTNET,
}

export const getTokenBestTvlProtocol = async (tokenAddress: string, chainId: ChainId): Promise<Protocol | null> => {
  const infos = SWAP_INFO_BY_CHAIN[chainId]
  if (infos) {
    const [v1, v2] = await Promise.allSettled([
      'v1' in infos ? request(infos.v1.url, getTVL(tokenAddress.toLowerCase())) : Promise.resolve(),
      'v2' in infos ? request(infos.v2.url, getTVL(tokenAddress.toLowerCase())) : Promise.resolve(),
    ])

    const results = [v1, v2]
    let bestProtocol: Protocol = 'v1'
    let bestTVL = 0
    for (const [index, result] of results.entries()) {
      if (result.status === 'fulfilled' && result.value && result.value.token) {
        if (+result.value.token.totalValueLocked > bestTVL) {
          bestTVL = +result.value.token.totalValueLocked
          bestProtocol = PROTOCOL[index]
        }
      }
    }

    return bestProtocol
  }

  return null
}

const getTokenDerivedUSDCPrices = async (tokenAddress: string, blocks: Block[], endpoint: string) => {
  const rawPrices: any | undefined = await multiQuery(
    getDerivedPricesQueryConstructor,
    getDerivedPrices(tokenAddress, blocks),
    endpoint,
    200,
  )

  if (!rawPrices) {
    console.error('Price data failed to load')
    return null
  }

  const prices = mapValues(rawPrices, (value) => {
    return value?.derivedUSD
  })

  // format token PLS price results
  const tokenPrices: {
    tokenAddress: string
    timestamp: string
    derivedUSD: number
  }[] = []

  // Get Token prices in PLS
  Object.keys(prices).forEach((priceKey) => {
    const timestamp = priceKey.split('t')[1]
    if (timestamp) {
      tokenPrices.push({
        tokenAddress,
        timestamp,
        derivedUSD: prices[priceKey] ? parseFloat(prices[priceKey]) : 0,
      })
    }
  })

  return orderBy(tokenPrices, (tokenPrice) => parseInt(tokenPrice.timestamp, 10))
}

const getInterval = (timeWindow: PairDataTimeWindowEnum) => {
  switch (timeWindow) {
    case PairDataTimeWindowEnum.DAY:
      return ONE_HOUR_SECONDS
    case PairDataTimeWindowEnum.WEEK:
      return ONE_HOUR_SECONDS * 4
    case PairDataTimeWindowEnum.MONTH:
      return ONE_DAY_UNIX
    case PairDataTimeWindowEnum.YEAR:
      return ONE_DAY_UNIX * 15
    default:
      return ONE_HOUR_SECONDS * 4
  }
}

const getSkipDaysToStart = (timeWindow: PairDataTimeWindowEnum) => {
  switch (timeWindow) {
    case PairDataTimeWindowEnum.DAY:
      return 1
    case PairDataTimeWindowEnum.WEEK:
      return 7
    case PairDataTimeWindowEnum.MONTH:
      return 30
    case PairDataTimeWindowEnum.YEAR:
      return 365
    default:
      return 7
  }
}

// Fetches derivedPls values for tokens to calculate derived price
// Used when no direct pool is available
const fetchDerivedPriceData = async (
  token0Address: string,
  token1Address: string,
  timeWindow: PairDataTimeWindowEnum,
  protocol0: Protocol,
  protocol1: Protocol,
  chainId: ChainId,
) => {
  const interval = getInterval(timeWindow)
  const endTimestamp = dayjs()
  const endTimestampUnix = endTimestamp.unix()
  let startTimestamp = endTimestamp.subtract(getSkipDaysToStart(timeWindow), 'days').startOf('hour').unix()
  // check if we are going too far back, if so, set to subgraph deployment timestamp
  if (startTimestamp < SWAP_INFO_BY_CHAIN[chainId][protocol0].timestamp) {
    startTimestamp = SWAP_INFO_BY_CHAIN[chainId][protocol0].timestamp
  }
  const timestamps: number[] = []
  let time = startTimestamp
  if (!SWAP_INFO_BY_CHAIN[chainId][protocol0] || !SWAP_INFO_BY_CHAIN[chainId][protocol1]) {
    return null
  }
  while (time <= endTimestampUnix) {
    timestamps.push(time)
    time += interval
  }

  try {
    const blocks = await getBlocksFromTimestamps(timestamps, 'asc', 500, chainId)
    if (!blocks || blocks.length === 0) {
      console.error('Error fetching blocks for timestamps', timestamps)
      return null
    }
    blocks.pop()
    const [token0DerivedUSD, token1DerivedUSD] = await Promise.all([
      getTokenDerivedUSDCPrices(token0Address, blocks, SWAP_INFO_BY_CHAIN[chainId][protocol0].url),
      getTokenDerivedUSDCPrices(token1Address, blocks, SWAP_INFO_BY_CHAIN[chainId][protocol1].url),
    ])
    return { token0DerivedUSD, token1DerivedUSD }
  } catch (error) {
    console.error('Failed to fetched derived price data for chart', error)
    return null
  }
}

export default fetchDerivedPriceData
