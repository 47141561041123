import { TOTAL_FEE, LP_HOLDERS_FEE, WEEKS_IN_YEAR } from '../config/constants/info'

export const getLpFeesAndApr = (volumeUSD: number, volumeUSDWeek: number, liquidityUSD: number) => {
  const totalFees24h = volumeUSD * TOTAL_FEE
  const totalFees7d = volumeUSDWeek * TOTAL_FEE
  const lpFees24h = volumeUSD * LP_HOLDERS_FEE
  const lpFees7d = volumeUSDWeek * LP_HOLDERS_FEE

  const lpApr7d = liquidityUSD > 0 ? (volumeUSDWeek * LP_HOLDERS_FEE * WEEKS_IN_YEAR * 100) / liquidityUSD : 0
  return {
    totalFees24h,
    totalFees7d,
    lpFees24h,
    lpFees7d,
    lpApr7d: lpApr7d !== Infinity ? lpApr7d : 0,
  }
}

export const getStableLpFeesAndApr = (volumeUSD: number, volumeUSDWeek: number, liquidityUSD: number) => {
  // TODO: get stable pool fees from the contract
  const TOTAL_FEE_STABLESWAP = 0.0004
  const LP_HOLDERS_FEE_STABLESWAP = 0.0002
  const totalFees24h = volumeUSD * TOTAL_FEE_STABLESWAP
  const totalFees7d = volumeUSDWeek * TOTAL_FEE_STABLESWAP
  const lpFees24h = volumeUSD * LP_HOLDERS_FEE_STABLESWAP
  const lpFees7d = volumeUSDWeek * LP_HOLDERS_FEE_STABLESWAP

  const lpApr7d = liquidityUSD > 0 ? (volumeUSDWeek * LP_HOLDERS_FEE_STABLESWAP * WEEKS_IN_YEAR * 100) / liquidityUSD : 0
  return {
    totalFees24h,
    totalFees7d,
    lpFees24h,
    lpFees7d,
    lpApr7d: lpApr7d !== Infinity ? lpApr7d : 0,
  }
}
