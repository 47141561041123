import BigNumber from 'bignumber.js'
import { styled, keyframes, css } from 'styled-components'
import { useTranslation } from '@pulsex/localization'
import { LinkExternal, Text } from '@pulsex/uikit'
import { FarmWidget } from '@pulsex/widgets-internal'
import { FarmWithStakedValue } from '@pulsex/farms'
import getLiquidityUrlPathParts from 'utils/getLiquidityUrlPathParts'
import { getBlockExplorerLink } from 'utils'
import { useActiveChainId } from 'hooks/useActiveChainId'
import StakedAction, { StakedContainer } from './StakedAction'
import HarvestAction, { HarvestActionContainer } from './HarvestAction'
import Apr, { AprProps } from '../Apr'

const { Liquidity } = FarmWidget.FarmTable
const { ProtocolTag } = FarmWidget.Tags

export interface ActionPanelProps {
  apr: AprProps
  liquidity: FarmWidget.FarmTableLiquidityProps
  details: FarmWithStakedValue
  userDataReady: boolean
  expanded: boolean
  protocol: string
  weight: BigNumber
}

const expandAnimation = keyframes`
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
`

const collapseAnimation = keyframes`
  from {
    max-height: 500px;
  }
  to {
    max-height: 0px;
  }
`

const Container = styled("div").withConfig({
  shouldForwardProp: (props) => !["expanded"].includes(props),
})<{ expanded }>`
  animation: ${({ expanded }) =>
    expanded
      ? css`
          ${expandAnimation} 300ms linear forwards
        `
      : css`
          ${collapseAnimation} 300ms linear forwards
        `};
  overflow: hidden;
  background-image: ${({ theme }) => theme.colors.gradientInverseBubblegum};
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  padding: 24px;

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
    align-items: center;
    padding: 16px 32px;
  }
`

const StyledLinkExternal = styled(LinkExternal)`
  font-weight: 400;
`

const StakeContainer = styled.div`
  color: ${({ theme }) => theme.colors.text};
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
  }
`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
  }
`

const InfoContainer = styled.div`
  min-width: 200px;
`

const ValueContainer = styled.div`
  display: block;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: none;
  }
`

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0px;
`

const ActionPanel: React.FC<React.PropsWithChildren<ActionPanelProps>> = ({
  details,
  apr,
  liquidity,
  userDataReady,
  expanded,
  protocol,
  weight
}) => {
  const farm = details

  const { t } = useTranslation()
  const { chainId } = useActiveChainId()
  const isActive = farm.multiplier !== '0X'
  const { quoteToken, token } = farm
  const lpLabel = farm.lpSymbol && farm.lpSymbol.toUpperCase().replace('PulseX', '')
  const liquidityUrlPathParts = getLiquidityUrlPathParts({
    protocol,
    quoteTokenAddress: quoteToken.address,
    tokenAddress: token.address,
  })
  const { lpAddress } = farm
  const blockExplorerLink = getBlockExplorerLink(lpAddress, 'address', chainId)
  const info = protocol === 'V1' ? `/info/pool/${lpAddress}` : `/info/v2/pool/${lpAddress}`

  return (
    <Container expanded={expanded}>
      <InfoContainer>
        {isActive && (
          <StakeContainer>
            <StyledLinkExternal to={`/add/${liquidityUrlPathParts}`}>
              {t('Add LP')}
            </StyledLinkExternal>
          </StakeContainer>
        )}
        <StyledLinkExternal to={blockExplorerLink}>{t('View Contract')}</StyledLinkExternal>
        <StyledLinkExternal to={info}>{t('See Pair Info')}</StyledLinkExternal>
      </InfoContainer>
      <ValueContainer>
        <ValueWrapper>
          <Text>{t('Type')}</Text>
          <ProtocolTag protocol={protocol} scale="sm" marginRight="12px" />
        </ValueWrapper>
        <ValueWrapper>
          <Text>{t('APR')}</Text>
          <Apr {...apr} />
        </ValueWrapper>
        <ValueWrapper>
          <Text>{t('Liquidity')}</Text>
          <Liquidity {...liquidity} />
        </ValueWrapper>
        <ValueWrapper>
          <Text>{t('Weight')}</Text>
          <Text marginRight="14px">{`${weight.times(100).toFixed(2)}%`}</Text>
        </ValueWrapper>
      </ValueContainer>
      <ActionContainer>
        <HarvestActionContainer {...farm} userDataReady={userDataReady} lpLabel={lpLabel} displayApr={apr.value}>
          {(props) => <HarvestAction {...props} />}
        </HarvestActionContainer>
        <StakedContainer {...farm} userDataReady={userDataReady} lpLabel={lpLabel} displayApr={apr.value}>
          {(props) => <StakedAction {...props} />}
        </StakedContainer>
      </ActionContainer>
    </Container>
  )
}

export default ActionPanel
