import { useTranslation } from '@pulsex/localization'
import { PLSX } from '@pulsex/tokens'
import {
  Flex, ButtonMenu, ButtonMenuItem, Box, Text, Heading,
  Card, Button, PageHeader, useToast
} from '@pulsex/uikit'
import { formatAmount } from '@pulsex/utils/formatInfoNumbers'
import BigNumber from 'bignumber.js'
import { useState } from 'react'
import { Address } from 'viem'
import { useAccount } from 'wagmi'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { ToastDescriptionWithTx } from 'components/Toast'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useCatchTxError from 'hooks/useCatchTxError'
import { useBuyAndBurnContract } from 'hooks/useContract'
import { useStablecoinPrice } from 'hooks/useStablecoinPrice'
import { useBuyAndBurnPairs } from 'state/buyandburn/hooks'
import { useFeeDataWithGasPrice } from 'state/user/hooks'
import { useProtocol } from 'state/user/smartRouter'
import { getViemClients } from 'utils/viem'
import PoolTable from './PoolsTable'
import { ChartCardsContainer } from './shared'

const Burn: React.FC = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()
  const { maxFeePerGas, maxPriorityFeePerGas } = useFeeDataWithGasPrice()
  const { toastSuccess } = useToast()
  const { fetchWithCatchTxError } = useCatchTxError()
  const [reset, setReset] = useState(false)
  const [checked, setChecked] = useState<string[]>([])
  const [selectedUSD, setSelectedUSD] = useState(0)
  const plsxPrice = useStablecoinPrice(PLSX[chainId])
  const plsxPriceBN = new BigNumber(plsxPrice?.toSignificant() ?? 0)


  const [protocol, setProtocol] = useProtocol()
  const buyandburn = useBuyAndBurnContract(protocol)
  const client = getViemClients({ chainId })

  const { result, isLoading } = useBuyAndBurnPairs(chainId, protocol, buyandburn, client)

  const handleCheck = (event) => {
    let updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const calculateUSD = (e, USDValue) => {
    let updatedCout = selectedUSD;
    if (e.target.checked) {
      updatedCout += USDValue;
    } else {
      updatedCout -= USDValue;
    }
    if (updatedCout < 0) {
      updatedCout = 0
    }
    setSelectedUSD(updatedCout)
  }

  const burn = async () => {
    const tokensArray0: string[] = []
    const tokensArray1: string[] = []
    for (let i = 0; i < checked.length; i++) {
      tokensArray0.push(checked[i].slice(0, 42))
      tokensArray1.push(checked[i].slice(43, 85))
    }
    const tokArr0 = tokensArray0 as Address[]
    const tokArr1 = tokensArray1 as Address[]
    const receipt = await fetchWithCatchTxError(async () => {
      const gasEstimate = await buyandburn.estimateGas.convertLps([tokArr0, tokArr1], {})
      return buyandburn.write.convertLps([tokArr0, tokArr1], {
        gas: gasEstimate,
        maxFeePerGas,
        maxPriorityFeePerGas,
        value: undefined,
        account: buyandburn.account || '0x',
        chain: buyandburn.chain,
      })
    })
    if (receipt?.status) {
      toastSuccess(
        t('PLSX Burned!'),
        <ToastDescriptionWithTx txHash={receipt.transactionHash}>
          {t('PLSX bounty has been sent to your wallet.')}
        </ToastDescriptionWithTx>,
      )
    }
    setChecked([])
    setSelectedUSD(0)
    setReset(true)
  }

  const handleReset = () => {
    setReset(false)
  }

  const protocolSelector =
    <ButtonMenu
      scale="sm"
      activeIndex={protocol === 'V1' ? 0 : 1}
      onItemClick={(index) => {
        setProtocol(index === 0 ? 'V1' : 'V2')
        setChecked([])
        setSelectedUSD(0)
        setReset(true)
      }}
      variant="subtle"
    >
      <ButtonMenuItem>V1</ButtonMenuItem>
      <ButtonMenuItem>V2</ButtonMenuItem>
    </ButtonMenu>

  return (
    <PageHeader>
      <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
        <Flex flex="2" flexDirection="column">
          <Heading scale="xl" mb="16px" id="info-overview-title">
            {t('Burn')}
          </Heading>
          <Heading scale="md" mb="16px" id="info-overview-title">
            {t('Select LP tokens to buy and burn PLSX')}
          </Heading>
        </Flex>
        <ChartCardsContainer>
          <Card>
            <Box p={['16px', '16px', '24px']}>
              <Flex alignItems="center" justifyContent="space-between">
                <Text bold fontSize="24px" color="secondary">
                  {t('Burn')} PLSX
                </Text>
                <Text fontSize="13px">
                  {t('Total PLSX burned')}: {formatAmount(result?.totalPlsxBurned.toNumber())}🔥
                </Text>
              </Flex>
              <Text color="textSubtle">{t('Selected USD value')}: ${formatAmount(selectedUSD)}</Text>
              <Text color="textSubtle">{t('This would burn')}: ~{formatAmount(selectedUSD / plsxPriceBN.toNumber())} PLSX</Text>
              <Text bold>
                {t('Bounty')}: {formatAmount(((selectedUSD - (selectedUSD * 0.1429)) / plsxPriceBN.toNumber()) * 0.001)} PLSX
                / ~${formatAmount((selectedUSD - (selectedUSD * 0.1429)) * 0.001)}
              </Text>
              <Box>
                <Flex paddingTop="20px" alignContent="center" justifyContent="center">
                  {account ? (
                    <Button width="100%" onClick={burn}>
                      {t('Burn').toLocaleUpperCase()}
                    </Button>
                  ) : (
                    <ConnectWalletButton width="100%" />
                  )}
                </Flex>
              </Box>
            </Box>
          </Card>
        </ChartCardsContainer>
      </Flex>

      <Flex alignItems="left" mt="40px" mb="16px">
        {protocolSelector}
      </Flex>

      <PoolTable
        lpData={result?.lpData ?? []}
        isLoading={isLoading}
        handleCheck={handleCheck}
        calculateUSD={calculateUSD}
        reset={reset}
        handleReset={handleReset}
      />
    </PageHeader>
  )
}

export default Burn
