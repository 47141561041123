import { ChainId } from '@pulsex/chains'
import { Address } from 'viem'

export const MULTICALL_ADDRESS: { [key in ChainId]?: Address } = {
  [ChainId.PULSECHAIN]: '0xD77D4b2F85590628Adc0D42189fD3Af4B9679d62',
  [ChainId.PULSECHAIN_TESTNET]: '0xA2313da2da9bf47DCE5f3cd36BE827ECd72aE685',
}

export const MULTICALL3_ADDRESS = '0xdc6ed7C2EE5ab7fd303334B34F7524Cbb463DE57'

export const MULTICALL3_ADDRESSES: {
  [key in ChainId]?: Address
} = {
  [ChainId.PULSECHAIN]: MULTICALL3_ADDRESS,
  [ChainId.PULSECHAIN_TESTNET]: "0x083F559993227395009596f77496FDc48e84C69a",
}
