import { styled } from 'styled-components'
import {
  Flex,
  Text,
  ArrowBackIcon,
  QuestionHelper,
} from '@pulsex/uikit'
import { Link } from 'react-router-dom'

interface Props {
  title: string
  subtitle?: string
  helper?: string
  backTo?: string
  protocolSelector?: JSX.Element
}

const AppHeaderContainer = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  padding: 17px 24px 17px 24px;
  width: 100%;
  gap: 16px;
`

const AppHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  helper,
  backTo,
  protocolSelector
}) => {
  return (
    <AppHeaderContainer>
      <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between" width="100%" style={{ gap: '16px' }}>
        <Flex alignItems="center">
          {backTo && (
            <Link to={backTo}>
              <ArrowBackIcon color="primary" width="22px" mr={1}/>
            </Link>
          )}
          <Text fontSize="17px" bold>
            {title}
          </Text>
          <Flex alignItems="center">{helper && <QuestionHelper size="18px" text={helper} ml="4px" />}</Flex>
        </Flex>
        {protocolSelector && <Flex>{protocolSelector}</Flex>}
      </Flex>
    </AppHeaderContainer>
  )
}

export default AppHeader
