import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { Flex, Menu as UikitMenu, NotificationDot } from '@pulsex/uikit'
import { languageList, useTranslation } from '@pulsex/localization'
import { PLSX } from '@pulsex/tokens'
import { useExpertMode } from '@pulsex/utils/user'
import { useTheme } from 'styled-components'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useStablecoinPrice } from 'hooks/useStablecoinPrice'
import config from './config/config'
import { hamburgerConfig } from './config/hamburgerConfig'
import UserMenu from './UserMenu'
import GlobalSettings from './GlobalSettings'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'

const Menu = (props) => {
  const { chainId } = useActiveChainId()
  const { isDark } = useTheme()
  const pulsexPriceUsd = useStablecoinPrice(PLSX[chainId])
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useLocation()
  const [expertMode] = useExpertMode()
  const activeMenuItem = getActiveMenuItem({ menuConfig: config(t), pathname })
  const activeHamburgerMenuItem = getActiveMenuItem({ menuConfig: hamburgerConfig(t), pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })
  const activeHamburgerSubMenuItem = getActiveSubMenuItem({ menuItem: activeHamburgerMenuItem, pathname })

  return (
    <>
      <UikitMenu
        linkComponent={(linkProps) => {
          return <Link to={linkProps.href} {...linkProps} />
        }}
        userMenu={<UserMenu />}
        globalMenu={
          <Flex mr="8px">
            <NotificationDot show={expertMode}>
              <GlobalSettings />
            </NotificationDot>
          </Flex>
        }
        isDark={isDark}
        currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        plsxPriceUsd={pulsexPriceUsd}
        links={config(t)}
        hamburgerLinks={hamburgerConfig(t)}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href || activeHamburgerSubMenuItem?.href}
        activeHamburgerItem={activeHamburgerMenuItem?.href}
        buyPulseXLabel='Buy PLSX'
        {...props}
      />
    </>
  )
}

export default Menu
