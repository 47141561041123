import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <path d="m7 2.3008c-1.0816 0.018868-0.75383 1.6118 0.19344 1.3984h4.8066c0.41152 0.033714 0.27829 0.50318 0.30078 0.78438v4.5164c0.02258 1.0761 1.6014 0.73707 1.3984-0.19344-0.0058-1.6596 0.01168-3.3199-0.0089-4.979-0.0742-0.91769-0.97742-1.6259-1.8838-1.5268h-4.8066z" />
      <path d="m3 6.3008c-0.68577-0.00114-0.72913 0.71215-0.69922 1.2151 0.00579 1.5521-0.01166 3.105 0.00886 4.6566 0.074199 0.91769 0.97742 1.6259 1.8838 1.5268h4.8066c1.0816-0.01887 0.75383-1.6118-0.19344-1.3984h-4.8066c-0.41152-0.03371-0.27829-0.50318-0.30078-0.78438v-4.5164c0.0098519-0.37345-0.32577-0.70907-0.69922-0.69922z" />
    </Svg>
  );
};

export default Icon;
