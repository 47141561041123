import { ChainId } from '@pulsex/chains'
import FarmsPLSPriceHelper from './369'
import FarmsPLSTestnetPriceHelper from './943'

export const getFarmsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.PULSECHAIN:
      return FarmsPLSPriceHelper
    case ChainId.PULSECHAIN_TESTNET:
      return FarmsPLSTestnetPriceHelper
    default:
      return []
  }
}
