import React from "react";
import { Link as ReactLink } from 'react-router-dom';
import { styled } from "styled-components";
import EXTERNAL_LINK_PROPS from "../../util/externalLinkProps";
import Text from "../Text/Text";
import { StyledLinkProps } from "./types";

export const StyledLink = styled(Text)<StyledLinkProps>`
  display: flex;
  align-items: center;
  width: fit-content;
  &:hover {
    text-decoration: underline;
  }
`;

export const Link: React.FC<React.PropsWithChildren<StyledLinkProps>> = ({ external, to, target, rel, ...props }) => {
  const internalProps = external ? EXTERNAL_LINK_PROPS : {};
  return (
    <ReactLink target={target} rel={rel} to={to ?? ''} {...internalProps}>
      <StyledLink to={to} {...props} />
    </ReactLink>
  )
};

/* eslint-disable react/default-props-match-prop-types */
Link.defaultProps = {
  color: "primary",
};
