import { ChainId } from '@pulsex/chains'
import BigNumber from 'bignumber.js'
import fromPairs from 'lodash/fromPairs.js'
import { Address, erc20Abi } from 'viem'
import { plsxLabAbi } from '../abis/plsxLab'
import { getPoolsConfig } from '../constants'
import { OnChainProvider, SerializedPool } from '../types'

const getPoolsFactory = (filter: (pool: SerializedPool) => boolean) => (chainId: ChainId) => {
  const poolsConfig = getPoolsConfig(chainId)
  if (!poolsConfig) {
    throw new Error(`Unable to get pools config on chain ${chainId}`)
  }
  return poolsConfig.filter(filter)
}
const getNonPlsPools = getPoolsFactory((pool) => pool.stakingToken.symbol !== 'BNB')
const getPlsPools = getPoolsFactory((pool) => pool.stakingToken.symbol === 'BNB')

interface FetchUserDataParams {
  account: string
  chainId: ChainId
  provider: OnChainProvider
}

export const fetchPoolsAllowance = async ({ account, chainId, provider }: FetchUserDataParams) => {
  const nonPlsPools = getNonPlsPools(chainId)
  const client = provider({ chainId })

  const allowances = await client.multicall({
    contracts: nonPlsPools.map(
      ({ contractAddress, stakingToken }) =>
        ({
          address: stakingToken.address,
          abi: erc20Abi,
          functionName: 'allowance',
          args: [account as Address, contractAddress] as const,
        } as const),
    ),
    allowFailure: false,
  })

  return fromPairs(
    nonPlsPools.map((pool, index) => [pool.poolId, new BigNumber(allowances[index].toString()).toJSON()]),
  )
}

export const fetchUserBalances = async ({ account, chainId, provider }: FetchUserDataParams) => {
  const nonPlsPools = getNonPlsPools(chainId)
  const plsPools = getPlsPools(chainId)

  const client = provider({ chainId })

  // Non PLS pools
  const calls = nonPlsPools.map((pool) => ({
    abi: erc20Abi,
    address: pool.stakingToken.address,
    functionName: 'balanceOf',
    args: [account] as const,
  } as const))

  const tokenBalancesRaw = await client.multicall({
    contracts: calls,
    allowFailure: false,
  }) as any

  const tokenBalances = fromPairs(nonPlsPools.map((token, index) => [token, tokenBalancesRaw[index].result as bigint]))

  // PLS pools
  const plsBalance = await client.getBalance({ address: account as Address})
  const plsBalances = plsPools.reduce(
    (acc, pool) => ({ ...acc, [pool.poolId]: new BigNumber(plsBalance.toString()).toJSON() }),
    {},
  )

  return { ...tokenBalances, ...plsBalances }
}

export const fetchUserStakeBalances = async ({ account, chainId, provider }: FetchUserDataParams) => {
  const poolsConfig = getPoolsConfig(chainId)
  if (!poolsConfig) {
    throw new Error(`Unable to get pools config on chain ${chainId}`)
  }
  const client = provider({ chainId })

  const calls = poolsConfig.map((p) => ({
    abi: plsxLabAbi,
    address: p.contractAddress,
    functionName: 'userInfo',
    args: [p.poolId, account as Address] as const,
  } as const))

  const userInfo = await client.multicall({
    contracts: calls,
    allowFailure: false,
  }) as any

  const stakedBalances = poolsConfig.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.poolId]: new BigNumber(userInfo[index].amount._hex).toJSON(),
    }),
    {},
  )

  return { ...stakedBalances }
}

export const fetchUserPendingRewards = async ({ account, chainId, provider }: FetchUserDataParams) => {
  const poolsConfig = getPoolsConfig(chainId)
  if (!poolsConfig) {
    throw new Error(`Unable to get pools config on chain ${chainId}`)
  }
  const client = provider({ chainId })

  const res = await client.multicall({
    contracts: poolsConfig.map(
      ({ contractAddress }) =>
        ({
          abi: plsxLabAbi,
          address: contractAddress,
          functionName: 'pendingReward',
          args: [account as Address] as const,
        } as const),
    ),
    allowFailure: false,
  }) as any

  return fromPairs(poolsConfig.map((pool, index) => [pool.poolId, new BigNumber(res[index].toString()).toJSON()]))
}
