import { ChainId } from '@pulsex/chains'
import { CurrencyAmount, Token, WNATIVE, PairV1, PairV2, Fraction, ERC20Token } from '@pulsex/sdk'
import { getStableSwapPools } from '@pulsex/stable-swap-sdk'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { DAI } from '@pulsex/tokens'
import BigNumber from 'bignumber.js'
import { Address, erc20Abi, formatEther, PublicClient } from 'viem'
import { pulsexPairAbi } from 'config/abi/IPulseXPair'
import { POOLS_API } from 'config/constants/endpoints'
import { useBuyAndBurnContract } from 'hooks/useContract'
import { useCurrentBlock } from 'state/block/hooks'
import { BuyAndBurnLpResponse, LP, Reserves } from 'state/buyandburn/types'
import { viemClients } from 'utils/viem'
import lpListV1 from './lists/lpListV1.json'
import lpListV2 from './lists/lpListV2.json'
import testnetLpListV1 from './lists/testnetLpListV1.json'
import testnetLpListV2 from './lists/testnetLpListV2.json'

type UniqueTokensMap = {
  [address: string]: Token
}

type FetchBuyAndBurnPairsResult = {
  result: { lpData: BuyAndBurnLpResponse[], totalPlsxBurned: BigNumber } | undefined
  isLoading: boolean
}

const lpListFallback = (chainId: number, protocol: string) => {
  return chainId === ChainId.PULSECHAIN ? (protocol === 'V1' ? lpListV1 as LP[] : lpListV2 as LP[]) :
    chainId === ChainId.PULSECHAIN_TESTNET ? (protocol === 'V1' ? testnetLpListV1 as LP[] : testnetLpListV2 as LP[]) : undefined
}

const getPair = (token0: Token, token1: Token, reserves: Reserves, protocol: string) => {
  const Pair = protocol === 'v1' ? PairV1 : PairV2
  const tok0 = token0.address.toLowerCase() < token1.address.toLowerCase() ? token0 : token1
  const tok1 = token0.address.toLowerCase() < token1.address.toLowerCase() ? token1 : token0

  const currencyAmount0 = CurrencyAmount.fromRawAmount(tok0, reserves.token0)
  const currencyAmount1 = CurrencyAmount.fromRawAmount(tok1, reserves.token1)
  return new Pair(currencyAmount0, currencyAmount1)
}

export const useBuyAndBurnPairs = (
  chainId: number,
  protocol: string,
  buyandburn: ReturnType<typeof useBuyAndBurnContract>,
  client: PublicClient
): FetchBuyAndBurnPairsResult => {
  const currentBlock = useCurrentBlock()
  const { data: lpList, isLoading: lpListLoading } = useQuery({
    queryKey: ['buyAndBurnLpList', chainId, protocol],
    queryFn: async () => {
      const response = await fetch(`${POOLS_API}?chainId=${chainId.toString()}&protocol=${protocol.toLowerCase()}`)
      if (response.ok) {
        const list = await response.json() as LP[]
        if (list.length > 0) {
          // validate each lp by calling gasEstimate on burn function
          const gasEstimatesPromises = list.map(async (lp) => {
            const token0 = lp.reserve0?.currency.address
            const token1 = lp.reserve1?.currency.address
            try {
              const gasLimit = await viemClients[chainId].estimateContractGas({
                address: buyandburn.address,
                abi: buyandburn.abi,
                functionName: 'convertLps',
                account: buyandburn.address,
                args: [[token0], [token1]],
              });
              return { status: 'fulfilled', value: gasLimit, lp };
            } catch (error) {
              return { status: 'rejected', reason: error, lp };
            }
          })

          const validLps = (await Promise.allSettled(gasEstimatesPromises))
            .filter(
              (result): result is PromiseFulfilledResult<{ status: string; value: bigint; lp: LP; reason?: undefined }> => {
                return result.status === 'fulfilled' && typeof result.value.value === 'bigint'
              }
            ).map(result => ({ ...result.value.lp, gasEstimate: result.value.value } as LP))
            // filter out high gas estimates as they will fail on execution
            .filter(lp => lp.gasEstimate && lp.gasEstimate <= 10000000n)

          return validLps
        }
        return lpListFallback(chainId, protocol)
      }
      // fallback to static pool list
      return lpListFallback(chainId, protocol)
    },
    enabled: Boolean(chainId && protocol),
  })

  const { data: lpListWithData, status: lpListWithDataStatus, isPlaceholderData } = useQuery({
    queryKey: ['buyAndBurnLpInfo', chainId, protocol, lpList?.length, currentBlock],
    queryFn: async () => {
      if (!lpList) return undefined

      const stablePools = getStableSwapPools(chainId)
      const allStableTokenAddresses = stablePools.reduce((acc, pool) => {
        const tokenAddresses = [pool.token0, pool.token1, pool.token2]
          .filter((token): token is Token => Boolean(token))
          .map(token => token.address as Address)
        return acc.concat(tokenAddresses)
      }, [] as Address[])
      const allStables = Array.from(new Set(allStableTokenAddresses))

      const contractCalls = lpList.map(lp => {
        return [
          {
            abi: pulsexPairAbi,
            address: lp.address as Address,
            functionName: 'getReserves',
          } as const,
          {
            abi: pulsexPairAbi,
            address: lp.address as Address,
            functionName: 'totalSupply',
          } as const,
          {
            abi: pulsexPairAbi,
            address: lp.address as Address,
            functionName: 'balanceOf',
            args: [buyandburn.address],
          } as const,
          {
            abi: erc20Abi,
            address: lp.reserve0.currency.address as Address,
            functionName: 'balanceOf',
            args: [buyandburn.address],
          } as const,
          {
            abi: erc20Abi,
            address: lp.reserve1.currency.address as Address,
            functionName: 'balanceOf',
            args: [buyandburn.address],
          } as const
        ]
      })

      const multicallResults = await Promise.all(contractCalls.map(async (contracts) => {
        const results = await client.multicall({
          contracts,
          allowFailure: true,
        })
        return results
      }))

      const lpData = multicallResults.map((res, index) => {
        for (const r of res) {
          if (r.status !== 'success') {
            return undefined
          }
        }
        if (res[2]?.result === 0n) return undefined

        const token0Address = lpList[index].reserve0?.currency.address
        const token0Decimals = lpList[index].reserve0?.currency.decimals
        const token0Symbol = lpList[index].reserve0?.currency.symbol
        const token1Address = lpList[index].reserve1?.currency.address
        const token1Decimals = lpList[index].reserve1?.currency.decimals
        const token1Symbol = lpList[index].reserve1?.currency.symbol

        const token0 = new Token(chainId, token0Address, token0Decimals, token0Symbol)
        const token1 = new Token(chainId, token1Address, token1Decimals, token1Symbol)
        const isToken0Stable = protocol === 'V2' && allStables.includes(token0.address)
        const isToken1Stable = protocol === 'V2' && allStables.includes(token1.address)

        const stableBalances = {
          token0: isToken0Stable ? CurrencyAmount.fromRawAmount(token0, res[3]?.result as bigint) : undefined,
          token1: isToken1Stable ? CurrencyAmount.fromRawAmount(token1, res[4]?.result as bigint) : undefined
        }

        const reserves = {
          token0: res[0]?.result?.[0],
          token1: res[0]?.result?.[1],
        } as Reserves

        const pair = getPair(token0, token1, reserves, protocol)
        const lpToken = pair.liquidityToken
        const totalSupply = CurrencyAmount.fromRawAmount(lpToken, res[1].result as bigint)
        const lpBalance = CurrencyAmount.fromRawAmount(lpToken, res[2].result as bigint)
        const token0Amount = pair.getLiquidityValue(token0, totalSupply, lpBalance, false)
        const token1Amount = pair.getLiquidityValue(token1, totalSupply, lpBalance, false)

        return {
          pair,
          totalSupply,
          lpBalance,
          token0Amount,
          token1Amount,
          stableBalances,
        }
      }).filter((lp): lp is BuyAndBurnLpResponse => lp !== undefined)

      return lpData
    },
    enabled: Boolean(lpList && !lpListLoading),
    placeholderData: keepPreviousData,
  })

  const lpListWithDataLoading = lpListWithDataStatus === 'pending' || isPlaceholderData

  const { data: lpListPrices, status: lpListPricesStatus, isPlaceholderData: lpListPricesIsPlaceholderData } = useQuery({
    queryKey: ['tokenPrices', chainId, protocol, lpListWithData?.length, currentBlock],
    queryFn: async () => {
      if (!lpListWithData) return undefined
      const wplsAddr = WNATIVE[chainId].address
      const daiAddr = DAI[chainId].address

      const Pair = protocol === 'v1' ? PairV1 : PairV2
      const wplsDaiAddr = Pair.getAddress(WNATIVE[chainId], DAI[chainId])

      const uniqueTokensSet = new Set<Token>()
      lpListWithData.forEach(lp => {
        if (lp.pair.token0.address !== wplsAddr && lp.pair.token0.address !== daiAddr) {
          uniqueTokensSet.add(lp.pair.token0)
        }
        if (lp.pair.token1.address !== wplsAddr && lp.pair.token1.address !== daiAddr) {
          uniqueTokensSet.add(lp.pair.token1)
        }
      })
      const uniqueTokensArray: Token[] = Array.from(uniqueTokensSet)

      const uniqueTokensMap: UniqueTokensMap = uniqueTokensArray.reduce((acc, token) => {
        return { ...acc, [token.address]: token }
      }, {} as UniqueTokensMap)

      const contractCalls = uniqueTokensArray.map(token => {
        const wplsPair = Pair.getAddress(WNATIVE[chainId], token)
        const daiPair = Pair.getAddress(DAI[chainId], token)

        return [
          {
            abi: pulsexPairAbi,
            address: wplsPair,
            functionName: 'token0',
          } as const,
          {
            abi: pulsexPairAbi,
            address: wplsPair,
            functionName: 'token1',
          } as const,
          {
            abi: pulsexPairAbi,
            address: wplsPair,
            functionName: 'getReserves',
          } as const,
          {
            abi: pulsexPairAbi,
            address: daiPair,
            functionName: 'getReserves',
          } as const,
        ]
      })

      const multicallResults = await Promise.all(contractCalls.map(async (contracts) => {
        const results = await client.multicall({
          contracts,
          allowFailure: true,
        })
        return results
      }))

      const wplsDaiReserves = await client.readContract({
        abi: pulsexPairAbi,
        address: wplsDaiAddr,
        functionName: 'getReserves',
      })
      const reserves = {
        token0: wplsDaiReserves[0],
        token1: wplsDaiReserves[1]
      } as Reserves

      const wplsDaiPair = getPair(WNATIVE[chainId], DAI[chainId], reserves, protocol)
      const wplsPrice = wplsDaiPair.priceOf(WNATIVE[chainId])

      const tokenPriceData: { [address: string]: Fraction } = {};

      multicallResults.forEach((res) => {
        for (const r of res) {
          if (r.status !== 'success') {
            return
          }
        }
        const wplsTokenToken0 = res[0].result as `0x${string}`
        const wplsTokenToken1 = res[1].result as `0x${string}`
        const wplsTokenReserves = {
          token0: res[2].result?.[0],
          token1: res[2].result?.[1],
        } as Reserves
        const daiTokenReserves = {
          token0: res[3].result?.[0],
          token1: res[3].result?.[1],
        } as Reserves

        const wplsToken0 = WNATIVE[chainId].address === wplsTokenToken0
        const otherToken = wplsToken0 ? uniqueTokensMap[wplsTokenToken1] : uniqueTokensMap[wplsTokenToken0]

        const wplsPair = getPair(WNATIVE[chainId], otherToken, wplsTokenReserves, protocol)
        const daiPair = getPair(DAI[chainId], otherToken, daiTokenReserves, protocol)

        const tokenDaiPrice = daiPair.priceOf(otherToken)
        const tokenWplsPrice = wplsPair.priceOf(otherToken)
        const tokenWplsUsdPrice = tokenWplsPrice.multiply(wplsPrice)
        const averagePriceFraction = tokenDaiPrice.add(tokenWplsUsdPrice).divide(2)
        tokenPriceData[otherToken.address] = averagePriceFraction
      })

      tokenPriceData[wplsAddr] = wplsPrice.asFraction
      tokenPriceData[daiAddr] = new Fraction(1, 1)

      const updatedLpData = lpListWithData.map(lp => {
        const price0 = tokenPriceData[lp.pair.token0.address]
        const price1 = tokenPriceData[lp.pair.token1.address]

        const token0AmountWithStable = CurrencyAmount.fromRawAmount(
          DAI[chainId],
          lp.stableBalances?.token0?.quotient ?? 0
        ) as CurrencyAmount<ERC20Token>
        const token1AmountWithStable = CurrencyAmount.fromRawAmount(
          DAI[chainId],
          lp.stableBalances?.token1?.quotient ?? 0
        ) as CurrencyAmount<ERC20Token>

        const token0Value =
          lp.stableBalances.token0
            ? CurrencyAmount.fromRawAmount(
              DAI[chainId],
              lp.token0Amount.multiply(price0 ?? 0).quotient
            ).add(token0AmountWithStable) as CurrencyAmount<ERC20Token>
            : CurrencyAmount.fromRawAmount(
              DAI[chainId],
              lp.token0Amount.multiply(price0 ?? 0).quotient
            ) as CurrencyAmount<ERC20Token>
        const token1Value =
          lp.stableBalances.token1
            ? CurrencyAmount.fromRawAmount(
              DAI[chainId],
              lp.token1Amount.multiply(price1 ?? 0).quotient
            ).add(token1AmountWithStable) as CurrencyAmount<ERC20Token>
            : CurrencyAmount.fromRawAmount(
              DAI[chainId],
              lp.token1Amount.multiply(price1 ?? 0).quotient
            ) as CurrencyAmount<ERC20Token>
        const totalValue = parseFloat(token0Value.add(token1Value).toExact())

        return {
          ...lp,
          token0Price: price0 ?? 0n,
          token0Value,
          token1Price: price1 ?? 0n,
          token1Value,
          totalValue
        }
      })

      const totalPlsxBurned = new BigNumber(formatEther(await buyandburn.read.burnedPLSX()))

      return { lpData: updatedLpData, totalPlsxBurned }
    },
    enabled: Boolean(lpListWithData && !lpListWithDataLoading),
    placeholderData: keepPreviousData,
  })

  const lpListPricesLoading = lpListPricesStatus === 'pending' || lpListPricesIsPlaceholderData

  return {
    result: lpListPrices,
    isLoading: lpListWithDataLoading || lpListPricesLoading
  }
}
