import { useCallback } from 'react'
import { unstakeFarm } from 'utils/calls'
import { useMasterchef } from 'hooks/useContract'
import { useFeeDataWithGasPrice } from 'state/user/hooks'

const useUnstakeFarms = (pid: number) => {
  const masterChefContract = useMasterchef()
  const { maxFeePerGas, maxPriorityFeePerGas } = useFeeDataWithGasPrice()

  const handleUnstake = useCallback(
    async (amount: string) => {
      return unstakeFarm(masterChefContract, pid, amount, maxFeePerGas, maxPriorityFeePerGas)
    },
    [masterChefContract, pid, maxFeePerGas, maxPriorityFeePerGas],
  )

  return { onUnstake: handleUnstake }
}

export default useUnstakeFarms
