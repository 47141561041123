import { FarmWidget, MultiCurrencyLogo } from '@pulsex/widgets-internal'
import { useFarmUser } from 'state/farms/hooks'

const { FarmTokenInfo } = FarmWidget.FarmTable

const Farm: React.FunctionComponent<React.PropsWithChildren<FarmWidget.FarmTableFarmTokenInfoProps>> = ({
  token,
  quoteToken,
  label,
  pid,
  isReady,
}) => {
  const { stakedBalance } = useFarmUser(pid)

  return (
    <FarmTokenInfo
      pid={pid}
      label={label}
      token={token}
      quoteToken={quoteToken}
      isReady={isReady}
      stakedBalance={stakedBalance}
    >
      <MultiCurrencyLogo currency0={token} currency1={quoteToken} size={22} overlap="0px" />
    </FarmTokenInfo>
  )
}

export default Farm
