import { Currency, Price, TradeType } from '@pulsex/sdk'
import { SmartRouterTrade } from '@pulsex/smart-router'
import { AutoColumn, ChevronDownIcon, Row, RowBetween, RowFixed, Text } from '@pulsex/uikit'
import { Swap as SwapUI } from '@pulsex/widgets-internal'
import { useUserSlippage } from '@pulsex/utils/user'
import styled from 'styled-components'
import { memo, useMemo, useState } from 'react'
import { useActiveChainId } from 'hooks/useActiveChainId'

import GasEstimateBadge from '../components/GasEstimateBadge'
import AnimatedDropdown from '../components/AnimatedDropdown'
import { AdvancedSwapDetails } from '../components/AdvancedSwapDetails'

import { useIsWrapping, useSlippageAdjustedAmounts } from '../hooks'
import { computeTradePriceBreakdown } from '../utils/exchange'

interface Props {
  loaded: boolean
  trade?: SmartRouterTrade<TradeType> | null
  priceLoading?: boolean
  price?: Price<Currency, Currency>
}

const Wrapper = styled(Row)`
  padding: 10px 10px;
  border-radius: 14px;
  background: ${({ theme }) => theme.colors.backgroundAlt2};
`
const StyledHeaderRow = styled(RowBetween) <{ disabled: boolean; open: boolean }>`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`
const RotatingArrow = styled(ChevronDownIcon) <{ trade?: boolean; open?: boolean; hidden?: boolean }>`
  hidden: ${({ hidden }) => (hidden ? 'true' : 'false')};
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  transition: transform 0.2s linear;
  stroke: ${({ theme, trade }) => trade ? theme.colors.tertiary : theme.colors.backgroundAlt2};
  height: 20px;
  color: ${({ theme }) => theme.colors.textSubtleDark};
`

export const TradeDetails = memo(function TradeDetails({ loaded, trade, price, priceLoading }: Props) {
  const { chainId } = useActiveChainId()
  const [allowedSlippage] = useUserSlippage()
  const [showDetails, setShowDetails] = useState(false)
  const slippageAdjustedAmounts = useSlippageAdjustedAmounts(trade ?? undefined)
  const isWrapping = useIsWrapping()
  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])

  if (isWrapping || !loaded || !trade || !slippageAdjustedAmounts) {
    return null
  }

  const { inputAmount, outputAmount, tradeType, routes } = trade

  return (
    <Wrapper>
      <AutoColumn style={{ width: '100%' }}>
        <StyledHeaderRow onClick={() => setShowDetails(!showDetails)} disabled={!trade} open={showDetails}>
          <RowFixed style={{ position: 'relative' }}>
            <SwapUI.TradePrice price={price} loading={priceLoading} />
            {!loaded && (
              <Text>Loading</Text>
            )}
          </RowFixed>
          <RowFixed>
            {!trade?.gasEstimateInUSD ||
              showDetails ||
              !chainId ? null : (
              <GasEstimateBadge
                trade={trade}
                loading={!loaded}
                showRoute={!showDetails}
                disableHover={showDetails}
              />
            )}
            <RotatingArrow open={Boolean(trade && showDetails)} />
          </RowFixed>
        </StyledHeaderRow>
        <AnimatedDropdown open={showDetails}>
          <AutoColumn gap="sm">
            {trade ? (
              <AdvancedSwapDetails
                inputAmount={inputAmount}
                outputAmount={outputAmount}
                trade={trade}
                allowedSlippage={allowedSlippage}
                syncing={!loaded}
                priceImpactWithoutFee={priceImpactWithoutFee ?? undefined}
                tradeType={tradeType}
                slippageAdjustedAmounts={slippageAdjustedAmounts}
                routes={routes}
              />
            ) : null}
          </AutoColumn>
        </AnimatedDropdown>
      </AutoColumn>
    </Wrapper>
  )
})
