import { useTranslation } from '@pulsex/localization'
import { Currency, CurrencyAmount } from '@pulsex/sdk'
import { AutoColumn } from '@pulsex/uikit'
import { useIsExpertMode } from '@pulsex/utils/user'
import { Address } from 'viem'
import { CommitButton } from 'components/CommitButton'
import ConnectWalletButton from 'components/ConnectWalletButton'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ApprovalState } from 'hooks/useApproveCallback'
import ApproveLiquidityTokens from './ApproveLiquidityTokens'

interface ConfirmAddLiquidityButtonProps {
  approvalA: ApprovalState
  approvalB: ApprovalState
  approvalC: ApprovalState
  showFieldAApproval: boolean
  showFieldBApproval: boolean
  showFieldCApproval: boolean
  currencyA?: Currency
  currencyB?: Currency
  currencyC?: Currency
  currentAllowanceA: CurrencyAmount<Currency> | undefined
  currentAllowanceB: CurrencyAmount<Currency> | undefined
  currentAllowanceC: CurrencyAmount<Currency> | undefined
  shouldShowApprovalGroup: boolean
  buttonDisabled: boolean
  approveACallback: () => Promise<{ hash: Address } | undefined>
  approveBCallback: () => Promise<{ hash: Address } | undefined>
  approveCCallback: () => Promise<{ hash: Address } | undefined>
  revokeACallback: () => Promise<{ hash: Address } | undefined>
  revokeBCallback: () => Promise<{ hash: Address } | undefined>
  revokeCCallback: () => Promise<{ hash: Address } | undefined>
  onAdd: () => Promise<void>
  onPresentAddLiquidityModal: () => void
  errorText?: string
}

export const ConfirmAddLiquidityButton: React.FC<ConfirmAddLiquidityButtonProps> = ({
  approvalA,
  approvalB,
  approvalC,
  showFieldAApproval,
  showFieldBApproval,
  showFieldCApproval,
  currencyA,
  currencyB,
  currencyC,
  currentAllowanceA,
  currentAllowanceB,
  currentAllowanceC,
  shouldShowApprovalGroup,
  buttonDisabled,
  approveACallback,
  approveBCallback,
  approveCCallback,
  revokeACallback,
  revokeBCallback,
  revokeCCallback,
  onAdd,
  onPresentAddLiquidityModal,
  errorText
}) => {
  const { account, isWrongNetwork, chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  const expertMode = useIsExpertMode()

  return (
    <>
      {!account ? (
        <ConnectWalletButton width="100%" />
      ) : isWrongNetwork ? (
        <CommitButton />
      ) : (
        <AutoColumn gap="md">
          <ApproveLiquidityTokens
            chainId={chainId}
            approvalA={approvalA}
            approvalB={approvalB}
            approvalC={approvalC}
            showFieldAApproval={showFieldAApproval}
            showFieldBApproval={showFieldBApproval}
            showFieldCApproval={showFieldCApproval}
            approveACallback={approveACallback}
            approveBCallback={approveBCallback}
            approveCCallback={approveCCallback}
            revokeACallback={revokeACallback}
            revokeBCallback={revokeBCallback}
            revokeCCallback={revokeCCallback}
            currencyA={currencyA}
            currencyB={currencyB}
            currencyC={currencyC}
            currentAllowanceA={currentAllowanceA}
            currentAllowanceB={currentAllowanceB}
            currentAllowanceC={currentAllowanceC}
            shouldShowApprovalGroup={shouldShowApprovalGroup}
          />

          <CommitButton
            variant={buttonDisabled ? 'danger' : 'primary'}
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              expertMode ? onAdd() : onPresentAddLiquidityModal()
            }}
            disabled={buttonDisabled}
          >
            {errorText || t('Supply')}
          </CommitButton>
        </AutoColumn>
      )}
    </>
  )
}
