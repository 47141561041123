import { ChainId } from '@pulsex/chains'
import { PublicClient, Address } from 'viem'
import type { SerializedWrappedToken } from '@pulsex/token-lists'
import BigNumber from 'bignumber.js'

export type OnChainProvider = ({ chainId }: { chainId?: ChainId }) => PublicClient

export type SerializedBigNumber = string

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'PULSE' = 'Pulse', // Pools using native BNB behave differently than pools using a token
  'AUTO' = 'Auto',
}

export interface PoolConfigBaseProps {
  poolId: number
  contractAddress: Address
  poolCategory: PoolCategory
  tokenPerBlock: string
  harvest?: boolean
  isFinished?: boolean
  enableEmergencyWithdraw?: boolean
  version?: number
}

interface GenericToken {
  decimals: number
  symbol: string
  address: string
}

export interface SerializedPoolConfig<T> extends PoolConfigBaseProps {
  earningToken: T & GenericToken
  stakingToken: T & GenericToken
}

export type SerializedPool = SerializedPoolConfig<SerializedWrappedToken>

export type SerializedPoolWithInfo = SerializedPool & SerializedPoolInfo

export interface DeserializedPoolConfig<T> extends PoolConfigBaseProps {
  earningToken: T & GenericToken
  stakingToken: T & GenericToken
}

interface SerializedPoolInfo extends CorePoolProps {
  totalStaked?: string
  stakingLimit?: string
  userLimitEnd?: number
  stakingLimitEndTimestamp?: number
  profileRequirement?: {
    required: boolean
    thresholdPoints: string
  }
  userDataLoaded?: boolean
  userData?: {
    allowance: string
    stakingTokenBalance: string
    stakedBalance: string
    pendingReward: string
  }
}

interface PoolInfo extends CorePoolProps {
  totalStaked?: BigNumber
  stakingLimit?: BigNumber
  stakingLimitEndTimestamp?: number
  profileRequirement?: {
    required: boolean
    thresholdPoints: BigNumber
  }
  userDataLoaded?: boolean
  userData?: {
    allowance: BigNumber
    stakingTokenBalance: BigNumber
    stakedBalance: BigNumber
    pendingReward: BigNumber
  }
}

export type DeserializedPool<T> = DeserializedPoolConfig<T> & PoolInfo

interface CorePoolProps {
  startTimestamp?: number
  endTimestamp?: number
  apr?: number
  rawApr?: number
  stakingTokenPrice?: number
  earningTokenPrice?: number
  timeStamp?: number
}
