import { PropsWithChildren, memo } from 'react'
import { Column } from '@pulsex/uikit'

import { Wrapper } from './styleds'

export const FormContainer = memo(function FormContainer({ children }: PropsWithChildren) {
  return (
    <Wrapper id="swap-page">
      <Column gap="sm">{children}</Column>
    </Wrapper>
  )
})
