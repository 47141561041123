import { useTranslation } from '@pulsex/localization'
import { ChainId } from '@pulsex/chains'
import truncateHash from '@pulsex/utils/truncateHash'
import { Link, PulseLogo, Text } from '@pulsex/uikit'
import { getBlockExplorerLink, getBlockExploreName } from 'utils'
import { useActiveChainId } from 'hooks/useActiveChainId'

interface DescriptionWithTxProps {
  description?: string
  txHash?: string
  txChainId?: number
}

const DescriptionWithTx: React.FC<React.PropsWithChildren<DescriptionWithTxProps>> = ({
  txHash,
  txChainId,
  children,
}) => {
  const { chainId } = useActiveChainId()
  const { t } = useTranslation()

  return (
    <>
      {typeof children === 'string' ? <Text as="p">{children}</Text> : children}
      {txHash && (
        <Link external to={getBlockExplorerLink(txHash, 'transaction', chainId)}>
          {t(`View on ${getBlockExploreName(chainId)}`)}: {truncateHash(txHash, 8, 0)}
          {(txChainId || chainId) === ChainId.PULSECHAIN && <PulseLogo color="primary" ml="4px" />}
        </Link>
      )}
    </>
  )
}

export default DescriptionWithTx
