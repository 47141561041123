import { ChainId } from '@pulsex/chains'
import { useDebounce, usePropsChanged } from '@pulsex/hooks'
import { Currency, CurrencyAmount, TradeType } from '@pulsex/sdk'
import { BigintIsh } from '@pulsex/swap-sdk-core'
import {
  PoolType,
  QuoteProvider,
  Route,
  SmartRouter,
  SmartRouterTrade,
} from '@pulsex/smart-router'
import { AbortControl } from '@pulsex/utils/abortControl'
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react'
import { publicClient } from 'utils/wagmi'
import { createViemPublicClientGetter } from 'utils/viem'
import { QUOTING_API } from 'config/constants/endpoints'
import { useIsWrapping } from 'hooks/useWrapCallback'
import { useCurrentBlock } from 'state/block/hooks'
import { useMulticallGasLimit } from './useMulticallGasLimit'
import {
  CommonPoolsParams,
  PoolsWithState,
  useCommonPools as useCommonPoolsOnChain,
} from './useCommonPools'
import { useGlobalWorker } from './useWorker'

export const POOLS_NORMAL_REVALIDATE = {
  [ChainId.PULSECHAIN]: 10_000,
  [ChainId.PULSECHAIN_TESTNET]: 10_000,
} as const satisfies Record<ChainId, number>

export class NoValidRouteError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'NoValidRouteError'
  }
}

SmartRouter.logger.enable('error,log')

type CreateQuoteProviderParams = {
  gasLimit?: bigint
} & AbortControl

type GetBestTradeParams = Parameters<typeof SmartRouter.getBestTrade>

interface FactoryOptions<T> {
  // use to identify hook
  key: string
  useCommonPools: (currencyA?: Currency, currencyB?: Currency, params?: CommonPoolsParams) => PoolsWithState
  useGetBestTrade: () => (...args: GetBestTradeParams) => Promise<T | undefined | null>
  createQuoteProvider: (params: CreateQuoteProviderParams) => QuoteProvider

  // Decrease the size of batch getting quotes for better performance
  quoterOptimization?: boolean
}

interface Options {
  amount?: CurrencyAmount<Currency>
  baseCurrency?: Currency | null
  currency?: Currency | null
  tradeType?: TradeType
  maxHops?: number
  maxSplits?: number
  v1Swap?: boolean
  v2Swap?: boolean
  stableSwap?: boolean
  enabled?: boolean
  autoRevalidate?: boolean
}

interface useBestAMMTradeOptions extends Options {
  type?: 'offchain' | 'quoter' | 'auto' | 'api'
}

type QuoteResult = ReturnType<ReturnType<typeof bestTradeHookFactory>>

export function useBetterQuote<A extends QuoteResult, B extends QuoteResult>(quoteA: A, quoteB: B) {
  return useMemo(() => {
    if (!quoteB.trade) {
      return quoteA
    }
    if (!quoteA.trade && quoteB.trade) {
      return quoteB
    }
    return quoteA.trade!.tradeType === TradeType.EXACT_INPUT
      ? quoteB.trade?.outputAmount.greaterThan(quoteA.trade!.outputAmount)
        ? quoteB
        : quoteA
      : quoteB.trade?.inputAmount.lessThan(quoteA.trade!.inputAmount)
        ? quoteB
        : quoteA
  }, [quoteA, quoteB])
}

export function useBestAMMTrade({ type = 'quoter', ...params }: useBestAMMTradeOptions) {
  const { amount, baseCurrency, currency, autoRevalidate, enabled = true } = params
  const isWrapping = useIsWrapping(baseCurrency, currency, amount?.toExact())

  const apiEnabled = useMemo(() => Boolean(!isWrapping && type === 'api'), [isWrapping, type])
  const apiAutoRevalidate = typeof autoRevalidate === 'boolean' ? autoRevalidate : apiEnabled

  const workerEnabled = useMemo(
    () => Boolean(!isWrapping && (type === 'quoter' || type === 'auto')),
    [type, isWrapping],
  )

  const bestTradeFromQuoterApi = useBestAMMTradeFromQuoterApi({
    ...params,
    enabled: Boolean(enabled && apiEnabled),
    autoRevalidate: apiAutoRevalidate,
  })

  const bestTradeFromWorker = useBestAMMTradeFromQuoterWorker({
    ...params,
    enabled: Boolean(enabled && workerEnabled) || Boolean(bestTradeFromQuoterApi.error),
    autoRevalidate: true,
  })

  const bestTradeFromWorkerV2 = useBestAMMTradeFromQuoterWorkerV2({
    ...params,
    enabled: type === 'offchain',
  })

  const finalTradeResult = useMemo(() => {
    if (type === 'offchain') {
      return bestTradeFromWorkerV2
    }
    if (apiEnabled) {
      return bestTradeFromQuoterApi
    }
    if (!apiEnabled && workerEnabled) {
      return bestTradeFromWorker
    }
    return bestTradeFromQuoterApi
  }, [apiEnabled, bestTradeFromQuoterApi, bestTradeFromWorker, workerEnabled]);
  return finalTradeResult
}

function createSimpleUseGetBestTradeHook<T>(
  getBestTrade: (...args: Parameters<typeof SmartRouter.getBestTrade>) => Promise<T | undefined | null>,
) {
  return function useGetBestTrade() {
    return useCallback(getBestTrade, [])
  }
}

function bestTradeHookFactory<
  T extends Pick<SmartRouterTrade<TradeType>, 'inputAmount' | 'outputAmount' | 'tradeType'> & {
    routes: Pick<Route, 'path' | 'pools' | 'inputAmount' | 'outputAmount'>[]
    blockNumber?: BigintIsh
  },
>({
  key,
  useCommonPools,
  createQuoteProvider: createCustomQuoteProvider,
  quoterOptimization = true,
  useGetBestTrade,
}: FactoryOptions<T>) {
  return function useBestTrade({
    amount,
    baseCurrency,
    currency,
    tradeType = TradeType.EXACT_INPUT,
    maxHops,
    maxSplits,
    v1Swap = true,
    v2Swap = true,
    stableSwap = true,
    enabled = true,
    autoRevalidate,
  }: Options) {
    const [refreshing, setRefreshing] = useState(false)
    const getBestTrade = useGetBestTrade()
    const gasLimit = useMulticallGasLimit(currency?.chainId)
    const currenciesUpdated = usePropsChanged(baseCurrency, currency)
    const queryClient = useQueryClient()

    const keepPreviousDataRef = useRef<boolean>(true)

    if (currenciesUpdated) {
      keepPreviousDataRef.current = false
    }

    const blockNumber = useCurrentBlock()
    const {
      refresh: refreshPools,
      pools: candidatePools,
      loading,
      syncing,
    } = useCommonPools(baseCurrency || amount?.currency, currency ?? undefined, {
      blockNumber,
      allowInconsistentBlock: true,
      enabled,
    })

    const poolProvider = useMemo(
      () => SmartRouter.createStaticPoolProvider(candidatePools),
      [candidatePools],
    )

    const deferQuotientRaw = useDeferredValue(amount?.quotient?.toString())
    const deferQuotient = useDebounce(deferQuotientRaw, 500)

    const poolTypes = useMemo(() => {
      const types: PoolType[] = []
      if (v1Swap) {
        types.push(PoolType.V1)
      }
      if (v2Swap) {
        types.push(PoolType.V2)
      }
      if (stableSwap) {
        types.push(PoolType.STABLE)
      }
      return types
    }, [v1Swap, v2Swap, stableSwap])

    const {
      data: trade,
      status,
      fetchStatus,
      isPlaceholderData,
      error,
      refetch,
    } = useQuery<T | undefined>({
      queryKey: [
        key,
        currency?.chainId,
        amount?.currency?.isNative ? amount?.currency?.symbol : amount?.currency?.wrapped?.address,
        currency?.isNative ? currency?.symbol : currency?.wrapped?.address,
        tradeType,
        deferQuotient,
        maxHops,
        maxSplits,
        poolTypes,
      ],
      queryFn: async ({ signal }) => {
        if (!amount || !amount.currency || !currency || !deferQuotient) {
          return undefined
        }
        const quoteProvider = createCustomQuoteProvider({
          gasLimit,
          signal,
        })

        const deferAmount = CurrencyAmount.fromRawAmount(amount.currency, deferQuotient)
        const res = await getBestTrade(deferAmount, currency, tradeType, {
          gasPriceWei: async () => publicClient({ chainId: amount.currency.chainId }).getGasPrice(),
          maxHops,
          poolProvider,
          maxSplits,
          quoteProvider,
          allowedPoolTypes: poolTypes,
          quoterOptimization,
          signal,
        })

        if (!res) {
          return undefined
        }
        const result: T = {
          ...res,
          blockNumber,
        }
        setRefreshing(false)
        return result
      },
      enabled: !!(amount && currency && candidatePools && !loading && deferQuotient && enabled),
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousDataRef.current ? keepPreviousData : undefined,
      retry: false,
      staleTime: autoRevalidate && amount?.currency.chainId ? POOLS_NORMAL_REVALIDATE[amount.currency.chainId] : 0,
      refetchInterval:
        autoRevalidate && amount?.currency.chainId ? POOLS_NORMAL_REVALIDATE[amount?.currency?.chainId] : 0,
    })

    useEffect(() => {
      if (!keepPreviousDataRef.current && trade) {
        keepPreviousDataRef.current = true
      }
    }, [trade, keepPreviousDataRef])

    const isValidating = fetchStatus === 'fetching'
    const isLoading = status === 'pending' || isPlaceholderData

    const refresh = useCallback(async (k: string) => {
      setRefreshing(true)
      await refreshPools()
      queryClient.removeQueries({queryKey: [k]})
      refetch()
    }, [refreshPools, queryClient, refetch])

    return {
      key,
      refreshing,
      refresh,
      trade,
      isLoading: isLoading || loading,
      isStale: trade?.blockNumber !== blockNumber,
      error: error as Error | undefined,
      syncing:
        syncing || isValidating || (amount?.quotient?.toString() !== deferQuotient && deferQuotient !== undefined),
    }
  }
}

function createQuoteProvider({ gasLimit, signal }: CreateQuoteProviderParams) {
  const onChainProvider = createViemPublicClientGetter({ transportSignal: signal })
  return SmartRouter.createQuoteProvider({ onChainProvider, gasLimit })
}

export const useBestAMMTradeFromQuoterApi = bestTradeHookFactory<SmartRouterTrade<TradeType>>({
  key: 'useBestAMMTradeFromQuoterApi',
  useCommonPools: useCommonPoolsOnChain,
  createQuoteProvider,
  useGetBestTrade: createSimpleUseGetBestTradeHook(
    async (amount, currency, tradeType, { maxHops, maxSplits, gasPriceWei, allowedPoolTypes }) => {
      const serverRes = await fetch(`${QUOTING_API}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chainId: currency.chainId,
          currency: SmartRouter.Transformer.serializeCurrency(currency),
          tradeType,
          amount: {
            currency: SmartRouter.Transformer.serializeCurrency(amount.currency),
            value: amount.quotient.toString(),
          },
          gasPriceWei: typeof gasPriceWei !== 'function' ? gasPriceWei?.toString() : undefined,
          maxHops,
          maxSplits,
          poolTypes: allowedPoolTypes,
        }),
      })
      const serializedRes = await serverRes.json()
      return SmartRouter.Transformer.parseTrade(currency.chainId, serializedRes)
    },
  ),
  // Since quotes are fetched on chain, which relies on network IO, not calculated offchain, we don't need to further optimize
  quoterOptimization: false,
})

function createUseWorkerGetBestTrade() {
  return function useWorkerGetBestTrade(): typeof SmartRouter.getBestTrade {
    const worker = useGlobalWorker()

    return useCallback(
      async (
        amount,
        currency,
        tradeType,
        {
          maxHops,
          maxSplits,
          allowedPoolTypes,
          poolProvider,
          gasPriceWei,
          quoteProvider,
          nativeCurrencyUsdPrice,
          quoteCurrencyUsdPrice,
          signal,
        },
      ) => {
        if (!worker) {
          throw new Error('Quote worker not initialized')
        }
        const candidatePools = await poolProvider.getCandidatePools({
          currencyA: amount.currency,
          currencyB: currency,
          protocols: allowedPoolTypes,
        })

        const quoterConfig = (quoteProvider as ReturnType<typeof SmartRouter.createQuoteProvider>)?.getConfig?.()
        const result = await worker.getBestTrade({
          chainId: currency.chainId,
          currency: SmartRouter.Transformer.serializeCurrency(currency),
          tradeType,
          amount: {
            currency: SmartRouter.Transformer.serializeCurrency(amount.currency),
            value: amount.quotient.toString(),
          },
          gasPriceWei: typeof gasPriceWei !== 'function' ? gasPriceWei?.toString() : undefined,
          maxHops,
          maxSplits,
          poolTypes: allowedPoolTypes,
          candidatePools: candidatePools.map(SmartRouter.Transformer.serializePool),
          onChainQuoterGasLimit: quoterConfig?.gasLimit?.toString(),
          quoteCurrencyUsdPrice,
          nativeCurrencyUsdPrice,
          signal,
        })
        return SmartRouter.Transformer.parseTrade(currency.chainId, result as any)
      },
      [worker],
    )
  }
}

export const useBestAMMTradeFromQuoterWorker = bestTradeHookFactory<SmartRouterTrade<TradeType>>({
  key: 'useBestAMMTradeFromQuoterWorker',
  useCommonPools: useCommonPoolsOnChain,
  createQuoteProvider,
  useGetBestTrade: createUseWorkerGetBestTrade(),
  // Since quotes are fetched on chain, which relies on network IO, not calculated offchain, we don't need to further optimize
  quoterOptimization: false,
})

export const useBestAMMTradeFromQuoterWorkerV2 = bestTradeHookFactory<SmartRouterTrade<TradeType>>({
  key: 'useBestAMMTradeFromQuoterWorkerV2',
  useCommonPools: useCommonPoolsOnChain,
  createQuoteProvider,
  useGetBestTrade: createUseWorkerGetBestTrade(),
  // Since quotes are fetched on chain, which relies on network IO, not calculated offchain, we don't need to further optimize
  quoterOptimization: false,
})
