import { SmartRouter } from '@pulsex/smart-router'
import { useMemo } from 'react'
import { CommitButton, FormHeader, FormMain, TradeDetails } from './containers'
import { useAllTypeBestTrade } from './hooks/useAllTypeBestTrade'

export function SwapForm() {
  const {
    key,
    bestTrade,
    tradeError,
    tradeLoaded,
    refreshTrade,
    refreshDisabled,
    pauseQuoting,
    resumeQuoting,
  } = useAllTypeBestTrade()

  const ammPrice = useMemo(() => (bestTrade ? SmartRouter.getExecutionPrice(bestTrade) : undefined), [bestTrade])
  const commitHooks = useMemo(() => {
    return {
      beforeCommit: pauseQuoting,
      afterCommit: resumeQuoting,
    }
  }, [pauseQuoting, resumeQuoting])

  return (
    <>
      <FormHeader onRefresh={() => refreshTrade(key)} refreshDisabled={refreshDisabled} />
      <FormMain
        tradeLoading={!tradeLoaded}
        inputAmount={bestTrade?.inputAmount}
        outputAmount={bestTrade?.outputAmount}
        tradeDetails={
          <TradeDetails
            loaded={tradeLoaded}
            trade={bestTrade}
            priceLoading={!tradeLoaded}
            price={ammPrice ?? undefined}
          />
        }
        swapCommitButton={
          <CommitButton
            trade={bestTrade}
            tradeError={tradeError}
            tradeLoaded={tradeLoaded}
            {...commitHooks}
          />
        }
      />
    </>
  )
}
