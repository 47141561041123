import { ChainId } from '@pulsex/chains'
import SwapWarningTokensConfig from 'config/constants/swapWarningTokens'
import SafemoonWarning from './SafemoonWarning'

const { safemoon } = SwapWarningTokensConfig[ChainId.PULSECHAIN]

const ETH_WARNING_LIST = {
  [safemoon.address]: {
    symbol: safemoon.symbol,
    component: <SafemoonWarning />,
  },
}

export default ETH_WARNING_LIST
