import { Toaster } from "sonner";

const ToastListener = () => {
  return (
    <Toaster
      duration={6000}
      position="top-right"
      gap={24}
      theme={"dark"}
      toastOptions={{
        style: {
          width: "100%",
        },
      }}
    />
  );
};

export default ToastListener;
