import { useTranslation } from '@pulsex/localization'
import { Currency, CurrencyAmount } from '@pulsex/sdk'
import { PoolType, StableSwapPool } from '@pulsex/stable-swap-sdk'
import {
  AutoColumn,
  Flex,
  InjectedModalProps,
  Modal,
  PreTitle,
  QuestionHelper,
  RowFixed,
  RowBetween,
  Text,
} from '@pulsex/uikit'
import { formatAmount } from '@pulsex/utils/formatFractions'
import { CurrencyLogo, MultiCurrencyLogo } from '@pulsex/widgets-internal'
import { useCallback } from 'react'
import { styled } from 'styled-components'

const ScrollableContainer = styled(Flex)`
  flex-direction: column;
  height: auto;
  ${({ theme }) => theme.mediaQueries.xs} {
    max-height: 90vh;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    max-height: none;
  }
`
const FixedHeightRow = styled(RowBetween)`
  height: 24px;
  margin-bottom: 0.5rem;
`
const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.cardBorder};
`

export const withCustomOnDismiss =
  (Component) =>
    ({
      onDismiss,
      customOnDismiss,
      ...props
    }: {
      onDismiss?: () => void
      customOnDismiss: () => void
    }) => {
      const handleDismiss = useCallback(() => {
        onDismiss?.()
        if (customOnDismiss) {
          customOnDismiss()
        }
      }, [customOnDismiss, onDismiss])

      return <Component {...props} onDismiss={handleDismiss} />
    }

interface InfoModalProps extends InjectedModalProps {
  pool: StableSwapPool
  currency0: Currency
  currency1: Currency
  currency2?: Currency
}

export const InfoModal: React.FC<InfoModalProps> = ({ onDismiss, pool, currency0, currency1, currency2 }) => {
  const { t } = useTranslation()
  const isThreePool = pool?.type === PoolType.THREE_POOL
  return (
    <Modal onDismiss={onDismiss} title={
      <Flex>
        <MultiCurrencyLogo currency0={currency0} currency1={currency1} currency2={currency2} size={20} margin />
        {`${currency0.symbol}/${currency1.symbol}${isThreePool ? `/${currency2?.symbol}` : ''}`}
      </Flex>}
    >
      <AutoColumn
        width={{
          xs: '100%',
          md: '420px',
        }}
      >
        <ScrollableContainer>
          <>
            <PreTitle mb="0.5rem">{t('User')}</PreTitle>
            <FixedHeightRow>
              <RowFixed>
                <CurrencyLogo size="20px" currency={currency0} />
                <Text color="textSubtle" ml="4px">
                  {t('Pooled %asset%', { asset: currency0.symbol })}
                </Text>
                <QuestionHelper
                  text={t(`Amount of your %asset% tokens deposited in this stable pool.`, { asset: currency0.symbol })}
                  placement="top"
                  ml="4px"
                />
              </RowFixed>
              {pool?.userData?.tokenAmounts?.token0 ? (
                <RowFixed>
                  <Text ml="6px">{pool.userData.tokenAmounts.token0.toSignificant(4)}</Text>
                </RowFixed>
              ) : (
                '0'
              )}
            </FixedHeightRow>
            <FixedHeightRow>
              <RowFixed>
                <CurrencyLogo size="20px" currency={currency1} />
                <Text color="textSubtle" ml="4px">
                  {t('Pooled %asset%', { asset: currency1.symbol })}
                </Text>
                <QuestionHelper
                  text={t(`Amount of your %asset% tokens deposited in this stable pool.`, { asset: currency1.symbol })}
                  placement="top"
                  ml="4px"
                />
              </RowFixed>
              {pool?.userData?.tokenAmounts?.token1 ? (
                <RowFixed>
                  <Text ml="6px">{pool.userData.tokenAmounts.token1.toSignificant(4)}</Text>
                </RowFixed>
              ) : (
                '0'
              )}
            </FixedHeightRow>
            {isThreePool && <FixedHeightRow>
              <RowFixed>
                <CurrencyLogo size="20px" currency={currency2} />
                <Text color="textSubtle" ml="4px">
                  {t('Pooled %asset%', { asset: currency2?.symbol })}
                </Text>
                <QuestionHelper
                  text={t(`Amount of your %asset% tokens deposited in this stable pool.`, { asset: currency2?.symbol })}
                  placement="top"
                  ml="4px"
                />
              </RowFixed>
              {pool?.userData?.tokenAmounts?.token2 ? (
                <RowFixed>
                  <Text ml="6px">{pool.userData.tokenAmounts.token2.toSignificant(4)}</Text>
                </RowFixed>
              ) : (
                '0'
              )}
            </FixedHeightRow>}
            <FixedHeightRow>
              <RowFixed>
                <Text color="textSubtle" ml="4px">
                  LP {t('Balance')}
                </Text>
                <QuestionHelper
                  text={t(`Amount of LP tokens you own.`)}
                  placement="top"
                  ml="4px"
                />
              </RowFixed>
              {pool?.userData?.lpBalance ? (
                <RowFixed>
                  <Text ml="6px">{pool.userData.lpBalance.toSignificant(4)}</Text>
                </RowFixed>
              ) : (
                '0'
              )}
            </FixedHeightRow>

            <Separator />

            <PreTitle mt="1rem" mb="0.5rem">{t('Global')}</PreTitle>
            <FixedHeightRow>
              <RowFixed>
                <CurrencyLogo size="20px" currency={currency0} />
                <Text color="textSubtle" ml="4px">
                  {t('Total %asset%', { asset: currency0.symbol })}
                </Text>
                <QuestionHelper
                  text={t(`Total amount of %asset% tokens deposited in this stable pool.`, { asset: currency0.symbol })}
                  placement="top"
                  ml="4px"
                />
              </RowFixed>
              {pool?.poolData?.balances?.token0 ? (
                <RowFixed>
                  <Text ml="6px">{formatAmount(CurrencyAmount.fromRawAmount(pool.token0, pool.poolData.balances.token0))}</Text>
                </RowFixed>
              ) : (
                '0'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <RowFixed>
                <CurrencyLogo size="20px" currency={currency1} />
                <Text color="textSubtle" ml="4px">
                  {t('Total %asset%', { asset: currency1.symbol })}
                </Text>
                <QuestionHelper
                  text={t(`Total amount of %asset% tokens deposited in this stable pool.`, { asset: currency1.symbol })}
                  placement="top"
                  ml="4px"
                />
              </RowFixed>
              {pool?.poolData?.balances?.token1 ? (
                <RowFixed>
                  <Text ml="6px">{formatAmount(CurrencyAmount.fromRawAmount(pool.token1, pool.poolData.balances.token1))}</Text>
                </RowFixed>
              ) : (
                '0'
              )}
            </FixedHeightRow>

            {isThreePool && <FixedHeightRow>
              <RowFixed>
                <CurrencyLogo size="20px" currency={currency2} />
                <Text color="textSubtle" ml="4px">
                  {t('Total %asset%', { asset: currency2?.symbol })}
                </Text>
                <QuestionHelper
                  text={t(`Total amount of %asset% tokens deposited in this stable pool.`, { asset: currency2?.symbol })}
                  placement="top"
                  ml="4px"
                />
              </RowFixed>
              {pool?.token2 && pool?.poolData?.balances?.token2 ? (
                <RowFixed>
                  <Text ml="6px">{formatAmount(CurrencyAmount.fromRawAmount(pool.token2, pool.poolData.balances.token2))}</Text>
                </RowFixed>
              ) : (
                '0'
              )}
            </FixedHeightRow>}

            <FixedHeightRow>
              <RowFixed>
                <Text color="textSubtle">{t('LP total supply')}</Text>
                <QuestionHelper
                  text={t(`Total supply of LP tokens of this stable pool.`)}
                  placement="top"
                  ml="4px"
                />
              </RowFixed>
              {pool?.liquidityToken && pool?.poolData?.totalSupply ? (
                <Text>
                  {formatAmount(CurrencyAmount.fromRawAmount(pool.liquidityToken, pool.poolData.totalSupply))}
                </Text>
              ) : (
                '0'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <RowFixed>
                <Text color="textSubtle">{t('Amplifier')}</Text>
                <QuestionHelper
                  text={t(`The amplification co-efficient (“A”) determines a pool’s tolerance for imbalance between the assets within it. A higher value means that trades will incur slippage sooner as the assets within the pool become imbalanced.`)}
                  placement="top"
                  ml="4px"
                />
              </RowFixed>
              {pool?.poolData?.amplifier ? (
                <Text>
                  {pool.poolData.amplifier.toString()}
                </Text>
              ) : (
                '0'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <RowFixed>
                <Text color="textSubtle">{t('Fee')}</Text>
                <QuestionHelper
                  text={t(`Total swap fee of the pool.`)}
                  placement="top"
                  ml="4px"
                />
              </RowFixed>
              {pool?.poolData?.fee ? (
                <Text>
                  {pool?.poolData.fee.toFixed(2)}%
                </Text>
              ) : (
                '0'
              )}
            </FixedHeightRow>
            <FixedHeightRow>
              <RowFixed>
                <Text color="textSubtle">{t('Burn')}</Text>
                <QuestionHelper
                  text={t(`Percentage of the swap fee that is sent to the PLSX buy and burn.`)}
                  placement="top"
                  ml="4px"
                />
              </RowFixed>
              {pool?.poolData?.adminFee ? (
                <Text>
                  {pool?.poolData.adminFee.toFixed(2)}%
                </Text>
              ) : (
                '0'
              )}
            </FixedHeightRow>
          </>
        </ScrollableContainer>
      </AutoColumn>
    </Modal>
  )
}
