import { SmartRouterTrade } from '@pulsex/smart-router'
import { TradeType } from '@pulsex/swap-sdk-core'
import { SwapCommitButton } from './SwapCommitButton'

type Trade = SmartRouterTrade<TradeType>

export type CommitButtonProps = {
  trade: Trade | undefined
  tradeError?: Error
  tradeLoaded: boolean
}

export const CommitButton: React.FC<CommitButtonProps> = ({
  trade,
  tradeError,
  tradeLoaded,
}) => {

  const currentTrade = trade as SmartRouterTrade<TradeType>
  return <SwapCommitButton trade={currentTrade} tradeError={tradeError} tradeLoading={!tradeLoaded} />
}
