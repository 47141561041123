import { ChainId } from '@pulsex/chains'
import { Address } from 'viem'
import addresses from 'config/constants/contracts'

export type Addresses = {
  [chainId in ChainId]?: Address
}

export const getAddressFromMap = (address: Addresses, chainId?: number): `0x${string}` => {
  return chainId && address[chainId] ? address[chainId] : address[ChainId.PULSECHAIN]
}

export const getRouterAddress = (chainId?: number) => {
  return getAddressFromMap(addresses.router, chainId)
}
export const getMasterChefAddress = (chainId?: number) => {
  return getAddressFromMap(addresses.masterChef, chainId)
}
export const getMulticallAddress = (chainId?: number) => {
  return getAddressFromMap(addresses.multiCall, chainId)
}
export const getPlsxLabAddress = (chainId?: number) => {
  return getAddressFromMap(addresses.plsxLab, chainId)
}
export const getBuyAndBurnAddress = (protocol: string, chainId?: number) => {
  return getAddressFromMap(protocol === 'V1' ? addresses.buyandburnV1 : addresses.buyandburnV2, chainId)
}
export const getStableSwapTwoNativeHelperAddress = (chainId?: number) => {
  return getAddressFromMap(addresses.stableSwapTwoNativeHelper, chainId)
}
export const getStableSwapThreeNativeHelperAddress = (chainId?: number) => {
  return getAddressFromMap(addresses.stableSwapThreeNativeHelper, chainId)
}
