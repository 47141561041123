import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { styled } from "styled-components";
import { useOnClickOutside } from "../../hooks";
import { Box } from "../Box";
import MenuButton from "./MenuButton";

interface Props {
  target: JSX.Element;
  setPercent: (percent: number) => void;
}

const DropdownContent = styled.div`
  align-items: center;
  width: 40px;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, 0);
  background-color: ${({ theme }) => theme.nav.background};
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  border-radius: ${({ theme }) => theme.radii.small};
`;

const PercentSelector: React.FC<React.PropsWithChildren<Props>> = ({ setPercent, target }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);
  const percents = [75, 50, 25];

  const { styles, attributes } = usePopper(targetRef, tooltipRef, {
    strategy: "fixed",
    placement: "bottom",
  });

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true);
    };

    const hideDropdownMenu = () => {
      setIsOpen(false);
    };

    const handleMouseEnter = () => {
      clearTimeout(timer.current!);
      showDropdownMenu();
    };

    const handleMouseLeave = () => {
      timer.current = setTimeout(hideDropdownMenu, 100);
    };

    if (targetRef && tooltipRef) {
      targetRef.addEventListener("mouseenter", handleMouseEnter);
      targetRef.addEventListener("mouseleave", handleMouseLeave);
      tooltipRef.addEventListener("mouseenter", handleMouseEnter);
      tooltipRef.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      if (targetRef && tooltipRef) {
        targetRef.removeEventListener("mouseenter", handleMouseEnter);
        targetRef.removeEventListener("mouseleave", handleMouseLeave);
        tooltipRef.removeEventListener("mouseenter", handleMouseEnter);
        tooltipRef.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [targetRef, tooltipRef]);

  const timer = useRef<NodeJS.Timeout | null>(null);

  useOnClickOutside(targetRef, () => {
    setIsOpen(false);
  });

  const handlePercentSelect = (percent: number) => {
    setPercent(percent);
    setIsOpen(false);
  };

  return (
    <Box ref={setTargetRef}>
      <Box
        onMouseEnter={() => {
          setIsOpen(true);
        }}
        onMouseLeave={() => {
          setIsOpen(false);
        }}
      >
        {target}
      </Box>

      {isOpen && (
        <DropdownContent
          ref={setTooltipRef}
          style={{...styles.popper}}
          {...attributes.popper}
        >
          {percents.map((percent) => (
            <MenuButton
              key={percent}
              onClick={() => handlePercentSelect(percent)}
              // Safari fix
              style={{ height: "25px", zIndex: 9999 }}
            >
              {percent}%
            </MenuButton>
          ))}
        </DropdownContent>
      )}
    </Box>
  );
};

export default PercentSelector;
