import { Button, Modal, Grid, Message, MessageText } from '@pulsex/uikit'
import { ChainId } from '@pulsex/chains'
import { useSwitchNetwork, useSwitchNetworkLocal } from 'hooks/useSwitchNetwork'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pulsex/localization'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import { chains } from 'utils/wagmi'

export function PageNetworkSupportModal() {
  const { t } = useTranslation()
  const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
  const switchNetworkLocal = useSwitchNetworkLocal()
  const { chainId, isConnected, isWrongNetwork } = useActiveWeb3React()
  const { logout } = useAuth()

  const foundChain = useMemo(() => chains.find((c) => c.id === chainId), [chainId])

  // const lastValidPath = historyManager?.history?.find((h) => ['/swap', 'liquidity', '/', '/info'].includes(h))

  const navigate = useNavigate()

  return (
    <Modal title={t('Check your network')} hideCloseButton pt="10px">
      <Grid style={{ gap: '16px' }} maxWidth="360px">
        {canSwitch ? (
          <Button
            variant={foundChain ? 'secondary' : 'primary'}
            isLoading={isLoading}
            onClick={() => (isWrongNetwork ? switchNetworkLocal(ChainId.PULSECHAIN) : switchNetworkAsync(ChainId.PULSECHAIN))}
          >
            {t('Switch to %chain%', { chain: 'PulseChain' })}
          </Button>
        ) : (
          <Message variant="danger">
            <MessageText>{t('Unable to switch network. Please try it on your wallet')}</MessageText>
          </Message>
        )}
        {isConnected && (
          <Button
            variant="secondary"
            onClick={() =>
              logout().then(() => {
                navigate('/')
              })
            }
          >
            {t('Disconnect Wallet')}
          </Button>
        )}
        {/* {foundChain && (
          <Link to={lastValidPath ?? ''}>
            <Button width="100%" as="a">
              {t('Stay on %chain%', { chain: foundChain.name })}
            </Button>
          </Link>
        )} */}
      </Grid>
    </Modal>
  )
}
