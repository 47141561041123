import { plsTokens } from '@pulsex/tokens'
import { getAddress } from 'viem'

import { PoolCategory, SerializedPool } from '../../types'

export const livePools: SerializedPool[] = [
  // {
  //   sousId: 0,
  //   stakingToken: plsTokens.pls,
  //   earningToken: plsTokens.plsx,
  //   contractAddress: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '10',
  //   isFinished: false,
  // },
]
// .map((p) => ({
//   ...p,
//   contractAddress: getAddress(p.contractAddress),
//   stakingToken: p.stakingToken.serialize,
//   earningToken: p.earningToken.serialize,
// }))

// known finished pools
const finishedPools: SerializedPool[] = [
  // {
  //   sousId: 1,
  //   stakingToken: plsTokens.pls,
  //   earningToken: plsTokens.wbtc,
  //   contractAddress: '0x854906693F7bAc4E4c8C31c001b6a42b3d93493E',
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.00004813',
  //   version: 3,
  // },
]
// .map((p) => ({
//   ...p,
//   isFinished: true,
//   contractAddress: getAddress(p.contractAddress),
//   stakingToken: p.stakingToken.serialize,
//   earningToken: p.earningToken.serialize,
// }))

export const pools: SerializedPool[] = [...livePools, ...finishedPools]
