import {
  Flex,
  IconButton,
  RefreshIcon2,
} from '@pulsex/uikit'
import { Swap } from '@pulsex/widgets-internal'
import { ReactElement, memo } from 'react'
import { CHAIN_REFRESH_TIME } from 'config/constants/exchange'
import { useActiveChainId } from 'hooks/useActiveChainId'

interface Props {
  title: string | ReactElement
  subtitle: string
  noConfig?: boolean
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
  hasTrade: boolean
  onRefreshPrice: () => void
}

const CurrencyInputHeader: React.FC<React.PropsWithChildren<Props>> = memo(
  ({ title, hasTrade, onRefreshPrice }) => {
    const { chainId } = useActiveChainId()

    // TODO: put the chart back when subgraph issues resolved
    // const { isChartSupported, isChartDisplayed, setIsChartDisplayed } =
    //   useContext(SwapFeaturesContext)
    // const toggleChartDisplayed = () => {
    //   setIsChartDisplayed?.((currentIsChartDisplayed) => !currentIsChartDisplayed)
    // }

    const titleContent = (
      <Flex width="100%" alignItems="center" justifyContent="space-between" flexDirection="column">
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Flex flexDirection="column" width="100%">
            <Swap.CurrencyInputHeaderTitle>{title}</Swap.CurrencyInputHeaderTitle>
          </Flex>
          <Flex>
            {/* {isChartSupported && (
              <ColoredIconButton onClick={toggleChartDisplayed} variant="text" scale="sm">
                {isChartDisplayed ? <LineGraphIcon width="18px" color="primary" /> : <LineGraphIcon width="18px" color="textSubtle" />}
              </ColoredIconButton>
            )} */}
            <IconButton variant="text" scale="sm" onClick={onRefreshPrice} data-dd-action-name="Swap refresh button">
              <RefreshIcon2
                width="24px"
                disabled={!hasTrade}
                duration={chainId && CHAIN_REFRESH_TIME[chainId] ? CHAIN_REFRESH_TIME[chainId] / 1000 : undefined}
              />
            </IconButton>
          </Flex>
        </Flex>
      </Flex>
    )

    return <Swap.CurrencyInputHeader title={titleContent} subtitle={<></>} />
  },
)

export default CurrencyInputHeader
