import { ChainId } from '@pulsex/chains'

export const DEFAULT_GAS_LIMIT = 30000000n

export const DEFAULT_GAS_LIMIT_BY_CHAIN: { [key in ChainId]?: bigint } = {
  [ChainId.PULSECHAIN]: 30000000n,
  [ChainId.PULSECHAIN_TESTNET]: 30000000n,
}

export const DEFAULT_GAS_BUFFER = 3000000n

export const DEFAULT_GAS_BUFFER_BY_CHAIN: { [key in ChainId]?: bigint } = {
  [ChainId.PULSECHAIN]: DEFAULT_GAS_BUFFER,
  [ChainId.PULSECHAIN_TESTNET]: DEFAULT_GAS_BUFFER,
}
