import { useTranslation } from '@pulsex/localization'
import { Box, Text } from '@pulsex/uikit'

const SafemoonWarning = () => {
  const { t } = useTranslation()

  // Break translation sentences into pieces because the current translation approach doesn't support Link interpolation.
  return (
    <Box maxWidth="380px">
      <Text>
        {t('SAFEMOON has been migrated to')}{' '}
        {/* <Link
          style={{ display: 'inline' }}
          external
          to=""
        >
          {t('a new contract address.')}
        </Link>{' '}
        {t(
          'Trading on the old address may result in the complete loss of your assets. For more information please refer to',
        )}{' '} */}
        {/* <Link style={{ display: 'inline' }} external to="https://twitter.com/safemoon/status/1477770592031887360">
          {t("Safemoon's announcement")}.
        </Link> */}
      </Text>
    </Box>
  )
}

export default SafemoonWarning
