import { SerializedFarm } from '@pulsex/farms'
import { DeserializedPool } from '@pulsex/pools'
import { Token } from '@pulsex/sdk'
import { deserializeToken } from '@pulsex/token-lists'
import { BIG_ZERO } from '@pulsex/utils/bigNumber'
import BigNumber from 'bignumber.js'
import { SerializedPool } from 'state/types'

type UserData =
  | DeserializedPool<Token>['userData']
  | {
    allowance: number | string
    stakingTokenBalance: number | string
    stakedBalance: number | string
    pendingReward: number | string
  }

export const transformUserData = (userData: UserData) => {
  return {
    allowance: userData ? new BigNumber(userData.allowance) : BIG_ZERO,
    stakingTokenBalance: userData ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO,
    stakedBalance: userData ? new BigNumber(userData.stakedBalance) : BIG_ZERO,
    pendingReward: userData ? new BigNumber(userData.pendingReward) : BIG_ZERO,
  }
}

const transformProfileRequirement = (profileRequirement?: { required: boolean; thresholdPoints: string }) => {
  return profileRequirement
    ? {
      required: profileRequirement.required,
      thresholdPoints: profileRequirement.thresholdPoints
        ? new BigNumber(profileRequirement.thresholdPoints)
        : BIG_ZERO,
    }
    : undefined
}

export const transformPool = (pool: SerializedPool): DeserializedPool<Token> => {
  const {
    totalStaked,
    stakingLimit,
    userLimitEnd,
    userData,
    profileRequirement,
    startTimestamp,
    stakingToken,
    earningToken,
    ...rest
  } = pool

  return {
    ...rest,
    startTimestamp,
    profileRequirement: transformProfileRequirement(profileRequirement),
    stakingToken: deserializeToken(stakingToken),
    earningToken: deserializeToken(earningToken),
    userData: transformUserData(userData),
    totalStaked: new BigNumber(totalStaked ?? 0),
    stakingLimit: new BigNumber(stakingLimit ?? 0),
    stakingLimitEndTimestamp: userLimitEnd,
  }
}

export const getTokenPricesFromFarm = (farms: SerializedFarm[]) => {
  return farms.reduce((prices, farm) => {
    const quoteTokenAddress = farm.quoteToken.address.toLocaleLowerCase()
    const tokenAddress = farm.token.address.toLocaleLowerCase()
    /* eslint-disable no-param-reassign */
    if (!prices[quoteTokenAddress]) {
      prices[quoteTokenAddress] = new BigNumber(farm?.quoteTokenPriceUsd ?? 0).toNumber()
    }
    if (!prices[tokenAddress]) {
      prices[tokenAddress] = new BigNumber(farm?.tokenPriceUsd ?? 0).toNumber()
    }
    /* eslint-enable no-param-reassign */
    return prices
  }, {})
}
