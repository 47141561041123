import { createContext, useContext } from 'react'
import { createReducer } from '@reduxjs/toolkit'
import { atomWithReducer } from 'jotai/utils'
import { useAtomValue, useSetAtom } from 'jotai'
import { Field, resetMintState, token0Input, token1Input, token2Input, typeInput } from './actions'

export interface MintState {
  readonly token0Value: string
  readonly token1Value: string
  readonly token2Value: string
  readonly independentField: Field
  readonly typedValue: string
}

const initialState: MintState = {
  token0Value: '',
  token1Value: '',
  token2Value: '',
  independentField: Field.LIQUIDITY_PERCENT,
  typedValue: '0',
}

export const reducer = createReducer<MintState>(initialState, (builder) =>
  builder
    .addCase(resetMintState, () => initialState)
    .addCase(token0Input, (state, { payload: { token0Value } }) => {
      return {
        ...state,
        token0Value
      }
    })
    .addCase(token1Input, (state, { payload: { token1Value } }) => {
      return {
        ...state,
        token1Value
      }
    })
    .addCase(token2Input, (state, { payload: { token2Value } }) => {
      return {
        ...state,
        token2Value
      }
    })
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue,
      }
    })
)

export const createFormAtom = () => atomWithReducer(initialState, reducer)

const AddLiquidityAtomContext = createContext({
  formAtom: createFormAtom(),
})

export const AddLiquidityAtomProvider = AddLiquidityAtomContext.Provider

export function useAddLiquidityFormState() {
  const ctx = useContext(AddLiquidityAtomContext)
  return useAtomValue(ctx.formAtom)
}

export function useAddLiquidityFormDispatch() {
  const ctx = useContext(AddLiquidityAtomContext)
  return useSetAtom(ctx.formAtom)
}
