import {
  Currency,
  CurrencyAmount,
  Fraction,
  Percent,
  TradeType,
  PairV1,
  PairV2,
  TradeV1,
  TradeV2
} from '@pulsex/sdk'
import { Trade } from '@pulsex/smart-order-router/src/routers/sdk'
import { pulsexRouter02Abi } from 'config/abi/IPulseXRouter02'
import {
  ALLOWED_PRICE_IMPACT_HIGH,
  ALLOWED_PRICE_IMPACT_LOW,
  ALLOWED_PRICE_IMPACT_MEDIUM,
  BIPS_BASE,
  BLOCKED_PRICE_IMPACT_NON_EXPERT,
  INPUT_FRACTION_AFTER_FEE,
  ONE_HUNDRED_PERCENT,
  ROUTER_ADDRESS_V1,
  ROUTER_ADDRESS_V2,
  ZERO_PERCENT
} from 'config/constants/exchange'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useContract } from 'hooks/useContract'
import { Field } from '../state/swap/actions'

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(BigInt(num), BIPS_BASE)
}

export function calculateSlippageAmount(value: CurrencyAmount<Currency>, slippage: number): [bigint, bigint] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    (value.quotient * BigInt(10000 - slippage)) / BIPS_BASE,
    (value.quotient * BigInt(10000 + slippage)) / BIPS_BASE,
  ]
}

// universal method for getting PulseXRouter across protocols and chains
export function useRouterContractBothProtocols(protocol: string) {
  const { chainId } = useActiveChainId()
  return useContract(
    protocol === 'V1' ? ROUTER_ADDRESS_V1[chainId] : ROUTER_ADDRESS_V2[chainId],
    pulsexRouter02Abi,
  )
}

export function computeRealizedPriceImpact(trade: Trade<Currency, Currency, TradeType>): {
  priceImpactWithoutFee: Percent | undefined
  realizedLPFee: CurrencyAmount<Currency> | undefined | null
} {
  const realizedLpFeePercent = computeRealizedLPFeePercent(trade)
  const lpFeeAmount = trade.inputAmount.multiply(realizedLpFeePercent)

  return {
    priceImpactWithoutFee: trade.priceImpact.subtract(realizedLpFeePercent),
    realizedLPFee: lpFeeAmount
  }
}

// computes realized lp fee as a percent
function computeRealizedLPFeePercent(trade: Trade<Currency, Currency, TradeType>): Percent {
  let percent: Percent

  // Since routes are either all v2 or all v3 right now, calculate separately
  if (trade.swaps[0].route.pools instanceof PairV1 || trade.swaps[0].route.pools instanceof PairV2) {
    // for each hop in our trade, take away the x*y=k price impact from 0.3% fees
    // e.g. for 3 tokens/2 hops: 1 - ((1 - .03) * (1-.03))
    percent = ONE_HUNDRED_PERCENT.subtract(
      trade.swaps.reduce<Percent>(
        (currentFee: Percent): Percent => currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
        ONE_HUNDRED_PERCENT
      )
    )
  } else {
    percent = ZERO_PERCENT
    for (const swap of trade.swaps) {
      const { numerator, denominator } = swap.inputAmount.divide(trade.inputAmount)
      const overallPercent = new Percent(numerator, denominator)

      const routeRealizedLPFeePercent = overallPercent.multiply(
        ONE_HUNDRED_PERCENT.subtract(
          swap.route.pools.reduce<Percent>((currentFee: Percent): Percent => {
            const fee = 2900
            return currentFee.multiply(ONE_HUNDRED_PERCENT.subtract(new Fraction(fee, 1_000_000)))
          }, ONE_HUNDRED_PERCENT)
        )
      )

      percent = percent.add(routeRealizedLPFeePercent)
    }
  }

  return new Percent(percent.numerator, percent.denominator)
}

// computes the minimum amount out and maximum amount in for a trade given a user specified allowed slippage in bips
export function computeSlippageAdjustedAmounts(
  trade: TradeV1<Currency, Currency, TradeType> | TradeV2<Currency, Currency, TradeType> | undefined,
  allowedSlippage: number,
): { [field in Field]?: CurrencyAmount<Currency> } {
  const pct = basisPointsToPercent(allowedSlippage)
  return {
    [Field.INPUT]: trade?.maximumAmountIn(pct),
    [Field.OUTPUT]: trade?.minimumAmountOut(pct),
  }
}

export function warningSeverity(priceImpact: Percent | undefined | null): 0 | 1 | 2 | 3 | 4 {
  if (!priceImpact?.lessThan(BLOCKED_PRICE_IMPACT_NON_EXPERT)) return 4
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_HIGH)) return 3
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_MEDIUM)) return 2
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_LOW)) return 1
  return 0
}
