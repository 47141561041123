import { useTranslation } from '@pulsex/localization'
import { Currency } from '@pulsex/sdk'
import { Route, SmartRouter } from '@pulsex/smart-router'
import {
  AtomBox,
  AutoColumn,
  Flex,
  Modal,
  ModalV2,
  Text,
  UseModalV2Props,
  useTooltip,
} from '@pulsex/uikit'
import { CurrencyLogo } from '@pulsex/widgets-internal'
import { memo, useMemo } from 'react'

import { RoutingSettingsButton } from 'components/Menu/GlobalSettings/SettingsModal'
import { CurrencyLogoWrapper, RouterBox, RouterPoolBox, RouterTypeText } from './RouterViewer'

type Pair = [Currency, Currency]

export type RouteDisplayEssentials = Pick<Route, 'path' | 'pools' | 'inputAmount' | 'outputAmount' | 'percent'>

interface Props extends UseModalV2Props {
  routes: RouteDisplayEssentials[]
}

export const RouteDisplayModal = memo(function RouteDisplayModal({ isOpen, onDismiss, routes }: Props) {
  const { t } = useTranslation()
  return (
    <ModalV2 closeOnOverlayClick isOpen={isOpen} onDismiss={onDismiss}>
      <Modal
        title={
          <Flex justifyContent="center">
            {t('Routing')}
          </Flex>
        }
        style={{ minHeight: '0' }}
        bodyPadding="24px"
      >
        <AutoColumn gap="48px">
          {routes.map((route, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <RouteDisplay key={i} route={route} />
          ))}
          <RoutingSettingsButton />
        </AutoColumn>
      </Modal>
    </ModalV2>
  )
})

interface RouteDisplayProps {
  route: RouteDisplayEssentials
}

export const RouteDisplay = memo(function RouteDisplay({ route }: RouteDisplayProps) {
  const { t } = useTranslation()
  const { path, pools, inputAmount, outputAmount } = route
  const { currency: inputCurrency } = inputAmount
  const { currency: outputCurrency } = outputAmount
  const { targetRef, tooltip, tooltipVisible } = useTooltip(<Text>{inputCurrency.symbol}</Text>, {
    placement: 'right',
  })

  const {
    targetRef: outputTargetRef,
    tooltip: outputTooltip,
    tooltipVisible: outputTooltipVisible,
  } = useTooltip(<Text>{outputCurrency.symbol}</Text>, {
    placement: 'right',
  })

  const pairs = useMemo<Pair[]>(() => {
    if (path.length <= 1) {
      return []
    }

    const currencyPairs: Pair[] = []
    for (let i = 0; i < path.length - 1; i += 1) {
      currencyPairs.push([path[i], path[i + 1]])
    }
    return currencyPairs
  }, [path])

  const pairNodes =
    pairs.length > 0
      ? pairs.map((p, index) => {
        const [input, output] = p
        const pool = pools[index]
        const isV1Pool = SmartRouter.isV1Pool(pool)
        const isV2Pool = SmartRouter.isV2Pool(pool)
        const key = isV2Pool ? `v2_${pool.reserve0.currency.symbol}_${pool.reserve1.currency.symbol}` : pool.address
        const text = isV2Pool
          ? 'V2'
          : isV1Pool
            ? `V1`
            : pool.balances.length === 3 ? t('Stable3') : t('Stable2')
        const tooltipText = 
          isV1Pool || isV2Pool ? `${input.symbol}/${output.symbol}` : 
          `${pool.balances[0].currency.symbol}/${pool.balances[1].currency.symbol}${pool.balances[2] ? '/'.concat(pool.balances[2].currency.symbol) : ''}`
        return (
          <PairNode
            pair={p}
            key={key}
            text={text}
            className=''
            tooltipText={tooltipText}
          />
        )
      })
      : null

  return (
    <AutoColumn gap="24px">
      <RouterBox justifyContent="space-between" alignItems="center">
        <CurrencyLogoWrapper
          size={{
            xs: '32px',
            md: '48px',
          }}
          ref={targetRef}
        >
          <CurrencyLogo size="100%" currency={inputCurrency} />
          <RouterTypeText fontWeight="bold">{Math.round(route.percent)}%</RouterTypeText>
        </CurrencyLogoWrapper>
        {tooltipVisible && tooltip}
        {pairNodes}
        <CurrencyLogoWrapper
          size={{
            xs: '32px',
            md: '48px',
          }}
          ref={outputTargetRef}
        >
          <CurrencyLogo size="100%" currency={outputCurrency} />
        </CurrencyLogoWrapper>
        {outputTooltipVisible && outputTooltip}
      </RouterBox>
    </AutoColumn>
  )
})

function PairNode({
  pair,
  text,
  className,
  tooltipText,
}: {
  pair: Pair
  text: string
  className: string
  tooltipText: string
}) {
  const [input, output] = pair

  const tooltip = useTooltip(tooltipText)

  return (
    <RouterPoolBox className={className} ref={tooltip.targetRef}>
      {tooltip.tooltipVisible && tooltip.tooltip}
      <AtomBox
        size={{
          xs: '24px',
          md: '32px',
        }}
      >
        <CurrencyLogo size="100%" currency={input} />
      </AtomBox>
      <AtomBox
        size={{
          xs: '24px',
          md: '32px',
        }}
      >
        <CurrencyLogo size="100%" currency={output} />
      </AtomBox>
      <RouterTypeText>{text}</RouterTypeText>
    </RouterPoolBox>
  )
}
