import { useTranslation } from "@pulsex/localization";
import BigNumber from "bignumber.js";
import { Button, Heading, Text, Balance } from "@pulsex/uikit";
import { ActionContainer, ActionContent, ActionTitles } from "./styles";

export interface HarvestActionProps {
  earnings: BigNumber;
  earningsUsd: number;
  displayBalance: string | JSX.Element;
  pendingTx: boolean;
  userDataReady: boolean;
  handleHarvest: () => void;
}

const HarvestAction: React.FunctionComponent<React.PropsWithChildren<HarvestActionProps>> = ({
  earnings,
  earningsUsd,
  displayBalance,
  pendingTx,
  userDataReady,
  handleHarvest,
}) => {
  const { t } = useTranslation();

  return (
    <ActionContainer style={{ minHeight: 124.5 }}>
      <ActionTitles>
        <Text bold textTransform="uppercase" color="secondary" fontSize="12px" pr="4px">
          INC
        </Text>
        <Text bold textTransform="uppercase" color="textSubtle" fontSize="12px">
          {t("Earned")}
        </Text>
      </ActionTitles>
      <ActionContent>
        <div>
          <Heading>{displayBalance}</Heading>
          {earningsUsd > 0 && (
            <Balance fontSize="12px" color="textSubtle" decimals={2} value={earningsUsd} unit=" USD" prefix="~" />
          )}
        </div>
        <Button ml="4px" disabled={earnings.eq(0) || pendingTx || !userDataReady} onClick={handleHarvest}>
          {pendingTx ? t("Harvesting") : t("Harvest")}
        </Button>
      </ActionContent>
    </ActionContainer>
  );
};

export default HarvestAction;
