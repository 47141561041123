import { atom, useAtom, useAtomValue } from 'jotai'
import { userSingleHopAtom } from '@pulsex/utils/user'
import atomWithStorageWithErrorCatch from 'utils/atomWithStorageWithErrorCatch'

const userUseStableSwapAtom = atomWithStorageWithErrorCatch<boolean>('useStableSwap', true)
const userUseV1SwapAtom = atomWithStorageWithErrorCatch<boolean>('useV1Swap', true)
const userUseV2SwapAtom = atomWithStorageWithErrorCatch<boolean>('useV2Swap', true)
const userUseSplitRouteAtom = atomWithStorageWithErrorCatch<boolean>('useSplitRouting', true)
const userProtocol = atomWithStorageWithErrorCatch<string>('useProtocol', 'V2')
const userUseAPI = atomWithStorageWithErrorCatch<boolean>('useUserUseAPI', false)

export function useUserStableSwapEnable() {
  return useAtom(userUseStableSwapAtom)
}

export function useUserV1SwapEnable() {
  return useAtom(userUseV1SwapAtom)
}

export function useUserV2SwapEnable() {
  return useAtom(userUseV2SwapAtom)
}

export function useUserSplitRouteEnable() {
  return useAtom(userUseSplitRouteAtom)
}

export function useProtocol() {
  return useAtom(userProtocol)
}

export function useUserUseAPI() {
  return useAtom(userUseAPI)
}

const derivedOnlyOneAMMSourceEnabledAtom = atom((get) => {
  return [get(userUseStableSwapAtom), get(userUseV1SwapAtom), get(userUseV2SwapAtom)].filter(Boolean).length === 1
})

export function useOnlyOneAMMSourceEnabled() {
  return useAtomValue(derivedOnlyOneAMMSourceEnabledAtom)
}

const derivedRoutingSettingChangedAtom = atom(
  (get) => {
    return [
      get(userUseStableSwapAtom),
      get(userUseV1SwapAtom),
      get(userUseV2SwapAtom),
      get(userUseSplitRouteAtom),
      !get(userSingleHopAtom),
      !get(userUseAPI),
    ].some((x) => x === false)
  },
  (_, set) => {
    set(userUseStableSwapAtom, true)
    set(userUseV1SwapAtom, true)
    set(userUseV2SwapAtom, true)
    set(userUseSplitRouteAtom, true)
    set(userSingleHopAtom, false)
    set(userUseAPI, false)
  },
)

export function useRoutingSettingChanged() {
  return useAtom(derivedRoutingSettingChangedAtom)
}
