import { ChainId } from '@pulsex/chains'

export default {
  router: {
    [ChainId.PULSECHAIN]: '0xDA9aBA4eACF54E0273f56dfFee6B8F1e20B23Bba',
    [ChainId.PULSECHAIN_TESTNET]: '0xE20223c331465Cf7dD2f659784Cc05e4d2e5A1c5',
  },
  multiCall: {
    [ChainId.PULSECHAIN]: '0xdc6ed7C2EE5ab7fd303334B34F7524Cbb463DE57',
    [ChainId.PULSECHAIN_TESTNET]: '0x083F559993227395009596f77496FDc48e84C69a',
  },
  masterChef: {
    [ChainId.PULSECHAIN]: '0xB2Ca4A66d3e57a5a9A12043B6bAD28249fE302d4',
    [ChainId.PULSECHAIN_TESTNET]: '0x3b34Fff74497cA21771ce9a0c11cb07490686A58',
  },
  plsxLab: {
    [ChainId.PULSECHAIN]: '0x3E3EC0af505D6458D18A3AA1E29A9d7f008dd98e',
    [ChainId.PULSECHAIN_TESTNET]: '0x959a437F1444DaDaC8aF997E71EAF0479c810267',
  },
  buyandburnV1: {
    [ChainId.PULSECHAIN]: '0xD46BD969d995A122AD5B803A45d309021A647B87',
    [ChainId.PULSECHAIN_TESTNET]: '0x7cD43983A963c62Add34694E83E896c5AfC3333f',
  },
  buyandburnV2: {
    [ChainId.PULSECHAIN]: '0xd6cA7ee047a6F45d20d2962E4394E070cF27724F',
    [ChainId.PULSECHAIN_TESTNET]: '0x3DdaBc2B4f5D0b74A28481eE6f42C67c495E0E5E',
  },
  stableSwapTwoNativeHelper: {
    [ChainId.PULSECHAIN]: '0xa511671819530490dcB371a25A24C2B33476F1B6',
    [ChainId.PULSECHAIN_TESTNET]: '0xcaC5856ff471AA35993A5d86cd383c1322597331',
  },
  stableSwapThreeNativeHelper: {
    [ChainId.PULSECHAIN]: '0x9cB06Bc186e2CD105eee898ef576c5465AB55a88',
    [ChainId.PULSECHAIN_TESTNET]: '0x3fcBA81F0896bc7eA4A7B4D7B96b7280870Ba2F2',
  },
} as const
