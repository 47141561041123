import { useTranslation } from '@pulsex/localization'
import { Currency, CurrencyAmount, Percent, Token } from '@pulsex/sdk'
import {
  getSwapOutput,
  getLPOutputWithoutFee,
  StableSwapPool,
  PoolType,
  serializeStableSwapPools,
  useFindStablePoolByPoolAddress,
  useStableSwapPools
} from '@pulsex/stable-swap-sdk'
import tryParseAmount from '@pulsex/utils/tryParseAmount'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo } from 'react'
import { Address, getAddress, parseUnits } from 'viem'
import { useAccount } from 'wagmi'
import { infoTwoStableSwapABI } from 'config/abi/infoTwoStableSwap'
import { infoThreeStableSwapABI } from 'config/abi/infoThreeStableSwap'
import { stableLPABI } from 'config/abi/stableLP'
import { stableSwapTwoABI } from 'config/abi/stableSwapTwo'
import { stableSwapThreeABI } from 'config/abi/stableSwapThree'
import { BIG_INT_ZERO } from 'config/constants/exchange'
import { ENABLE_STABLESWAP } from 'config'
import { useActiveChainId } from 'hooks/useActiveChainId'
import {
  useStableSwapContract,
  useStableSwapTwoInfoContract,
  useStableSwapThreeInfoContract,
  useStableSwapLPContract,
  UseStableSwapTwoInfoContract,
  UseStableSwapThreeInfoContract,
} from 'hooks/useContract'
import { useStableSwapInfo } from 'hooks/useStableSwapInfo'
import useTotalSupply from 'hooks/useTotalSupply'
import { useSingleCallResult } from 'state/multicall/hooks'
import { useAddLiquidityFormDispatch, useAddLiquidityFormState } from 'state/stableswap/reducer'
import { useTokenBalancesWithLoadingIndicator, useCurrencyBalances, useTokenBalance } from 'state/wallet/hooks'
import { publicClient } from 'utils/wagmi'
import { Field, token0Input, token1Input, token2Input, typeInput } from './actions'

export function useMintActionHandlers(): {
  onToken0Input: (token0Value: string) => void
  onToken1Input: (token1Value: string) => void
  onToken2Input: (token2Value: string) => void
} {
  const dispatch = useAddLiquidityFormDispatch()

  const onToken0Input = useCallback(
    (token0Value: string) => {
      dispatch(token0Input({ token0Value }))
    },
    [dispatch],
  )
  const onToken1Input = useCallback(
    (token1Value: string) => {
      dispatch(token1Input({ token1Value }))
    },
    [dispatch],
  )
  const onToken2Input = useCallback(
    (token2Value: string) => {
      dispatch(token2Input({ token2Value }))
    },
    [dispatch],
  )

  return {
    onToken0Input,
    onToken1Input,
    onToken2Input
  }
}

export function useBurnActionHandlers(): {
  onUserInput: (field: Field, typedValue: string) => void
} {
  const dispatch = useAddLiquidityFormDispatch()

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch],
  )

  return {
    onUserInput,
  }
}

export function useAllStableSwapPoolsWithUserData(account: string | undefined) {
  const { chainId } = useActiveChainId()
  const stablePools = useStableSwapPools(chainId)

  const [lpBalances, isLoading] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    stablePools.map(({ liquidityToken }) => liquidityToken),
  )

  const poolsWithBalance: StableSwapPool[] = useMemo(() => {
    const tokensWithBalance: StableSwapPool[] = []
    for (const pool of stablePools) {
      const userBalance = lpBalances[pool.liquidityToken.address] ?? CurrencyAmount.fromRawAmount(pool?.liquidityToken, '0')
      tokensWithBalance.push({
        ...pool,
        userData: {
          lpBalance: userBalance
        },
      })
    }
    return tokensWithBalance
  }, [stablePools, lpBalances])

  const serializedPoolsWithBalance = useMemo(() => {
    return serializeStableSwapPools(poolsWithBalance)
  }, [poolsWithBalance])

  // This query fetches global data for each stableswap pool
  const { data: poolDatas, isLoading: poolDatasLoading } = useQuery({
    queryKey: ['useStableSwapInfo'],
    queryFn: async () => {
      const contractCalls = poolsWithBalance.map((pool, poolIndex) => ({
        poolIndex,
        calls: pool.type === PoolType.TWO_POOL ? [
          {
            chainId,
            abi: stableSwapTwoABI,
            address: pool.stableSwapAddress,
            functionName: 'balances',
            args: [0n],
          } as const,
          {
            chainId,
            abi: stableSwapTwoABI,
            address: pool.stableSwapAddress,
            functionName: 'balances',
            args: [1n],
          } as const,
          {
            chainId,
            abi: stableSwapTwoABI,
            address: pool.stableSwapAddress,
            functionName: 'A',
          } as const,
          {
            chainId,
            abi: stableLPABI,
            address: pool.liquidityToken.address,
            functionName: 'totalSupply',
          } as const,
          {
            chainId,
            abi: stableSwapTwoABI,
            address: pool.stableSwapAddress,
            functionName: 'fee',
          } as const,
          {
            chainId,
            abi: stableSwapTwoABI,
            address: pool.stableSwapAddress,
            functionName: 'admin_fee',
          } as const,
          {
            chainId,
            abi: stableSwapTwoABI,
            address: pool.stableSwapAddress,
            functionName: 'FEE_DENOMINATOR',
          } as const
        ] : [
          {
            chainId,
            abi: stableSwapThreeABI,
            address: pool.stableSwapAddress,
            functionName: 'balances',
            args: [0n],
          } as const,
          {
            chainId,
            abi: stableSwapThreeABI,
            address: pool.stableSwapAddress,
            functionName: 'balances',
            args: [1n],
          } as const,
          {
            chainId,
            abi: stableSwapThreeABI,
            address: pool.stableSwapAddress,
            functionName: 'balances',
            args: [2n],
          } as const,
          {
            chainId,
            abi: stableSwapThreeABI,
            address: pool.stableSwapAddress,
            functionName: 'A',
          } as const,
          {
            chainId,
            abi: stableLPABI,
            address: pool.liquidityToken.address,
            functionName: 'totalSupply',
          } as const,
          {
            chainId,
            abi: stableSwapThreeABI,
            address: pool.stableSwapAddress,
            functionName: 'fee',
          } as const,
          {
            chainId,
            abi: stableSwapThreeABI,
            address: pool.stableSwapAddress,
            functionName: 'admin_fee',
          } as const,
          {
            chainId,
            abi: stableSwapThreeABI,
            address: pool.stableSwapAddress,
            functionName: 'FEE_DENOMINATOR',
          } as const
        ]
      }))

      const multicallResults = await Promise.all(contractCalls.map(async ({ calls, poolIndex }) => {
        const results = await publicClient({ chainId }).multicall({
          contracts: calls as any,
          allowFailure: false,
        })

        return { results: results as bigint[], pool: poolsWithBalance[poolIndex] }
      }))

      const poolData = multicallResults.map(({ results, pool }) => {
        const isThreePool = results?.length === 8
        const feeNumerator = isThreePool ? results?.[5] as bigint : results?.[4] as bigint
        const adminFeeNumerator = isThreePool ? results?.[6] as bigint : results?.[5] as bigint
        const feeDenominator = isThreePool ? results?.[7] as bigint : results?.[6] as bigint

        const balances = isThreePool && pool.token2 ? [
          CurrencyAmount.fromRawAmount(pool.token0, results[0]),
          CurrencyAmount.fromRawAmount(pool.token1, results[1]),
          CurrencyAmount.fromRawAmount(pool.token2, results[2]),
        ] : [
          CurrencyAmount.fromRawAmount(pool.token0, results[0]),
          CurrencyAmount.fromRawAmount(pool.token1, results[1]),
        ]

        const isNewPool = isThreePool
          ? (balances[0].equalTo(0) && balances[1].equalTo(0) && balances[2].equalTo(0))
          : (balances[0].equalTo(0) && balances[1].equalTo(0))

        const zeroFee = new Percent(0, 1)
        const poolPrices = !isNewPool ? (isThreePool && pool.token2 ? {
          token0: [
            getSwapOutput({
              amplifier: results[3] as bigint,
              balances,
              outputCurrency: pool.token1,
              amount: CurrencyAmount.fromRawAmount(pool.token0, parseUnits('1', pool.token0.decimals)),
              fee: zeroFee
            }),
            getSwapOutput({
              amplifier: results[3] as bigint,
              balances,
              outputCurrency: pool.token2,
              amount: CurrencyAmount.fromRawAmount(pool.token0, parseUnits('1', pool.token0.decimals)),
              fee: zeroFee
            })
          ],
          token1: [
            getSwapOutput({
              amplifier: results[3] as bigint,
              balances,
              outputCurrency: pool.token0,
              amount: CurrencyAmount.fromRawAmount(pool.token1, parseUnits('1', pool.token1.decimals)),
              fee: zeroFee
            }),
            getSwapOutput({
              amplifier: results[3] as bigint,
              balances,
              outputCurrency: pool.token2,
              amount: CurrencyAmount.fromRawAmount(pool.token1, parseUnits('1', pool.token1.decimals)),
              fee: zeroFee
            })
          ],
          token2: [
            getSwapOutput({
              amplifier: results[3] as bigint,
              balances,
              outputCurrency: pool.token0,
              amount: CurrencyAmount.fromRawAmount(pool.token2, parseUnits('1', pool.token2.decimals)),
              fee: zeroFee
            }),
            getSwapOutput({
              amplifier: results[3] as bigint,
              balances,
              outputCurrency: pool.token1,
              amount: CurrencyAmount.fromRawAmount(pool.token2, parseUnits('1', pool.token2.decimals)),
              fee: zeroFee
            })
          ],
        } : {
          token0: [
            getSwapOutput({
              amplifier: results[2] as bigint,
              balances,
              outputCurrency: pool.token1,
              amount: CurrencyAmount.fromRawAmount(pool.token0, parseUnits('1', pool.token0.decimals)),
              fee: zeroFee
            })
          ],
          token1: [
            getSwapOutput({
              amplifier: results[2] as bigint,
              balances,
              outputCurrency: pool.token0,
              amount: CurrencyAmount.fromRawAmount(pool.token1, parseUnits('1', pool.token1.decimals)),
              fee: zeroFee
            })
          ],
        }) : {}

        return {
          balances: {
            token0: results[0] as bigint,
            token1: results[1] as bigint,
            token2: isThreePool ? results[2] as bigint : undefined
          },
          poolPrices,
          amplifier: isThreePool ? results[3] as bigint : results[2] as bigint,
          totalSupply: isThreePool ? results[4] as bigint : results[3] as bigint,
          fee: feeNumerator && feeDenominator && new Percent(feeNumerator, feeDenominator),
          adminFee: adminFeeNumerator && feeDenominator && new Percent(adminFeeNumerator, feeDenominator),
        }
      })
      return {
        poolData,
        loading: isLoading,
      }
    },
    enabled: ENABLE_STABLESWAP && poolsWithBalance.length > 0 && !isLoading,
  })

  type PoolWithUserData = StableSwapPool & {
    userData: {
      lpBalance: {
        quotient: bigint
      }
    }
  }
  function isPoolWithUserData(pool: StableSwapPool): pool is PoolWithUserData {
    return pool?.userData?.lpBalance?.quotient !== undefined && pool?.userData?.lpBalance.quotient !== 0n
  }
  const poolsToQuery = poolsWithBalance.filter(isPoolWithUserData)

  // This query fetches users current token amounts locked in each pool where
  // users address owns some lp tokens
  const { data: userTokenAmounts, isLoading: userTokenAmountsLoading } = useQuery({
    queryKey: ['stableSwapInfoContract', 'calc_coins_amount', serializedPoolsWithBalance],
    queryFn: async () => {
      const response = await publicClient({ chainId }).multicall({
        contracts: poolsToQuery.map((pool) => {
          return {
            abi: pool.type === PoolType.TWO_POOL ? infoTwoStableSwapABI : infoThreeStableSwapABI,
            address: pool.infoStableSwapAddress,
            functionName: 'calc_coins_amount',
            args: [pool.stableSwapAddress, BigInt(pool.userData.lpBalance.quotient)] as const,
          }
        }),
        allowFailure: false,
      })
      return response
    },
    enabled: ENABLE_STABLESWAP && poolsToQuery.length > 0 && !isLoading,
  })

  return useMemo(() => {
    const loading = isLoading || userTokenAmountsLoading || poolDatasLoading

    const allPairsWithLiquidity = poolsWithBalance.map((pool, index) => {
      const poolTokenAmountsIndex = poolsToQuery.findIndex(p => p.stableSwapAddress === pool.stableSwapAddress)
      const userTokenAmountsData = userTokenAmounts && poolTokenAmountsIndex !== -1 && userTokenAmounts[poolTokenAmountsIndex] ?
        {
          token0: CurrencyAmount.fromRawAmount(pool.token0, userTokenAmounts[poolTokenAmountsIndex][0]),
          token1: CurrencyAmount.fromRawAmount(pool.token1, userTokenAmounts[poolTokenAmountsIndex][1]),
          token2: pool.type === PoolType.THREE_POOL ?
            CurrencyAmount.fromRawAmount(pool.token2!, userTokenAmounts[poolTokenAmountsIndex][2]) :
            undefined
        } :
        undefined
      const poolData = poolDatas?.poolData[index]

      return {
        ...pool,
        userData: {
          lpBalance: pool?.userData?.lpBalance,
          tokenAmounts: userTokenAmountsData
        },
        poolData,
      } as StableSwapPool
    })

    return {
      data: allPairsWithLiquidity,
      loading,
    }
  }, [isLoading, userTokenAmountsLoading, poolDatasLoading, poolsWithBalance, userTokenAmounts, poolDatas, poolsToQuery])
}

export function useStableConfig({
  chainId,
  poolAddress,
  account,
}: {
  chainId: number | undefined | null
  poolAddress: Address | undefined | null
  account: Address | undefined | null
}) {
  const poolAddressChecksum = getAddress(poolAddress as `0x${string}`)
  const pool = useFindStablePoolByPoolAddress({ chainId, poolAddress: poolAddressChecksum })
  const stableSwapContract = useStableSwapContract(pool?.stableSwapAddress, pool?.type)
  const stableSwapTwoInfoContract = useStableSwapTwoInfoContract(pool?.infoStableSwapAddress)
  const stableSwapThreeInfoContract = useStableSwapThreeInfoContract(pool?.infoStableSwapAddress)
  const stableSwapLPContract = useStableSwapLPContract(pool?.liquidityToken.address)
  const totalSupply = useTotalSupply(pool?.liquidityToken)
  const userLpBalance = useTokenBalance(account ?? undefined, pool?.liquidityToken)
  const isThreePool = pool?.type === PoolType.THREE_POOL

  const { data: poolPrices, isLoading: poolPricesLoading } = useQuery({
    queryKey: ['poolData'],
    queryFn: async () => {
      if (!chainId || !pool) return undefined
      const contractCalls = isThreePool ? [
        {
          abi: stableSwapThreeABI,
          address: pool.stableSwapAddress as Address,
          functionName: 'balances',
          args: [0n],
        } as const,
        {
          abi: stableSwapThreeABI,
          address: pool.stableSwapAddress as Address,
          functionName: 'balances',
          args: [1n],
        } as const,
        {
          abi: stableSwapThreeABI,
          address: pool.stableSwapAddress as Address,
          functionName: 'balances',
          args: [2n],
        } as const,
        {
          abi: stableSwapThreeABI,
          address: pool.stableSwapAddress,
          functionName: 'A',
        } as const,
      ] : [
        {
          abi: stableSwapTwoABI,
          address: pool?.stableSwapAddress as Address,
          functionName: 'balances',
          args: [0n],
        } as const,
        {
          abi: stableSwapTwoABI,
          address: pool?.stableSwapAddress as Address,
          functionName: 'balances',
          args: [1n],
        } as const,
        {
          abi: stableSwapTwoABI,
          address: pool?.stableSwapAddress,
          functionName: 'A',
        } as const,
      ]

      const results = await publicClient({ chainId }).multicall({
        contracts: contractCalls as any,
        allowFailure: false,
      }) as bigint[]

      const zeroFee = new Percent(0, 1)
      const balances = isThreePool && pool.token2 ? [
        CurrencyAmount.fromRawAmount(pool.token0, results[0]),
        CurrencyAmount.fromRawAmount(pool.token1, results[1]),
        CurrencyAmount.fromRawAmount(pool.token2, results[2]),
      ] : [
        CurrencyAmount.fromRawAmount(pool.token0, results[0]),
        CurrencyAmount.fromRawAmount(pool.token1, results[1]),
      ]

      return isThreePool && pool.token2 ? {
        token0: [
          getSwapOutput({
            amplifier: results[3] as bigint,
            balances,
            outputCurrency: pool.token1,
            amount: CurrencyAmount.fromRawAmount(pool.token0, parseUnits('1', pool.token0.decimals)),
            fee: zeroFee
          }),
          getSwapOutput({
            amplifier: results[3] as bigint,
            balances,
            outputCurrency: pool.token2,
            amount: CurrencyAmount.fromRawAmount(pool.token0, parseUnits('1', pool.token0.decimals)),
            fee: zeroFee
          })
        ],
        token1: [
          getSwapOutput({
            amplifier: results[3] as bigint,
            balances,
            outputCurrency: pool.token0,
            amount: CurrencyAmount.fromRawAmount(pool.token1, parseUnits('1', pool.token1.decimals)),
            fee: zeroFee
          }),
          getSwapOutput({
            amplifier: results[3] as bigint,
            balances,
            outputCurrency: pool.token2,
            amount: CurrencyAmount.fromRawAmount(pool.token1, parseUnits('1', pool.token1.decimals)),
            fee: zeroFee
          })
        ],
        token2: [
          getSwapOutput({
            amplifier: results[3] as bigint,
            balances,
            outputCurrency: pool.token0,
            amount: CurrencyAmount.fromRawAmount(pool.token2, parseUnits('1', pool.token2.decimals)),
            fee: zeroFee
          }),
          getSwapOutput({
            amplifier: results[3] as bigint,
            balances,
            outputCurrency: pool.token1,
            amount: CurrencyAmount.fromRawAmount(pool.token2, parseUnits('1', pool.token2.decimals)),
            fee: zeroFee
          })
        ],
      } : {
        token0: [
          getSwapOutput({
            amplifier: results[2] as bigint,
            balances,
            outputCurrency: pool.token1,
            amount: CurrencyAmount.fromRawAmount(pool.token0, parseUnits('1', pool.token0.decimals)),
            fee: zeroFee
          })
        ],
        token1: [
          getSwapOutput({
            amplifier: results[2] as bigint,
            balances,
            outputCurrency: pool.token0,
            amount: CurrencyAmount.fromRawAmount(pool.token1, parseUnits('1', pool.token1.decimals)),
            fee: zeroFee
          })
        ],
      }
    }
  })

  const { data: userTokenAmounts, isLoading: userTokenAmountsLoading } = useQuery({
    queryKey: ['userTokenAmounts', pool?.stableSwapAddress],
    queryFn: async () => {
      if (!pool || !userLpBalance || !stableSwapThreeInfoContract || !stableSwapTwoInfoContract) return undefined
      const infoContract = isThreePool ? stableSwapThreeInfoContract : stableSwapTwoInfoContract
      const response = await infoContract.read.calc_coins_amount(
        [pool?.stableSwapAddress, BigInt(userLpBalance?.quotient)]
      )
      const amounts = [
        CurrencyAmount.fromRawAmount(pool.token0, response[0]),
        CurrencyAmount.fromRawAmount(pool.token1, response[1])
      ]

      if (response.length > 2 && pool?.token2 && response[2]) {
        amounts.push(CurrencyAmount.fromRawAmount(pool.token2, response[2]) as CurrencyAmount<Token>)
      }

      return amounts
    },
    enabled: userLpBalance?.greaterThan('0'),
  })

  return useMemo(
    () => ({
      pool,
      stableSwapContract,
      stableSwapTwoInfoContract,
      stableSwapThreeInfoContract,
      stableSwapLPContract,
      totalSupply,
      userLpBalance,
      isThreePool,
      userTokenAmounts,
      userTokenAmountsLoading,
      poolPrices,
      poolPricesLoading
    }),
    [
      isThreePool,
      totalSupply,
      userLpBalance,
      pool,
      stableSwapContract,
      stableSwapTwoInfoContract,
      stableSwapThreeInfoContract,
      stableSwapLPContract,
      poolPrices,
      poolPricesLoading,
      userTokenAmounts,
      userTokenAmountsLoading,
    ],
  )
}

export function useDerivedLPInfo(
  pool?: StableSwapPool,
  amountA?: CurrencyAmount<Currency> | undefined,
  amountB?: CurrencyAmount<Currency> | undefined,
  amountC?: CurrencyAmount<Currency> | undefined,
): {
  lpOutputWithoutFee: CurrencyAmount<Currency> | null
} {
  const { pool: poolWithInfo, loading } = useStableSwapInfo(pool)
  const { poolData } = poolWithInfo

  const token0 = amountA?.currency?.wrapped
  const token1 = amountB?.currency?.wrapped
  const token2 = amountC?.currency?.wrapped

  const poolBalances = useMemo<CurrencyAmount<Currency>[] | undefined>(() => {
    if (token0 && token1 && poolData?.balances?.token0 && poolData.balances.token1) {
      const balances = [
        CurrencyAmount.fromRawAmount(token0, poolData.balances.token0),
        CurrencyAmount.fromRawAmount(token1, poolData.balances.token1),
      ]

      if (token2 && poolData.balances.token2) {
        balances.push(CurrencyAmount.fromRawAmount(token2, poolData.balances.token2))
      }

      return balances as CurrencyAmount<Currency>[]
    }
    return undefined
  }, [poolData?.balances, token0, token1, token2])

  const totalSupplyAmount = useMemo(
    () => (poolData?.totalSupply && pool && CurrencyAmount.fromRawAmount(pool.liquidityToken, poolData.totalSupply)) ?? undefined,
    [poolData?.totalSupply, pool],
  )

  return useMemo(() => {
    const emptyResult = {
      loading,
      lpOutputWithoutFee: null,
    }
    if (!totalSupplyAmount || !poolBalances || !poolData?.amplifier || !amountA || !amountB) {
      return emptyResult
    }
    const totalValue = poolBalances[0].quotient + poolBalances[1].quotient
    if (totalValue === BIG_INT_ZERO) {
      return emptyResult
    }
    let lpOutputWithoutFee: CurrencyAmount<Currency> | null = null
    try {
      lpOutputWithoutFee = getLPOutputWithoutFee({
        amplifier: poolData.amplifier,
        balances: poolBalances,
        totalSupply: totalSupplyAmount,
        amounts: token2 ? [amountA, amountB, amountC] : [amountA, amountB],
      })
    } catch (e) {
      console.error(e)
    }
    return {
      loading,
      lpOutputWithoutFee,
    }
  }, [totalSupplyAmount, poolBalances, poolData?.amplifier, loading, amountA, amountB, amountC, token2])
}

function useMintedStableLP({
  stableSwapTwoInfoContract,
  stableSwapThreeInfoContract,
  stableSwapPool,
  stableSwapAddress,
  token0Amount,
  token1Amount,
  token2Amount,
}: {
  stableSwapTwoInfoContract?: UseStableSwapTwoInfoContract
  stableSwapThreeInfoContract?: UseStableSwapThreeInfoContract
  stableSwapPool?: StableSwapPool
  stableSwapAddress?: Address
  token0Amount: bigint | undefined
  token1Amount: bigint | undefined
  token2Amount: bigint | undefined
}) {
  const quotient0 = token0Amount || 0n
  const quotient1 = token1Amount || 0n
  const quotient2 = token2Amount || 0n

  const isTriplePool = stableSwapPool?.type === PoolType.THREE_POOL

  const twoAmounts = useMemo(() => {
    return [quotient0, quotient1] as const
  }, [quotient0, quotient1])

  const threeAmounts = useMemo(() => {
    return [quotient0, quotient1, quotient2] as const
  }, [quotient0, quotient1, quotient2])

  const twoInputs = useMemo(() => {
    return [stableSwapAddress as Address, twoAmounts] as const
  }, [stableSwapAddress, twoAmounts])

  const threeInputs = useMemo(() => {
    return [stableSwapAddress as Address, threeAmounts] as const
  }, [stableSwapAddress, threeAmounts])

  const { data, error, isLoading } = useQuery({
    queryKey: ['get_add_liquidity_mint_amount', quotient0.toString(), quotient1.toString(), quotient2.toString()],
    queryFn: async () => {
      if (isTriplePool && stableSwapThreeInfoContract) {
        const res = await stableSwapThreeInfoContract.read.get_add_liquidity_mint_amount(threeInputs)
        return res
      }
      const res = stableSwapTwoInfoContract && await stableSwapTwoInfoContract.read.get_add_liquidity_mint_amount(twoInputs)
      return res
    },
    enabled: Boolean(stableSwapPool) && (quotient0 > 0n || quotient1 > 0n || quotient2 > 0n)
  })

  // TODO: Combine get_add_liquidity_mint_amount + balances in one call
  const balanceResult = useSingleCallResult({
    contract: stableSwapTwoInfoContract,
    functionName: 'balances',
    args: useMemo(() => [stableSwapAddress as Address] as const, [stableSwapAddress]),
  })

  return useMemo(
    () => ({
      reserves: isTriplePool ? balanceResult?.result as unknown as [bigint, bigint, bigint] || [0n, 0n, 0n] :
        balanceResult?.result as [bigint, bigint] || [0n, 0n],
      data: data as bigint,
      loading: isLoading,
      error
    }),
    [isTriplePool, balanceResult?.result, data, error, isLoading],
  )
}

export function useStableLPDerivedMintInfo(
  stableSwapPool?: StableSwapPool,
  currencyA?: Currency,
  currencyB?: Currency,
  currencyC?: Currency,
  stableSwapContract?: any,
  stableSwapTwoInfoContract?: UseStableSwapTwoInfoContract,
  stableSwapThreeInfoContract?: UseStableSwapThreeInfoContract
): {
  currencies: { [field in Address]?: Currency }
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> }
  parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  noLiquidity?: boolean
  loading?: boolean
  liquidityMinted?: CurrencyAmount<Token>
  poolTokenPercentage?: Percent
  error?: string
  addError?: string
  reserves?: readonly [bigint, bigint] | readonly [bigint, bigint, bigint]
} {
  const { address: account } = useAccount()

  const { t } = useTranslation()

  const { onToken0Input, onToken1Input, onToken2Input } = useMintActionHandlers()
  const { token0Value, token1Value, token2Value } = useAddLiquidityFormState()

  const totalSupply = useTotalSupply(stableSwapPool?.liquidityToken)

  const noLiquidity = Boolean(totalSupply && totalSupply.quotient === BIG_INT_ZERO)

  // balances
  const balances = useCurrencyBalances(
    account ?? undefined,
    useMemo(() => [currencyA, currencyB, currencyC], [currencyA, currencyB, currencyC]),
  )
  const currencyBalances: { [currency in Field]?: CurrencyAmount<Currency> } = {
    [Field.CURRENCY_A]: balances[0],
    [Field.CURRENCY_B]: balances[1],
    [Field.CURRENCY_C]: balances[2],
  }

  // amounts
  const token0Amount: CurrencyAmount<Currency> | undefined =
    (currencyA && tryParseAmount(token0Value, currencyA)) ||
    (currencyA && CurrencyAmount.fromRawAmount(currencyA, '0')) ||
    undefined
  const token1Amount: CurrencyAmount<Currency> | undefined =
    (currencyB && tryParseAmount(token1Value, currencyB)) ||
    (currencyB && CurrencyAmount.fromRawAmount(currencyB, '0')) ||
    undefined
  const token2Amount: CurrencyAmount<Currency> | undefined =
    (currencyC && tryParseAmount(token2Value, currencyC)) ||
    (currencyC && CurrencyAmount.fromRawAmount(currencyC, '0')) ||
    undefined

  const currencyAAmountQuotient = token0Amount?.quotient
  const currencyBAmountQuotient = token1Amount?.quotient
  const currencyCAmountQuotient = token2Amount?.quotient

  const parsedAmounts: { [field in Field]: CurrencyAmount<Currency> | undefined } = useMemo(
    () => ({
      [Field.LIQUIDITY]: undefined,
      [Field.LIQUIDITY_PERCENT]: undefined,
      [Field.CURRENCY_A]: token0Amount,
      [Field.CURRENCY_B]: token1Amount,
      [Field.CURRENCY_C]: token2Amount,
    }),
    [token0Amount, token1Amount, token2Amount],
  )

  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.CURRENCY_A]: currencyA ?? undefined,
      [Field.CURRENCY_B]: currencyB ?? undefined,
      [Field.CURRENCY_C]: currencyC ?? undefined,
    }),
    [currencyA, currencyB, currencyC],
  )

  useEffect(() => {
    onToken0Input('')
    onToken1Input('')
    onToken2Input('')
  }, [stableSwapContract?.address, onToken0Input, onToken1Input, onToken2Input])

  const {
    reserves,
    data: lpMinted,
    error: estimateLPError,
    loading,
  } = useMintedStableLP({
    stableSwapAddress: stableSwapPool?.stableSwapAddress,
    stableSwapTwoInfoContract,
    stableSwapThreeInfoContract,
    stableSwapPool,
    token0Amount: currencyAAmountQuotient,
    token1Amount: currencyBAmountQuotient,
    token2Amount: currencyCAmountQuotient
  })

  // liquidity minted
  const liquidityMinted = useMemo(() => {
    if (stableSwapPool?.liquidityToken && totalSupply && lpMinted) {
      try {
        return CurrencyAmount.fromRawAmount(stableSwapPool?.liquidityToken, lpMinted?.toString())
      } catch (error) {
        console.error(error)
        return undefined
      }
    }
    return undefined
  }, [stableSwapPool?.liquidityToken, totalSupply, lpMinted])

  const poolTokenPercentage = useMemo(() => {
    if (liquidityMinted && totalSupply) {
      return new Percent(liquidityMinted.quotient, totalSupply.add(liquidityMinted).quotient)
    }
    return undefined
  }, [liquidityMinted, totalSupply])

  let error: string | undefined
  let addError: string | undefined
  if (!account) {
    error = t('Connect Wallet')
  }

  if (
    token0Amount &&
    token1Amount &&
    token2Amount &&
    currencyBalances?.[Field.CURRENCY_A]?.equalTo(0) &&
    currencyBalances?.[Field.CURRENCY_B]?.equalTo(0) &&
    currencyBalances?.[Field.CURRENCY_C]?.equalTo(0)
  ) {
    error = error ?? t('No token balance')
  }

  const oneCurrencyRequired =
    !parsedAmounts[Field.CURRENCY_A]?.greaterThan(0)
    && !parsedAmounts[Field.CURRENCY_B]?.greaterThan(0)
    && !parsedAmounts[Field.CURRENCY_C]?.greaterThan(0)
  const twoCurrenciesRequired =
    !parsedAmounts[Field.CURRENCY_A]?.greaterThan(0)
    || !parsedAmounts[Field.CURRENCY_B]?.greaterThan(0)
    || currencyC && !parsedAmounts[Field.CURRENCY_C]?.greaterThan(0)

  if (noLiquidity ? twoCurrenciesRequired : oneCurrencyRequired) {
    addError = t('Enter an amount')
  }

  if (token0Amount && currencyBalances?.[Field.CURRENCY_A]?.lessThan(token0Amount)) {
    addError = t('Insufficient %symbol% balance', { symbol: currencies[Field.CURRENCY_A]?.symbol })
  }

  if (token1Amount && currencyBalances?.[Field.CURRENCY_B]?.lessThan(token1Amount)) {
    addError = t('Insufficient %symbol% balance', { symbol: currencies[Field.CURRENCY_B]?.symbol })
  }

  if (estimateLPError) {
    addError = t('Unable to supply')
  }

  return {
    parsedAmounts,
    currencyBalances,
    currencies,
    loading,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
    addError,
    reserves,
  }
}

export function useGetRemovedTokenAmounts({
  pool,
  stableSwapTwoInfoContract,
  stableSwapThreeInfoContract,
  lpAmount,
}: {
  pool?: StableSwapPool
  stableSwapTwoInfoContract?: UseStableSwapTwoInfoContract
  stableSwapThreeInfoContract?: UseStableSwapThreeInfoContract
  lpAmount?: string
}) {
  const { token0, token1, token2, stableSwapAddress, type } = pool || {}
  const isThreePool = type === PoolType.THREE_POOL
  const { data: twoData } = useQuery({
    queryKey: ['stableSwapTwoInfoContract', 'calc_coins_amount', stableSwapAddress, lpAmount],

    queryFn: async () => {
      if (!stableSwapTwoInfoContract || !lpAmount) return undefined
      return stableSwapTwoInfoContract.read.calc_coins_amount([stableSwapAddress as Address, BigInt(lpAmount)])
    },

    enabled: !isThreePool && Boolean(lpAmount),
  })
  const { data: threeData } = useQuery({
    queryKey: ['stableSwapThreeInfoContract', 'calc_coins_amount', stableSwapAddress, lpAmount],

    queryFn: async () => {
      if (!stableSwapThreeInfoContract || !lpAmount) return undefined
      return stableSwapThreeInfoContract.read.calc_coins_amount([stableSwapAddress as Address, BigInt(lpAmount)])
    },

    enabled: isThreePool && Boolean(lpAmount),
  })

  if ((!Array.isArray(twoData) && !Array.isArray(threeData)) || !token0 || !token1) return []

  const tokenAAmount = isThreePool
    ? CurrencyAmount.fromRawAmount(token0, threeData?.[0]?.toString() ?? 0)
    : CurrencyAmount.fromRawAmount(token0, twoData?.[0]?.toString() ?? 0)
  const tokenBAmount = isThreePool
    ? CurrencyAmount.fromRawAmount(token1, threeData?.[1]?.toString() ?? 0)
    : CurrencyAmount.fromRawAmount(token1, twoData?.[1]?.toString() ?? 0)
  const tokenCAmount = isThreePool && token2
    ? CurrencyAmount.fromRawAmount(token2, threeData?.[2]?.toString() ?? 0)
    : undefined

  return [tokenAAmount, tokenBAmount, tokenCAmount]
}

export function useStableDerivedBurnInfo(
  chainId: number | undefined,
  poolAddress: Address | undefined
): {
  pool?: StableSwapPool | null
  parsedAmounts: {
    [Field.LIQUIDITY_PERCENT]: Percent
    [Field.LIQUIDITY]?: CurrencyAmount<Token>
    [Field.CURRENCY_A]?: CurrencyAmount<Currency>
    [Field.CURRENCY_B]?: CurrencyAmount<Currency>
    [Field.CURRENCY_C]?: CurrencyAmount<Currency>
  }
  error?: string
  tokenToReceive?: string
} {
  const { address: account } = useAccount()
  const { independentField, typedValue } = useAddLiquidityFormState()
  const { t } = useTranslation()

  // pair + totalsupply
  const { pool, stableSwapTwoInfoContract, stableSwapThreeInfoContract, userLpBalance } = useStableConfig({
    chainId,
    poolAddress,
    account
  })

  let percentToRemove: Percent = new Percent('0', '100')
  let liquidityToRemove: CurrencyAmount<Token> | undefined
  // user specified a %
  if (independentField === Field.LIQUIDITY_PERCENT) {
    percentToRemove = new Percent(typedValue, '100')
    liquidityToRemove =
      userLpBalance && percentToRemove && percentToRemove.greaterThan('0')
        ? CurrencyAmount.fromRawAmount(userLpBalance.currency, percentToRemove.multiply(userLpBalance.quotient).quotient)
        : undefined
  }
  // user specified a specific amount of liquidity tokens
  else if (independentField === Field.LIQUIDITY) {
    const parsedAmount = tryParseAmount(typedValue, userLpBalance?.currency)
    liquidityToRemove =
      userLpBalance && parsedAmount && parsedAmount.greaterThan('0') && !parsedAmount.greaterThan(userLpBalance)
        ? CurrencyAmount.fromRawAmount(userLpBalance.currency, parsedAmount.quotient)
        : undefined
  }

  const amounts = useGetRemovedTokenAmounts({
    pool,
    stableSwapTwoInfoContract,
    stableSwapThreeInfoContract,
    lpAmount: liquidityToRemove?.quotient?.toString(),
  })

  const parsedAmounts: {
    [Field.LIQUIDITY_PERCENT]: Percent
    [Field.LIQUIDITY]?: CurrencyAmount<Token>
    [Field.CURRENCY_A]?: CurrencyAmount<Token>
    [Field.CURRENCY_B]?: CurrencyAmount<Token>
    [Field.CURRENCY_C]?: CurrencyAmount<Token>
  } = {
    [Field.LIQUIDITY_PERCENT]: percentToRemove,
    [Field.LIQUIDITY]: liquidityToRemove,
    [Field.CURRENCY_A]: amounts?.[0],
    [Field.CURRENCY_B]: amounts?.[1],
    [Field.CURRENCY_C]: amounts?.[2],
  }

  let error: string | undefined
  if (!account) {
    error = t('Connect Wallet')
  }

  if (!parsedAmounts[Field.LIQUIDITY]) {
    error = error ?? t('Enter an amount')
  }

  return { pool, parsedAmounts, error }
}
