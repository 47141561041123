import { useTranslation } from '@pulsex/localization'
import { Flex, Text } from '@pulsex/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { styled } from 'styled-components'
import { FarmWithStakedValue } from '@pulsex/farms'
import { HarvestActionContainer } from '../FarmTable/Actions/HarvestAction'
import { StakedContainer } from '../FarmTable/Actions/StakedAction'
import HarvestAction from './HarvestAction'
import StakeAction from './StakeAction'

const Action = styled.div`
  padding-top: 16px;
`

interface FarmCardActionsProps {
  farm: FarmWithStakedValue
  account?: string
  addLiquidityUrl?: string
  lpLabel?: string
  displayApr?: string
}

const CardActions: React.FC<React.PropsWithChildren<FarmCardActionsProps>> = ({
  farm,
  account,
  addLiquidityUrl,
  lpLabel,
  displayApr
}) => {
  const { t } = useTranslation()
  const { pid, token, quoteToken, lpSymbol } = farm
  const { earnings } = farm.userData || {}

  return (
    <Action>
      <Flex>
        <Text bold textTransform="uppercase" color="secondary" fontSize="12px" pr="4px">
          INC
        </Text>
        <Text bold textTransform="uppercase" color="textSubtle" fontSize="12px">
          {t('Earned')}
        </Text>
      </Flex>
      <HarvestActionContainer
        earnings={earnings}
        pid={pid}
        token={token}
        quoteToken={quoteToken}
        lpSymbol={lpSymbol}
      >
        {(props) => <HarvestAction {...props} />}
      </HarvestActionContainer>
      <Flex>
        <Text bold textTransform="uppercase" color="secondary" fontSize="12px" pr="4px">
          {farm.lpSymbol}
        </Text>
        <Text bold textTransform="uppercase" color="textSubtle" fontSize="12px">
          {t('Staked')}
        </Text>
      </Flex>
      {!account ? (
        <ConnectWalletButton mt="8px" width="100%" />
      ) : (
        <StakedContainer {...farm} lpLabel={lpLabel} addLiquidityUrl={addLiquidityUrl} displayApr={displayApr}>
          {(props) => <StakeAction {...props} />}
        </StakedContainer>
      )}
    </Action>
  )
}

export default CardActions
