import { ChainId } from '@pulsex/chains'
import { viemClients } from 'utils/viem'
import {
  Abi,
  Address,
  GetContractReturnType,
  PublicClient,
  WalletClient,
  erc20Abi,
  getContract as viemGetContract,
} from 'viem'
import { PLSX } from '@pulsex/tokens'

// Addresses
import {
  getMasterChefAddress,
  getPlsxLabAddress,
  getBuyAndBurnAddress,
  getStableSwapTwoNativeHelperAddress,
  getStableSwapThreeNativeHelperAddress,
} from 'utils/addressHelpers'

// ABI
import { plsxAbi } from 'config/abi/plsx'
import { plsxLabAbi } from 'config/abi/plsxLab'
import { lpTokenAbi } from 'config/abi/lpToken'
import { masterChefAbi } from 'config/abi/masterchef'
import { buyandburnAbi } from 'config/abi/buyandburn'
import { stableSwapTwoNativeHelperABI } from 'config/abi/stableSwapTwoNativeHelper'
import { stableSwapThreeNativeHelperABI } from 'config/abi/stableSwapThreeNativeHelper'

export const getContract = <TAbi extends Abi | readonly unknown[], TWalletClient extends WalletClient>({
  abi,
  address,
  chainId = ChainId.PULSECHAIN,
  publicClient,
  signer,
}: {
  abi: TAbi | readonly unknown[]
  address: Address
  chainId?: ChainId
  signer?: TWalletClient
  publicClient?: PublicClient
}) => {
  const c = viemGetContract({
    abi,
    address,
    client: {
      public: publicClient ?? viemClients[chainId],
      wallet: signer,
    },
  }) as unknown as GetContractReturnType<TAbi, PublicClient, Address>

  return {
    ...c,
    account: signer?.account,
    chain: signer?.chain,
  }
}

export const getPrc20Contract = (address: Address, signer?: WalletClient) => {
  return getContract({ abi: erc20Abi, address, signer })
}

export const getLpContract = (address: Address, chainId?: number, signer?: WalletClient) => {
  return getContract({ abi: lpTokenAbi, address, signer, chainId })
}

export const getPlsxContract = (chainId: number, walletClient?: WalletClient) => {
  return getContract({
    abi: plsxAbi,
    address: chainId ? PLSX[chainId]?.address : PLSX[ChainId.PULSECHAIN].address,
    signer: walletClient
  })
}

export const getMasterChefContract = (signer?: WalletClient, chainId?: number) => {
  return getContract({
    abi: masterChefAbi,
    address: getMasterChefAddress(chainId),
    chainId,
    signer,
  })
}

export const getPlsxLabContract = (walletClient?: WalletClient, chainId?: number) => {
  return getContract({
    abi: plsxLabAbi, 
    address: getPlsxLabAddress(chainId), 
    signer: walletClient
  })
}

export const getBuyAndBurnContract = (protocol: string, walletClient?: WalletClient, chainId?: number) => {
  return getContract({
    abi: buyandburnAbi, 
    address: getBuyAndBurnAddress(protocol, chainId), 
    chainId,
    signer: walletClient
  })
}

export const getStableSwapTwoNativeHelperContract = (signer?: WalletClient, chainId?: number) => {
  return getContract({
    abi: stableSwapTwoNativeHelperABI,
    address: getStableSwapTwoNativeHelperAddress(chainId),
    chainId,
    signer,
  })
}

export const getStableSwapThreeNativeHelperContract = (signer?: WalletClient, chainId?: number) => {
  return getContract({
    abi: stableSwapThreeNativeHelperABI,
    address: getStableSwapThreeNativeHelperAddress(chainId),
    chainId,
    signer,
  })
}
