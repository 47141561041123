import { Price, Currency } from "@pulsex/swap-sdk-core";
import { formatPrice } from "@pulsex/utils/formatFractions";
import { useState } from "react";
import { AtomBox, Text, Loading, SwapCSS } from "@pulsex/uikit";

interface TradePriceProps {
  price?: Price<Currency, Currency>;
  loading?: boolean;
}

export function TradePrice({ price, loading }: TradePriceProps) {
  const [showInverted, setShowInverted] = useState<boolean>(false);

  const formattedPrice = showInverted ? formatPrice(price, 6) : formatPrice(price?.invert(), 6);
  const show = Boolean(price?.baseCurrency && price?.quoteCurrency);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setShowInverted(!showInverted);
  };

  return (
    <Text
      onClick={handleClick}
      fontSize="12px"
      style={{ justifyContent: "center", alignItems: "center", display: "flex", opacity: loading ? 0.6 : 1 }}
    >
      {show ? (
        <>
          {`1 ${showInverted ? price?.baseCurrency?.symbol : price?.quoteCurrency?.symbol}`}
          <Text color="textSubtleDark" px="5px">=</Text>
          {`${formattedPrice} ${showInverted ? price?.quoteCurrency?.symbol : price?.baseCurrency?.symbol}`}
          {loading && (
            <AtomBox className={SwapCSS.iconButtonClass}>
              <Loading width="12px" height="12px" />
            </AtomBox>
          )}
        </>
      ) : (
        "-"
      )}
    </Text>
  );
}
