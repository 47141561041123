export const baseColors = {
  white: 'white',
  failure: '#cc0000',
  failure33: '#cc0000',
  primary: '#00b33c',
  primary0f: '#00b33c',
  primary3D: '#1FC7D43D',
  primaryBright: '#00ff55',
  primaryDark: '#00b33c',
  success: '#00b33c',
  success19: '#00b33c',
  warning: '#ff9d00',
  warning2D: '#ED4B9E2D',
  warning33: 'rgba(255, 178, 55, 0.2)',
}

export const additionalColors = {
  binance: "#dbaa0a",
  overlay: "rgba(0, 0, 0, 0.7)",
  gold: "#FFC700",
  silver: "#B2B2B2",
  bronze: "#E7974D",
};

export const lightColors = {
  ...baseColors,
  ...additionalColors,
  secondary: '#7645D9',
  secondary80: '#7645D980',
  background: '#FAF9FA',
  backgroundDisabled: '#E9EAEB',
  backgroundAlt: '#FFFFFF',
  backgroundAlt2: 'rgba(255, 255, 255, 0.7)',
  cardBorder: '#E7E3EB',
  contrast: '#191326',
  dropdown: '#F6F6F6',
  dropdownDeep: '#EEEEEE',
  invertedContrast: '#FFFFFF',
  input: '#EEEAF4',
  inputSecondary: '#d7caec',
  tertiary: '#EFF4F5',
  text: '#280D5F',
  text99: '#280D5F99',
  textDisabled: '#BDC2C4',
  textSubtle: '#7A6EAA',
  textSubtleDark: '#8b8b92',
  disabled: '#E9EAEB',
  gradientBubblegum: 'linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)',
  gradientInverseBubblegum: 'linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)',
  gradientCardHeader: 'linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)',
  gradientBlue: 'linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)',
  gradientViolet: 'linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)',
  gradientVioletAlt: 'linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)',
  gradientGold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
  gradientBold: 'linear-gradient(#53DEE9, #7645D9)',
}

export const darkColors = {
  ...baseColors,
  ...additionalColors,
  secondary: '#ffffff',
  secondary80: '#ffffff',
  background: '#08060B',
  backgroundDisabled: '#24282d',
  backgroundAlt: '#1a1d20',
  backgroundAlt2: '#101417',
  cardBorder: 'rgba(50, 50, 52, 1)',
  contrast: '#FFFFFF',
  dropdown: '#1a1d20',
  dropdownDeep: '#1a1d20',
  invertedContrast: 'rgba(28, 28, 32, 1)',
  input: '#121416',
  inputSecondary: 'rgba(30, 30, 32, 1)',
  primaryDark: '#00b33c',
  tertiary: '#24282d',
  text: '#f2f2f2',
  text99: '#f2f2f2',
  textDisabled: '#4d4d4d',
  textSubtle: '#cccccc',
  textSubtleDark: '#8b8b92',
  disabled: '#4d4d4d',
  gradientBubblegum: 'radial-gradient(150% 100vh at 50% 0%, #080d16 0px, #070707 100%)',
  gradientInverseBubblegum: 'linear-gradient(200.0deg, #070707 0%, #18181b 100%)',
  gradientCardHeader: 'linear-gradient(200.0deg, #070707 0%, #18181b 100%)',
  gradientBlue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
  gradientViolet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
  gradientVioletAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
  gradientGold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
  gradientBold: 'linear-gradient(#53DEE9, #9A6AFF)',
}
