import { useTranslation } from '@pulsex/localization'
import { Currency, CurrencyAmount } from '@pulsex/swap-sdk-core'
import { NATIVE_USDT } from '@pulsex/tokens'
import { Button, Dots, Message, MessageText, RowBetween } from '@pulsex/uikit'
import { useMemo } from 'react'
import { ApprovalState } from 'hooks/useApproveCallback'
import { Address } from 'viem'

interface ApproveLiquidityTokensProps {
  chainId?: number
  currencyA?: Currency
  currencyB?: Currency
  currencyC?: Currency
  shouldShowApprovalGroup: boolean
  showFieldAApproval: boolean
  approveACallback: () => Promise<{ hash: Address } | undefined>
  revokeACallback: () => Promise<{ hash: Address } | undefined>
  currentAllowanceA: CurrencyAmount<Currency> | undefined
  approvalA: ApprovalState
  showFieldBApproval: boolean
  approveBCallback: () => Promise<{ hash: Address } | undefined>
  revokeBCallback: () => Promise<{ hash: Address } | undefined>
  currentAllowanceB: CurrencyAmount<Currency> | undefined
  approvalB: ApprovalState
  showFieldCApproval: boolean
  approveCCallback: () => Promise<{ hash: Address } | undefined>
  revokeCCallback: () => Promise<{ hash: Address } | undefined>
  currentAllowanceC: CurrencyAmount<Currency> | undefined
  approvalC: ApprovalState
}

export default function ApproveLiquidityTokens({
  chainId,
  shouldShowApprovalGroup,
  showFieldAApproval,
  approvalA,
  approveACallback,
  revokeACallback,
  currentAllowanceA,
  currencyA,
  currencyB,
  currencyC,
  showFieldBApproval,
  approvalB,
  approveBCallback,
  revokeBCallback,
  currentAllowanceB,
  showFieldCApproval,
  approvalC,
  approveCCallback,
  revokeCCallback,
  currentAllowanceC,
}: ApproveLiquidityTokensProps) {
  const { t } = useTranslation()

  const revokeANeeded = useMemo(() => {
    return (
      showFieldAApproval &&
      currentAllowanceA?.greaterThan(0) &&
      chainId &&
      currencyA?.chainId === chainId &&
      currencyA?.wrapped.address.toLowerCase() === NATIVE_USDT[chainId].address.toLowerCase()
    )
  }, [showFieldAApproval, currentAllowanceA, currencyA, chainId])
  const revokeBNeeded = useMemo(() => {
    return (
      showFieldBApproval &&
      currentAllowanceB?.greaterThan(0) &&
      chainId &&
      currencyB?.chainId === chainId &&
      currencyB?.wrapped.address.toLowerCase() === NATIVE_USDT[chainId].address.toLowerCase()
    )
  }, [showFieldBApproval, currentAllowanceB, currencyB, chainId])
  const revokeCNeeded = useMemo(() => {
    return (
      currencyC ? showFieldCApproval &&
        currentAllowanceC?.greaterThan(0) &&
        chainId &&
        currencyC?.chainId === chainId &&
        currencyC?.wrapped.address.toLowerCase() === NATIVE_USDT[chainId].address.toLowerCase() :
        false
    )
  }, [showFieldCApproval, currentAllowanceC, currencyC, chainId])
  const anyRevokeNeeded = revokeANeeded || revokeBNeeded || revokeCNeeded

  return shouldShowApprovalGroup ? (
    <RowBetween style={{ gap: '12px' }}>
      {anyRevokeNeeded && (
        <Message variant="warning">
          <MessageText>
            <span>
              {t('Native USDT requires resetting approval when spending allowances are too low.')}
            </span>
          </MessageText>
        </Message>
      )}
      {showFieldAApproval &&
        (revokeANeeded ? (
          <Button
            onClick={revokeACallback}
            disabled={approvalA === ApprovalState.PENDING}
            width='100%'
          >
            {approvalA === ApprovalState.PENDING ? (
              <Dots>{t('Reset Approval on USDT', { asset: currencyA?.symbol })}</Dots>
            ) : (
              t('Reset Approval on USDT', { asset: currencyA?.symbol })
            )}
          </Button>
        ) : (
          <Button
            onClick={approveACallback}
            disabled={approvalA === ApprovalState.PENDING}
            width='100%'
          >
            {approvalA === ApprovalState.PENDING ? (
              <Dots>{t('Enabling %asset%', { asset: currencyA?.symbol })}</Dots>
            ) : (
              t('Enable %asset%', { asset: currencyA?.symbol })
            )}
          </Button>
        ))}
      {showFieldBApproval &&
        (revokeBNeeded ? (
          <Button
            onClick={revokeBCallback}
            disabled={approvalB === ApprovalState.PENDING}
            width='100%'
          >
            {approvalB === ApprovalState.PENDING ? (
              <Dots>{t('Reset Approval on USDT', { asset: currencyB?.symbol })}</Dots>
            ) : (
              t('Reset Approval on USDT', { asset: currencyB?.symbol })
            )}
          </Button>
        ) : (
          <Button
            onClick={approveBCallback}
            disabled={approvalB === ApprovalState.PENDING}
            width='100%'
          >
            {approvalB === ApprovalState.PENDING ? (
              <Dots>{t('Enabling %asset%', { asset: currencyB?.symbol })}</Dots>
            ) : (
              t('Enable %asset%', { asset: currencyB?.symbol })
            )}
          </Button>
        ))}
      {currencyC && showFieldCApproval &&
        (revokeCNeeded ? (
          <Button
            onClick={revokeCCallback}
            disabled={approvalC === ApprovalState.PENDING}
            width='100%'
          >
            {approvalC === ApprovalState.PENDING ? (
              <Dots>{t('Reset Approval on %asset%', { asset: currencyC?.symbol })}</Dots>
            ) : (
              t('Reset Approval on %asset%', { asset: currencyC?.symbol })
            )}
          </Button>
        ) : (
          <Button
            onClick={approveCCallback}
            disabled={approvalC === ApprovalState.PENDING}
            width='100%'
          >
            {approvalC === ApprovalState.PENDING ? (
              <Dots>{t('Enabling %asset%', { asset: currencyC?.symbol })}</Dots>
            ) : (
              t('Enable %asset%', { asset: currencyC?.symbol })
            )}
          </Button>
        ))}
    </RowBetween>
  ) : null
}
