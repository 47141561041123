import {
  MenuItemsType,
  HamburgerIcon,
  DropdownMenuItems,
  DropdownMenuItemType,
} from '@pulsex/uikit'
import { ContextApi } from '@pulsex/localization'

export type ConfigMenuDropDownItemsType = DropdownMenuItems
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & {
  items?: ConfigMenuDropDownItemsType[]
}

export const hamburgerConfig: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: '',
    href: '/',
    icon: HamburgerIcon,
    items: [
      {
        label: t('Exchange'),
        href: '/',
      },
      {
        label: t('Liquidity'),
        href: '/liquidity',
      },
      {
        label: t('Farm'),
        href: '/farms',
      },
      {
        label: t('Burn'),
        href: '/burn',
      },
      {
        label: t('Info'),
        href: '/info',
      },
      {
        label: t('Bridge'),
        href: 'https://bridge.pulsechain.com/',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    ],
  },
]
