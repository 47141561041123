import { ChainId } from '@pulsex/chains'
import { WNATIVE } from '@pulsex/sdk'
import { PoolType } from '@pulsex/stable-swap-sdk'
import { useMemo } from 'react'
import { Abi, Address, erc20Abi } from 'viem'
import { useWalletClient } from 'wagmi'
import { useActiveChainId } from 'hooks/useActiveChainId'

import { getMulticallAddress } from 'utils/addressHelpers'
import {
  getContract,
  getPlsxContract,
  getMasterChefContract,
  getPlsxLabContract,
  getBuyAndBurnContract,
  getStableSwapTwoNativeHelperContract,
  getStableSwapThreeNativeHelperContract,
} from 'utils/contractHelpers'

import { multicallAbi } from 'config/abi/multicall'
import { erc20BytesAbi } from 'config/abi/erc20_bytes32'
import { pulsexPairAbi } from 'config/abi/IPulseXPair'
import { wethAbi } from 'config/abi/weth'
import { stableLPABI } from 'config/abi/stableLP'
import { stableSwapTwoABI } from 'config/abi/stableSwapTwo'
import { stableSwapThreeABI } from 'config/abi/stableSwapThree'
import { infoTwoStableSwapABI } from 'config/abi/infoTwoStableSwap'
import { infoThreeStableSwapABI } from 'config/abi/infoThreeStableSwap'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: Address) => {
  return useContract(address, erc20Abi)
}

export const usePlsx = () => {
  const { chainId } = useActiveChainId()
  return useMemo(() => chainId && getPlsxContract(chainId), [chainId])
}

export const useMasterchef = () => {
  const { chainId } = useActiveChainId()
  const { data: signer } = useWalletClient()
  return useMemo(() => getMasterChefContract(signer ?? undefined, chainId), [signer, chainId])
}

export const usePlsxLab = () => {
  const { data: signer } = useWalletClient()
  return useMemo(() => getPlsxLabContract(signer ?? undefined), [signer])
}

export const useBuyAndBurnContract = (protocol: string) => {
  const { chainId } = useActiveChainId()
  const { data: signer } = useWalletClient()
  return useMemo(() => getBuyAndBurnContract(protocol, signer ?? undefined, chainId), [chainId, protocol, signer])
}

export type UseStableSwapContract = ReturnType<typeof useStableSwapContract>
export type UseStableSwapTwoInfoContract = ReturnType<typeof useStableSwapTwoInfoContract>
export type UseStableSwapThreeInfoContract = ReturnType<typeof useStableSwapThreeInfoContract>

export function useStableSwapContract(address?: Address, type?: PoolType) {
  return useContract(address, type === PoolType.TWO_POOL ? stableSwapTwoABI : stableSwapThreeABI)
}

export function useStableSwapTwoInfoContract(address?: Address) {
  return useContract(address, infoTwoStableSwapABI)
}

export function useStableSwapThreeInfoContract(address?: Address) {
  return useContract(address, infoThreeStableSwapABI)
}

export function useStableSwapLPContract(address?: Address) {
  return useContract(address, stableLPABI)
}

export const useInfoStableSwapTwoContract = (infoAddress?: Address) => {
  return useContract(infoAddress, infoTwoStableSwapABI)
}

export const useInfoStableSwapThreeContract = (infoAddress?: Address) => {
  return useContract(infoAddress, infoThreeStableSwapABI)
}

export const useStableSwapTwoNativeHelperContract = () => {
  const { chainId } = useActiveChainId()
  const { data: signer } = useWalletClient()
  return useMemo(() => getStableSwapTwoNativeHelperContract(signer ?? undefined, chainId), [signer, chainId])
}

export const useStableSwapThreeNativeHelperContract = () => {
  const { chainId } = useActiveChainId()
  const { data: signer } = useWalletClient()
  return useMemo(() => getStableSwapThreeNativeHelperContract(signer ?? undefined, chainId), [signer, chainId])
}

// Code below migrated from Exchange useContract.ts

type UseContractOptions = {
  chainId?: ChainId
}

// returns null on errors
export function useContract<TAbi extends Abi>(
  addressOrAddressMap?: Address | { [chainId: number]: Address },
  abi?: TAbi,
  options?: UseContractOptions,
) {
  const { chainId: currentChainId } = useActiveChainId()
  const chainId = options?.chainId || currentChainId
  const { data: walletClient } = useWalletClient()

  return useMemo(() => {
    if (!addressOrAddressMap || !abi || !chainId) return null
    let address: Address | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract({
        abi,
        address,
        chainId,
        signer: walletClient ?? undefined,
      })
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, abi, chainId, walletClient])
}

export function useTokenContract(tokenAddress?: Address) {
  return useContract(tokenAddress, erc20Abi)
}

export function useWETHContract() {
  const { chainId } = useActiveChainId()
  return useContract(chainId ? WNATIVE[chainId]?.address : undefined, wethAbi)
}

export function useBytes32TokenContract(tokenAddress?: Address) {
  return useContract(tokenAddress, erc20BytesAbi)
}

export function usePairContract(pairAddress?: Address) {
  return useContract(pairAddress, pulsexPairAbi)
}

export function useMulticallContract() {
  const { chainId } = useActiveChainId()
  return useContract(getMulticallAddress(chainId), multicallAbi)
}
