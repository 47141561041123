import { useTranslation } from '@pulsex/localization'
import { Box, Flex, Text } from '@pulsex/uikit'
import { useMemo } from 'react'
import { styled } from 'styled-components'
import { ConfirmModalStateV1, PendingConfirmModalStateV1 } from '../types'

const StepsContainer = styled(Flex)`
  width: 100px;
  height: 8px;
  border-radius: 4px;
  margin: 16px auto auto auto;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.input};
`

const Step = styled('div') <{ active: boolean; width: string }>`
  height: 100%;
  width: ${({ width }) => width};
  background: ${({ theme, active }) => (active ? theme.colors.primaryDark : theme.colors.backgroundAlt2)};
`

interface ApproveStepFlowProps {
  confirmModalState: ConfirmModalStateV1
  pendingModalSteps: PendingConfirmModalStateV1[]
}

export const ApproveStepFlow: React.FC<React.PropsWithChildren<ApproveStepFlowProps>> = ({
  confirmModalState,
  pendingModalSteps,
}) => {
  const { t } = useTranslation()

  const stepWidth = useMemo(() => `${100 / pendingModalSteps.length}%`, [pendingModalSteps])
  const hideStepIndicators = useMemo(() => pendingModalSteps.length === 1, [pendingModalSteps])

  return (
    <Box mt="32px">
      <Text fontSize="12px" textAlign="center" color="textSubtle">
        {t('Proceed in your wallet')}
      </Text>
      {!hideStepIndicators && (
        <>
          <StepsContainer>
            {pendingModalSteps.length !== 3 && (
              <Step active={confirmModalState === ConfirmModalStateV1.RESETTING_APPROVAL} width={stepWidth} />
            )}
            <Step active={confirmModalState === ConfirmModalStateV1.APPROVING_TOKEN} width={stepWidth} />
            <Step active={confirmModalState === ConfirmModalStateV1.APPROVE_PENDING} width={stepWidth} />
            <Step active={confirmModalState === ConfirmModalStateV1.PENDING_CONFIRMATION} width={stepWidth} />
          </StepsContainer>
        </>
      )}
    </Box>
  )
}
