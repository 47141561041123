import { useTranslation } from '@pulsex/localization'
import { Currency, CurrencyAmount, Percent } from '@pulsex/sdk'
import { Button, Text } from '@pulsex/uikit'
import { CurrencyLogo } from '@pulsex/widgets-internal'
import { Field } from 'state/stableswap/actions'
import { RowBetween, RowFixed } from 'components/Layout/Row'

function ConfirmAddModalBottom({
  noLiquidity,
  poolPrices,
  currencyA,
  currencyB,
  currencyC,
  parsedAmounts,
  poolTokenPercentage,
  onAdd,
}: {
  noLiquidity?: boolean
  poolPrices?: {
    token0?: CurrencyAmount<Currency>[];
    token1?: CurrencyAmount<Currency>[];
    token2?: CurrencyAmount<Currency>[];
  }
  currencyA?: Currency
  currencyB?: Currency
  currencyC?: Currency
  parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  poolTokenPercentage?: Percent
  onAdd: () => void
}) {
  const { t } = useTranslation()
  return (
    <>
      <RowBetween>
        <Text>{t('%asset% Deposited', { asset: currencyA?.symbol })}</Text>
        <RowFixed>
          <Text>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</Text>
          <CurrencyLogo currency={currencyA} style={{ marginLeft: '8px' }} size="22px" />
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <Text>{t('%asset% Deposited', { asset: currencyB?.symbol })}</Text>
        <RowFixed>
          <Text>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</Text>
          <CurrencyLogo currency={currencyB} style={{ marginLeft: '8px' }} size="22px" />
        </RowFixed>
      </RowBetween>
      {currencyC && <RowBetween>
        <Text>{t('%asset% Deposited', { asset: currencyC?.symbol })}</Text>
        <RowFixed>
          <Text>{parsedAmounts[Field.CURRENCY_C]?.toSignificant(6)}</Text>
          <CurrencyLogo currency={currencyC} style={{ marginLeft: '8px' }} size="22px" />
        </RowFixed>
      </RowBetween>}
      <RowBetween>
        <Text>{t('Rates')}</Text>
        <Text>
          {`1 ${currencyA?.symbol} = ${poolPrices?.token0?.[0]?.toSignificant(4)} ${currencyB?.symbol}`} {poolPrices?.token2 && `/ ${poolPrices.token0?.[1]?.toSignificant(4)} ${currencyC?.symbol}`}
        </Text>
      </RowBetween>
      <RowBetween style={{ justifyContent: 'flex-end' }}>
        <Text>
          {`1 ${currencyB?.symbol} = ${poolPrices?.token1?.[0]?.toSignificant(4)} ${currencyA?.symbol}`} {poolPrices?.token2 && `/ ${poolPrices.token1?.[1]?.toSignificant(4)} ${currencyC?.symbol}`}
        </Text>
      </RowBetween>
      {currencyC && (
        <RowBetween style={{ justifyContent: 'flex-end' }}>
          <Text>
            {`1 ${currencyC?.symbol} = ${poolPrices?.token2?.[0]?.toSignificant(4)} ${currencyA?.symbol}`} {`/ ${poolPrices?.token2?.[1]?.toSignificant(4)} ${currencyB?.symbol}`}
          </Text>
        </RowBetween>
      )}
      <RowBetween>
        <Text>{t('Share of Pool')}:</Text>
        <Text>{noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%</Text>
      </RowBetween>
      <Button width="100%" onClick={onAdd} mt="20px">
        {noLiquidity ? t('Setup & Supply') : t('Confirm Supply')}
      </Button>
    </>
  )
}

export default ConfirmAddModalBottom
