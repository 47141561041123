import { Box, ButtonMenu, ButtonMenuItem, Flex } from '@pulsex/uikit'
import { useTranslation } from '@pulsex/localization'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Search from 'pages/Info/components/InfoSearch'

const NavWrapper = styled(Flex)`
  justify-content: space-between;
  padding: 20px 0;
  flex-direction: column;
  gap: 8px;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 20px 0;
    flex-direction: row;
  }
`

const InfoNav: React.FC<React.PropsWithChildren<{ protocol: string }>> = ({ protocol }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isV1 = protocol === 'v1'
  const isPools = pathname.includes('/pool')
  const isTokens = pathname.includes('/token')
  let activeIndex = 0
  if (isPools) {
    activeIndex = 1
  }
  if (isTokens) {
    activeIndex = 2
  }
  return (
    <NavWrapper>
      <Box>
        <ButtonMenu activeIndex={activeIndex} scale="sm" variant="subtle">
          <ButtonMenuItem as={Link} to={isV1 ? "/info" : `/info/${protocol}`}>
            {t('Overview')}
          </ButtonMenuItem>
          <ButtonMenuItem as={Link} to={isV1 ? "/info/pools" : `/info/${protocol}/pools`}>
            {t('Pools')}
          </ButtonMenuItem>
          <ButtonMenuItem as={Link} to={isV1 ? "/info/tokens" : `/info/${protocol}/tokens`}>
            {t('Tokens')}
          </ButtonMenuItem>
        </ButtonMenu>
      </Box>
      <Box width={['100%', '100%', '250px']}>
        <Search protocol={protocol} />
      </Box>
    </NavWrapper>
  )
}

export default InfoNav
