import { useTranslation } from '@pulsex/localization'
import { Currency, CurrencyAmount, Percent, Token } from '@pulsex/sdk'
import { StableSwapPool } from '@pulsex/stable-swap-sdk'
import { AddIcon, AutoColumn, Button, InjectedModalProps, Text } from '@pulsex/uikit'
import { ConfirmationModalContent, CurrencyLogo, MultiCurrencyLogo } from '@pulsex/widgets-internal'
import { useCallback } from 'react'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { ApprovalState } from 'hooks/useApproveCallback'
import { Field } from 'state/stableswap/actions'

interface ConfirmRemoveLiquidityModalProps {
  title: string
  customOnDismiss: () => void
  attemptingTxn: boolean
  pool?: StableSwapPool
  hash: string
  pendingText: string
  parsedAmounts: {
    [Field.LIQUIDITY_PERCENT]: Percent
    [Field.LIQUIDITY]?: CurrencyAmount<Token>
    [Field.CURRENCY_A]?: CurrencyAmount<Currency>
    [Field.CURRENCY_B]?: CurrencyAmount<Currency>
    [Field.CURRENCY_C]?: CurrencyAmount<Currency>
  }
  allowedSlippage: number
  onRemove: () => void
  liquidityErrorMessage?: string
  approval: ApprovalState
  signatureData?: any
  tokenA?: Token
  tokenB?: Token
  tokenC?: Token
  currencyA?: Currency
  currencyB?: Currency
  currencyC?: Currency
}

const ConfirmRemoveLiquidityModal: React.FC<InjectedModalProps & ConfirmRemoveLiquidityModalProps> = ({
  title,
  onDismiss,
  customOnDismiss,
  attemptingTxn,
  pool,
  hash,
  approval,
  signatureData,
  pendingText,
  parsedAmounts,
  allowedSlippage,
  onRemove,
  liquidityErrorMessage,
  currencyA,
  currencyB,
  currencyC,
}) => {
  const { t } = useTranslation()

  const modalHeader = useCallback(() => {
    return (
      <AutoColumn gap="md">
        <RowBetween align="flex-end">
          <Text fontSize="24px">{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={currencyA} size="24px" />
            <Text fontSize="24px" ml="10px">
              {currencyA?.symbol}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowFixed>
          <AddIcon width="16px" />
        </RowFixed>
        <RowBetween align="flex-end">
          <Text fontSize="24px">{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={currencyB} size="24px" />
            <Text fontSize="24px" ml="10px">
              {currencyB?.symbol}
            </Text>
          </RowFixed>
        </RowBetween>
        {currencyC && (
          <>
            <RowFixed>
              <AddIcon width="16px" />
            </RowFixed>
            <RowBetween align="flex-end">
              <Text fontSize="24px">{parsedAmounts[Field.CURRENCY_C]?.toSignificant(6)}</Text>
              <RowFixed gap="4px">
                <CurrencyLogo currency={currencyC} size="24px" />
                <Text fontSize="24px" ml="10px">
                  {currencyC?.symbol}
                </Text>
              </RowFixed>
            </RowBetween>
          </>
        )}

        <Text small textAlign="left" pt="12px">
          {t('Output is estimated. If the price changes by more than %slippage%% your transaction will revert.', {
            slippage: allowedSlippage / 100,
          })}
        </Text>
      </AutoColumn>
    )
  }, [allowedSlippage, currencyA, currencyB, currencyC, parsedAmounts, t])

  const modalBottom = useCallback(() => {
    return (
      <>
        <RowBetween>
          <Text>
            {t('%assetA%/%assetB%/%assetC% Burned', { assetA: currencyA?.symbol ?? '', assetB: currencyB?.symbol ?? '', assetC: currencyC?.symbol ?? '' })}
          </Text>
          <RowFixed>
            <MultiCurrencyLogo currency0={currencyA} currency1={currencyB} currency2={currencyC} margin />
            <Text>{parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}</Text>
          </RowFixed>
        </RowBetween>
        {pool && (
          <>
            <RowBetween>
              <Text>{t('Price')}</Text>
              {/* <Text>
                1 {currencyA?.symbol} = {tokenA ? pool.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
              </Text> */}
            </RowBetween>
            <RowBetween>
              <div />
              {/* <Text>
                1 {currencyB?.symbol} = {tokenB ? pool.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
              </Text> */}
            </RowBetween>
          </>
        )}
        <Button width="100%" mt="20px" disabled={!(approval === ApprovalState.APPROVED || signatureData !== null)} onClick={onRemove}>
          {t('Confirm')}
        </Button>
      </>
    )
  }, [currencyA, currencyB, currencyC, parsedAmounts, approval, onRemove, pool, signatureData, t])

  const confirmationContent = useCallback(
    () => <ConfirmationModalContent topContent={modalHeader} bottomContent={modalBottom} />,
    [modalHeader, modalBottom],
  )

  return (
    <TransactionConfirmationModal
      title={title}
      width={['100%', '100%', '467px', '467px']}
      bodyPadding='30px 20px 20px 20px'
      onDismiss={onDismiss}
      customOnDismiss={customOnDismiss}
      attemptingTxn={attemptingTxn}
      errorMessage={liquidityErrorMessage}
      hash={hash}
      content={confirmationContent}
      pendingText={pendingText}
    />
  )
}

export default ConfirmRemoveLiquidityModal
