import { ModalV2 } from '@pulsex/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ChainId } from '@pulsex/chains'
import { useCallback, useMemo } from 'react'
import { atom, useAtom } from 'jotai'
import { SUPPORT_ONLY_PULSE } from 'config/constants/supportChains'
import { viemClients } from 'utils/viem'
import { CHAIN_IDS } from 'utils/wagmi'
import { PageNetworkSupportModal } from './PageNetworkSupportModal'
import { WrongNetworkModal } from './WrongNetworkModal'
import { UnsupportedNetworkModal } from './UnsupportedNetworkModal'

export const hideWrongNetworkModalAtom = atom(false)

export const NetworkModal = ({ pageSupportedChains = SUPPORT_ONLY_PULSE }: { pageSupportedChains?: number[] }) => {
  const { chainId, chain, isWrongNetwork } = useActiveWeb3React()
  const [dismissWrongNetwork, setDismissWrongNetwork] = useAtom(hideWrongNetworkModalAtom)

  const isPLSOnlyPage = useMemo(() => {
    return pageSupportedChains?.length === 1 && pageSupportedChains[0] === ChainId.PULSECHAIN
  }, [pageSupportedChains])

  const isPageNotSupported = useMemo(
    () => Boolean(pageSupportedChains.length) && !pageSupportedChains.includes(chainId),
    [chainId, pageSupportedChains],
  )
  const handleDismiss = useCallback(() => setDismissWrongNetwork(true), [setDismissWrongNetwork])

  if (pageSupportedChains?.length === 0) return null // open to all chains

  if (isPageNotSupported && isPLSOnlyPage) {
    return (
      <ModalV2 isOpen closeOnOverlayClick={false}>
        <PageNetworkSupportModal />
      </ModalV2>
    )
  }

  if (isWrongNetwork && !dismissWrongNetwork && !isPageNotSupported) {
    // @ts-ignore
    const currentChain = Object.values(viemClients).map((client) => client.chain).find((c) => c?.id === chainId)
    if (!currentChain) return null
    return (
      <ModalV2 isOpen={isWrongNetwork} closeOnOverlayClick onDismiss={handleDismiss}>
        <WrongNetworkModal currentChain={currentChain} onDismiss={handleDismiss} />
      </ModalV2>
    )
  }

  if ((chain?.unsupported ?? false) || isPageNotSupported) {
    return (
      <ModalV2 isOpen closeOnOverlayClick={false}>
        <UnsupportedNetworkModal pageSupportedChains={pageSupportedChains?.length ? pageSupportedChains : CHAIN_IDS} />
      </ModalV2>
    )
  }

  

  return null
}
