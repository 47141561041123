import { Skeleton } from '@pulsex/uikit'
import BigNumber from 'bignumber.js'
import { styled } from 'styled-components'
import ApyButton from 'pages/Farms/components/FarmCard/ApyButton'
import getLiquidityUrlPathParts from 'utils/getLiquidityUrlPathParts'

export interface AprProps {
  value: string
  multiplier: string
  pid: number
  lpLabel: string
  lpSymbol: string
  lpRewardsApr: number
  lpTokenPrice: BigNumber
  tokenAddress?: string
  quoteTokenAddress?: string
  incPrice: BigNumber
  originalValue: number
  hideButton?: boolean
  protocol: string
}

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-right: 15px;
  color: ${({ theme }) => theme.colors.text};

  button {
    width: 20px;
    height: 20px;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.textSubtle};
      }
    }
  }
`

const AprWrapper = styled.div`
  min-width: 60px;
  text-align: left;
`

const Apr: React.FC<AprProps> = ({
  value,
  pid,
  lpLabel,
  lpSymbol,
  lpRewardsApr,
  lpTokenPrice,
  multiplier,
  tokenAddress,
  quoteTokenAddress,
  incPrice,
  originalValue,
  protocol,
  hideButton = false,
}) => {
  const liquidityUrlPathParts = getLiquidityUrlPathParts({
    protocol,
    quoteTokenAddress, 
    tokenAddress 
  })
  const addLiquidityUrl = `/add/${liquidityUrlPathParts}`

  return originalValue !== 0 ? (
    <Container>
      {originalValue ? (
        <ApyButton
          variant={hideButton ? 'text' : 'text-and-button'}
          pid={pid}
          lpSymbol={lpSymbol}
          lpLabel={lpLabel}
          lpTokenPrice={lpTokenPrice}
          lpRewardsApr={lpRewardsApr}
          multiplier={multiplier}
          incPrice={incPrice}
          apr={originalValue}
          displayApr={value}
          addLiquidityUrl={addLiquidityUrl}
        />
      ) : (
        <AprWrapper>
          <Skeleton width={60} />
        </AprWrapper>
      )}
    </Container>
  ) : (
    <Container>
      <AprWrapper>{originalValue}%</AprWrapper>
    </Container>
  )
}

export default Apr
