import { WalletConfigV2, WalletFilledIcon } from '@pulsex/uikit'
import { getTrustWalletProvider } from '@pulsex/wagmi/connectors/trustWallet'
import { Config } from 'wagmi'
import { ConnectMutateAsync } from 'wagmi/query'
import { chains, createWagmiConfig, walletConnectNoQrCodeConnector } from '../utils/wagmi'

const domain = 'https://tokens.app.pulsex.com'

export enum ConnectorNames {
  MetaMask = 'metaMask',
  Injected = 'injected',
  WalletConnect = 'walletConnect',
  WalletConnectV1 = 'walletConnectLegacy',
  Ledger = 'ledger',
  TrustWallet = 'trustWallet',
}

const createQrCode =
  <config extends Config = Config, context = unknown>(chainId: number, connect: ConnectMutateAsync<config, context>) =>
    async () => {
      const wagmiConfig = createWagmiConfig()
      const injectedConnector = wagmiConfig.connectors.find((connector) => connector.id === ConnectorNames.Injected)
      if (!injectedConnector) {
        return ''
      }
      // HACK: utilizing event emitter from injected connector to notify wagmi of the connect events
      const connector = {
        ...walletConnectNoQrCodeConnector({
          chains,
          emitter: injectedConnector?.emitter,
        }),
        emitter: injectedConnector.emitter,
        uid: injectedConnector.uid,
      }
      const provider = await connector.getProvider()

      return new Promise<string>((resolve) => {
        provider.on('display_uri', (uri) => {
          resolve(uri)
        })
        connect({ connector, chainId })
      })
    }

const isMetamaskInstalled = () => {
  if (typeof window === 'undefined') {
    return false
  }

  if (window.ethereum?.isMetaMask) {
    return true
  }

  if (window.ethereum?.providers?.some((p) => p.isMetaMask)) {
    return true
  }

  return false
}

const walletsConfig = <config extends Config = Config, context = unknown>({
  chainId,
  connect,
}: {
  chainId: number
  connect: ConnectMutateAsync<config, context>
}): WalletConfigV2<ConnectorNames>[] => {
  const qrCode = createQrCode(chainId, connect)
  return [
    {
      id: 'metamask',
      title: 'Metamask',
      icon: `${domain}/images/metamask.png`,
      get installed() {
        return isMetamaskInstalled()
        // && metaMaskConnector.ready
      },
      connectorId: ConnectorNames.MetaMask,
      deepLink: 'https://metamask.app.link/dapp/pulsex.com/',
      qrCode,
      downloadLink: 'https://metamask.app.link/dapp/pulsex.com/',
    },
    {
      id: 'rabby',
      title: 'Rabby Wallet',
      icon: `${domain}/images/rabby.png`,
      connectorId: ConnectorNames.Injected,
      get installed() {
        return typeof window !== 'undefined' && Boolean(window.ethereum?.isRabby)
      },
      downloadLink: 'https://rabby.io/',
    },
    {
      id: 'trust',
      title: 'Trust Wallet',
      icon: `${domain}/images/trust.png`,
      connectorId: ConnectorNames.TrustWallet,
      get installed() {
        return !!getTrustWalletProvider()
      },
      downloadLink: 'https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph',
      guide: {
        desktop: 'https://trustwallet.com/browser-extension',
        mobile: 'https://trustwallet.com/',
      },
      qrCode,
    },
    {
      id: 'walletconnect',
      title: 'WalletConnect',
      icon: `${domain}/images/walletconnect.png`,
      connectorId: ConnectorNames.WalletConnect,
    },
    {
      id: 'opera',
      title: 'Opera Wallet',
      icon: `${domain}/images/opera.png`,
      connectorId: ConnectorNames.Injected,
      get installed() {
        return typeof window !== 'undefined' && Boolean(window.ethereum?.isOpera)
      },
      downloadLink: 'https://www.opera.com/crypto/next',
    },
    {
      id: 'brave',
      title: 'Brave Wallet',
      icon: `${domain}/images/brave.png`,
      connectorId: ConnectorNames.Injected,
      get installed() {
        return typeof window !== 'undefined' && Boolean(window.ethereum?.isBraveWallet)
      },
      downloadLink: 'https://brave.com/wallet/',
    },
    {
      id: 'ledger',
      title: 'Ledger',
      icon: `${domain}/images/ledger.png`,
      connectorId: ConnectorNames.Ledger,
    },
  ]
}

export const createWallets = (chainId: number, connect: any) => {
  const hasInjected = typeof window !== 'undefined' && Boolean(window.ethereum)
  const config = walletsConfig({ chainId, connect })
  return hasInjected && config.some((c) => c.installed && (c.connectorId === ConnectorNames.MetaMask || c.connectorId === ConnectorNames.Injected))
    ? config // add injected icon if none of injected type wallets installed
    : [
      ...config,
      {
        id: 'injected',
        title: 'Injected',
        icon: WalletFilledIcon,
        connectorId: ConnectorNames.Injected,
        installed: typeof window !== 'undefined' && Boolean(window.ethereum),
      },
    ]
}
