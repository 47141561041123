import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router";
import { Link } from 'react-router-dom';
import { useTranslation } from "@pulsex/localization";
import { NotificationDot, ButtonMenu, ButtonMenuItem, Flex } from "@pulsex/uikit";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: 0px;
  }
`;

export interface FarmTabButtonsProps {
  hasStakeInFinishedFarms: boolean;
}

export const FarmTabButtons: React.FC<React.PropsWithChildren<FarmTabButtonsProps>> = ({ hasStakeInFinishedFarms }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  let activeIndex;
  switch (pathname) {
    case "/farms":
      activeIndex = 0;
      break;
    case "/farms/history":
      activeIndex = 1;
      break;
    case "/_mp/farms/history":
      activeIndex = 1;
      break;
    case "/farms/archived":
      activeIndex = 2;
      break;
    default:
      activeIndex = 0;
      break;
  }

  return (
    <Wrapper>
      <Flex width="max-content" flexDirection="column">
        {/* <Text textTransform="uppercase" color="textSubtle" fontSize="12px" bold>
          {t("Filter by")}
        </Text> */}
        <ButtonMenu activeIndex={activeIndex} scale="sm" variant="subtle">
          <ButtonMenuItem as={Link} to="/farms">
            {t("Live")}
          </ButtonMenuItem>
          <NotificationDot show={hasStakeInFinishedFarms}>
            <ButtonMenuItem as={Link} to="/farms/history" id="finished-farms-button">
              {t("Finished")}
            </ButtonMenuItem>
          </NotificationDot>
        </ButtonMenu>
      </Flex>
    </Wrapper>
  );
};
