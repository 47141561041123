import { useTranslation } from '@pulsex/localization'
import { Percent } from '@pulsex/sdk'
import {
  AutoColumn,
  Button,
  Card,
  CardProps,
  ChevronUpIcon,
  ChevronDownIcon,
  Flex,
  HelpIcon,
  Tag,
  Text,
  useModal,
} from '@pulsex/uikit'
import { PoolType, StableSwapPool } from '@pulsex/stable-swap-sdk'
import { CurrencyLogo, MultiCurrencyLogo } from '@pulsex/widgets-internal'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import { BIG_INT_ZERO } from 'config/constants/exchange'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import Dots from 'components/Loader/Dots'
import { useTotalSupply } from 'hooks/useTotalSupply'
import { unwrappedToken } from 'utils/wrappedCurrency'
import { InfoModal } from './InfoModal'

const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`
const StyledHelpIcon = styled(HelpIcon)`
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    opacity: 0.8;
  }
`

interface PositionCardProps extends CardProps {
  pool: StableSwapPool
  showUnwrapped?: boolean
}

export function StableSwapPositionCard({ pool, ...props }: PositionCardProps) {
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)
  const isThreePool = pool?.type === PoolType.THREE_POOL
  const currency0 = unwrappedToken(pool.token0)
  const currency1 = unwrappedToken(pool.token1)
  const currency2 = pool.token2 && unwrappedToken(pool.token2)
  const totalPoolTokens = useTotalSupply(pool.liquidityToken)

  const poolTokenPercentage =
    !!pool.userData && !!totalPoolTokens && !!pool?.userData?.lpBalance?.quotient && totalPoolTokens.quotient >= pool.userData.lpBalance.quotient
      ? new Percent(pool?.userData?.lpBalance?.quotient ?? 0, totalPoolTokens.quotient)
      : undefined

  const [onPresentInfoModal] = useModal(<InfoModal pool={pool} currency0={currency0} currency1={currency1} currency2={currency2} />)

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    onPresentInfoModal()
  }

  return (
    <Card style={{ background: "none", borderRadius: '10px' }} {...props}>
      <Flex justifyContent="space-between" role="button" onClick={() => setShowMore(!showMore)} p="16px">
        <Flex flexDirection="column">
          <Flex flexDirection="column" alignItems="left" mb="4px" justifyContent="center">
            <MultiCurrencyLogo currency0={currency0} currency1={currency1} currency2={currency2} size={20} />
            <Flex alignItems="center">
              <Text bold>
                {!currency0 || !currency1 ? <Dots>{t('Loading')}</Dots> : `${currency0.symbol}/${currency1.symbol}${currency2 ? `/${currency2.symbol}` : ''}`}
              </Text>
              <StyledHelpIcon ml={1} color="primary" onClick={handleClick} />
            </Flex>
          </Flex>

          {pool?.userData?.lpBalance?.greaterThan(0) && <Text fontSize="14px" color="textSubtle">
            {pool.userData.lpBalance?.toSignificant(4)}
          </Text>}
        </Flex>
        <Flex alignItems="center">
          <Tag variant="textDisabled">{t('Stable')} LP</Tag>
          {showMore ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Flex>
      </Flex>
      {showMore && (
        <AutoColumn gap="8px" style={{ padding: '16px' }}>
          <FixedHeightRow>
            <RowFixed>
              <CurrencyLogo size="20px" currency={currency0} />
              <Text color="textSubtle" ml="4px">
                {t('Pooled %asset%', { asset: currency0.symbol })}:
              </Text>
            </RowFixed>
            {pool?.userData?.tokenAmounts ? (
              <RowFixed>
                <Text ml="6px">{pool?.userData?.tokenAmounts?.token0?.toSignificant(6)}</Text>
              </RowFixed>
            ) : (
              '0'
            )}
          </FixedHeightRow>
          <FixedHeightRow>
            <RowFixed>
              <CurrencyLogo size="20px" currency={currency1} />
              <Text color="textSubtle" ml="4px">
                {t('Pooled %asset%', { asset: currency1.symbol })}:
              </Text>
            </RowFixed>
            {pool?.userData?.tokenAmounts ? (
              <RowFixed>
                <Text ml="6px">{pool?.userData?.tokenAmounts?.token1?.toSignificant(6)}</Text>
              </RowFixed>
            ) : (
              '0'
            )}
          </FixedHeightRow>

          {isThreePool && <FixedHeightRow>
            <RowFixed>
              <CurrencyLogo size="20px" currency={currency2} />
              <Text color="textSubtle" ml="4px">
                {t('Pooled %asset%', { asset: currency2?.symbol })}:
              </Text>
            </RowFixed>
            {pool?.userData?.tokenAmounts ? (
              <RowFixed>
                <Text ml="6px">{pool?.userData?.tokenAmounts?.token2?.toSignificant(6)}</Text>
              </RowFixed>
            ) : (
              '0'
            )}
          </FixedHeightRow>}

          <FixedHeightRow>
            <Text color="textSubtle">{t('Share of Pool')}</Text>
            <Text>
              {poolTokenPercentage && poolTokenPercentage.greaterThan(0n)
                ? `${poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)}%`
                : '0'}
            </Text>
          </FixedHeightRow>
          <RowBetween>
            <Button
              as={Link}
              to={`/add/stable/${pool.stableSwapAddress}`}
              width={pool?.userData?.lpBalance && pool.userData.lpBalance.quotient > BIG_INT_ZERO ? '48%' : '100%'}
            >
              {t('Add')}
            </Button>
            {pool?.userData?.lpBalance && pool.userData.lpBalance.quotient > BIG_INT_ZERO && (
              <Button
                as={Link}
                to={`/remove/stable/${pool.stableSwapAddress}`}
                variant="secondary"
                width="48%"
              >
                {t('Remove')}
              </Button>
            )}
          </RowBetween>
        </AutoColumn>
      )}
    </Card>
  )
}
