import memoize from 'lodash/memoize'
import { Transport } from 'viem'
import { createConfig, fallback, http } from 'wagmi'
import { injected, safe, walletConnect } from 'wagmi/connectors'
import { projectId } from 'config'
import { CHAINS } from 'config/chains'
import { CLIENT_CONFIG, publicClient } from './viem'

export const chains = CHAINS

export const injectedConnector = injected({
  shimDisconnect: false,
})

export const walletConnectConnector = walletConnect({
  // ignore the error in test environment
  // Error: To use QR modal, please install @walletconnect/modal package
  showQrModal: process.env.NODE_ENV !== 'test',
  projectId,
})

export const walletConnectNoQrCodeConnector = walletConnect({
  showQrModal: false,
  projectId
})

export const metaMaskConnector = injected({ target: 'metaMask', shimDisconnect: false })
export const trustConnector = injected({ target: 'trust', shimDisconnect: false })

export const transports = chains.reduce((ts, chain) => {
  if (ts) {
    return {
      ...ts,
      [chain.id]: fallback([http(chain.rpcUrls.default.http[0])]),
    }
  }
  return {
    [chain.id]: fallback([http(chain.rpcUrls.default.http[0])]),
  }
}, {} as Record<number, Transport>)

export function createWagmiConfig() {
  return createConfig({
    chains,
    ssr: false,
    syncConnectedChain: true,
    transports,
    ...CLIENT_CONFIG,

    connectors: [
      metaMaskConnector,
      injectedConnector,
      safe(),
      walletConnectConnector,
      // ledgerConnector,
      trustConnector,
    ],
  })
}

export const CHAIN_IDS = chains.map((c) => c.id)

export const isChainSupported = memoize((chainId: number) => CHAIN_IDS.includes(chainId))
export const isChainTestnet = memoize((chainId: number) => chains.find((c) => c.id === chainId)?.testnet)

export { publicClient }
