import { useTranslation } from '@pulsex/localization'
import {
  AddIcon,
  Button,
  ButtonMenu,
  ButtonMenuItem,
  CardBody,
  CardFooter,
  Text, Flex,
} from '@pulsex/uikit'
import { styled } from 'styled-components'
import { ReactNode, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { AppBody, AppHeader } from 'components/App'
import Dots from 'components/Loader/Dots'
import FullPositionCard from 'components/PositionCard'
import { StableSwapPositionCard } from 'components/PositionCard/StableSwapPositionCard'
import useAccountActiveChain from 'hooks/useAccountActiveChain'
import { useV1PairsByAccount, useV2PairsByAccount } from 'hooks/usePairs'
import { useAllStableSwapPoolsWithUserData } from 'state/stableswap/hooks'
import { useProtocol } from 'state/user/smartRouter'
import { ENABLE_STABLESWAP } from 'config'
import Page from '../Page'

const Body = styled(CardBody)`
  background-color: ${({ theme }) => theme.colors.backgroundAlt2};
`

enum FILTER {
  ALL = 0,
  V1 = 1,
  V2 = 2,
  STABLE = 3,
}

export default function Pool() {
  const { account } = useAccountActiveChain()
  const { t } = useTranslation()

  const [selectedTypeIndex, setSelectedTypeIndex] = useState(FILTER.ALL)

  const [, setProtocol] = useProtocol()

  const { data: v1Pairs, loading: v1Loading } = useV1PairsByAccount(account)
  const { data: v2Pairs, loading: v2Loading } = useV2PairsByAccount(account)
  const { data: stablePools, loading: stableLoading } = useAllStableSwapPoolsWithUserData(account)

  const filteredStablePools = stablePools.filter((pool) => pool?.userData?.lpBalance?.quotient && pool.userData.lpBalance.quotient > 0n)

  const v1PairsSection: null | ReactNode[] = v1Pairs?.length
    ? v1Pairs.map((pair) => (
      <FullPositionCard
        key={pair?.liquidityToken?.address}
        pair={pair}
        mb='16px'
      />
    ))
    : null

  const v2PairsSection: null | ReactNode[] = v2Pairs?.length
    ? v2Pairs.map((pair) => (
      <FullPositionCard
        key={pair?.liquidityToken?.address}
        pair={pair}
        mb='16px'
      />
    ))
    : null

  const allStablePoolsSection: null | ReactNode[] = useMemo(() => {
    if (!stablePools?.length) return null

    return stablePools.map((pool) => (
      <StableSwapPositionCard
        key={pool.liquidityToken.address}
        pool={pool}
        mb='16px'
      />
    ))
  }, [stablePools])

  const stablePoolsSectionWithBalance: null | ReactNode[] = useMemo(() => {
    if (!filteredStablePools?.length) return null

    return filteredStablePools.map((pool) => (
      <StableSwapPositionCard
        key={pool.liquidityToken.address}
        pool={pool}
        mb='16px'
      />
    ))
  }, [filteredStablePools])

  const mainSection = useMemo(() => {
    let resultSection: null | ReactNode | (ReactNode[] | null | undefined)[] = null

    if (!account) {
      resultSection = (
        <Text color="textSubtle" textAlign="center">
          {t('Connect to a wallet to view your liquidity.')}
        </Text>
      );
    } else if (v1Loading || v2Loading || stableLoading) {
      resultSection = (
        <Text color="textSubtle" textAlign="center">
          <Dots>{t('Loading')}</Dots>
        </Text>
      );
    } else if (!v1PairsSection && !v2PairsSection && !allStablePoolsSection) {
      resultSection = (
        <Text color="textSubtle" textAlign="center">
          {t('No liquidity found.')}
        </Text>
      );
    } else {
      switch (selectedTypeIndex) {
        case FILTER.ALL:
          resultSection = [v1PairsSection, v2PairsSection, ENABLE_STABLESWAP && stablePoolsSectionWithBalance].flatMap(section => section)
          break;
        case FILTER.STABLE:
          resultSection = allStablePoolsSection
          break
        default:
          resultSection =
            selectedTypeIndex === FILTER.V1 ? [v1PairsSection].flatMap(section => section) :
              [v2PairsSection].flatMap(section => section)
          break
      }
    }

    return resultSection;
  }, [account, selectedTypeIndex, t, v1Loading, v1PairsSection, v2Loading, v2PairsSection, stableLoading, allStablePoolsSection, stablePoolsSectionWithBalance])

  const protocolSelector =
    <ButtonMenu
      scale="sm"
      activeIndex={selectedTypeIndex}
      onItemClick={(index) => {
        if (index === 1) {
          setProtocol('V1')
        }
        if (index === 2) {
          setProtocol('V2')
        }
        setSelectedTypeIndex(index)
      }}
      variant="subtle"
    >
      <ButtonMenuItem>{t('All')}</ButtonMenuItem>
      <ButtonMenuItem>V1</ButtonMenuItem>
      <ButtonMenuItem>V2</ButtonMenuItem>
      {ENABLE_STABLESWAP ? <ButtonMenuItem>{t('Stable')}</ButtonMenuItem> : <></>}
    </ButtonMenu>

  return (
    <Page>
      <AppBody>
        <AppHeader
          title={selectedTypeIndex === 3 ? `${t('Stable')} ${t('Pools').toLocaleLowerCase()}` : t('Your Liquidity')}
          protocolSelector={protocolSelector}
        />
        <Body>
          {mainSection}
          {account && !v1Loading && !v2Loading && !stableLoading && selectedTypeIndex !== 3 && (
            <Flex flexDirection="column" alignItems="center" mt="24px">
              <Text color="textSubtle" mb="8px">
                {t("Don't see a pool you joined?")}
              </Text>
              <Link to="/find">
                <Button id="import-pool-link" variant="secondary" scale="sm">
                  {t('Find other LP tokens')}
                </Button>
              </Link>
            </Flex>
          )}
        </Body>
        {selectedTypeIndex !== 3 && <CardFooter style={{ textAlign: 'center' }}>
          <Link to={selectedTypeIndex === 1 ? "/add/V1" : "/add/V2"}>
            <Button
              id="join-pool-button"
              width="100%"
              startIcon={<AddIcon color="white" />}
            >
              {t('Add Liquidity')}
            </Button>
          </Link>
        </CardFooter>}
      </AppBody>
    </Page>
  )
}
