import { ChainId } from '@pulsex/chains'
import { ERC20Token } from '@pulsex/sdk'
import { plsTestnetTokens } from '@pulsex/tokens'
import { SerializedStableSwapPool, PoolType } from '../../types'

export const pools: SerializedStableSwapPool[] = [
  {
    type: PoolType.TWO_POOL,
    lpSymbol: 'DAI-USDC LP',
    liquidityToken: new ERC20Token(ChainId.PULSECHAIN_TESTNET, '0x152D51035e1ab2C69e1079C1471592e888fE32d3', 18, 'STABLE-LP'),
    token0: plsTestnetTokens.dai, // coins[0]
    token1: plsTestnetTokens.usdc, // coins[1]
    stableSwapAddress: '0x0e71Ae2804C61d2b6E9FB1278349aE6Fd79824Bd',
    infoStableSwapAddress: '0x000779f79ED4CB840884407cCB1Ff8E45B4BdD88',
  },
  {
    type: PoolType.THREE_POOL,
    lpSymbol: 'DAI-USDC-USDT LP',
    liquidityToken: new ERC20Token(ChainId.PULSECHAIN_TESTNET, '0x3062e29cfe09C87A432c0957fD5A7626b919Cd61', 18, 'STABLE-LP'),
    token0: plsTestnetTokens.dai, // coins[0]
    token1: plsTestnetTokens.usdc, // coins[1]
    token2: plsTestnetTokens.usdt, // coins[2]
    stableSwapAddress: '0xedfCA3C2012dA7DcEc4B5d7B9728A0A590Df2820',
    infoStableSwapAddress: '0x5505Cb740ebf2Cb69645B6cc1Be7D501221477Fd',
  }
]
