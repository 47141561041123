import { useTranslation } from "@pulsex/localization";
import {
  Tag,
  TagProps,
  BlockIcon,
  CommunityIcon,
  RefreshIcon,
  TimerIcon,
  VerifiedIcon,
  VoteIcon,
  CheckmarkCircleIcon,
} from "@pulsex/uikit";

const CoreTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag
      variant="secondary"
      style={{ background: "none", width: "fit-content" }}
      outline
      startIcon={<VerifiedIcon width="18px" color="secondary" mr="4px" />}
      {...props}
    >
      {t("Core")}
    </Tag>
  );
};

const CommunityTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag
      variant="failure"
      style={{ background: "none" }}
      outline
      startIcon={<CommunityIcon width="18px" color="failure" mr="4px" />}
      {...props}
    >
      {t("Community")}
    </Tag>
  );
};

const DualTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag variant="textSubtle" style={{ background: "none" }} outline {...props}>
      {t("Dual")}
    </Tag>
  );
};

const ManualPoolTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag
      variant="secondary"
      style={{ background: "none" }}
      outline
      startIcon={<RefreshIcon width="18px" color="secondary" mr="4px" />}
      {...props}
    >
      {t("Manual")}
    </Tag>
  );
};

const VoteNowTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag variant="success" startIcon={<VoteIcon width="18px" color="success" mr="4px" />} {...props}>
      {t("Vote Now")}
    </Tag>
  );
};

const VotedTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag
      variant="success"
      style={{ background: "none" }}
      outline
      startIcon={<CheckmarkCircleIcon width="18px" color="success" mr="4px" />}
      {...props}
    >
      {t("Voted")}
    </Tag>
  );
};

const SoonTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag variant="binance" startIcon={<TimerIcon width="18px" color="success" mr="4px" />} {...props}>
      {t("Soon")}
    </Tag>
  );
};

const ClosedTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag variant="textDisabled" startIcon={<BlockIcon width="18px" color="textDisabled" mr="4px" />} {...props}>
      {t("Closed")}
    </Tag>
  );
};

const ProtocolTag: React.FC<React.PropsWithChildren<TagProps>> = ({ protocol, ...props }) => {
  return (
    <Tag variant="textSubtle" style={{ background: "none" }} outline textTransform="uppercase" {...props}>
      {protocol}
    </Tag>
  );
};

const Tags = {
  CoreTag,
  DualTag,
  ManualPoolTag,
  VoteNowTag,
  SoonTag,
  ClosedTag,
  CommunityTag,
  VotedTag,
  ProtocolTag,
};

export default Tags;
