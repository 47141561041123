import { useCallback } from 'react'
import { harvestFarm } from 'utils/calls'
import { useMasterchef } from 'hooks/useContract'
import { useFeeDataWithGasPrice } from 'state/user/hooks'

const useHarvestFarm = (farmPid: number) => {
  const masterChefContract = useMasterchef()
  const { maxFeePerGas, maxPriorityFeePerGas } = useFeeDataWithGasPrice()

  const handleHarvest = useCallback(async () => {
    return harvestFarm(masterChefContract, farmPid, maxFeePerGas, maxPriorityFeePerGas)
  }, [farmPid, masterChefContract, maxFeePerGas, maxPriorityFeePerGas])

  return { onReward: handleHarvest }
}

export default useHarvestFarm
