import { ChainId } from '@pulsex/chains'
import { Address } from 'viem'

import { SupportedChainId } from './supportedChains'

export type ContractAddresses<T extends ChainId = SupportedChainId> = {
  [chainId in T]: Address
}

export const PLSX = {
  [ChainId.PULSECHAIN]: '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab',
  [ChainId.PULSECHAIN_TESTNET]: '0x8a810ea8B121d08342E9e7696f4a9915cBE494B7',
} as const satisfies ContractAddresses<SupportedChainId>

export const PLSX_LAB = {
  [ChainId.PULSECHAIN]: '0x3E3EC0af505D6458D18A3AA1E29A9d7f008dd98e',
  [ChainId.PULSECHAIN_TESTNET]: '0x959a437F1444DaDaC8aF997E71EAF0479c810267',
} as const satisfies ContractAddresses<SupportedChainId>
