import clsx from "clsx";
import { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { NumericFormat } from 'react-number-format'
import { SwapCSS } from "@pulsex/uikit";

export type NumericalInputProps2 = {
  value: string | number;
  onUserInput: (input: string) => void;
  fontSize?: string;
} & SwapCSS.InputVariants &
  Omit<React.HTMLProps<HTMLInputElement>, "ref" | "onChange" | "as">;

const StyledInput = styled(NumericFormat) <{ error?: boolean; fontSize?: string; align?: string }>`
  color: ${({ error, theme }) => (error ? theme.colors.failure : theme.colors.text)};
  width: 0;
  position: relative;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  -webkit-appearance: textfield;

  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.textSubtle};
  }
`

export const NumericalInput2 = memo(function InnerInput({
  value,
  onUserInput,
  placeholder,
  className,
  error,
  align,
  loading,
}: NumericalInputProps2) {

  // Using this state variable to catch global state value updates
  // and forse re-render NumericFormat component
  const [displayValue, setDisplayValue] = useState('')

  const handleInput = (val: string) => {
    onUserInput(val)
  }

  useEffect(() => {
    setDisplayValue(value.toString())
  }, [value])

  return (
    <StyledInput
      className={clsx(
        className,
        SwapCSS.inputVariants({
          error,
          align,
          loading,
        })
      )}
      fontSize="16px"
      value={displayValue}
      thousandSeparator=","
      allowNegative={false}
      allowLeadingZeros={false}
      placeholder={placeholder || '0.0'}
      onChange={(event) => handleInput(event.target.value)}
    />
  )
})
