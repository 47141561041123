import { useTranslation } from '@pulsex/localization'
import { Currency } from '@pulsex/sdk'
import {
  AddIcon,
  Button,
  ButtonMenu,
  ButtonMenuItem,
  ChevronDownIcon,
  Link,
  Text,
  useModal
} from '@pulsex/uikit'
import { CurrencyLogo } from '@pulsex/widgets-internal'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { BIG_INT_ZERO } from 'config/constants/exchange'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { LightCard } from 'components/Card'
import { AutoColumn, ColumnCenter } from 'components/Layout/Column'
import { MinimalPositionCard } from 'components/PositionCard'
import Row from 'components/Layout/Row'
import CurrencySearchModal from 'components/SearchModal/CurrencySearchModal'
import { useProtocol } from 'state/user/smartRouter'
import { PairState, usePairBothProtocols } from 'hooks/usePairs'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { usePairAdder } from 'state/user/hooks'
import { useTokenBalance } from 'state/wallet/hooks'
import { currencyId } from 'utils/currencyId'
import Dots from 'components/Loader/Dots'
import { AppHeader, AppBody } from 'components/App'
import Page from '../Page'

enum Fields {
  TOKEN0 = 0,
  TOKEN1 = 1,
}

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.input};
  color: ${({ theme }) => theme.colors.text};
  box-shadow: none;
  border-radius: 10px;
`

export default function PoolFinder() {
  const { account } = useActiveWeb3React()
  const native = useNativeCurrency()
  const { t } = useTranslation()

  const [activeField, setActiveField] = useState<number>(Fields.TOKEN1)
  const [currency0, setCurrency0] = useState<Currency | null>(native)
  const [currency1, setCurrency1] = useState<Currency | null>(null)

  const [protocol, setProtocol] = useProtocol()

  const [pairState, pair] = usePairBothProtocols(currency0 ?? undefined, currency1 ?? undefined, protocol)
  const addPair = usePairAdder()
  useEffect(() => {
    if (pair) {
      addPair(pair)
    }
  }, [pair, addPair])

  const validPairNoLiquidity: boolean =
    pairState === PairState.NOT_EXISTS ||
    Boolean(
      pairState === PairState.EXISTS &&
      pair &&
      pair.reserve0.quotient === BIG_INT_ZERO &&
      pair.reserve1.quotient === BIG_INT_ZERO,
    )

  const position = useTokenBalance(account ?? undefined, pair?.liquidityToken)
  const hasPosition = Boolean(position && position.quotient > BIG_INT_ZERO)

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      if (activeField === Fields.TOKEN0) {
        setCurrency0(currency)
      } else {
        setCurrency1(currency)
      }
    },
    [activeField],
  )

  const prerequisiteMessage = (
    <LightCard padding="45px 10px">
      <Text textAlign="center">
        {!account ? t('Connect to a wallet to find pools') : t('Select a token to find your liquidity.')}
      </Text>
    </LightCard>
  )

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={handleCurrencySelect}
      showCommonBases
      selectedCurrency={(activeField === Fields.TOKEN0 ? currency1 : currency0) ?? undefined}
    />,
    true,
    true,
    'selectCurrencyModal',
  )

  const protocolSelector =
    <ButtonMenu
      scale="sm"
      activeIndex={protocol === 'V1' ? 0 : 1}
      onItemClick={(index) => setProtocol(index === 0 ? 'V1' : 'V2')}
      variant="subtle"
    >
      <ButtonMenuItem>V1</ButtonMenuItem>
      <ButtonMenuItem>V2</ButtonMenuItem>
    </ButtonMenu>

  return (
    <Page>
      <AppBody>
        <AppHeader
          title={t('Import Pool')}
          subtitle={t('Import an existing pool')}
          backTo="/liquidity"
          protocolSelector={protocolSelector}
        />
        <AutoColumn style={{ padding: '1rem' }} gap="md">
          <StyledButton
            endIcon={<ChevronDownIcon />}
            onClick={() => {
              onPresentCurrencyModal()
              setActiveField(Fields.TOKEN0)
            }}
          >
            {currency0 ? (
              <Row>
                <CurrencyLogo currency={currency0} />
                <Text ml="8px">{currency0.symbol}</Text>
              </Row>
            ) : (
              <Text ml="8px">{t('Select a Token')}</Text>
            )}
          </StyledButton>

          <ColumnCenter>
            <AddIcon />
          </ColumnCenter>

          <StyledButton
            endIcon={<ChevronDownIcon />}
            onClick={() => {
              onPresentCurrencyModal()
              setActiveField(Fields.TOKEN1)
            }}
          >
            {currency1 ? (
              <Row>
                <CurrencyLogo currency={currency1} />
                <Text ml="8px">{currency1.symbol}</Text>
              </Row>
            ) : (
              <Text as={Row}>{t('Select a Token')}</Text>
            )}
          </StyledButton>

          {hasPosition && pair && (
            <ColumnCenter
              style={{ justifyItems: 'center', backgroundColor: '', padding: '12px 0px', borderRadius: '10px' }}
            >
              <Text textAlign="center">{t('Pool Found!')}</Text>
              <Link to={`/add/${protocol}/${pair.token0.address}/${pair.token1.address}`}>
                <Text textAlign="center">{t('Manage this pool.')}</Text>
              </Link>
            </ColumnCenter>
          )}

          {currency0 && currency1 ? (
            pairState === PairState.EXISTS ? (
              hasPosition && pair ? (
                <MinimalPositionCard pair={pair} />
              ) : (
                <LightCard padding="45px 10px">
                  <AutoColumn gap="sm" justify="center">
                    <Text textAlign="center">{t('You don’t have liquidity in this pool yet.')}</Text>
                    <Link to={`/add/${protocol}/${currencyId(currency0)}/${currencyId(currency1)}`}>
                      <Text textAlign="center">{t('Add Liquidity')}</Text>
                    </Link>
                  </AutoColumn>
                </LightCard>
              )
            ) : validPairNoLiquidity ? (
              <LightCard padding="45px 10px">
                <AutoColumn gap="sm" justify="center">
                  <Text textAlign="center">{t('No pool found.')}</Text>
                  <Link to={`/add/${protocol}/${currencyId(currency0)}/${currencyId(currency1)}`}>
                    {t('Create pool.')}
                  </Link>
                </AutoColumn>
              </LightCard>
            ) : pairState === PairState.INVALID ? (
              <LightCard padding="45px 10px">
                <AutoColumn gap="sm" justify="center">
                  <Text textAlign="center" fontWeight={500}>
                    {t('Invalid pair.')}
                  </Text>
                </AutoColumn>
              </LightCard>
            ) : pairState === PairState.LOADING ? (
              <LightCard padding="45px 10px">
                <AutoColumn gap="sm" justify="center">
                  <Text textAlign="center">
                    {t('Loading')}
                    <Dots />
                  </Text>
                </AutoColumn>
              </LightCard>
            ) : null
          ) : (
            prerequisiteMessage
          )}
        </AutoColumn>
      </AppBody>
    </Page>
  )
}
