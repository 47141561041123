import { styled } from 'styled-components'
import { useTranslation } from '@pulsex/localization'
import { Currency, CurrencyAmount, Percent, TradeType } from '@pulsex/sdk'
import { Box, Text, RowFixed, useMatchBreakpoints, MouseoverTooltip, useModal } from '@pulsex/uikit'
import { SmartRouterTrade } from '@pulsex/smart-router'
import { formatAmount } from '@pulsex/utils/formatFractions'
import { Field } from 'state/swap/actions'
import SettingsModal from 'components/Menu/GlobalSettings/SettingsModal'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useNativeCurrency from 'hooks/useNativeCurrency'
import FormattedPriceImpact from './FormattedPriceImpact'
import { RouteDisplayEssentials, RoutesBreakdown } from '.'
import { SlippageAdjustedAmounts } from '../utils/exchange'

const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.15rem 0;
`
const Container = styled.div`
  padding-top: 0.5rem;
  display: flex;
  flex-flow: column nowrap;
`
const EditButton = styled(Box)`
  cursor: pointer;
`

interface AdvancedSwapDetailsProps {
  inputAmount?: CurrencyAmount<Currency>
  outputAmount?: CurrencyAmount<Currency>
  trade?: SmartRouterTrade<TradeType>
  allowedSlippage: number
  syncing?: boolean
  hideInfoTooltips?: boolean
  priceImpactWithoutFee?: Percent | null
  slippageAdjustedAmounts: SlippageAdjustedAmounts
  tradeType?: TradeType
  routes?: RouteDisplayEssentials[]
}

export function AdvancedSwapDetails({
  inputAmount,
  outputAmount,
  trade,
  allowedSlippage,
  priceImpactWithoutFee,
  slippageAdjustedAmounts,
  tradeType,
  routes
}: AdvancedSwapDetailsProps) {
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()
  const { chainId } = useActiveWeb3React()
  const nativeCurrency = useNativeCurrency()
  const [onPresentSettingsModal] = useModal(<SettingsModal />)
  const isExactIn = tradeType === TradeType.EXACT_INPUT

  return (
    <Container>
      <LabelRow>
        <RowFixed>
          <Text fontSize="12px" color="textSubtleDark">
            {t('Slippage tolerance')}
          </Text>
        </RowFixed>
        <RowFixed alignItems="center">
          <EditButton onClick={onPresentSettingsModal}>
            <Text fontSize="14px" color="primary" pr={2}>{t('Edit')}</Text>
          </EditButton>
          <Text fontSize="14px" bold>{allowedSlippage / 100}%</Text>
        </RowFixed>
      </LabelRow>
      <LabelRow>
        <RowFixed>
          <MouseoverTooltip
            isMobile={isMobile}
            text={t(
              'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
            )}
          >
            <Text fontSize="12px" color="textSubtleDark">
              {isExactIn ? t('Minimum receive') : t('Maximum sell')}
            </Text>
          </MouseoverTooltip>
        </RowFixed>
        <RowFixed>
          <Text fontSize="14px">
            {isExactIn
              ? `${formatAmount(slippageAdjustedAmounts[Field.OUTPUT], 4)} ${outputAmount?.currency?.symbol}` ?? '-'
              : `${formatAmount(slippageAdjustedAmounts[Field.INPUT], 4)} ${inputAmount?.currency?.symbol}` ?? '-'}
          </Text>
        </RowFixed>
      </LabelRow>

      <LabelRow>
        <RowFixed>
          <MouseoverTooltip
            isMobile={isMobile}
            text={t('The difference between the market price and estimated price due to trade size.')}
          >
            <Text fontSize="12px" color="textSubtleDark">
              {t('Price Impact')}
            </Text>
          </MouseoverTooltip>
        </RowFixed>
        <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
      </LabelRow>

      {!trade?.gasEstimateInUSD || !chainId ? null : (
        <LabelRow>
          <RowFixed>
            <MouseoverTooltip
              isMobile={isMobile}
              text={t(`The fee paid to the Network to process your transaction. This must be paid in %asset%.`, { asset: nativeCurrency.symbol })}
            >
              <Text fontSize="12px" color="textSubtleDark">
                {t('Network Fee')}
              </Text>
            </MouseoverTooltip>
          </RowFixed>
          <Text fontSize="14px" textAlign="right">
            ~${trade.gasEstimateInUSD.toFixed(2)}
          </Text>
        </LabelRow>
      )}

      <LabelRow>
        <RowFixed>
          <MouseoverTooltip
            isMobile={isMobile}
            text={t('Route is automatically calculated based on your routing preference to achieve the best price for your trade.')}
          >
            <Text fontSize="12px" color="textSubtleDark">
              {t('Route')}
            </Text>
          </MouseoverTooltip>
        </RowFixed>
        <RoutesBreakdown trade={trade} routes={routes} />
      </LabelRow>
    </Container>
  )
}
