import { ChainId } from '@pulsex/chains'
import { deserializeToken } from '@pulsex/token-lists'

import { isStableSwapSupported, STABLE_POOL_MAP, STABLE_SUPPORTED_CHAIN_IDS } from './pools'
import { DeserializedStableSwapPool, SerializedStableSwapPoolWithData, SerializedStableSwapPool, StableSwapPool } from '../../types'

export const deserializeStableSwapConfig = (pool: SerializedStableSwapPool): DeserializedStableSwapPool => {
  return {
    ...pool,
    liquidityToken: deserializeToken(pool.liquidityToken),
    token0: deserializeToken(pool.token0),
    token1: deserializeToken(pool.token1),
    token2: pool.token2 ? deserializeToken(pool.token2) : undefined
  }
}

export const serializeStableSwapPools = (pools: StableSwapPool[]): SerializedStableSwapPoolWithData[] => {
  return pools.map((pool) => {
    return {
      type: pool.type,
      lpSymbol: pool.lpSymbol,
      stableSwapAddress: pool.stableSwapAddress,
      infoStableSwapAddress: pool.infoStableSwapAddress,
      liquidityToken: pool.liquidityToken.serialize,
      token0: pool.token0.serialize,
      token1: pool.token1.serialize,
      token2: pool?.token2 ? pool?.token2.serialize : undefined,
      poolData: {
        balances: {
          token0: pool.poolData?.balances?.token0?.toString(),
          token1: pool.poolData?.balances?.token1?.toString(),
          token2: pool.poolData?.balances?.token2?.toString(),
        },
        amplifier: pool.poolData?.amplifier?.toString(),
        totalSupply: pool.poolData?.totalSupply?.toString(),
        fee: pool.poolData?.fee?.toString(),
        adminFee: pool.poolData?.adminFee?.toString()
      },
      userData: {
        lpBalance: pool.userData?.lpBalance?.toExact(),
        tokenAmounts: {
          token0: pool.userData?.tokenAmounts?.token0?.toExact(),
          token1: pool.userData?.tokenAmounts?.token1?.toExact(),
          token2: pool.userData?.tokenAmounts?.token2?.toExact()
        }
      }
    }
  })
}

export function getStableSwapPools(chainId: ChainId): StableSwapPool[] {
  if (!isStableSwapSupported(chainId)) {
    return []
  }
  return STABLE_POOL_MAP[chainId].map((pool) => deserializeStableSwapConfig(pool)) || []
}

export { isStableSwapSupported, STABLE_SUPPORTED_CHAIN_IDS, STABLE_POOL_MAP }
