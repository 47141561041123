import { useTranslation } from '@pulsex/localization'
import { Currency, CurrencyAmount, Percent, TradeType } from '@pulsex/sdk'
import { AutoColumn, Button, Flex, ErrorIcon, Text } from '@pulsex/uikit'
import { formatAmount } from '@pulsex/utils/formatFractions'
import truncateHash from '@pulsex/utils/truncateHash'
import { CurrencyLogo, Swap as SwapUI } from '@pulsex/widgets-internal'
import { styled } from 'styled-components'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import { warningSeverity } from 'utils/exchange'
import { SlippageAdjustedAmounts } from '../utils/exchange'
import { SwapShowAcceptChanges, TruncatedText } from './styleds'

const CurrencyHeader = styled(Flex)`
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.backgroundAlt2};
  padding: 0.75rem;
  justify-content: space-between;
`

export default function SwapModalHeader({
  inputAmount,
  outputAmount,
  tradeType,
  currencyBalances,
  priceImpactWithoutFee,
  isEnoughInputBalance,
  recipient,
  showAcceptChanges,
  onAcceptChanges,
}: {
  inputAmount: CurrencyAmount<Currency>
  outputAmount: CurrencyAmount<Currency>
  currencyBalances?: {
    INPUT?: CurrencyAmount<Currency>
    OUTPUT?: CurrencyAmount<Currency>
  }
  tradeType: TradeType
  priceImpactWithoutFee?: Percent
  slippageAdjustedAmounts: SlippageAdjustedAmounts | undefined | null
  isEnoughInputBalance?: boolean
  recipient?: string
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const { t } = useTranslation()

  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const inputTextColor =
    showAcceptChanges && tradeType === TradeType.EXACT_OUTPUT && isEnoughInputBalance
      ? 'primary'
      : tradeType === TradeType.EXACT_OUTPUT && !isEnoughInputBalance
        ? 'failure'
        : 'text'

  const truncatedRecipient = recipient ? truncateHash(recipient) : ''

  const recipientInfoText = t('Output will be sent to %recipient%', {
    recipient: truncatedRecipient,
  })

  const [recipientSentToText, postSentToText] = recipientInfoText.split(truncatedRecipient)

  return (
    <AutoColumn gap="md">
      <Flex flexDirection="column">
        <CurrencyHeader>
          <RowFixed>
            <CurrencyLogo currency={currencyBalances?.INPUT?.currency ?? inputAmount.currency} size="24px" />
            <Text fontSize="14px" ml="10px" mr="8px" bold>
              {inputAmount.currency.symbol}
            </Text>
          </RowFixed>
          <RowFixed>
            <TruncatedText fontSize="20px" color={inputTextColor} textAlign="right">
              {formatAmount(inputAmount, 6)}
            </TruncatedText>
          </RowFixed>
        </CurrencyHeader>
        <Flex justifyContent="center" zIndex="2">
          <SwapUI.CircleArrow marginTop="-7px" marginBottom="-7px"/> 
        </Flex>
        <CurrencyHeader>
          <RowFixed>
            <CurrencyLogo currency={currencyBalances?.OUTPUT?.currency ?? outputAmount.currency} size="24px" />
            <Text fontSize="14px" ml="10px" mr="8px" bold>
              {outputAmount.currency.symbol}
            </Text>
          </RowFixed>
          <RowFixed>
            <TruncatedText
              textAlign="right"
              fontSize="20px"
              color={
                priceImpactSeverity > 2
                  ? 'failure'
                  : showAcceptChanges && tradeType === TradeType.EXACT_INPUT
                    ? 'primary'
                    : 'text'
              }
            >
              {formatAmount(outputAmount, 6)}
            </TruncatedText>
          </RowFixed>
        </CurrencyHeader>
      </Flex>

      {showAcceptChanges ? (
        <SwapShowAcceptChanges justify="flex-start" gap="0px">
          <RowBetween>
            <RowFixed>
              <ErrorIcon mr="8px" />
              <Text bold> {t('Price Updated')}</Text>
            </RowFixed>
            <Button onClick={onAcceptChanges}>{t('Accept')}</Button>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}
      {tradeType === TradeType.EXACT_OUTPUT && !isEnoughInputBalance && (
        <Text fontSize={12} color="failure" textAlign="left" style={{ width: '100%' }}>
          {t('Insufficient input token balance. Your transaction may fail.')}
        </Text>
      )}
      {recipient ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <Text fontSize={12} color="textSubtle">
            {recipientSentToText}
            <b title={recipient}>{truncatedRecipient}</b>
            {postSentToText}
          </Text>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}
