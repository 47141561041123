import { styled } from "styled-components";
import { Placement, useTooltip } from "../../hooks";
import { BoxProps, Flex } from "../Box";
import { HelpIcon } from "../Svg";

interface Props extends BoxProps {
  text: string | React.ReactNode;
  placement?: Placement;
  size?: string;
  isMobile?: boolean;
};

const QuestionWrapper = styled.div`
  &:hover,
  &:focus {
    opacity: 0.7;
  }
`;

export const QuestionHelper: React.FC<React.PropsWithChildren<Props>> = ({
  text,
  placement = "auto",
  size = "16px",
  ...props
}) => {
  const { targetRef, tooltip, tooltipVisible } = useTooltip(text, { placement, trigger: 'hover' });

  return (
    <Flex alignItems="center" {...props} ref={targetRef}>
      {tooltipVisible && tooltip}
      <QuestionWrapper as={Flex} alignItems="center">
        <HelpIcon color={props.color ?? "textSubtle"} width={size} />
      </QuestionWrapper>
    </Flex>
  );
};

export const MouseoverTooltip: React.FC<Props> = ({ text, isMobile, children, ...props }) => {
  const placement = props.placement ?? isMobile ? 'top' : 'right';
  const { targetRef, tooltip, tooltipVisible } = useTooltip(text, { placement, trigger: 'hover' });

  return (
    <>
      <div ref={targetRef} {...props}>{children}</div>
      {tooltipVisible && tooltip}
    </>
  );
};
