import { useTranslation } from '@pulsex/localization'
import { ChainId } from '@pulsex/chains'
import { Button, Flex, Grid, Message, MessageText, Modal } from '@pulsex/uikit'
import { ChainLogo } from '@pulsex/widgets-internal'
import useAuth from 'hooks/useAuth'
import { useSessionChainId } from 'hooks/useSessionChainId'
import { useSwitchNetwork } from 'hooks/useSwitchNetwork'
import { Chain } from 'viem'
import { useAccount } from 'wagmi'
import Dots from '../Loader/Dots'

// Where page network is not equal to wallet network
export function WrongNetworkModal({ currentChain, onDismiss }: { currentChain: Chain; onDismiss: () => void }) {
  const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
  const { logout } = useAuth()
  const { isConnected } = useAccount()
  const [, setSessionChainId] = useSessionChainId()
  const chainId = currentChain.id || ChainId.PULSECHAIN
  const { t } = useTranslation()

  const switchText = t('Switch to %network%', { network: currentChain.name })

  return (
    <Modal title={t('You are on a wrong network')} onDismiss={onDismiss}>
      <Grid style={{ gap: '16px' }} maxWidth="100%" minWidth="336px">
        <Message variant="warning" icon={false} p="8px 12px">
          <MessageText>
            <Flex alignItems="center">
              <Flex height="24px" pr={2}>
                <ChainLogo chainId={chainId} />
              </Flex>
              <span>{t('Please switch to %network% to continue.', { network: currentChain.name })}</span>
            </Flex>
          </MessageText>
        </Message>
        {canSwitch ? (
          <Button isLoading={isLoading} onClick={() => switchNetworkAsync(chainId)}>
            {isLoading ? <Dots>{switchText}</Dots> : switchText}
          </Button>
        ) : (
          <Message variant="danger">
            <MessageText>{t('Unable to switch network. Please try it on your wallet')}</MessageText>
          </Message>
        )}
        {isConnected && (
          <Button
            onClick={() =>
              logout().then(() => {
                setSessionChainId(chainId)
              })
            }
            variant="secondary"
          >
            {t('Disconnect Wallet')}
          </Button>
        )}
      </Grid>
    </Modal>
  )
}
