import { ButtonProps, Flex, IconButton, ArrowDownIcon } from "@pulsex/uikit";
import { styled } from "styled-components";
import { CurrencyInputPanel } from "./CurrencyInputPanel";
import { CurrencyInputHeader, CurrencyInputHeaderTitle } from "./CurrencyInputHeader";
import { SwapPage as Page } from "./Page";
import { SwapFooter as Footer } from "./Footer";
import { SwapInfo as Info, SwapInfoLabel as InfoLabel } from "./SwapInfo";
import { TradePrice } from "./TradePrice";

const SwitchIconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.backgroundDisabled};
  border-radius: 50%;
  width: 24px;
  height: 24px;
`

const RotatingArrow = styled(ArrowDownIcon)`
  background-color: ${({ theme }) => theme.colors.backgroundDisabled};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: transform 0.2s linear;
  transform: none;

  &:hover,
  &:active {
    transform: rotate(180deg);
  }
`

const Circle = styled(Flex)`
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.backgroundDisabled};
  border-radius: 50%;
  width: 24px;
  height: 24px;
`

const Arrow = styled(ArrowDownIcon)`
  background-color: ${({ theme }) => theme.colors.backgroundDisabled};
  width: 20px;
  height: 20px;
  border-radius: 50%;
`

const SwitchButton = (props: ButtonProps) => (
  <SwitchIconButton variant="light" scale="sm" {...props}>
    <RotatingArrow {...props}/>
  </SwitchIconButton>
);

const CircleArrow = (props: ButtonProps) => (
  <Circle {...props}>
    <Arrow {...props}/>
  </Circle>
);

export {
  SwitchButton,
  CircleArrow,
  CurrencyInputHeaderTitle,
  CurrencyInputHeader,
  CurrencyInputPanel,
  Page,
  Footer,
  Info,
  InfoLabel,
  TradePrice,
};
