import { useTranslation } from '@pulsex/localization'
import { Currency, CurrencyAmount } from '@pulsex/sdk'
import {
  AutoColumn,
  Button,
  Flex,
  Message,
  MessageText,
} from '@pulsex/uikit'
import { useIsExpertMode } from '@pulsex/utils/user'
import { Address } from 'viem'
import { CommitButton } from 'components/CommitButton'
import ConnectWalletButton from 'components/ConnectWalletButton'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ApprovalState } from 'hooks/useApproveCallback'
import { Field } from 'state/mint/actions'
import ApproveLiquidityTokens from './ApproveLiquidityTokens'

interface ConfirmAddLiquidityButtonProps {
  approvalA: ApprovalState
  approvalB: ApprovalState
  showFieldAApproval: boolean
  showFieldBApproval: boolean
  addIsUnsupported: boolean
  addIsWarning: boolean
  currencies: {
    [Field.CURRENCY_A]?: Currency
    [Field.CURRENCY_B]?: Currency
  }
  currentAllowanceA: CurrencyAmount<Currency> | undefined
  currentAllowanceB: CurrencyAmount<Currency> | undefined
  shouldShowApprovalGroup: boolean
  buttonDisabled: boolean
  approveACallback: () => Promise<{ hash: Address } | undefined>
  approveBCallback: () => Promise<{ hash: Address } | undefined>
  revokeACallback: () => Promise<{ hash: Address } | undefined>
  revokeBCallback: () => Promise<{ hash: Address } | undefined>
  onAdd: () => Promise<void>
  onPresentAddLiquidityModal: () => void
  isOneWeiAttack?: boolean
  errorText?: string
}

export const ConfirmAddLiquidityButton: React.FC<ConfirmAddLiquidityButtonProps> = ({
  approvalA,
  approvalB,
  showFieldAApproval,
  showFieldBApproval,
  addIsUnsupported,
  addIsWarning,
  currencies,
  currentAllowanceA,
  currentAllowanceB,
  shouldShowApprovalGroup,
  buttonDisabled,
  approveACallback,
  approveBCallback,
  revokeACallback,
  revokeBCallback,
  onAdd,
  onPresentAddLiquidityModal,
  isOneWeiAttack,
  errorText
}) => {
  const { account, isWrongNetwork, chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  const expertMode = useIsExpertMode()

  return (
    <>
      {addIsUnsupported || addIsWarning ? (
        <Button disabled mb="4px">
          {t('Unsupported Asset')}
        </Button>
      ) : !account ? (
        <ConnectWalletButton width="100%" />
      ) : isWrongNetwork ? (
        <CommitButton />
      ) : (
        <AutoColumn gap="md">
          <ApproveLiquidityTokens
            chainId={chainId}
            approvalA={approvalA}
            approvalB={approvalB}
            showFieldAApproval={showFieldAApproval}
            showFieldBApproval={showFieldBApproval}
            approveACallback={approveACallback}
            approveBCallback={approveBCallback}
            revokeACallback={revokeACallback}
            revokeBCallback={revokeBCallback}
            currencies={currencies}
            currentAllowanceA={currentAllowanceA}
            currentAllowanceB={currentAllowanceB}
            shouldShowApprovalGroup={shouldShowApprovalGroup}
          />

          {isOneWeiAttack && (
            <Message variant="warning">
              <Flex flexDirection="column">
                <MessageText>
                  {t(
                    'Adding liquidity to this pair is currently not available due to one wei attack. This pair needs to be manually fixed first by sending some tokens of the missing side and calling sync function on the pair.',
                  )}
                </MessageText>
              </Flex>
            </Message>
          )}
          <CommitButton
            variant={buttonDisabled ? 'danger' : 'primary'}
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              expertMode ? onAdd() : onPresentAddLiquidityModal()
            }}
            disabled={buttonDisabled}
          >
            {errorText || t('Supply')}
          </CommitButton>
        </AutoColumn>
      )}
    </>
  )
}
