import { ChainId } from '@pulsex/chains'

import { SerializedStableSwapPool } from '../../types'
import { pools as plsPools } from './pulsechain'
import { pools as plsTestnetPools } from './pulsechain-testnet'

export type StableSwapPoolMap<TChainId extends number> = {
  [chainId in TChainId]: SerializedStableSwapPool[]
}

export const isStableSwapSupported = (chainId: number | undefined): chainId is StableSupportedChainId => {
  if (!chainId) {
    return false
  }
  return STABLE_SUPPORTED_CHAIN_IDS.includes(chainId)
}

export const STABLE_SUPPORTED_CHAIN_IDS = [ChainId.PULSECHAIN, ChainId.PULSECHAIN_TESTNET] as const

export type StableSupportedChainId = (typeof STABLE_SUPPORTED_CHAIN_IDS)[number]

export const STABLE_POOL_MAP = {
  [ChainId.PULSECHAIN]: plsPools,
  [ChainId.PULSECHAIN_TESTNET]: plsTestnetPools
} satisfies StableSwapPoolMap<StableSupportedChainId>
