/* eslint-disable @typescript-eslint/no-shadow, no-await-in-loop, no-constant-condition, no-console */
import { Currency } from '@pulsex/sdk'
import { Pool } from '@pulsex/smart-router'
import { useMemo, useCallback } from 'react'

import {
  useStableTwoCandidatePools,
  useStableThreeCandidatePools,
  useV1CandidatePools,
  useV2CandidatePools
} from './usePoolsOnChain'

export interface PoolsWithState {
  refresh: () => Promise<unknown>
  pools: Pool[] | undefined
  loading: boolean
  syncing: boolean
  blockNumber?: number
  dataUpdatedAt?: number
}

export interface CommonPoolsParams {
  blockNumber?: number
  allowInconsistentBlock?: boolean
  enabled?: boolean
}

export function commonPoolsHookCreator() {
  return function useCommonPools(
    currencyA?: Currency,
    currencyB?: Currency,
    { blockNumber, allowInconsistentBlock = false, enabled = true }: CommonPoolsParams = {},
  ): PoolsWithState {
    const {
      pools: v1Pools,
      loading: v1Loading,
      syncing: v1Syncing,
      blockNumber: v1BlockNumber,
      refresh: v1Refresh,
      dataUpdatedAt: v1PoolsUpdatedAt,
    } = useV1CandidatePools(currencyA, currencyB, { blockNumber, enabled })
    const {
      pools: v2Pools,
      loading: v2Loading,
      syncing: v2Syncing,
      blockNumber: v2BlockNumber,
      refresh: v2Refresh,
      dataUpdatedAt: v2PoolsUpdatedAt,
    } = useV2CandidatePools(currencyA, currencyB, { blockNumber, enabled })
    const {
      pools: stableTwoPools,
      loading: stableTwoLoading,
      syncing: stableTwoSyncing,
      blockNumber: stableTwoBlockNumber,
      refresh: stableTwoRefresh,
      dataUpdatedAt: stableTwoPoolsUpdatedAt,
    } = useStableTwoCandidatePools(currencyA, currencyB, { blockNumber, enabled })
    const {
      pools: stableThreePools,
      loading: stableThreeLoading,
      syncing: stableThreeSyncing,
      blockNumber: stableThreeBlockNumber,
      refresh: stableThreeRefresh,
      dataUpdatedAt: stableThreePoolsUpdatedAt,
    } = useStableThreeCandidatePools(currencyA, currencyB, { blockNumber, enabled })

    const consistentBlockNumber = useMemo(
      () =>
        v1BlockNumber &&
        v2BlockNumber &&
        stableTwoBlockNumber &&
        stableThreeBlockNumber &&
        v1BlockNumber === stableTwoBlockNumber &&
        v2BlockNumber === stableTwoBlockNumber &&
        v1BlockNumber === stableThreeBlockNumber &&
        v2BlockNumber === stableThreeBlockNumber &&
        v1BlockNumber === v2BlockNumber
          ? v1BlockNumber
          : undefined,
      [v1BlockNumber, v2BlockNumber, stableTwoBlockNumber, stableThreeBlockNumber],
    )
    // FIXME: allow inconsistent block not working as expected
    const poolsData: [Pool[], number] | undefined = useMemo(
      () =>
        (!v1Loading || v1Pools) &&
        (!v2Loading || v2Pools) &&
        (!stableTwoLoading || stableTwoPools) &&
        (!stableThreeLoading || stableThreePools) &&
        (allowInconsistentBlock || !!consistentBlockNumber)
          ? [
              [...(stableThreePools || []), ...(stableTwoPools || []), ...(v2Pools || []), ...(v1Pools || [])],
              Math.max(v1PoolsUpdatedAt || 0, Math.max(v2PoolsUpdatedAt || 0, Math.max(stableTwoPoolsUpdatedAt || 0, stableThreePoolsUpdatedAt))),
            ]
          : undefined,
      [
        v1Loading,
        v1Pools,
        v2Loading,
        v2Pools,
        stableTwoLoading,
        stableTwoPools,
        stableThreeLoading,
        stableThreePools,
        allowInconsistentBlock,
        consistentBlockNumber,
        v1PoolsUpdatedAt,
        v2PoolsUpdatedAt,
        stableTwoPoolsUpdatedAt,
        stableThreePoolsUpdatedAt,
      ],
    )

    const refresh = useCallback(async () => {
      return Promise.all([v1Refresh(), v2Refresh(), stableTwoRefresh(), stableThreeRefresh()])
    }, [v1Refresh, v2Refresh, stableTwoRefresh, stableThreeRefresh])

    const loading = v1Loading || v2Loading || stableTwoLoading || stableThreeLoading
    const syncing = v1Syncing || v2Syncing || stableTwoSyncing || stableThreeSyncing
    return {
      refresh,
      pools: poolsData?.[0],
      blockNumber: consistentBlockNumber,
      loading,
      syncing,
      dataUpdatedAt: poolsData?.[1],
    }
  }
}

export const useCommonPools = commonPoolsHookCreator()