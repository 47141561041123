import { Percent } from '@pulsex/sdk'
import { StableSwapPool, PoolType } from '@pulsex/stable-swap-sdk'
import { useReadContracts } from '@pulsex/wagmi'
import { useMemo } from 'react'
import { stableLPABI } from 'config/abi/stableLP'
import { stableSwapTwoABI } from 'config/abi/stableSwapTwo'
import { stableSwapThreeABI } from 'config/abi/stableSwapThree'

import { useActiveChainId } from './useActiveChainId'

export function useStableSwapInfo(pool?: StableSwapPool) {
  const { chainId } = useActiveChainId()

  const { liquidityToken, stableSwapAddress, type } = pool || {}
  const isThreePool = type === PoolType.THREE_POOL

  const contracts = useMemo(() => {
    if (!pool || !stableSwapAddress || !liquidityToken?.address) return []

    return isThreePool ? [
      {
        chainId,
        abi: stableSwapThreeABI,
        address: stableSwapAddress!,
        functionName: 'balances',
        args: [0n],
      },
      {
        chainId,
        abi: stableSwapThreeABI,
        address: stableSwapAddress!,
        functionName: 'balances',
        args: [1n],
      },
      {
        chainId,
        abi: stableSwapThreeABI,
        address: stableSwapAddress!,
        functionName: 'balances',
        args: [2n],
      },
      {
        chainId,
        abi: stableSwapThreeABI,
        address: stableSwapAddress!,
        functionName: 'A',
      },
      {
        chainId,
        abi: stableLPABI,
        address: liquidityToken.address!,
        functionName: 'totalSupply',
      },
      {
        chainId,
        abi: stableSwapThreeABI,
        address: stableSwapAddress!,
        functionName: 'fee',
      },
      {
        chainId,
        abi: stableSwapThreeABI,
        address: stableSwapAddress!,
        functionName: 'admin_fee',
      },
      {
        chainId,
        abi: stableSwapThreeABI,
        address: stableSwapAddress!,
        functionName: 'FEE_DENOMINATOR',
      },
    ] : [
      {
        chainId,
        abi: stableSwapTwoABI,
        address: stableSwapAddress!,
        functionName: 'balances',
        args: [0n],
      },
      {
        chainId,
        abi: stableSwapTwoABI,
        address: stableSwapAddress!,
        functionName: 'balances',
        args: [1n],
      },
      {
        chainId,
        abi: stableSwapTwoABI,
        address: stableSwapAddress!,
        functionName: 'A',
      },
      {
        chainId,
        abi: stableLPABI,
        address: liquidityToken.address!,
        functionName: 'totalSupply',
      },
      {
        chainId,
        abi: stableSwapTwoABI,
        address: stableSwapAddress!,
        functionName: 'fee',
      },
      {
        chainId,
        abi: stableSwapTwoABI,
        address: stableSwapAddress!,
        functionName: 'admin_fee',
      },
      {
        chainId,
        abi: stableSwapTwoABI,
        address: stableSwapAddress!,
        functionName: 'FEE_DENOMINATOR',
      },
    ]
  }, [chainId, isThreePool, liquidityToken?.address, pool, stableSwapAddress])

  const { data: results, isLoading } = useReadContracts({
    watch: true,
    query: {
      enabled: Boolean(stableSwapAddress && liquidityToken?.address),
    },
    contracts: contracts as any,
  })

  const feeNumerator = isThreePool ? results?.[5]?.result as bigint : results?.[4]?.result as bigint
  const adminFeeNumerator = isThreePool ? results?.[6]?.result as bigint : results?.[5]?.result as bigint
  const feeDenominator = isThreePool ? results?.[7]?.result as bigint : results?.[6]?.result as bigint

  return {
    pool: {
      ...pool,
      poolData: {
        balances: isThreePool ? {
          token0: results?.[0]?.result,
          token1: results?.[1]?.result,
          token2: results?.[2]?.result
        } : {
          token0: results?.[0]?.result,
          token1: results?.[1]?.result
        },
        amplifier: isThreePool ? results?.[3]?.result as bigint : results?.[2]?.result as bigint,
        totalSupply: isThreePool ? results?.[4]?.result as bigint : results?.[3]?.result as bigint,
        fee: feeNumerator && feeDenominator && new Percent(feeNumerator, feeDenominator),
        adminFee: adminFeeNumerator && feeDenominator && new Percent(adminFeeNumerator, feeDenominator),
      },
    } as StableSwapPool,
    loading: isLoading
  }
}
