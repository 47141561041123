import { gql } from 'graphql-request'
import { mapBurns, mapMints, mapSwaps } from 'state/info/queries/helpers'
import { BurnResponse, MintResponse, SwapResponse } from 'state/info/queries/types'
import { Transaction } from 'state/info/types'
import { infoClientWithChain } from 'utils/graphql'

/**
 * Data to display transaction table on Token page
 */
interface TransactionResults {
  mintsAs0: MintResponse[]
  mintsAs1: MintResponse[]
  mintsAs2?: MintResponse[]
  swapsAs0: SwapResponse[]
  swapsAs1: SwapResponse[]
  swapsAs2?: SwapResponse[]
  burnsAs0: BurnResponse[]
  burnsAs1: BurnResponse[]
  burnsAs2?: BurnResponse[]
}

const fetchTokenTransactions = async (chainId: number, protocol: string, address: string): Promise<{ data?: Transaction[]; error: boolean }> => {
  try {
    const query = gql`
      query tokenTransactions($address: String!) {
        mintsAs0: mints(first: 10, orderBy: timestamp, orderDirection: desc, where: { token0: $address }) {
          id
          timestamp
          pair {
            token0 {
              id
              symbol
            }
            token1 {
              id
              symbol
            }
            ${protocol === 'stable' ? `
            token2 {
              id
              symbol
            }
            ` : ''}
          }
          to
          sender
          amount0
          amount1
          ${protocol === 'stable' ? 'amount2' : ''}
          amountUSD
        }
        mintsAs1: mints(first: 10, orderBy: timestamp, orderDirection: desc, where: { token1: $address }) {
          id
          timestamp
          pair {
            token0 {
              id
              symbol
            }
            token1 {
              id
              symbol
            }
            ${protocol === 'stable' ? `
            token2 {
              id
              symbol
            }
            ` : ''}
          }
          to
          sender
          amount0
          amount1
          ${protocol === 'stable' ? 'amount2' : ''}
          amountUSD
        }
        swapsAs0: swaps(first: 10, orderBy: timestamp, orderDirection: desc, where: { token0: $address }) {
          id
          timestamp
          pair {
            token0 {
              id
              symbol
            }
            token1 {
              id
              symbol
            }
            ${protocol === 'stable' ? `
            token2 {
              id
              symbol
            }
            ` : ''}
          }
          from
          amount0In
          amount1In
          amount0Out
          amount1Out
          ${protocol === 'stable' ? `
          amount2In
          amount2Out
          ` : ''}
          amountUSD
        }
        swapsAs1: swaps(first: 10, orderBy: timestamp, orderDirection: desc, where: { token1: $address }) {
          id
          timestamp
          pair {
            token0 {
              id
              symbol
            }
            token1 {
              id
              symbol
            }
            ${protocol === 'stable' ? `
            token2 {
              id
              symbol
            }
            ` : ''}
          }
          from
          amount0In
          amount1In
          amount0Out
          amount1Out
          ${protocol === 'stable' ? `
          amount2In
          amount2Out
          ` : ''}
          amountUSD
        }
        burnsAs0: burns(first: 10, orderBy: timestamp, orderDirection: desc, where: { token0: $address }) {
          id
          timestamp
          pair {
            token0 {
              id
              symbol
            }
            token1 {
              id
              symbol
            }
            ${protocol === 'stable' ? `
            token2 {
              id
              symbol
            }
            ` : ''}
          }
          sender
          amount0
          amount1
          ${protocol === 'stable' ? 'amount2' : ''}
          amountUSD
        }
        burnsAs1: burns(first: 10, orderBy: timestamp, orderDirection: desc, where: { token1: $address }) {
          id
          timestamp
          pair {
            token0 {
              id
              symbol
            }
            token1 {
              id
              symbol
            }
            ${protocol === 'stable' ? `
            token2 {
              id
              symbol
            }
            ` : ''}
          }
          sender
          amount0
          amount1
          ${protocol === 'stable' ? 'amount2' : ''}
          amountUSD
        }
        ${protocol === 'stable' ? `
        mintsAs2: mints(first: 10, orderBy: timestamp, orderDirection: desc, where: { token2: $address }) {
          id
          timestamp
          pair {
            token0 {
              id
              symbol
            }
            token1 {
              id
              symbol
            }
            token2 {
              id
              symbol
            }
          }
          to
          sender
          amount0
          amount1
          amount2
          amountUSD
        }
        swapsAs2: swaps(first: 10, orderBy: timestamp, orderDirection: desc, where: { token2: $address }) {
          id
          timestamp
          pair {
            token0 {
              id
              symbol
            }
            token1 {
              id
              symbol
            }
            token2 {
              id
              symbol
            }
          }
          from
          amount0In
          amount1In
          amount0Out
          amount1Out
          amount2In
          amount2Out
          amountUSD
        }
        burnsAs2: burns(first: 10, orderBy: timestamp, orderDirection: desc, where: { token2: $address }) {
          id
          timestamp
          pair {
            token0 {
              id
              symbol
            }
            token1 {
              id
              symbol
            }
            token2 {
              id
              symbol
            }
          }
          sender
          amount0
          amount1
          amount2
          amountUSD
        }
        ` : ''}
      }
    `
    const data = await infoClientWithChain(chainId, protocol).request<TransactionResults>(query, {
      address,
    })
    const mints0 = data.mintsAs0.map(mapMints)
    const mints1 = data.mintsAs1.map(mapMints)
    const mints2 = data.mintsAs2 ? data.mintsAs2.map(mapMints) : []

    const burns0 = data.burnsAs0.map(mapBurns)
    const burns1 = data.burnsAs1.map(mapBurns)
    const burns2 = data.burnsAs2 ? data.burnsAs2.map(mapBurns) : []

    const swaps0 = data.swapsAs0.map(mapSwaps)
    const swaps1 = data.swapsAs1.map(mapSwaps)
    const swaps2 = data.swapsAs2 ? data.swapsAs2.map(mapSwaps) : []

    return { data: [...mints0, ...mints1, ...burns0, ...burns1, ...swaps0, ...swaps1, ...mints2, ...burns2, ...swaps2], error: false }
  } catch (error) {
    console.error(`Failed to fetch transactions for token ${address}`, error)
    return {
      error: true,
    }
  }
}

export default fetchTokenTransactions
