import { plsTestnetTokens } from '@pulsex/tokens'
import { getAddress } from 'viem'

import { PoolCategory, SerializedPool } from '../../types'

export const livePools: SerializedPool[] = [
  // {
  //   sousId: 0,
  //   stakingToken: bscTestnetTokens.cake2,
  //   earningToken: bscTestnetTokens.cake2,
  //   contractAddress: '0x4c650FB471fe4e0f476fD3437C3411B1122c4e3B',
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.01',
  //   isFinished: false,
  // },
]
// .map((p) => ({
//   ...p,
//   contractAddress: getAddress(p.contractAddress, 97),
//   stakingToken: p.stakingToken.serialize,
//   earningToken: p.earningToken.serialize,
// }))

// known finished pools
export const finishedPools: SerializedPool[] = []

export const pools: SerializedPool[] = [...livePools, ...finishedPools]
