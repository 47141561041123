import { useCallback } from 'react'
import { MaxUint256 } from '@pulsex/swap-sdk-core'
import { getMasterChefAddress } from 'utils/addressHelpers'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { useERC20 } from 'hooks/useContract'

const useApproveFarm = (lpContract: ReturnType<typeof useERC20>, chainId: number) => {
  const contractAddress = getMasterChefAddress(chainId)

  const { callWithGasPrice } = useCallWithGasPrice()
  const handleApprove = useCallback(async () => {
    return callWithGasPrice(lpContract, 'approve', [contractAddress, MaxUint256])
  }, [lpContract, contractAddress, callWithGasPrice])

  return { onApprove: handleApprove }
}

export default useApproveFarm
