import React, { useState, useMemo, useEffect } from 'react'
import { Text, Flex, Box, ArrowBackIcon, ArrowForwardIcon, Skeleton, QuestionHelper } from '@pulsex/uikit'
import { MultiCurrencyLogo } from '@pulsex/widgets-internal'
import { useTranslation } from '@pulsex/localization'
import { formatAmount } from 'utils/formatInfoNumbers'
import { BuyAndBurnLpResponse } from 'state/buyandburn/types'
import { ENABLE_STABLESWAP } from 'config'
import {
  TableWrapper,
  PageButtons,
  Arrow,
  Break,
  ResponsiveGrid,
  StyledCheckbox,
  TableLoader
} from './shared'

const ITEMS_PER_PAGE = 10

interface DataRowProps {
  lp: BuyAndBurnLpResponse
  handleCheck: (event: any) => void
  calculateUSD: (event: any, event2: any) => void
  reset?: boolean
  handleReset: () => void
  isLoading?: boolean
}

const DataRow: React.FC<DataRowProps> = ({
  lp,
  handleCheck,
  calculateUSD,
  reset,
  handleReset,
  isLoading
}) => {
  const { t } = useTranslation()
  const [isChecked, setIsChecked] = useState(false)

  function click(e) {
    setIsChecked(!isChecked)
    handleCheck(e)
    calculateUSD(e, lp.totalValue)
  }

  useEffect(() => {
    if (reset) {
      setIsChecked(false)
      handleReset()
    }
  }, [reset, handleReset])

  const showStableBonus = lp.stableBalances.token0?.greaterThan(0) || lp.stableBalances.token1?.greaterThan(0)
  const bothAreStable = lp.stableBalances.token0 && lp.stableBalances.token1;
  const stableBonusText = t(
    bothAreStable
      ? `Burning this LP burns extra ${lp.stableBalances.token0.toSignificant(4)} ${lp.pair.token0.symbol} and ${lp.stableBalances.token1.toSignificant(4)} ${lp.pair.token1.symbol}`
      : `Burning this LP burns extra ${lp.stableBalances.token0 ? `${lp.stableBalances.token0.toSignificant(4)} ${lp.pair.token0.symbol}` : ''} ${lp.stableBalances.token1 ? `${lp.stableBalances.token1.toSignificant(4)} ${lp.pair.token1.symbol}` : ''}`
  )

  return (
    <ResponsiveGrid>
      <StyledCheckbox scale='sm' checked={isChecked} value={[lp.pair.token0.address, lp.pair.token1.address]} onChange={(e) => click(e)} />
      <Flex alignItems="center">
        <MultiCurrencyLogo currency0={lp.pair.token0} currency1={lp.pair.token1} overlap="0px"/>
        <Text ml="8px">
          {lp.pair.token0.symbol}/{lp.pair.token1.symbol}
        </Text>
      </Flex>
      {isLoading ? <Skeleton /> :
        <Flex flexDirection="column">
          <Text>{lp.totalValue < 0.01 ? '<$0.01' : `$${formatAmount(lp.totalValue)}`}</Text>
          {ENABLE_STABLESWAP && showStableBonus && (lp.stableBalances.token0 || lp.stableBalances.token1) && (
            <Flex alignItems="center" flexDirection="row">
              <Text fontSize="10px" color="primary">Stable balance</Text>
              <QuestionHelper
                color="primary"
                text={stableBonusText}
                placement="top"
                ml="4px"
              />
            </Flex>
          )}
        </Flex>
      }
      {isLoading ? <Skeleton /> : <Text>{formatAmount(parseFloat(lp.token0Amount.toExact()))}</Text>}
      {isLoading ? <Skeleton /> : <Text>{formatAmount(parseFloat(lp.token1Amount.toExact()))}</Text>}
      {isLoading ? <Skeleton /> : <Text>{formatAmount(parseFloat(lp.lpBalance.toExact()))}</Text>}
    </ResponsiveGrid>
  )
}

interface PoolTableProps {
  lpData: BuyAndBurnLpResponse[]
  isLoading: boolean
  handleCheck: (event: any) => void
  calculateUSD: (event: any, event2: any) => void
  reset?: boolean
  handleReset: () => void
}

const PoolTable: React.FC<PoolTableProps> = ({
  lpData,
  isLoading,
  handleCheck,
  calculateUSD,
  reset,
  handleReset
}) => {
  const { t } = useTranslation()

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  useEffect(() => {
    let extraPages = 1
    if (lpData && lpData.length % ITEMS_PER_PAGE === 0) {
      extraPages = 0
    }
    setMaxPage(Math.floor(lpData?.length / ITEMS_PER_PAGE) + extraPages)
  }, [lpData])

  const sortedLps = useMemo(() => {
    return lpData
      .sort((a, b) => b.totalValue - a.totalValue)  // Sort by USD value in descending order
      .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)
  }, [page, lpData])

  return (
    <TableWrapper>
      <ResponsiveGrid>
        <Text color="secondary" fontSize="12px" bold />
        <Text color="secondary" fontSize="12px" bold textTransform="uppercase">
          {t('LPs')}
        </Text>
        <Text
          color="secondary"
          fontSize="12px"
          bold
          textTransform="uppercase"
        >
          {t('USD value')}
        </Text>
        <Text
          color="secondary"
          fontSize="12px"
          bold
          textTransform="uppercase"
        >
          {t('Token0 amount')}
        </Text>
        <Text
          color="secondary"
          fontSize="12px"
          bold
          textTransform="uppercase"
        >
          {t('Token1 amount')}
        </Text>
        <Text
          color="secondary"
          fontSize="12px"
          bold
          textTransform="uppercase"
        >
          {t('LP amount')}
        </Text>
      </ResponsiveGrid>
      <Break />
      {sortedLps.length > 0 ? (
        <>
          {sortedLps.map((lp) => {
            return (
              <React.Fragment key={lp.pair.liquidityToken.address}>
                <DataRow
                  lp={lp}
                  handleCheck={handleCheck}
                  calculateUSD={calculateUSD}
                  reset={reset}
                  handleReset={handleReset}
                  isLoading={isLoading}
                />
                <Break />
              </React.Fragment>
            )
          })}
          <PageButtons>
            <Arrow
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <ArrowBackIcon color={page === 1 ? 'textDisabled' : 'primary'} />
            </Arrow>

            <Text>{t('Page %page% of %maxPage%', { page, maxPage })}</Text>

            <Arrow
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <ArrowForwardIcon color={page === maxPage ? 'textDisabled' : 'primary'} />
            </Arrow>
          </PageButtons>
        </>
      ) : (
        <>
          <TableLoader />
          <Box />
        </>
      )}
    </TableWrapper>
  )
}

export default PoolTable
