import { TOKEN_BLACKLIST } from 'config/constants/info'
import { gql } from 'graphql-request'
import { useEffect, useState } from 'react'
import { infoClientWithChain } from 'utils/graphql'
import { getDeltaTimestamps } from 'utils/getDeltaTimestamps'

interface TopTokensResponse {
  tokenDayDatas: {
    id: string
  }[]
}

/**
 * Tokens to display on Home page
 * The actual data is later requested in tokenData.ts
 * Note: dailyTxns_gt: 300 is there to prevent fetching incorrectly priced tokens with high dailyVolumeUSD
 */
const fetchTopTokens = async (chainId: number, protocol: string, timestamp24hAgo: number): Promise<string[]> => {
  try {
    const query = gql`
      query topTokens($blacklist: [String!], $timestamp24hAgo: Int) {
        tokenDayDatas(
          first: 30
          where: { ${protocol === 'stable' ? '' : 'dailyTxns_gt: 300'}, id_not_in: $blacklist, date_gt: $timestamp24hAgo }
          orderBy: dailyVolumeUSD
          orderDirection: desc
        ) {
          id
        }
      }
    `
    const data = await infoClientWithChain(chainId, protocol).request<TopTokensResponse>(query, { blacklist: TOKEN_BLACKLIST, timestamp24hAgo })
    // tokenDayDatas id has compound id "0xTOKENADDRESS-NUMBERS", extracting token address with .split('-')
    return data.tokenDayDatas.map((t) => t.id.split('-')[0])
  } catch (error) {
    console.error('Failed to fetch top tokens', error)
    return []
  }
}

/**
 * Fetch top addresses by volume
 */
const useTopTokenAddresses = (chainId: number, protocol: string): string[] => {
  const [topTokenAddresses, setTopTokenAddresses] = useState<string[]>([])
  const [timestamp24hAgo] = getDeltaTimestamps()

  useEffect(() => {
    const fetch = async () => {
      const addresses = await fetchTopTokens(chainId, protocol, timestamp24hAgo)
      if (addresses && addresses.length > 0) {
        setTopTokenAddresses(addresses)
      }
    }
    if (topTokenAddresses.length === 0) {
      fetch()
    }
  }, [chainId, topTokenAddresses, timestamp24hAgo, protocol])

  return topTokenAddresses
}

export const fetchTokenAddresses = async (chainId: number, protocol: string) => {
  const [timestamp24hAgo] = getDeltaTimestamps()

  const addresses = await fetchTopTokens(chainId, protocol, timestamp24hAgo)

  return addresses
}

export default useTopTokenAddresses
