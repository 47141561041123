import { Checkbox, Flex, Text } from '@pulsex/uikit'
import { useTranslation } from '@pulsex/localization'
import { memo } from 'react'

import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import { useSwapState } from 'state/swap/hooks'

import AddressInputPanel from '../components/AddressInputPanel'
import { useAllowRecipient } from '../hooks'

interface RecipientProps {
  asset?: string
}

export const Recipient: React.FC<RecipientProps> = memo(({ asset }) => {
  const { t } = useTranslation()
  const { recipient } = useSwapState()
  const { onChangeRecipient } = useSwapActionHandlers()
  const allowRecipient = useAllowRecipient()

  if (!allowRecipient) {
    return null
  }

  const openDetails = () => {
    if (recipient === null) {
      onChangeRecipient('')
    } else {
      onChangeRecipient(null)
    }
  }

  return (
    <>
      <Flex alignItems="center">
        <Checkbox checked={recipient !== null} scale='sm' onClick={() => openDetails()}/>
        <Text color="textSubtleDark" pl={1}>{t('Receive %asset% to another wallet', { asset })}</Text>
      </Flex>

      {recipient !== null && (
        <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
      )}
    </>
  )
})
