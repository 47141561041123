import { useTranslation } from '@pulsex/localization'
import { CurrencyAmount, Percent, Token } from '@pulsex/sdk'
import { Flex, Text, AddIcon, CardBody, Message, MouseoverTooltip, useMatchBreakpoints, useModal } from '@pulsex/uikit'
import { useIsExpertMode, useUserSlippage } from '@pulsex/utils/user'
import { STABLE_POOL_MAP } from '@pulsex/stable-swap-sdk'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ONE_BIPS, ONE_HUNDRED_PERCENT } from 'config/constants/exchange'
import { LightGreyCard } from 'components/Card'
import { AutoColumn, ColumnCenter } from 'components/Layout/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { StableSwapMinimalPositionCard } from 'components/PositionCard/StableSwapMinimalPositionCard'
import { AppHeader, AppBody } from 'components/App'
import useAccountActiveChain from 'hooks/useAccountActiveChain'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useStableSwapTwoNativeHelperContract, useStableSwapThreeNativeHelperContract } from 'hooks/useContract'
import { useCurrency } from 'hooks/Tokens'
import { Field } from 'state/stableswap/actions'
import {
  useMintActionHandlers,
  useStableConfig,
  useStableLPDerivedMintInfo,
  useDerivedLPInfo
} from 'state/stableswap/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useFeeDataWithGasPrice } from 'state/user/hooks'
import { calculateGasMargin } from 'utils'
import { calculateSlippageAmount } from 'utils/exchange'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { isUserRejected } from 'utils/sentry'
import { transactionErrorToUserReadableMessage } from 'utils/transactionErrorToUserReadableMessage'
import ConfirmAddLiquidityModal from './components/ConfirmAddLiquidityModal'
import { ConfirmAddLiquidityButton } from './components/ConfirmAddLiquidityButton'
import { warningSeverity } from './utils/slippage'
import Page from '../../Page'

export default function AddStableLiquidity() {
  const { account, chainId } = useAccountActiveChain()
  const { isMobile } = useMatchBreakpoints()
  const { poolAddress = STABLE_POOL_MAP[chainId][0]?.stableSwapAddress } = useParams<{ poolAddress?: string }>()

  const {
    stableSwapContract,
    pool,
    stableSwapTwoInfoContract,
    stableSwapThreeInfoContract,
    totalSupply,
    userLpBalance,
    userTokenAmounts,
    poolPrices,
    isThreePool
  } = useStableConfig({
    chainId,
    poolAddress,
    account
  })

  const currencyA = useCurrency(pool?.token0?.address)?.wrapped
  const currencyB = useCurrency(pool?.token1?.address)?.wrapped
  const currencyC = useCurrency(isThreePool && pool?.token2 ? pool?.token2.address : undefined)?.wrapped

  const expertMode = useIsExpertMode()

  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()
  const { maxFeePerGas, maxPriorityFeePerGas } = useFeeDataWithGasPrice()

  // mint state
  const {
    currencies,
    parsedAmounts,
    currencyBalances,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
    addError,
  } = useStableLPDerivedMintInfo(
    pool,
    currencyA || undefined,
    currencyB || undefined,
    currencyC || undefined,
    stableSwapContract,
    stableSwapTwoInfoContract,
    stableSwapThreeInfoContract
  )

  // get formatted amounts
  const formattedAmounts = useMemo(
    () => ({
      [Field.CURRENCY_A]: parsedAmounts[Field.CURRENCY_A]?.equalTo(0) ? '' : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6),
      [Field.CURRENCY_B]: parsedAmounts[Field.CURRENCY_B]?.equalTo(0) ? '' : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6),
      [Field.CURRENCY_C]: parsedAmounts[Field.CURRENCY_C]?.equalTo(0) ? '' : parsedAmounts[Field.CURRENCY_C]?.toSignificant(6),
    }),
    [parsedAmounts]
  )

  const { onToken0Input, onToken1Input, onToken2Input } = useMintActionHandlers()

  // modal and loading
  const [{ attemptingTxn, liquidityErrorMessage, txHash }, setLiquidityState] = useState<{
    attemptingTxn: boolean
    liquidityErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    attemptingTxn: false,
    liquidityErrorMessage: undefined,
    txHash: undefined,
  })

  // txn values
  const [allowedSlippage] = useUserSlippage() // custom from users
  const { lpOutputWithoutFee: expectedOutputWithoutFee } = useDerivedLPInfo(
    pool,
    parsedAmounts[Field.CURRENCY_A],
    parsedAmounts[Field.CURRENCY_B],
    parsedAmounts[Field.CURRENCY_C]
  )
  const minLPOutput = useMemo(
    () => expectedOutputWithoutFee && calculateSlippageAmount(expectedOutputWithoutFee, allowedSlippage)[0],
    [expectedOutputWithoutFee, allowedSlippage],
  )
  const executionSlippage = useMemo(() => {
    if (!liquidityMinted || !expectedOutputWithoutFee) {
      return undefined
    }
    return ONE_HUNDRED_PERCENT.subtract(new Percent(liquidityMinted.quotient, expectedOutputWithoutFee.quotient))
  }, [liquidityMinted, expectedOutputWithoutFee])

  const slippageSeverity = executionSlippage && warningSeverity(executionSlippage)

  const maxAmounts: { [field in Field]?: CurrencyAmount<Token> } = [Field.CURRENCY_A, Field.CURRENCY_B, Field.CURRENCY_C].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {},
  )

  const handlePercentInputA = useCallback(
    (percent: number) => {
      if (maxAmounts[Field.CURRENCY_A]) {
        onToken0Input(maxAmounts[Field.CURRENCY_A].multiply(new Percent(percent, 100)).toExact())
      }
    },
    // eslint-disable-next-line
    [maxAmounts[Field.CURRENCY_A], onToken0Input, maxAmounts],
  )

  const handlePercentInputB = useCallback(
    (percent: number) => {
      if (maxAmounts[Field.CURRENCY_B]) {
        onToken1Input(maxAmounts[Field.CURRENCY_B].multiply(new Percent(percent, 100)).toExact())
      }
    },
    // eslint-disable-next-line
    [maxAmounts[Field.CURRENCY_B], onToken1Input, maxAmounts],
  )

  const handlePercentInputC = useCallback(
    (percent: number) => {
      if (maxAmounts[Field.CURRENCY_C]) {
        onToken2Input(maxAmounts[Field.CURRENCY_C].multiply(new Percent(percent, 100)).toExact())
      }
    },
    // eslint-disable-next-line
    [maxAmounts[Field.CURRENCY_B], onToken2Input, maxAmounts],
  )

  const atMaxAmounts: { [field in Field]?: CurrencyAmount<Token> } =
    [Field.CURRENCY_A, Field.CURRENCY_B, Field.CURRENCY_C].reduce(
      (accumulator, field) => {
        return {
          ...accumulator,
          [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
        }
      },
      {},
    )

  const twoNativeHelperContract = useStableSwapTwoNativeHelperContract()
  const threeNativeHelperContract = useStableSwapThreeNativeHelperContract()

  const needWrapped = currencyA?.isNative || currencyB?.isNative || currencyC?.isNative

  // check whether the user has approved tokens for addling LPs
  const {
    approvalState: approvalA,
    approveCallback: approveACallback,
    revokeCallback: revokeACallback,
    currentAllowance: currentAllowanceA,
  } = useApproveCallback(
    parsedAmounts[Field.CURRENCY_A],
    needWrapped && isThreePool ?
      threeNativeHelperContract?.address : needWrapped && !isThreePool ?
        twoNativeHelperContract?.address : stableSwapContract?.address
  )
  const {
    approvalState: approvalB,
    approveCallback: approveBCallback,
    revokeCallback: revokeBCallback,
    currentAllowance: currentAllowanceB,
  } = useApproveCallback(
    parsedAmounts[Field.CURRENCY_B],
    needWrapped && isThreePool ?
      threeNativeHelperContract?.address : needWrapped && !isThreePool ?
        twoNativeHelperContract?.address : stableSwapContract?.address
  )
  const {
    approvalState: approvalC,
    approveCallback: approveCCallback,
    revokeCallback: revokeCCallback,
    currentAllowance: currentAllowanceC,
  } = useApproveCallback(
    parsedAmounts[Field.CURRENCY_C],
    needWrapped && isThreePool ?
      threeNativeHelperContract?.address : needWrapped && !isThreePool ?
        twoNativeHelperContract?.address : stableSwapContract?.address
  )

  const addTransaction = useTransactionAdder()

  async function onAdd() {
    const contract = needWrapped && isThreePool ?
      threeNativeHelperContract : needWrapped && !isThreePool ? twoNativeHelperContract : stableSwapContract

    if (!chainId || !account || !contract) return

    const {
      [Field.CURRENCY_A]: parsedAmountA,
      [Field.CURRENCY_B]: parsedAmountB,
      [Field.CURRENCY_C]: parsedAmountC
    } = parsedAmounts

    const atLeastOneCurrencyProvided =
      parsedAmountA?.greaterThan(0) || parsedAmountB?.greaterThan(0) || parsedAmountC?.greaterThan(0)
    const allCurrenciesProvided =
      parsedAmountA?.greaterThan(0) && parsedAmountB?.greaterThan(0) && (isThreePool ? parsedAmountC?.greaterThan(0) : true)

    if (noLiquidity ? !allCurrenciesProvided : !atLeastOneCurrencyProvided) {
      return
    }

    const lpMintedSlippage =
      liquidityMinted && calculateSlippageAmount(liquidityMinted, noLiquidity ? 0 : allowedSlippage)[0]

    const quotientA = parsedAmountA?.quotient || 0n
    const quotientB = parsedAmountB?.quotient || 0n
    const quotientC = parsedAmountC?.quotient || 0n

    const tokenAmountsTwoPool = [quotientA, quotientB] as const
    const tokenAmountsThreePool = [quotientA, quotientB, quotientC] as const

    let args_

    let value_: bigint | undefined
    let call: Promise<`0x${string}`>

    // use native helper contract
    if (needWrapped) {
      if (!stableSwapContract) return

      if (isThreePool) {
        const args = [stableSwapContract.address, tokenAmountsThreePool, (minLPOutput || lpMintedSlippage)!] as const
        args_ = args
        const value = (currencyC?.isNative ? parsedAmountC : currencyB?.isNative ? parsedAmountB : parsedAmountA)?.quotient
        value_ = value
        call = threeNativeHelperContract.estimateGas
          .add_liquidity(
            args,
            {
              value,
              account: contract.account!,
            },
          )
          .then((estimatedGasLimit) => {
            return threeNativeHelperContract.write.add_liquidity(args, {
              gas: calculateGasMargin(estimatedGasLimit),
              maxFeePerGas,
              maxPriorityFeePerGas,
              value,
              account: contract.account!,
              chain: contract.chain,
            })
          })
      } else {
        const args = [stableSwapContract.address, tokenAmountsTwoPool, (minLPOutput || lpMintedSlippage)!] as const
        args_ = args
        const value = (currencyB?.isNative ? parsedAmountB : parsedAmountA)?.quotient
        value_ = value
        call = twoNativeHelperContract.estimateGas
          .add_liquidity(
            args,
            {
              value,
              account: contract.account!,
            },
          )
          .then((estimatedGasLimit) => {
            return twoNativeHelperContract.write.add_liquidity(args, {
              gas: calculateGasMargin(estimatedGasLimit),
              maxFeePerGas,
              maxPriorityFeePerGas,
              value,
              account: contract.account!,
              chain: contract.chain,
            })
          })
      }
    } else {
      if (!stableSwapContract || !contract.account) return

      const contractAccount = contract.account

      if (isThreePool) {
        const args = [tokenAmountsThreePool, (minLPOutput || lpMintedSlippage)!] as const
        args_ = args

        call = stableSwapContract.estimateGas
          .add_liquidity(args, {
            account: contractAccount!,
          })
          .then((estimatedGasLimit) => {
            return stableSwapContract.write.add_liquidity(args, {
              gas: calculateGasMargin(estimatedGasLimit),
              maxFeePerGas,
              maxPriorityFeePerGas,
              account: contractAccount,
              chain: contract.chain,
            })
          })
      } else {
        const args = [tokenAmountsTwoPool, (minLPOutput || lpMintedSlippage)!] as const
        args_ = args

        call = stableSwapContract.estimateGas
          .add_liquidity(args, {
            account: contract.account!,
          })
          .then((estimatedGasLimit) => {
            return stableSwapContract.write.add_liquidity(args, {
              gas: calculateGasMargin(estimatedGasLimit),
              maxFeePerGas,
              maxPriorityFeePerGas,
              account: contractAccount,
              chain: contract.chain,
            })
          })
      }
    }

    setLiquidityState({ attemptingTxn: true, liquidityErrorMessage: undefined, txHash: undefined })
    await call
      .then((response) => {
        setLiquidityState({ attemptingTxn: false, liquidityErrorMessage: undefined, txHash: response })

        const symbolA = currencyA?.symbol
        const amountA = parsedAmounts[Field.CURRENCY_A]?.toSignificant(3) || '0'
        const symbolB = currencyB?.symbol
        const amountB = parsedAmounts[Field.CURRENCY_B]?.toSignificant(3) || '0'
        const symbolC = currencyC?.symbol
        const amountC = parsedAmounts[Field.CURRENCY_C]?.toSignificant(3) || '0'
        addTransaction(
          { hash: response },
          {
            summary: isThreePool ? `Add ${amountA} ${symbolA} and ${amountB} ${symbolB} and ${amountC} ${symbolC}` :
              `Add ${amountA} ${symbolA} and ${amountB} ${symbolB}`,
            translatableSummary: {
              text: isThreePool ? 'Add %amountA% %symbolA% and %amountB% %symbolB% and %amountC% %symbolC%' :
                'Add %amountA% %symbolA% and %amountB% %symbolB%',
              data: isThreePool ? { amountA, symbolA, amountB, symbolB, amountC, symbolC } :
                { amountA, symbolA, amountB, symbolB },
            },
            type: 'add-liquidity',
          },
        )
      })
      .catch((err) => {
        if (err && !isUserRejected(err)) {
          console.error(`Add Liquidity failed`, err, args_, value_)
        }
        setLiquidityState({
          attemptingTxn: false,
          liquidityErrorMessage:
            err && !isUserRejected(err)
              ? t('Add liquidity failed: %message%', { message: transactionErrorToUserReadableMessage(err, t) })
              : undefined,
          txHash: undefined,
        })
      })
  }

  const pendingText = isThreePool ? t('Supplying %amountA% %symbolA% and %amountB% %symbolB% and %amountC% %symbolC%', {
    amountA: formatCurrencyAmount(parsedAmounts[Field.CURRENCY_A], 4, locale),
    symbolA: currencyA?.symbol ?? '',
    amountB: formatCurrencyAmount(parsedAmounts[Field.CURRENCY_B], 4, locale),
    symbolB: currencyB?.symbol ?? '',
    amountC: formatCurrencyAmount(parsedAmounts[Field.CURRENCY_C], 4, locale),
    symbolC: currencyC?.symbol ?? '',
  }) :
    t('Supplying %amountA% %symbolA% and %amountB% %symbolB%', {
      amountA: formatCurrencyAmount(parsedAmounts[Field.CURRENCY_A], 4, locale),
      symbolA: currencyA?.symbol ?? '',
      amountB: formatCurrencyAmount(parsedAmounts[Field.CURRENCY_B], 4, locale),
      symbolB: currencyB?.symbol ?? '',
    })

  const handleDismissConfirmation = useCallback(() => {
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onToken0Input('')
      onToken1Input('')
      onToken2Input('')
    }

    setLiquidityState({
      attemptingTxn: false,
      liquidityErrorMessage: undefined,
      txHash: undefined,
    })
  }, [onToken0Input, onToken1Input, onToken2Input, txHash])

  const [onPresentAddLiquidityModal] = useModal(
    <ConfirmAddLiquidityModal
      title={noLiquidity ? t('Setting up a stable pool') : t('Adding Stable liquidity')}
      customOnDismiss={handleDismissConfirmation}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      pendingText={pendingText}
      currencyToAdd={pool?.liquidityToken}
      allowedSlippage={allowedSlippage}
      onAdd={onAdd}
      parsedAmounts={parsedAmounts}
      currencyA={currencyA}
      currencyB={currencyB}
      currencyC={currencyC}
      liquidityErrorMessage={liquidityErrorMessage}
      poolPrices={poolPrices}
      noLiquidity={noLiquidity}
      poolTokenPercentage={poolTokenPercentage}
      liquidityMinted={liquidityMinted}
    />,
    true,
    true,
    'addLiquidityModal',
  )

  let isValid = !error
  let errorText = error

  isValid = !error && !addError
  errorText = error ?? addError

  const {
    [Field.CURRENCY_A]: parsedAmountA,
    [Field.CURRENCY_B]: parsedAmountB,
    [Field.CURRENCY_C]: parsedAmountC
  } = parsedAmounts

  const notApprovalYet =
    (parsedAmountA?.greaterThan(0) && approvalA !== ApprovalState.APPROVED) ||
    (parsedAmountB?.greaterThan(0) && approvalB !== ApprovalState.APPROVED) ||
    (parsedAmountC?.greaterThan(0) && approvalC !== ApprovalState.APPROVED)

  const buttonDisabled = !isValid || notApprovalYet || (!!slippageSeverity && slippageSeverity > 2 && !expertMode)

  const showFieldAApproval = approvalA === ApprovalState.NOT_APPROVED || approvalA === ApprovalState.PENDING
  const showFieldBApproval = approvalB === ApprovalState.NOT_APPROVED || approvalB === ApprovalState.PENDING
  const showFieldCApproval = approvalC === ApprovalState.NOT_APPROVED || approvalC === ApprovalState.PENDING

  const shouldShowApprovalGroup = (showFieldAApproval || showFieldBApproval || showFieldCApproval) && isValid

  return (
    <Page>
      <AppBody>
        <AppHeader
          title={t('Add Liquidity')}
          subtitle={t('Add liquidity to receive LP tokens')}
          helper={t(`By providing liquidity in stable pools, you'll earn trading fees, with rates varying across different pools. For detailed fee information, refer to the info section of each respective pool.`)}
          backTo="/liquidity"
        />
        <CardBody>
          <AutoColumn gap="10px">
            {noLiquidity && (
              <ColumnCenter>
                <Message variant="warning">
                  <div>
                    <Text bold mb="8px">
                      {t('You are setting up a stable pool.')}
                    </Text>
                    <Text mb="8px">{t('It is required to provide all tokens of the pool in order to initialize liquidity.')}</Text>
                  </div>
                </Message>
              </ColumnCenter>
            )}
            <CurrencyInputPanel
              disableCurrencySelect
              value={formattedAmounts[Field.CURRENCY_A] || ''}
              onUserInput={onToken0Input}
              onPercentInput={handlePercentInputA}
              maxAmount={maxAmounts[Field.CURRENCY_A]}
              onMax={() => {
                onToken0Input(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
              }}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
              showQuickInputButton
              currency={currencies[Field.CURRENCY_A]}
              id="add-liquidity-input-tokena"
              label={t("You add")}
              showCommonBases
              showUSDPrice
            />
            <ColumnCenter>
              <AddIcon width="16px" />
            </ColumnCenter>
            <CurrencyInputPanel
              disableCurrencySelect
              value={formattedAmounts[Field.CURRENCY_B] || ''}
              onUserInput={onToken1Input}
              onPercentInput={handlePercentInputB}
              maxAmount={maxAmounts[Field.CURRENCY_B]}
              onMax={() => {
                onToken1Input(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
              }}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
              showQuickInputButton
              currency={currencies[Field.CURRENCY_B]}
              id="add-liquidity-input-tokenb"
              label={t("You add")}
              showCommonBases
              showUSDPrice
            />
            {isThreePool && (
              <>
                <ColumnCenter>
                  <AddIcon width="16px" />
                </ColumnCenter>
                <CurrencyInputPanel
                  disableCurrencySelect
                  value={formattedAmounts[Field.CURRENCY_C] || ''}
                  onUserInput={onToken2Input}
                  onPercentInput={handlePercentInputC}
                  maxAmount={maxAmounts[Field.CURRENCY_C]}
                  onMax={() => {
                    onToken2Input(maxAmounts[Field.CURRENCY_C]?.toExact() ?? '')
                  }}
                  showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                  showQuickInputButton
                  currency={currencies[Field.CURRENCY_C]}
                  id="add-liquidity-input-tokenc"
                  label={t("You add")}
                  showCommonBases
                  showUSDPrice
                />
              </>
            )}

            {pool && poolPrices && (
              <AutoColumn gap="12px" style={{ marginTop: '16px' }}>
                <LightGreyCard>
                  <Flex justifyContent="space-between" mb={1}>
                    <Text>
                      {t('Share of Pool')}
                    </Text>
                    <Text>
                      {noLiquidity && poolPrices
                        ? '100'
                        : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
                      %
                    </Text>
                  </Flex>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text>
                      Prices
                    </Text>
                    <MouseoverTooltip
                      isMobile={isMobile}
                      text={
                        <>
                          <Text fontSize="12px" color="textSubtleDark">
                            {t(`1 ${currencyA?.symbol} = ${poolPrices.token0[0].toSignificant(4)} ${currencyB?.symbol}`)}
                            {poolPrices?.token2 && t(` = ${poolPrices.token0[1].toSignificant(4)} ${currencyC?.symbol}`)}
                          </Text>
                          <Text fontSize="12px" color="textSubtleDark">
                            {t(`1 ${currencyB?.symbol} = ${poolPrices.token1[0].toSignificant(4)} ${currencyA?.symbol}`)}
                            {poolPrices?.token2 && t(` = ${poolPrices.token1[1].toSignificant(4)} ${currencyC?.symbol}`)}
                          </Text>
                          {poolPrices?.token2 && (
                            <Text fontSize="12px" color="textSubtleDark">
                              {t(`1 ${currencyC?.symbol} = ${poolPrices.token2[0].toSignificant(4)} ${currencyA?.symbol} = ${poolPrices.token2[1].toSignificant(4)} ${currencyB?.symbol}`)}
                            </Text>
                          )}
                        </>
                      }
                      placement='top'
                    >
                      <Text fontSize="12px" color="textSubtleDark">
                        {`1 ${currencyA?.symbol} = `} {poolPrices.token0[0].toSignificant(4)} {currencyB?.symbol} {poolPrices?.token2 && `= ${poolPrices.token2[1].toSignificant(4)} ${currencyC?.symbol}`}
                      </Text>
                    </MouseoverTooltip>
                  </Flex>
                </LightGreyCard>
              </AutoColumn>
            )}

            <ConfirmAddLiquidityButton
              approvalA={approvalA}
              approvalB={approvalB}
              approvalC={approvalC}
              showFieldAApproval={showFieldAApproval}
              showFieldBApproval={showFieldBApproval}
              showFieldCApproval={showFieldCApproval}
              currencyA={currencyA}
              currencyB={currencyB}
              currencyC={currencyC}
              currentAllowanceA={currentAllowanceA}
              currentAllowanceB={currentAllowanceB}
              currentAllowanceC={currentAllowanceC}
              shouldShowApprovalGroup={shouldShowApprovalGroup}
              buttonDisabled={buttonDisabled}
              approveACallback={approveACallback}
              approveBCallback={approveBCallback}
              approveCCallback={approveCCallback}
              revokeACallback={revokeACallback}
              revokeBCallback={revokeBCallback}
              revokeCCallback={revokeCCallback}
              onAdd={onAdd}
              onPresentAddLiquidityModal={onPresentAddLiquidityModal}
              errorText={errorText}
            />

          </AutoColumn>
        </CardBody>
      </AppBody>
      {pool && !noLiquidity ? (
        <AutoColumn style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', marginTop: '1rem' }}>
          <StableSwapMinimalPositionCard
            pool={pool}
            isThreePool={isThreePool}
            totalSupply={totalSupply}
            userLpBalance={userLpBalance}
            userTokenAmounts={userTokenAmounts}
          />
        </AutoColumn>
      ) : null}
    </Page>
  )
}
