import { gql } from 'graphql-request'
import { mapBurns, mapMints, mapSwaps } from 'state/info/queries/helpers'
import { BurnResponse, MintResponse, SwapResponse } from 'state/info/queries/types'
import { Transaction } from 'state/info/types'
import { infoClientWithChain } from 'utils/graphql'

/**
 * Transactions for Transaction table on the Home page
 */
interface TransactionResults {
  mints: MintResponse[]
  swaps: SwapResponse[]
  burns: BurnResponse[]
}

const fetchTopTransactions = async (chainId: number, protocol: string): Promise<Transaction[] | undefined> => {
  try {
    const query = gql`
      query overviewTransactions {
        mints: mints(first: 33, orderBy: timestamp, orderDirection: desc) {
          id
          timestamp
          pair {
            token0 {
              id
              symbol
            }
            token1 {
              id
              symbol
            }
            ${protocol === 'stable' ?
            `token2 {
              id
              symbol
            }` : ''}
          }
          to
          sender
          amount0
          amount1
          ${protocol === 'stable' ? 'amount2' : ''}
          amountUSD
        }
        swaps: swaps(first: 33, orderBy: timestamp, orderDirection: desc) {
          id
          timestamp
          pair {
            token0 {
              id
              symbol
            }
            token1 {
              id
              symbol
            }
            ${protocol === 'stable' ?
            `token2 {
              id
              symbol
            }` : ''}
          }
          from
          amount0In
          amount1In
          amount0Out
          amount1Out
          ${protocol === 'stable' ? `
          amount2In
          amount2Out
          ` : ''}
          amountUSD
        }
        burns: burns(first: 33, orderBy: timestamp, orderDirection: desc) {
          id
          timestamp
          pair {
            token0 {
              id
              symbol
            }
            token1 {
              id
              symbol
            }
            ${protocol === 'stable' ?
            `token2 {
              id
              symbol
            }` : ''}
          }
          sender
          amount0
          amount1
          ${protocol === 'stable' ? 'amount2' : ''}
          amountUSD
        }
      }
    `
    const data = await infoClientWithChain(chainId, protocol).request<TransactionResults>(query)

    if (!data) {
      return undefined
    }

    const mints = data.mints.map(mapMints)
    const burns = data.burns.map(mapBurns)
    const swaps = data.swaps.map(mapSwaps)

    return [...mints, ...burns, ...swaps].sort((a, b) => {
      return parseInt(b.timestamp, 10) - parseInt(a.timestamp, 10)
    })
  } catch {
    return undefined
  }
}

export default fetchTopTransactions
