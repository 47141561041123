import styled from 'styled-components'
import { Flex, Heading } from '@pulsex/uikit'
import { FarmWidget, MultiCurrencyLogo } from '@pulsex/widgets-internal'
import { Token } from '@pulsex/sdk'

const { ProtocolTag } = FarmWidget.Tags
export interface ExpandableSectionProps {
  lpLabel?: string
  multiplier?: string
  isCommunityFarm?: boolean
  token: Token
  quoteToken: Token
  pid?: number
  protocol: string
}

const Wrapper = styled(Flex)`
  svg {
    margin-right: 4px;
  }
`

const CardHeading: React.FC<React.PropsWithChildren<ExpandableSectionProps>> = ({
  lpLabel,
  token,
  quoteToken,
  protocol
}) => {
  return (
    <Wrapper justifyContent="space-between" alignItems="center" mb="12px">
      <MultiCurrencyLogo currency0={token} currency1={quoteToken} size={48} overlap="10px" />
      <Flex flexDirection="column" alignItems="flex-end">
        <Heading mb="10px">{lpLabel?.replace(" from Ethereum", "")?.split(' ')?.[0] ?? ''}</Heading>
        <ProtocolTag protocol={protocol} scale="sm" />
      </Flex>
    </Wrapper>
  )
}

export default CardHeading
