import { ReactNode, memo } from "react";
import { AtomBox, Text } from "@pulsex/uikit";

interface Props {
  title: ReactNode;
  subtitle: ReactNode;
}

export const CurrencyInputHeader = memo(({ title }: Props) => {
  return (
    <AtomBox width="100%" alignItems="center" flexDirection="column" paddingX="20px" paddingTop="12px" paddingBottom="8px">
      <AtomBox display="flex" width="100%" alignItems="center" justifyContent="space-between">
        {title}
      </AtomBox>
    </AtomBox>
  );
});

export const CurrencyInputHeaderTitle = memo(({ children }: { children: ReactNode }) => (
  <Text fontSize="17px" bold>{children}</Text>
));
