import { Skeleton, Text } from "@pulsex/uikit";
import { styled } from "styled-components";
import { FarmTableLiquidityProps } from "../../types";

const LiquidityWrapper = styled.div`
  min-width: 110px;
  font-weight: 600;
  text-align: right;
  margin-right: 14px;

  ${({ theme }) => theme.mediaQueries.lg} {
    text-align: left;
    margin-right: 0;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const StakedLiquidity: React.FunctionComponent<React.PropsWithChildren<FarmTableLiquidityProps>> = ({
  liquidity,
  inactive,
}) => {
  if (inactive) {
    return <Text>-</Text>;
  }

  return <LiquidityComp liquidity={liquidity} />;
};

const LiquidityComp = ({liquidity}: {
  liquidity: FarmTableLiquidityProps["liquidity"];
}) => {
  const displayLiquidity =
    liquidity && liquidity.gt(0) ? (
      `$${Number(liquidity).toLocaleString(undefined, { maximumFractionDigits: 0 })}`
    ) : (
      <Skeleton width={60} />
    );
  return (
    <Container>
      <LiquidityWrapper>
        <Text>{displayLiquidity}</Text>
      </LiquidityWrapper>
    </Container>
  );
};

const Liquidity: React.FunctionComponent<React.PropsWithChildren<FarmTableLiquidityProps>> = ({ liquidity }) => {
  return <LiquidityComp liquidity={liquidity} />;
};

export default Liquidity;
