import { Currency, CurrencyAmount, TradeType } from '@pulsex/sdk'
import { SmartRouterTrade } from '@pulsex/smart-router'
import { ConfirmationModalContent } from '@pulsex/widgets-internal'
import { memo, useCallback, useMemo } from 'react'
import { Field } from 'state/swap/actions'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import SwapModalHeader from './SwapModalHeader'
import {
  TradeEssentialForPriceBreakdown,
  computeSlippageAdjustedAmounts as computeSlippageAdjustedAmountsWithSmartRouter,
  computeTradePriceBreakdown as computeTradePriceBreakdownWithSmartRouter,
} from '../utils/exchange'
import { SwapModalFooter } from './SwapModalFooter'

type Trade = TradeEssentialForPriceBreakdown & Pick<SmartRouterTrade<TradeType>, 'tradeType'>

/**
 * Returns true if the trade requires a confirmation of details before we can submit it
 * @param tradeA trade A
 * @param tradeB trade B
 */
function tradeMeaningfullyDiffers(tradeA: Trade, tradeB: Trade): boolean {
  return (
    tradeA.tradeType !== tradeB.tradeType ||
    !tradeA.inputAmount.currency.equals(tradeB.inputAmount.currency) ||
    !tradeA.inputAmount.equalTo(tradeB.inputAmount) ||
    !tradeA.outputAmount.currency.equals(tradeB.outputAmount.currency) ||
    !tradeA.outputAmount.equalTo(tradeB.outputAmount)
  )
}

interface TransactionConfirmSwapContentProps {
  trade: Trade | undefined | null
  originalTrade: Trade | undefined | null
  onAcceptChanges: () => void
  allowedSlippage: number
  onConfirm: () => void
  recipient?: string | null
  currencyBalances?: {
    INPUT?: CurrencyAmount<Currency>
    OUTPUT?: CurrencyAmount<Currency>
  }
}

export const TransactionConfirmSwapContent = memo<TransactionConfirmSwapContentProps>(
  function TransactionConfirmSwapContentComp({
    trade,
    recipient,
    originalTrade,
    allowedSlippage,
    currencyBalances,
    onConfirm,
    onAcceptChanges,
  }) {
    const showAcceptChanges = useMemo(
      () => Boolean(trade && originalTrade && tradeMeaningfullyDiffers(trade, originalTrade)),
      [originalTrade, trade],
    )

    const slippageAdjustedAmounts = useMemo(
      () => computeSlippageAdjustedAmountsWithSmartRouter(trade, allowedSlippage),
      [trade, allowedSlippage],
    )
    const { priceImpactWithoutFee, lpFeeAmount } = useMemo(
      () => computeTradePriceBreakdownWithSmartRouter(trade),
      [trade],
    )

    const isEnoughInputBalance = useMemo(() => {
      if (trade?.tradeType !== TradeType.EXACT_OUTPUT) return null

      const isInputBalanceExist = !!(currencyBalances && currencyBalances[Field.INPUT])
      const isInputBalancePLS = isInputBalanceExist && currencyBalances[Field.INPUT]?.currency.isNative
      const inputCurrencyAmount = isInputBalanceExist
        ? isInputBalancePLS
          ? maxAmountSpend(currencyBalances[Field.INPUT])
          : currencyBalances[Field.INPUT]
        : null
      return inputCurrencyAmount && slippageAdjustedAmounts && slippageAdjustedAmounts[Field.INPUT]
        ? inputCurrencyAmount.greaterThan(slippageAdjustedAmounts[Field.INPUT]) ||
            inputCurrencyAmount.equalTo(slippageAdjustedAmounts[Field.INPUT])
        : false
    }, [currencyBalances, trade, slippageAdjustedAmounts])

    const modalHeader = useCallback(() => {
      return trade ? (
        <SwapModalHeader
          inputAmount={trade.inputAmount}
          outputAmount={trade.outputAmount}
          currencyBalances={currencyBalances}
          tradeType={trade.tradeType}
          priceImpactWithoutFee={priceImpactWithoutFee ?? undefined}
          slippageAdjustedAmounts={slippageAdjustedAmounts ?? undefined}
          isEnoughInputBalance={isEnoughInputBalance ?? undefined}
          recipient={recipient ?? undefined}
          showAcceptChanges={showAcceptChanges}
          onAcceptChanges={onAcceptChanges}
        />
      ) : null
    }, [
      priceImpactWithoutFee,
      currencyBalances,
      onAcceptChanges,
      recipient,
      showAcceptChanges,
      trade,
      slippageAdjustedAmounts,
      isEnoughInputBalance,
    ])

    const modalBottom = useCallback(() => {
      return trade ? (
        <SwapModalFooter
          trade={trade}
          tradeType={trade.tradeType}
          inputAmount={trade.inputAmount}
          outputAmount={trade.outputAmount}
          currencyBalances={currencyBalances}
          lpFee={lpFeeAmount ?? undefined}
          priceImpact={priceImpactWithoutFee ?? undefined}
          disabledConfirm={showAcceptChanges}
          slippageAdjustedAmounts={slippageAdjustedAmounts ?? undefined}
          isEnoughInputBalance={isEnoughInputBalance ?? undefined}
          onConfirm={onConfirm}
          allowedSlippage={allowedSlippage}
        />
      ) : null
    }, [
      allowedSlippage,
      trade,
      currencyBalances,
      lpFeeAmount,
      showAcceptChanges,
      isEnoughInputBalance,
      slippageAdjustedAmounts,
      priceImpactWithoutFee,
      onConfirm,
    ])

    return <ConfirmationModalContent topContent={modalHeader} bottomContent={modalBottom} />
  },
)
