import { useTranslation } from '@pulsex/localization'
import { Currency, CurrencyAmount, Percent } from '@pulsex/sdk'
import { formatAmount } from '@pulsex/utils/formatFractions'
import { ReactNode, useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { useCurrency } from 'hooks/Tokens'
import { Field } from 'state/swap/actions'
import { useDefaultsFromURLSearch, useSwapState } from 'state/swap/hooks'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import { useCurrencyBalances } from 'state/wallet/hooks'
import { maxAmountSpend } from 'utils/maxAmountSpend'

import { useAccount } from 'wagmi'
import useWarningImport from '../hooks/useWarningImport'
import { FormContainer } from '../components'
import { useIsWrapping } from '../hooks'
import FlipButton from './FlipButton'
import { Recipient } from './Recipient'

interface Props {
  inputAmount?: CurrencyAmount<Currency>
  outputAmount?: CurrencyAmount<Currency>
  tradeLoading?: boolean
  tradeDetails?: ReactNode
  swapCommitButton?: ReactNode
}

export function FormMain({ inputAmount, outputAmount, tradeLoading, tradeDetails, swapCommitButton }: Props) {
  const { address: account } = useAccount()
  const { t } = useTranslation()
  const [search, setSearch] = useSearchParams()
  const warningSwapHandler = useWarningImport()
  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()
  const isWrapping = useIsWrapping()
  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)
  const { onCurrencySelection, onUserInput } = useSwapActionHandlers()
  const [inputBalance] = useCurrencyBalances(account, [inputCurrency, outputCurrency])
  const maxAmountInput = useMemo(() => maxAmountSpend(inputBalance), [inputBalance])
  const loadedUrlParams = useDefaultsFromURLSearch()
  const handleTypeInput = useCallback((value: string) => onUserInput(Field.INPUT, value), [onUserInput])
  const handleTypeOutput = useCallback((value: string) => onUserInput(Field.OUTPUT, value), [onUserInput])

  const handlePercentInput = useCallback(
    (percent: number) => {
      if (maxAmountInput) {
        onUserInput(Field.INPUT, maxAmountInput.multiply(new Percent(percent, 100)).toExact())
      }
    },
    [maxAmountInput, onUserInput],
  )

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact())
    }
  }, [maxAmountInput, onUserInput])

  const handleCurrencySelect = useCallback(
    (
      newCurrency: Currency,
      field: Field,
    ) => {
      onCurrencySelection(field, newCurrency)

      warningSwapHandler(newCurrency)

      search.delete('inputCurrency')
      search.delete('outputCurrency')
      setSearch(search)
    },
    [onCurrencySelection, warningSwapHandler, search, setSearch],
  )
  const handleInputSelect = useCallback(
    (newCurrency: Currency) =>
      handleCurrencySelect(newCurrency, Field.INPUT),
    [handleCurrencySelect],
  )
  const handleOutputSelect = useCallback((newCurrency: Currency) => {
    handleCurrencySelect(newCurrency, Field.OUTPUT)
  }, [handleCurrencySelect])

  const isTypingInput = independentField === Field.INPUT
  const inputValue = useMemo(
    () => typedValue && (isTypingInput ? typedValue : formatAmount(inputAmount) || ''),
    [typedValue, isTypingInput, inputAmount],
  )
  const outputValue = useMemo(
    () => typedValue && (isTypingInput ? formatAmount(outputAmount) || '' : typedValue),
    [typedValue, isTypingInput, outputAmount],
  )
  const inputLoading = typedValue ? !isTypingInput && tradeLoading : false
  const outputLoading = typedValue ? isTypingInput && tradeLoading : false

  return (
    <FormContainer>
      <CurrencyInputPanel
        id="swap-currency-input"
        value={isWrapping ? typedValue : inputValue}
        onUserInput={handleTypeInput}
        onPercentInput={handlePercentInput}
        onMax={handleMaxInput}
        showQuickInputButton
        showMaxButton
        maxAmount={maxAmountInput}
        label={t('You pay')}
        onCurrencySelect={handleInputSelect}
        currency={inputCurrency}
        otherCurrency={outputCurrency}
        showCommonBases
        showUSDPrice
        currencyLoading={!loadedUrlParams}
        inputLoading={!isWrapping && inputLoading}
      />
      <FlipButton />
      <CurrencyInputPanel
        id="swap-currency-output"
        value={isWrapping ? typedValue : outputValue}
        onUserInput={handleTypeOutput}
        showQuickInputButton
        showMaxButton
        label={t('You receive')}
        onCurrencySelect={handleOutputSelect}
        currency={outputCurrency}
        otherCurrency={outputCurrency}
        showCommonBases
        showUSDPrice
        currencyLoading={!loadedUrlParams}
        inputLoading={!isWrapping && outputLoading}
      />
      <Recipient asset={outputCurrency?.symbol}/>
      {tradeDetails}
      {swapCommitButton}
    </FormContainer>
  )
}
