import { ChainId } from '@pulsex/chains'
import { ToastListener } from '@pulsex/uikit'
import React, { useEffect, useState } from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'
import { chains } from 'utils/wagmi'
import { PersistGate } from 'redux-persist/integration/react'
import { ENABLE_STABLESWAP } from 'config'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import useUserAgent from 'hooks/useUserAgent'
import { useInitGlobalWorker } from 'hooks/useWorker'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { usePollBlockNumber } from 'state/block/hooks'
import { persistor } from 'state'
import ListsUpdater from 'state/lists/updater'
import MulticallUpdater from 'state/multicall/updater'
import TransactionUpdater from 'state/transactions/updater'
import { NetworkModal } from 'components/NetworkModal'
import TestnetWarningBanner from "components/TestnetWarningBanner"
import NotFound from 'pages/NotFound'
import Farms from 'pages/Farms'
import PoolFinder from 'pages/PoolFinder'
import Token from 'pages/Info/Tokens/TokenPage'
import TokensOverview from 'pages/Info/Tokens'
import PoolsOverview from 'pages/Info/Pools'
import AddV2Liquidity from 'pages/AddLiquidity/AddV2Liquidity'
import RemoveLiquidity from 'pages/RemoveLiquidity'
import PoolPage from './Info/Pools/PoolPage'
import AddStableLiquidity from './AddLiquidity/AddStableLiquidity'
import RemoveStableLiquidity from './RemoveLiquidity/RemoveStableLiquidity'
import Menu from '../components/Menu'
import Swap from './Swap'
import { SwapFeaturesProvider } from './Swap/SwapFeaturesContext'
import Liquidity from './Pool'
import Burn from './Burn'
import Overview from './Info/Overview'

function GlobalHooks() {
  useInitGlobalWorker()
  usePollBlockNumber()
  useUserAgent()
  useAccountEventListener()
  return null
}

export function Updaters() {
  return (
    <>
      <ListsUpdater />
      {chains.map((chain) => (
        <TransactionUpdater key={`trxUpdater#${chain.id}`} chainId={chain.id} />
      ))}
      <MulticallUpdater />
    </>
  )
}

export default function App() {
  const { chainId } = useActiveChainId()
  const [showWarning, setShowWarning] = useState(false)

  useEffect(() => {
    if (ChainId.PULSECHAIN_TESTNET === chainId) {
      setShowWarning(true)
    } else {
      setShowWarning(false)
    }
  }, [chainId])

  return (
    <>
      <GlobalHooks />
      <PersistGate loading={null} persistor={persistor}>
        <Updaters />
        {/* <Menu> */}
        {showWarning && typeof window !== 'undefined' && <TestnetWarningBanner setShowWarning={setShowWarning} />}
        <Menu />

        <Routes>
          {/* Swap / Homepage */}
          <Route
            path="/"
            element={
              <SwapFeaturesProvider>
                <Swap />
              </SwapFeaturesProvider>}
          />

          {/* Liquidity list */}
          <Route path="/liquidity" element={<Liquidity />} />

          {/* Add liquidity V1/V2 */}
          <Route path="add/:initialProtocol" element={<AddV2Liquidity />}>
            <Route path=":currencyIdA" />
            <Route path=":currencyIdA/:currencyIdB" />
          </Route>

          {/* Add liquidity Stable */}
          {ENABLE_STABLESWAP && (
            <Route path="add/stable" element={<AddStableLiquidity />}>
              <Route path=":poolAddress" />
            </Route>
          )}

          {/* Remove liquidity V1/V2 */}
          <Route path="remove/:currencyIdA/:currencyIdB" element={<RemoveLiquidity />} />

          {/* Remove liquidity Stable */}
          {ENABLE_STABLESWAP && (
            <Route path="remove/stable/:poolAddress" element={<RemoveStableLiquidity />} />
          )}

          {/* Pools finder */}
          <Route path="/find" element={<PoolFinder />} />

          {/* Farms */}
          <Route path="/farms" element={<Farms />} />
          <Route path="/farms/history" element={<Farms />} />

          {/* Burn */}
          <Route path="/burn" element={<Burn />} />

          {/* V1 Info page */}
          <Route
            path="/info"
            element={
              <Overview />
            }
          />
          <Route
            path="/info/tokens"
            element={
              <TokensOverview protocol='v1' />
            }
          />
          <Route
            path="/info/token/:routeAddress"
            element={
              <Token protocol='v1' />
            }
          />
          <Route
            path="/info/pools"
            element={
              <PoolsOverview protocol='v1' />
            }
          />
          <Route
            path="/info/pool/:routeAddress"
            element={
              <PoolPage protocol='v1' />
            }
          />

          {/* V2 Info page */}
          <Route
            path="/info/v2"
            element={
              <Overview />
            }
          />
          <Route
            path="/info/v2/tokens"
            element={
              <TokensOverview protocol='v2' />
            }
          />
          <Route
            path="/info/v2/token/:routeAddress"
            element={
              <Token protocol='v2' />
            }
          />
          <Route
            path="/info/v2/pools"
            element={
              <PoolsOverview protocol='v2' />
            }
          />
          <Route
            path="/info/v2/pool/:routeAddress"
            element={
              <PoolPage protocol='v2' />
            }
          />

          {/* StableSwap Info page */}
          {ENABLE_STABLESWAP && (
            <>
              <Route
                path="/info/stable"
                element={
                  <Overview />
                }
              />
              <Route
                path="/info/stable/tokens"
                element={
                  <TokensOverview protocol='stable' />
                }
              />
              <Route
                path="/info/stable/token/:routeAddress"
                element={
                  <Token protocol='stable' />
                }
              />
              <Route
                path="/info/stable/pools"
                element={
                  <PoolsOverview protocol='stable' />
                }
              />
              <Route
                path="/info/stable/pool/:routeAddress"
                element={
                  <PoolPage protocol='stable' />
                }
              />
            </>
          )}

          <Route path="*" element={<Navigate to="/not-found" replace />} />
          <Route path="/not-found" element={<NotFound />} />
        </Routes>
        <NetworkModal />
        <ToastListener />
      </PersistGate>
    </>
  )
}
