import { useTranslation } from '@pulsex/localization'
import { useModal, RoiCalculatorModal } from '@pulsex/uikit'
import { FarmWidget } from '@pulsex/widgets-internal'
import { BIG_ZERO } from '@pulsex/utils/bigNumber'
import { MouseEvent } from 'react'
import BigNumber from 'bignumber.js'
import { useAccount } from 'wagmi'
import { useFarmUser } from 'state/farms/hooks'

export interface ApyButtonProps {
  variant: 'text' | 'text-and-button'
  pid: number
  lpSymbol: string
  lpTokenPrice?: BigNumber
  lpLabel?: string
  multiplier?: string
  incPrice?: BigNumber
  apr?: number
  displayApr?: string
  lpRewardsApr?: number
  addLiquidityUrl?: string
  hideButton?: boolean
}

const ApyButton: React.FC<React.PropsWithChildren<ApyButtonProps>> = ({
  variant,
  pid,
  lpLabel,
  lpTokenPrice = BIG_ZERO,
  lpSymbol,
  incPrice,
  apr = 0,
  multiplier,
  displayApr,
  addLiquidityUrl,
  hideButton
}) => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { tokenBalance, stakedBalance } = useFarmUser(pid)

  const userBalanceInFarm = stakedBalance.plus(tokenBalance).gt(0)
    ? stakedBalance.plus(tokenBalance) : new BigNumber(0)

  const [onPresentApyModal] = useModal(
    <RoiCalculatorModal
      account={account}
      pid={pid}
      linkLabel={t('Add %symbol%', { symbol: lpLabel })}
      stakingTokenBalance={userBalanceInFarm}
      stakingTokenDecimals={18}
      stakingTokenSymbol={lpSymbol}
      stakingTokenPrice={lpTokenPrice.toNumber()}
      earningTokenPrice={incPrice?.toNumber() ?? 0}
      apr={apr}
      multiplier={multiplier}
      displayApr={displayApr}
      linkHref={addLiquidityUrl}
      isFarm
    />,
    false,
    true,
    `FarmModal${pid}`,
  )

  const handleClickButton = (event: MouseEvent): void => {
    event.stopPropagation()
    onPresentApyModal()
  }

  return (
    <FarmWidget.FarmApyButton
      variant={variant}
      hideButton={hideButton}
      handleClickButton={handleClickButton}
    >
      <>{displayApr}%</>
    </FarmWidget.FarmApyButton>
  )
}

export default ApyButton
