import { useTranslation } from '@pulsex/localization'
import { TradeType } from '@pulsex/sdk'
import { Route, SmartRouterTrade } from '@pulsex/smart-router'
import { Flex, FullscreenIcon, Text, useModalV2 } from '@pulsex/uikit'
import { styled } from 'styled-components'
import { memo } from 'react'

import { RowBetween } from 'components/Layout/Row'
import SwapRoute from './SwapRoute'
import { RouteDisplayEssentials, RouteDisplayModal } from './RouteDisplayModal'

interface Props {
  trade?: SmartRouterTrade<TradeType>
  routes?: RouteDisplayEssentials[]
}
const Container = styled(Flex)`
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.dropdown};
  }
`

export const RoutesBreakdown = memo(function RoutesBreakdown({ routes = [] }: Props) {
  const { t } = useTranslation()
  const routeDisplayModal = useModalV2()

  if (!routes.length) {
    return null
  }

  const count = routes.length

  return (
    <>
      <Container onClick={routeDisplayModal.onOpen}>
        {count > 1 ? (
          <Text color="textSubtleDark" fontSize="14px">{t('%count% steps in the route', { count })}</Text>
        ) : (
          <RouteComp route={routes[0]} />
        )}
        <FullscreenIcon ml="8px" height="16px" color="textSubtleDark" />
      </Container>
      <RouteDisplayModal {...routeDisplayModal} routes={routes} />
    </>
  )
})

interface RouteProps {
  route: Pick<Route, 'path'>
}

function RouteComp({ route }: RouteProps) {
  const { path } = route

  return (
    <RowBetween>
      <SwapRoute path={path} />
    </RowBetween>
  )
}
