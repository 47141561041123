import { useTranslation } from '@pulsex/localization'
import { Currency, CurrencyAmount, PairV1, PairV2 } from '@pulsex/sdk'
import { StableSwapPool } from '@pulsex/stable-swap-sdk'
import { WrappedTokenInfo } from '@pulsex/token-lists'
import {
  Box,
  Button,
  ChevronDownIcon,
  CopyButton,
  Flex,
  Loading,
  Skeleton,
  PercentSelector,
  Text,
  useModal,
} from '@pulsex/uikit'
import { formatNumber } from '@pulsex/utils/formatBalance'
import { formatAmount } from '@pulsex/utils/formatFractions'
import { CurrencyLogo, MultiCurrencyLogo } from '@pulsex/widgets-internal'
import { useCallback, useMemo, useRef } from 'react'
import { styled } from 'styled-components'
import { useAccount } from 'wagmi'
import AddToWalletButton from 'components/AddToWallet/AddToWalletButton'
import CurrencySearchModal from 'components/SearchModal/CurrencySearchModal'
import { useStablecoinPriceAmount } from 'hooks/useStablecoinPrice'
import { useCurrencyBalance } from 'state/wallet/hooks'
import { safeGetAddress } from 'utils'
import NumInput from './NumericalInput'

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.backgroundAlt2};
  padding: 0.5rem;
`
const InputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })`
  padding: 0px;
`
const PercentButton = styled(Button).attrs({ variant: 'text', scale: 'xs' })`
  height: 18px;
  padding: 0px;
`

interface CurrencyInputPanelProps {
  id: string
  value: string
  onUserInput: (value: string) => void
  onPercentInput?: (percent: number) => void
  onMax?: () => void
  showQuickInputButton?: boolean
  showMaxButton: boolean
  maxAmount?: CurrencyAmount<Currency>
  lpPercent?: string
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: PairV1 | PairV2 | StableSwapPool | null
  otherCurrency?: Currency | null
  showCommonBases?: boolean
  disabled?: boolean
  error?: boolean | string
  showUSDPrice?: boolean
  currencyLoading?: boolean
  inputLoading?: boolean
  hideBalanceComp?: boolean
}
export default function CurrencyInputPanel({
  id,
  value,
  onUserInput,
  onPercentInput,
  onMax,
  showQuickInputButton = false,
  maxAmount,
  lpPercent,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  otherCurrency,
  showCommonBases,
  disabled,
  error,
  showUSDPrice,
  currencyLoading,
  inputLoading,
  hideBalanceComp,
}: CurrencyInputPanelProps) {
  const { address: account } = useAccount()

  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { t } = useTranslation()

  const token = pair ? pair.liquidityToken : currency?.isToken ? currency : null
  const tokenAddress = token ? safeGetAddress(token.address) : null

  const valueWithoutCommas = useMemo(() => {
    return value.replace(/[,\s]/g, '')
  }, [value])

  const amountInDollar = useStablecoinPriceAmount(
    showUSDPrice ? currency ?? undefined : undefined,
    valueWithoutCommas !== undefined && Number.isFinite(+valueWithoutCommas) ? +valueWithoutCommas : undefined,
    {
      hideIfPriceImpactTooHigh: true,
      enabled: Boolean(valueWithoutCommas !== undefined && Number.isFinite(+valueWithoutCommas)),
    },
  )

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
    />,
  )

  const onCurrencySelectClick = useCallback(() => {
    if (!disableCurrencySelect) {
      onPresentCurrencyModal()
    }
  }, [onPresentCurrencyModal, disableCurrencySelect])

  const isAtPercentMax = (maxAmount && value === maxAmount.toExact()) || (lpPercent && lpPercent === '100')
  const balance = !hideBalance && !!currency ? formatAmount(selectedCurrencyBalance, 6) : undefined

  // Ref for NumInput
  const numInputRef = useRef<HTMLInputElement>(null)

  // Handle click to focus NumInput
  const handleWrapperClick = (e) => {
    if (numInputRef.current && !e.target.closest('.token-amount-input') && !e.target.closest('.open-currency-select-button')) {
      numInputRef.current.focus()
    }
  }

  return (
    <Box id={id}>
      <Wrapper onClick={handleWrapperClick}>
        <Flex style={{ gap: "6px" }} flexDirection="column" width="100%">
          <InputRow>
            <Text fontSize="14px" color="textSubtleDark">{label}</Text>
            <Flex justifyContent="flex-end" flexDirection="column">
              {account && !hideBalanceComp && (
                <Flex alignItems="center" justifyContent="flex-end">
                  <Text
                    data-dd-action-name="Token balance"
                    color="textSubtleDark"
                    fontSize="12px"
                    ellipsis
                    title={!hideBalance && !!currency ? t('Balance: %balance%', { balance: balance ?? t('Loading') }) : ' -'}
                    style={{ display: 'inline' }}
                  >
                    {!hideBalance && !!currency
                      ? (balance?.replace('.', '')?.length || 0) > 12
                        ? balance
                        : t('Balance: %balance%',
                          { balance: balance && Number.isFinite(+balance) ? Number(balance).toLocaleString('en-EU') : balance ?? t('Loading') }
                        )
                      : ' -'}
                  </Text>
                  {account && currency && !disabled && label !== 'You receive' && (
                    <>
                      {maxAmount?.greaterThan(0) &&
                        showQuickInputButton &&
                        onPercentInput && (
                          <Flex pl="5px" alignItems="center">
                            <PercentSelector
                              setPercent={onPercentInput}
                              target={
                                <PercentButton
                                  data-dd-action-name="Balance percent max"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    onMax?.()
                                  }}
                                  scale="xs"
                                  variant={isAtPercentMax ? 'text' : 'text'}
                                  style={{ textTransform: 'uppercase' }}
                                >
                                  {t('Max')}
                                </PercentButton>
                              }
                            />
                          </Flex>
                        )
                      }
                    </>
                  )}
                </Flex>
              )}
            </Flex>
          </InputRow>

          <InputRow>
            <CurrencySelectButton
              className="open-currency-select-button"
              data-dd-action-name="Select currency"
              selected={!!currency}
              onClick={onCurrencySelectClick}
            >
              <Flex alignItems="center" justifyContent="space-between">
                {pair && !(pair instanceof PairV1) && !(pair instanceof PairV2) ? (
                  <MultiCurrencyLogo currency0={pair.token0} currency1={pair.token1} currency2={pair.token2} size={16} margin />
                ) : pair && (pair instanceof PairV1) || (pair instanceof PairV2) ? (
                  <MultiCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                ) : currency ? (
                  <CurrencyLogo currency={currency} size="26px" style={{ marginRight: '8px' }} />
                ) : currencyLoading ? (
                  <Skeleton width="26px" height="26px" variant="circle" />
                ) : null}
                {currencyLoading ? null : pair && !(pair instanceof PairV1) && !(pair instanceof PairV2) ? (
                  <Text fontSize="12px" id="pair" bold>
                    {pair?.token0.symbol}:{pair?.token1.symbol}{pair?.token2 ? `:${pair?.token2.symbol}` : ''}
                  </Text>
                ) : pair && (pair instanceof PairV1) || (pair instanceof PairV2) ? (
                  <Text id="pair" bold>
                    {pair?.token0.symbol}:{pair?.token1.symbol}
                  </Text>
                ) : (
                  <Text id="pair" fontSize="14px" bold>
                    {(currency && currency.symbol && currency.symbol.length > 10
                      ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                        currency.symbol.length - 5,
                        currency.symbol.length,
                      )}`
                      : currency?.symbol) || t('Select a currency')}
                  </Text>
                )}
                {!currencyLoading && !disableCurrencySelect && <ChevronDownIcon />}

              </Flex>
            </CurrencySelectButton>

            <NumInput
              error={Boolean(error)}
              disabled={disabled}
              className="token-amount-input"
              value={value}
              ref={numInputRef}
              onUserInput={(val) => {
                onUserInput(val)
              }}
            />
          </InputRow>

          <InputRow>
            <Flex flexDirection="column" justifyContent="left">
              {token && tokenAddress ? (
                <Flex alignItems="center" height="16px">
                  <CopyButton
                    data-dd-action-name="Copy token address"
                    width="16px"
                    buttonColor="textSubtle"
                    text={tokenAddress}
                    tooltipMessage={t('Token address copied')}
                  />
                  <AddToWalletButton
                    data-dd-action-name="Add to wallet"
                    variant="text"
                    p="0"
                    height="auto"
                    width="fit-content"
                    tokenAddress={tokenAddress}
                    tokenSymbol={token.symbol}
                    tokenDecimals={token.decimals}
                    tokenLogo={token instanceof WrappedTokenInfo ? token.logoURI : undefined}
                  />
                </Flex>
              ) : null}
            </Flex>

            <Flex flexDirection="column">
              {!!showUSDPrice && (
                <Flex justifyContent="flex-end">
                  <Flex maxWidth="200px">
                    {inputLoading ? (
                      <Loading width="14px" height="14px" />
                    ) : showUSDPrice && Number.isFinite(amountInDollar) ? (
                      <Text fontSize="12px" color="textSubtleDark" ellipsis>
                        {`~$${amountInDollar ? formatNumber(amountInDollar) : 0}`}
                      </Text>
                    ) : (
                      <Box height="18px" />
                    )}
                  </Flex>
                </Flex>
              )}
            </Flex>
          </InputRow>
        </Flex>
      </Wrapper>
    </Box>
  )
}
