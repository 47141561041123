import { AppBody } from 'components/App'
import { SwapForm } from './SwapForm'
import Page from '../Page'

export default function Swap() {

  return (
    <Page>
      <AppBody>
        <SwapForm />
      </AppBody>
    </Page>
  )
}
