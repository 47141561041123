import { useTranslation } from '@pulsex/localization'
import { Currency, CurrencyAmount, Percent, Token } from '@pulsex/sdk'
import { Flex, InjectedModalProps, Text } from '@pulsex/uikit'
import { ConfirmationModalContent, MultiCurrencyLogo } from '@pulsex/widgets-internal'
import { useCallback } from 'react'
import { Field } from 'state/stableswap/actions'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { AutoColumn } from 'components/Layout/Column'
import Row from 'components/Layout/Row'
import ConfirmAddModalBottom from './ConfirmAddModalBottom'

interface ConfirmAddLiquidityModalProps {
  title: string
  customOnDismiss: () => void
  attemptingTxn: boolean
  hash?: string
  pendingText: string
  currencyA?: Currency
  currencyB?: Currency
  currencyC?: Currency
  noLiquidity?: boolean
  allowedSlippage: number
  liquidityErrorMessage?: string
  poolPrices?: {
    token0?: CurrencyAmount<Currency>[];
    token1?: CurrencyAmount<Currency>[];
    token2?: CurrencyAmount<Currency>[];
  }
  parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  onAdd: () => void
  poolTokenPercentage?: Percent
  liquidityMinted?: CurrencyAmount<Token>
  currencyToAdd?: Token | null
}

const ConfirmAddLiquidityModal: React.FC<InjectedModalProps & ConfirmAddLiquidityModalProps> = ({
  title,
  onDismiss,
  customOnDismiss,
  attemptingTxn,
  hash,
  pendingText,
  poolPrices,
  currencyA,
  currencyB,
  currencyC,
  noLiquidity,
  allowedSlippage,
  parsedAmounts,
  liquidityErrorMessage,
  onAdd,
  poolTokenPercentage,
  liquidityMinted,
  currencyToAdd,
}) => {
  const { t } = useTranslation()

  const modalHeader = useCallback(() => {
    return noLiquidity ? (
      <Flex alignItems="center">
        <Text fontSize="28px" marginRight="10px">
          {`${currencyA?.symbol}/${currencyB?.symbol}${currencyC ? `/${currencyC.symbol}` : ''}`}
        </Text>
        <MultiCurrencyLogo
          currency0={currencyA}
          currency1={currencyB}
          currency2={currencyC}
          size={30}
        />
      </Flex>
    ) : (
      <AutoColumn>
        <Flex alignItems="center">
          <Text fontSize="48px" marginRight="25px">
            {liquidityMinted?.toSignificant(6)}
          </Text>
          <MultiCurrencyLogo
            currency0={currencyA}
            currency1={currencyB}
            currency2={currencyC}
            size={35}
          />
        </Flex>
        <Row>
          <Text fontSize="24px">
            {`${currencyA?.symbol}/${currencyB?.symbol}${currencyC ? `/${currencyC.symbol}` : ''} Pool Tokens`}
          </Text>
        </Row>
        <Text small textAlign="left" my="24px">
          {t('Output is estimated. If the price changes by more than %slippage%% your transaction will revert.', {
            slippage: allowedSlippage / 100,
          })}
        </Text>
      </AutoColumn>
    )
  }, [currencyA, currencyB, currencyC, liquidityMinted, allowedSlippage, noLiquidity, t])

  const modalBottom = useCallback(() => {
    return (
      <ConfirmAddModalBottom
        poolPrices={poolPrices}
        currencyA={currencyA}
        currencyB={currencyB}
        currencyC={currencyC}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    )
  }, [currencyA, currencyB, currencyC, noLiquidity, onAdd, parsedAmounts, poolTokenPercentage, poolPrices])

  const confirmationContent = useCallback(
    () => <ConfirmationModalContent topContent={modalHeader} bottomContent={modalBottom} />,
    [modalHeader, modalBottom],
  )

  return (
    <TransactionConfirmationModal
      title={title}
      width={['100%', '100%', '467px', '467px']}
      bodyPadding='30px 20px 20px 20px'
      onDismiss={onDismiss}
      customOnDismiss={customOnDismiss}
      attemptingTxn={attemptingTxn}
      currencyToAdd={currencyToAdd}
      errorMessage={liquidityErrorMessage}
      hash={hash}
      content={confirmationContent}
      pendingText={pendingText}
    />
  )
}

export default ConfirmAddLiquidityModal
