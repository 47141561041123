import React from "react";
import { styled, keyframes } from "styled-components";
import PulseXIcon from "./PulseXIcon";
import { LogoLoaderProps } from "./types";

const pulse = keyframes`
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
  100% {
		transform: scale(1);
	}
`;

const Container = styled.div`
  position: relative;
`;

const FloatingPulseXIcon = styled(PulseXIcon)`
  animation: ${pulse} 3s ease-in-out infinite;
  transform: translate3d(0, 0, 0);
`;

const Spinner: React.FC<React.PropsWithChildren<LogoLoaderProps>> = ({ size = 80 }) => {
  return (
    <Container>
      <FloatingPulseXIcon width={`${size}px`} />
    </Container>
  );
};

export default Spinner;
