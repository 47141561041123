import { styled } from "styled-components";
import { Box } from "../Box";
import Container from "../Layouts/Container";
import { PageHeaderProps } from "./types";

const Outer = styled(Box)<{ background?: string }>`
  min-height: calc(100vh - 119px);
  background: ${({ theme, background }) => background || theme.colors.gradientBubblegum};

  ${({ theme }) => theme.mediaQueries.lg} {
    min-height: calc(100vh - 119px);
  }
`

const Inner = styled(Container)`
  padding-top: 132px;
  padding-bottom: 70px;
  position: relative;
`;

const PageHeader: React.FC<React.PropsWithChildren<PageHeaderProps>> = ({ background, children, ...props }) => (
  <Outer background={background} {...props}>
    <Inner>{children}</Inner>
  </Outer>
);

export default PageHeader;
