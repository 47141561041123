
import { ChainId } from '@pulsex/chains'
import { ERC20Token } from '@pulsex/sdk'
import { plsTokens } from '@pulsex/tokens'
import { SerializedStableSwapPool, PoolType } from '../../types'

export const pools: SerializedStableSwapPool[] = [
  {
    type: PoolType.THREE_POOL,
    lpSymbol: 'DAI-USDC-USDT LP',
    liquidityToken: new ERC20Token(ChainId.PULSECHAIN, '0xC607606C0DC9B084D4e4458449963a975a762093', 18, 'STABLE-LP'),
    token0: plsTokens.usdt, // coins[0] 0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f
    token1: plsTokens.usdc, // coins[1] 0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07
    token2: plsTokens.dai, // coins[2] 0xefD766cCb38EaF1dfd701853BFCe31359239F305
    stableSwapAddress: '0xE3acFA6C40d53C3faf2aa62D0a715C737071511c',
    infoStableSwapAddress: '0xEfcaac7485d08B3839884F7f656f10d53A3bDaEc',
  }
]
