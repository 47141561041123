import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox="0 0 18 18" {...props}>
    <path d="m4.4222 0c-2.5517 0-4.4222 2.4246-4.4222 5.1795v7.641c0 2.7548 1.8705 5.1795 4.4222 5.1795h9.1556c2.5517 0 4.4222-2.4246 4.4222-5.1795v-7.641c0-2.7548-1.8705-5.1795-4.4222-5.1795zm0 2.0037h9.1556c1.2382 0 2.4185 1.3013 2.4185 3.1758v7.641c0 1.8744-1.1804 3.1758-2.4185 3.1758h-9.1556c-1.2382 0-2.4185-1.3013-2.4185-3.1758v-7.641c0-1.8744 1.1804-3.1758 2.4185-3.1758zm9.9676 3.1366a1.0018 1.0018 0 0 0-0.69855 0.31699l-3.6023 3.845-1.988-2.0252a1.0019 1.0019 0 0 0-1.4304 0l-3.8078 3.8802a1.0018 1.0018 0 0 0 0.013697 1.4167 1.0018 1.0018 0 0 0 1.4167-0.0137l3.0936-3.1523 2.0037 2.0428a1.0019 1.0019 0 0 0 1.446-0.01566l4.3166-4.6081a1.0018 1.0018 0 0 0-0.045-1.4167 1.0018 1.0018 0 0 0-0.71812-0.27003z"/>
  </Svg>
);

export default Icon;
