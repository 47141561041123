import { Box, ColumnCenter, Flex, Spinner } from "@pulsex/uikit"

interface ApproveModalContentProps {
  title: React.ReactNode;
}

export const ApproveModalContentV1: React.FC<ApproveModalContentProps> = ({ title }) => {

  return (
    <Box width="100%">
      <Box mb="16px">
        <ColumnCenter>
          <Spinner />
        </ColumnCenter>
      </Box>
      <Flex justifyContent="center" alignItems="center">
        {title}
      </Flex>
    </Box>
  );
};
