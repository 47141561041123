import BigNumber from 'bignumber.js'
import { SLOW_INTERVAL } from 'config/constants/exchange'
import { getFarmConfig } from '@pulsex/farms/constants'
import { useStablecoinPrice } from 'hooks/useStablecoinPrice'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useQuery } from '@tanstack/react-query'
import { getMasterChefContract } from 'utils/contractHelpers'
import { INC } from '@pulsex/tokens'
import { DeserializedFarmsState, DeserializedFarmUserData } from '@pulsex/farms'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAccountActiveChain from 'hooks/useAccountActiveChain'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync } from '.'
import {
  farmSelector,
  makeFarmFromPidSelector,
  makeLpTokenPriceFromLpSymbolSelector,
  makeUserFarmFromPidSelector,
} from './selectors'

export function useFarmsLength() {
  const { chainId } = useActiveChainId()
  return useQuery({
    queryKey: ['farmsLength', chainId],
    queryFn: async () => {
      const mc = getMasterChefContract(undefined, chainId)
      return Number(await mc.read.poolLength())
    },
    enabled: Boolean(chainId),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
}

export const usePollFarmsWithUserData = () => {
  const dispatch = useAppDispatch()
  const { account, chainId } = useAccountActiveChain()

  useQuery({
    queryKey: ['publicFarmData', chainId],
    queryFn: async () => {
      if (!chainId) {
        throw new Error('ChainId is not defined')
      }

      const farmsConfig = await getFarmConfig(chainId)
      
      if (!farmsConfig) {
        throw new Error('Failed to fetch farm config')
      }
      const pids = farmsConfig.map((farmToFetch) => farmToFetch.pid)
      dispatch(fetchFarmsPublicDataAsync({ pids, chainId }))
      return null
    },
    enabled: Boolean(chainId),
    refetchInterval: SLOW_INTERVAL,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  useQuery({
    queryKey: ['farmsWithUserData', account, chainId],
    queryFn: async () => {
      const farmsConfig = await getFarmConfig(chainId)
      
      if (!chainId || !farmsConfig || !account) return
      const pids = farmsConfig.map((farmToFetch) => farmToFetch.pid)
      const params = { account, pids, chainId }
      dispatch(fetchFarmUserDataAsync(params))
    },
    enabled: Boolean(account && chainId),
    refetchInterval: SLOW_INTERVAL,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
}

export const useFarms = (): DeserializedFarmsState => {
  const { chainId } = useActiveChainId()
  return useSelector(useMemo(() => farmSelector(chainId), [chainId]))
}

export const useFarmFromPid = (pid?: number) => {
  const farmFromPid = useMemo(() => makeFarmFromPidSelector(pid), [pid])
  return useSelector(farmFromPid)
}

export const useFarmUser = (pid): DeserializedFarmUserData => {
  const farmFromPidUser = useMemo(() => makeUserFarmFromPidSelector(pid), [pid])
  return useSelector(farmFromPidUser)
}

export const useLpTokenPrice = (symbol: string) => {
  const lpTokenPriceFromLpSymbol = useMemo(() => makeLpTokenPriceFromLpSymbolSelector(symbol), [symbol])
  return useSelector(lpTokenPriceFromLpSymbol)
}

// Return the Incentive token price in USD
export const usePriceINCUsdc = (): BigNumber => {
  const { chainId } = useActiveChainId()
  const incentivePrice = useStablecoinPrice(INC[chainId])?.toFixed()

  const incPriceBusd = useMemo(() => {
    return incentivePrice ? new BigNumber(incentivePrice) : new BigNumber(0)
  }, [incentivePrice])

  return incPriceBusd
}
