import { LanguageProvider } from '@pulsex/localization'
import { dark, ModalProvider, ResetCSS, UIKitProvider } from '@pulsex/uikit'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ThemeProvider as NextThemeProvider } from 'next-themes'
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { WagmiProvider } from 'wagmi'
import { createWagmiConfig } from 'utils/wagmi'
import App from './pages/App'
import store from './state'
import GlobalStyle from './style/Global'

// Create a client
const queryClient = new QueryClient()

const StyledUIKitProvider: React.FC<React.PropsWithChildren> = ({ children, ...props }) => {
  return (
    <UIKitProvider theme={dark} {...props}>
      {children}
    </UIKitProvider>
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HashRouter>
          <WagmiProvider reconnectOnMount config={createWagmiConfig()}>
            <NextThemeProvider forcedTheme='dark'>
              <StyledUIKitProvider>
                <LanguageProvider>
                  <ModalProvider>
                    <ResetCSS />
                    <GlobalStyle />
                    <App />
                  </ModalProvider>
                </LanguageProvider>
              </StyledUIKitProvider>
            </NextThemeProvider>
          </WagmiProvider>
        </HashRouter>
      </QueryClientProvider>
    </Provider>
  </StrictMode>
)
