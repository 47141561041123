import React, { useContext } from "react";
import { MenuContext } from "../../widgets/Menu/context";
import IconComponent from "../Svg/IconComponent";
import StyledMenuItem, { StyledMenuItemContainer } from "./styles";
import { MenuItemProps } from "./types";
import { DropdownMenuItemType } from "../DropdownMenu/types";

const MenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = ({
  children,
  href,
  isActive = false,
  variant = "default",
  statusColor,
  type,
  ...props
}) => {
  const { linkComponent } = useContext(MenuContext);
  const itemLinkProps: any = href ? type === DropdownMenuItemType.EXTERNAL_LINK ? {
      as: linkComponent,
      href,
      target: "_blank"
    } : {
      as: linkComponent,
      href,
    }
    : {
      as: linkComponent,
    };
  return (
    <StyledMenuItemContainer $isActive={isActive} $variant={variant}>
      <StyledMenuItem {...itemLinkProps} $isActive={isActive} $variant={variant} $statusColor={statusColor} {...props}>
        {children}
        {type === DropdownMenuItemType.EXTERNAL_LINK && <IconComponent pl={1} iconName="Logout" />}
      </StyledMenuItem>
    </StyledMenuItemContainer>
  );
};

export default MenuItem;
