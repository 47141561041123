import { Currency } from '@pulsex/sdk'
import { styled } from 'styled-components'
import { CurrencyLogo } from './CurrencyLogo'

const Wrapper = styled.div<{ margin: boolean; sizeraw: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-right: ${({ sizeraw, margin }) => margin && (sizeraw / 3).toString().concat('px')};
`
const LowerLogoWrapper = styled.div`
  z-index: 0;
`
const TopLogoWrapper = styled.div<{ sizeraw: number, overlap?: string }>`
  position: relative;
  margin-left: ${({ sizeraw, overlap }) => overlap ? `-${overlap}` : `-${(sizeraw / 2)}px`};
  z-index: 0;
`;
const TopLogoWrapper2 = styled.div<{ sizeraw: number, overlap?: string }>`
  position: relative;
  margin-left: ${({ sizeraw, overlap }) => overlap ? `-${overlap}` : `-${(sizeraw / 2)}px`};
  z-index: 0;
`;

interface MultiCurrencyLogoProps {
  margin?: boolean
  size?: number
  currency0?: Currency
  currency1?: Currency
  currency2?: Currency
  overlap?: string
}

export function MultiCurrencyLogo({
  currency0,
  currency1,
  currency2,
  size = 20,
  margin = false,
  overlap,
}: MultiCurrencyLogoProps) {
  return (
    <Wrapper sizeraw={size} margin={margin}>
      {currency0 && (
        <LowerLogoWrapper>
          <CurrencyLogo currency={currency0} size={`${size.toString()}px`} />
        </LowerLogoWrapper>
      )}
      {currency1 && (
        <TopLogoWrapper sizeraw={size} overlap={overlap}>
          <CurrencyLogo currency={currency1} size={`${size.toString()}px`} />
        </TopLogoWrapper>
      )}
      {currency2 && (
        <TopLogoWrapper2 sizeraw={size} overlap={overlap}>
          <CurrencyLogo currency={currency2} size={`${size.toString()}px`} />
        </TopLogoWrapper2>
      )}
    </Wrapper>
  )
}
