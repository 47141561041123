import BigNumber from "bignumber.js";
import { useTranslation } from "@pulsex/localization";
import { styled } from "styled-components";
import {
  Flex,
  LinkExternal,
  Skeleton,
  Text,
} from "@pulsex/uikit";

export interface ExpandableSectionProps {
  scanAddressLink?: string;
  infoAddress?: string;
  removed?: boolean;
  totalValueFormatted?: string;
  lpLabel: string;
  addLiquidityUrl: string;
  isCommunity?: boolean;
  auctionHostingEndDate?: string;
  alignLinksToRight?: boolean;
  weight: BigNumber;
}

const Wrapper = styled.div`
  margin-top: 24px;
`;

const StyledLinkExternal = styled(LinkExternal)`
  font-weight: 400;
`;

export const DetailsSection: React.FC<React.PropsWithChildren<ExpandableSectionProps>> = ({
  scanAddressLink,
  infoAddress,
  removed,
  totalValueFormatted,
  addLiquidityUrl,
  alignLinksToRight = true,
  weight,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Flex justifyContent="space-between">
        <Text>{t("Total Liquidity")}:</Text>
        {totalValueFormatted ? <Text>{totalValueFormatted}</Text> : <Skeleton width={75} height={25} />}
      </Flex>
      <Flex justifyContent="space-between">
        <Text>{t("Weight")}:</Text>
        <Text>{`${weight.times(100).toFixed(2)}%`}</Text>
      </Flex>
      {!removed && (
        <Flex mb="2px" justifyContent={alignLinksToRight ? "flex-end" : "flex-start"}>
          <StyledLinkExternal to={addLiquidityUrl}>{t("Add LP")}</StyledLinkExternal>
        </Flex>
      )}
      {infoAddress && (
        <Flex mb="2px" justifyContent={alignLinksToRight ? "flex-end" : "flex-start"}>
          <StyledLinkExternal to={infoAddress}>{t("See Pair Info")}</StyledLinkExternal>
        </Flex>
      )}
      {scanAddressLink && (
        <Flex mb="2px" justifyContent={alignLinksToRight ? "flex-end" : "flex-start"}>
          <StyledLinkExternal to={scanAddressLink}>{t('View Contract')}</StyledLinkExternal>
        </Flex>
      )}
    </Wrapper>
  );
};
