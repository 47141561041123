import BigNumber from 'bignumber.js'
import { SECONDS_PER_YEAR, INC_PER_YEAR } from 'config'
import lpAprs from 'config/constants/lpAprs.json'

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number | null,
  rewardTokenPrice: number | null,
  totalStaked: number | null,
  tokenPerBlock: number | null,
): number | null => {
  if (stakingTokenPrice === null || rewardTokenPrice === null || totalStaked === null || tokenPerBlock === null) {
    return null
  }
  
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(SECONDS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

const BIG_NUMBER_NAN = new BigNumber(NaN)

/**
 * Get farm APR value in %
 * @param chainId
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param incentivePriceUsd INC price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @param farmAddress Farm Address
 * @returns Farm Apr
 */
export const getFarmApr = (
  poolWeight: BigNumber,
  incentivePriceUsd: BigNumber,
  poolLiquidityUsd: BigNumber,
  farmAddress: string,
): { incentiveRewardsApr:number | null; lpRewardsApr: number } => {
  const yearlyIncentiveRewardAllocation = poolWeight ? poolWeight.times(INC_PER_YEAR) : new BigNumber(NaN)
  const incentiveRewardsApr = yearlyIncentiveRewardAllocation
    .times(incentivePriceUsd || BIG_NUMBER_NAN)
    .div(poolLiquidityUsd || BIG_NUMBER_NAN)
    .times(100)
  let incentiveRewardsAprAsNumber: number | null = null
  if (!incentiveRewardsApr.isNaN() && incentiveRewardsApr.isFinite()) {
    incentiveRewardsAprAsNumber = incentiveRewardsApr.toNumber()
  }
  const lpRewardsApr = (lpAprs[farmAddress?.toLowerCase()] || lpAprs[farmAddress]) ?? 0
  return { incentiveRewardsApr: incentiveRewardsAprAsNumber, lpRewardsApr }
}

export default null
