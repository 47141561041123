export const infoTwoStableSwapABI = [
  {
    "inputs": [],
    "name": "FEE_DENOMINATOR",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "N_COINS",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "PRECISION",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_swap",
        "type": "address"
      }
    ],
    "name": "PRECISION_MUL",
    "outputs": [
      {
        "internalType": "uint256[2]",
        "name": "swapPRECISION_MUL",
        "type": "uint256[2]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_swap",
        "type": "address"
      }
    ],
    "name": "RATES",
    "outputs": [
      {
        "internalType": "uint256[2]",
        "name": "swapRATES",
        "type": "uint256[2]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_swap",
        "type": "address"
      },
      {
        "internalType": "uint256[2]",
        "name": "_balances",
        "type": "uint256[2]"
      }
    ],
    "name": "_xp_mem",
    "outputs": [
      {
        "internalType": "uint256[2]",
        "name": "result",
        "type": "uint256[2]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_swap",
        "type": "address"
      }
    ],
    "name": "balances",
    "outputs": [
      {
        "internalType": "uint256[2]",
        "name": "swapBalances",
        "type": "uint256[2]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_swap",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_amount",
        "type": "uint256"
      }
    ],
    "name": "calc_coins_amount",
    "outputs": [
      {
        "internalType": "uint256[2]",
        "name": "",
        "type": "uint256[2]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_swap",
        "type": "address"
      },
      {
        "internalType": "uint256[2]",
        "name": "_balances",
        "type": "uint256[2]"
      },
      {
        "internalType": "uint256",
        "name": "amp",
        "type": "uint256"
      }
    ],
    "name": "get_D_mem",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_swap",
        "type": "address"
      },
      {
        "internalType": "uint256[2]",
        "name": "amounts",
        "type": "uint256[2]"
      }
    ],
    "name": "get_add_liquidity_fee",
    "outputs": [
      {
        "internalType": "uint256[2]",
        "name": "liquidityFee",
        "type": "uint256[2]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_swap",
        "type": "address"
      },
      {
        "internalType": "uint256[2]",
        "name": "amounts",
        "type": "uint256[2]"
      }
    ],
    "name": "get_add_liquidity_mint_amount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_swap",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "i",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "j",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "dy",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "max_dx",
        "type": "uint256"
      }
    ],
    "name": "get_dx",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_swap",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "i",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "j",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "dx",
        "type": "uint256"
      }
    ],
    "name": "get_exchange_fee",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "exFee",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "exAdminFee",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_swap",
        "type": "address"
      },
      {
        "internalType": "uint256[2]",
        "name": "amounts",
        "type": "uint256[2]"
      }
    ],
    "name": "get_remove_liquidity_imbalance_fee",
    "outputs": [
      {
        "internalType": "uint256[2]",
        "name": "liquidityFee",
        "type": "uint256[2]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_swap",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_token_amount",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "i",
        "type": "uint256"
      }
    ],
    "name": "get_remove_liquidity_one_coin_fee",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "adminFee",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_swap",
        "type": "address"
      }
    ],
    "name": "token",
    "outputs": [
      {
        "internalType": "contract IERC20",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
] as const
