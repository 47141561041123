/* eslint-disable no-param-reassign */
import {
  Currency,
  CurrencyAmount,
  isTradeBetter,
  PairV1,
  PairV2,
  Token,
  TradeV1,
  TradeV2,
  TradeType
} from '@pulsex/sdk'
import flatMap from 'lodash/flatMap'
import { useMemo } from 'react'
import { useUserSingleHopOnly } from 'state/user/hooks'
import {
  BASES_TO_CHECK_TRADES_AGAINST,
  CUSTOM_BASES,
  BETTER_TRADE_LESS_HOPS_THRESHOLD,
  ADDITIONAL_BASES,
} from '../config/constants/exchange'
import { PairState, usePairsBothProtocols } from './usePairs'
import { wrappedCurrency } from '../utils/wrappedCurrency'

import { useUnsupportedTokens, useWarningTokens } from './Tokens'
import { useActiveChainId } from './useActiveChainId'

function useAllCommonPairs(protocol: string, currencyA?: Currency, currencyB?: Currency): (PairV1 | PairV2)[] {
  const { chainId } = useActiveChainId()

  const [tokenA, tokenB] = chainId
    ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
    : [undefined, undefined]

  const bases: Token[] = useMemo(() => {
    if (!chainId) return []

    const common = BASES_TO_CHECK_TRADES_AGAINST[chainId] ?? []
    const additionalA = tokenA ? ADDITIONAL_BASES[chainId]?.[tokenA.address] ?? [] : []
    const additionalB = tokenB ? ADDITIONAL_BASES[chainId]?.[tokenB.address] ?? [] : []

    return [...common, ...additionalA, ...additionalB]
  }, [chainId, tokenA, tokenB])

  const basePairs: [Token, Token][] = useMemo(
    () => flatMap(bases, (base): [Token, Token][] => bases.map((otherBase) => [base, otherBase])),
    [bases],
  )

  const allPairCombinations: [Token, Token][] = useMemo(
    () =>
      tokenA && tokenB
        ? [
            // the direct pair
            [tokenA, tokenB],
            // token A against all bases
            ...bases.map((base): [Token, Token] => [tokenA, base]),
            // token B against all bases
            ...bases.map((base): [Token, Token] => [tokenB, base]),
            // each base against all bases
            ...basePairs,
          ]
            .filter((tokens): tokens is [Token, Token] => Boolean(tokens[0] && tokens[1]))
            .filter(([t0, t1]) => t0.address !== t1.address)
            .filter(([tokenA_, tokenB_]) => {
              if (!chainId) return true
              const customBases = CUSTOM_BASES[chainId]

              const customBasesA: Token[] | undefined = customBases?.[tokenA_.address]
              const customBasesB: Token[] | undefined = customBases?.[tokenB_.address]

              if (!customBasesA && !customBasesB) return true

              if (customBasesA && !customBasesA.find((base) => tokenB_.equals(base))) return false
              if (customBasesB && !customBasesB.find((base) => tokenA_.equals(base))) return false

              return true
            })
        : [],
    [tokenA, tokenB, bases, basePairs, chainId],
  )

  const allPairs = usePairsBothProtocols(allPairCombinations, protocol)

  // only pass along valid pairs, non-duplicated pairs
  return useMemo(
    () =>
      Object.values(
        allPairs
          // filter out invalid pairs
          .filter((result): result is [PairState.EXISTS, PairV1 | PairV2] => Boolean(result[0] === PairState.EXISTS && result[1]))
          // filter out duplicated pairs
          .reduce<{ [pairAddress: string]: PairV1 | PairV2 }>((memo, [, curr]) => {
            memo[curr.liquidityToken.address] = memo[curr.liquidityToken.address] ?? curr
            return memo
          }, {}),
      ),
    [allPairs],
  )
}

const MAX_HOPS = 3

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
export function useTradeExactIn(
  protocol: string,
  currencyAmountIn?: CurrencyAmount<Currency>,
  currencyOut?: Currency
): TradeV1<Currency, Currency, TradeType> | TradeV2<Currency, Currency, TradeType> | null {
  const [singleHopOnly] = useUserSingleHopOnly()

  const allowedPairs = useAllCommonPairs(protocol, currencyAmountIn?.currency, currencyOut)
  const V1Pairs = allowedPairs.filter((pair): pair is PairV1 => pair instanceof PairV1)
  const V2Pairs = allowedPairs.filter((pair): pair is PairV2 => pair instanceof PairV2)

  return useMemo(() => {
    if (protocol === 'V1') {
      if (currencyAmountIn && currencyOut && allowedPairs.length > 0) {
        if (singleHopOnly) {
          return (
            TradeV1.bestTradeExactIn(V1Pairs, currencyAmountIn, currencyOut, { maxHops: 1, maxNumResults: 1 })[0] ??
            null
          )
        }
        // search through trades with varying hops, find best trade out of them
        let bestTradeSoFar: TradeV1<Currency, Currency, TradeType> | null = null
        for (let i = 1; i <= MAX_HOPS; i++) {
          const currentTrade: TradeV1<Currency, Currency, TradeType> | null =
            TradeV1.bestTradeExactIn(V1Pairs, currencyAmountIn, currencyOut, { maxHops: i, maxNumResults: 1 })[0] ??
            null
          // if current trade is best yet, save it
          if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
            bestTradeSoFar = currentTrade
          }
        }
        return bestTradeSoFar
      }
    } else if (protocol === 'V2') {
      if (currencyAmountIn && currencyOut && allowedPairs.length > 0) {
        if (singleHopOnly) {
          return (
            TradeV2.bestTradeExactIn(V2Pairs, currencyAmountIn, currencyOut, { maxHops: 1, maxNumResults: 1 })[0] ??
            null
          )
        }
        // search through trades with varying hops, find best trade out of them
        let bestTradeSoFar: TradeV2<Currency, Currency, TradeType> | null = null
        for (let i = 1; i <= MAX_HOPS; i++) {
          const currentTrade: TradeV2<Currency, Currency, TradeType> | null =
            TradeV2.bestTradeExactIn(V2Pairs, currencyAmountIn, currencyOut, { maxHops: i, maxNumResults: 1 })[0] ??
            null
          // if current trade is best yet, save it
          if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
            bestTradeSoFar = currentTrade
          }
        }
        return bestTradeSoFar
      }
    }

    return null
  }, [V1Pairs, V2Pairs, allowedPairs, protocol, currencyAmountIn, currencyOut, singleHopOnly])
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function useTradeExactOut(
  protocol: string,
  currencyIn?: Currency,
  currencyAmountOut?: CurrencyAmount<Currency>
): TradeV1<Currency, Currency, TradeType> | TradeV2<Currency, Currency, TradeType> | null {
  
  const [singleHopOnly] = useUserSingleHopOnly()
  const allowedPairs = useAllCommonPairs(protocol, currencyIn, currencyAmountOut?.currency)
  const V1Pairs = allowedPairs.filter((pair): pair is PairV1 => pair instanceof PairV1)
  const V2Pairs = allowedPairs.filter((pair): pair is PairV2 => pair instanceof PairV2)

  return useMemo(() => {
    if (protocol === 'V1') {
      if (currencyIn && currencyAmountOut && allowedPairs.length > 0) {
        if (singleHopOnly) {
          return (
            TradeV1.bestTradeExactOut(V1Pairs, currencyIn, currencyAmountOut, { maxHops: 1, maxNumResults: 1 })[0] ??
            null
          )
        }
        // search through trades with varying hops, find best trade out of them
        let bestTradeSoFar: TradeV1<Currency, Currency, TradeType> | null = null
        for (let i = 1; i <= MAX_HOPS; i++) {
          const currentTrade =
            TradeV1.bestTradeExactOut(V1Pairs, currencyIn, currencyAmountOut, { maxHops: i, maxNumResults: 1 })[0] ??
            null
          if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
            bestTradeSoFar = currentTrade
          }
        }
        return bestTradeSoFar
      }
    } else if (protocol === 'V2') {
      if (currencyIn && currencyAmountOut && allowedPairs.length > 0) {
        if (singleHopOnly) {
          return (
            TradeV2.bestTradeExactOut(V2Pairs, currencyIn, currencyAmountOut, { maxHops: 1, maxNumResults: 1 })[0] ??
            null
          )
        }
        // search through trades with varying hops, find best trade out of them
        let bestTradeSoFar: TradeV2<Currency, Currency, TradeType> | null = null
        for (let i = 1; i <= MAX_HOPS; i++) {
          const currentTrade =
            TradeV2.bestTradeExactOut(V2Pairs, currencyIn, currencyAmountOut, { maxHops: i, maxNumResults: 1 })[0] ??
            null
          if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
            bestTradeSoFar = currentTrade
          }
        }
        return bestTradeSoFar
      }
    }
    return null
  }, [V1Pairs, V2Pairs, allowedPairs, currencyIn, currencyAmountOut, protocol, singleHopOnly])
}

export function useIsTransactionUnsupported(currencyIn?: Currency | null, currencyOut?: Currency | null): boolean {
  const unsupportedTokens: { [address: string]: Token } = useUnsupportedTokens()
  const { chainId } = useActiveChainId()

  const tokenIn = wrappedCurrency(currencyIn, chainId)
  const tokenOut = wrappedCurrency(currencyOut, chainId)

  // if unsupported list loaded & either token on list, mark as unsupported
  if (unsupportedTokens) {
    if (tokenIn && Object.keys(unsupportedTokens).includes(tokenIn.address)) {
      return true
    }
    if (tokenOut && Object.keys(unsupportedTokens).includes(tokenOut.address)) {
      return true
    }
  }

  return false
}

export function useIsTransactionWarning(currencyIn?: Currency, currencyOut?: Currency): boolean {
  const warningTokens: { [address: string]: Token } = useWarningTokens()
  const { chainId } = useActiveChainId()

  const tokenIn = wrappedCurrency(currencyIn, chainId)
  const tokenOut = wrappedCurrency(currencyOut, chainId)

  if (warningTokens) {
    if (tokenIn && Object.keys(warningTokens).includes(tokenIn.address)) {
      return true
    }
    if (tokenOut && Object.keys(warningTokens).includes(tokenOut.address)) {
      return true
    }
  }

  return false
}
